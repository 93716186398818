import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { User } from 'src/interfaces/user.interface';
import { Observable } from 'rxjs';
import { Order } from 'src/interfaces/order.interface';
import { Select, Store } from '@ngxs/store';
import { SetLoyaltyLocations, UpdateProfileInfo } from '../../../../../store/actions/user.actions';
import { ToastService } from '../../../../../services/toast.service';
import { filter } from 'rxjs/operators';
import { NavigationService } from '@modules/navigation/services';
import { MainSettings } from '../../../../../vendors/directus/interfaces/main-settings.interface';
import { OrderTypeService } from '@modules/cart/services/order-type.service';
import { ModeService } from '../../../../../services/mode.service';
import { DirectusService } from '../../../../../vendors/directus/directus.service';
import { ModalController } from '@ionic/angular';
import { AuthService } from '@modules/auth/services';
import { SubscriptionComponent } from '@common/components';
import { PageService } from '@common/services';
import { MobileService } from '../../../../../services/mobile.service';

@Component({
  selector: 'app-profile-dashboard',
  templateUrl: './profile-dashboard.component.html',
  styleUrls: ['./profile-dashboard.component.scss'],
})
export class ProfileDashboardComponent extends SubscriptionComponent implements OnDestroy, OnInit {
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;
  @Select(state => state.app.showFooter) showFooter$: Observable<boolean>;

  // Display variables for view
  displayOrder: Order;
  displayUser: User;
  isLoading = false;
  orderNowLoading = false;

  showFab = false;

  @ViewChild('passwordInput') passwordInput;
  @ViewChild('birthdayPick') birthdayPick;

  username: string;
  password = null;
  phone: string;
  password2: string;
  firstname: string;
  lastname: string;
  emailOpt = false;
  smsOpt = false;
  pushOpt = false;
  smsDidOpt = false;

  // fishbowlToken: string;
  // memberId: number;

  form = new UntypedFormGroup({
    phone: new UntypedFormControl(),
    firstname: new UntypedFormControl(),
    lastname: new UntypedFormControl(),
    username: new UntypedFormControl(),
    birthday: new UntypedFormControl(''),
    emailOpt: new UntypedFormControl(false),
    smsOpt: new UntypedFormControl(false),
  });

  constructor(
    private toast: ToastService,
    private navigation: NavigationService,
    private store: Store,
    private directus: DirectusService,
    // private navbarService: NavBarService,
    private orderTypeService: OrderTypeService,
    public modeService: ModeService,
    private modal: ModalController,
    public authService: AuthService,
    private pageService: PageService,
    public mobile: MobileService
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new SetLoyaltyLocations());
    this.pageService.initialization('Profile Dashboard', '');
    this.subs.push(
      this.order$.pipe(filter(o => !!o)).subscribe(order => {
        this.displayOrder = order;
      }),
      this.user$.subscribe(user => {
        if (user && !user.isGuest) {
          this.displayUser = user;
          this.form.setValue({
            username: this.displayUser.email,
            phone: this.displayUser.phoneNumber ? this.displayUser.phoneNumber : '',
            firstname: this.displayUser.firstName,
            lastname: this.displayUser.lastName,
            birthday: '',
            emailOpt: false,
            smsOpt: false,
          });
        } else {
          this.navigation.navigateToLocationsPage();
          this.displayUser = null;
        }
      })
    );
  }

  onKey(e, nextElement, goBirth?) {
    if (e.key === 'Enter') {
      if (goBirth) {
        this.birthdayPick.open();
      } else {
        nextElement.setFocus();
      }
    }
  }

  // birthDayChange(nextElement) {
  birthDayChange() {
    // nextElement.setFocus();
  }

  // TODO: Decoupling
  // async togglePush(event) {
  //   if (event.detail.checked) {
  //     this.loyalty.updateDevice(this.memberId, this.fishbowlToken);
  //   }
  // }

  smsChange() {
    if (this.smsOpt) {
      this.smsDidOpt = true;
    }
  }

  saveChangesClicked() {
    this.isLoading = true;
    const newUser = {
      userID: this.displayUser.userID,
      firstName: this.form.value.firstname,
      lastName: this.form.value.lastname,
      phoneNumber: this.form.value.phone,
      email: this.form.value.username,
      emailOptIn: this.emailOpt,
      sMSOptIn: this.smsOpt && this.smsDidOpt ? true : null,
      pushOptIn: this.pushOpt,
      birthday: this.form.value.birthday,
    } as User;
    // TODO: Map additional fields to an array of UserField objects
    const additionalFields = [];
    this.store
      .dispatch(new UpdateProfileInfo(newUser, additionalFields))
      .toPromise()
      .then(() => {
        this.toast.success('Member info updated');
        this.isLoading = false;
      })
      .catch(error => {
        this.toast.danger(error.message);
        this.isLoading = false;
      });
  }

  activatePassword() {
    if (!this.password) {
      this.password = '';
      setTimeout(() => {
        this.passwordInput.setFocus();
      }, 200);
    }
  }

  logout() {
    this.authService.logOut();
  }
}
