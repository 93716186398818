<!--<app-route-modal-frame title="Start Group Order" (clickModalClose)="closePage()">-->
<div [class.bg-light]="mode.mode === 'desktop'" class="h-100">
  <div class="container d-flex justify-content-center align-items-start p-0 h-100">
    <form action="" autocomplete="on" class="start-group-container mt-0 mt-sm-5 mb-0 mb-sm-5">
      <div class="d-flex flex-column w-100">
        <ng-container *ngIf="mode.mode !== 'tableside'">
          <div class="d-flex flex-row gap-2 mb-1">
            <h2 class="header-font fw-bold grow-1 text-center">
              <!--          Group Order Instructions-->
              Start a Group Order
            </h2>
          </div>
        </ng-container>
        <ng-container *ngIf="mode.mode === 'tableside' else defaultGroupOrderCopy">
          <ng-container *ngIf="textFields$ | async as textFields">
            {{ textFields.tableside_group_order_copy }}
          </ng-container>
        </ng-container>

        <ng-template #defaultGroupOrderCopy>
          <p class="text-center">{{groupOrderInstructions}}</p>
        </ng-template>
      </div>

      <hr class="w-full">

      <div class="d-flex h-100 flex-column gap-5 justify-content-between">
        <div class="d-flex flex-column gap-4">
          <div class="d-flex flex-column gap-2">
            <form class="form-group" [formGroup]="deadlineForm">
              <label for="groupOrderDeadline">Group Order Deadline (required)*</label>
              <div class="d-flex flex-row flex-nowrap gap-2 w-100" role="group" aria-label="Group Order Deadline">
                <button
                        *ngFor="let time of availableTimes; let i = index"
                        type="button"
                        class="text-nowrap font-size-body-small flex-grow-1 flex-basis-0"
                        [ngClass]="{
                'de-primary-btn-outline': i !== timeIndex,
                'de-primary-btn': i === timeIndex
            }"
                        (click)="onTimeButtonClick(i)">
                  {{ time.key }}
                </button>
              </div>
            </form>

            <small>
              *Others must add their food by the time of the deadline.
            </small>
          </div>

          <ng-container *ngIf="mode.mode !== 'tableside'">
            <!--      <div class="d-flex flex-column gap-2">-->
            <!--        <form class="form-group" [formGroup]="membersForm">-->
            <!--          <label for="sendInvites">Send Invites Via Email (optional)*</label>-->
            <!--          <textarea type="email" class="form-control" formControlName="members"-->
            <!--                    id="sendInvites" name="sendInvites" rows="3" placeholder="name@example.com"-->
            <!--          ></textarea>-->
            <!--        </form>-->
            <!--        <small>-->
            <!--          *Type as many email addresses as you'd like, using commas between email addresses.-->
            <!--        </small>-->
            <!--      </div>-->

            <form class="form-group" [formGroup]="noteForm">
              <label for="sendNote">Send a Note with Your Invite (optional)</label>
              <textarea type="text" class="form-control" formControlName="note" maxlength="90"
                     id="sendNote" name="sendNote" placeholder="ex. Group order on me!"></textarea>
            </form>
          </ng-container>

          <ng-container *ngIf="startAttempt && deadline.invalid">
            <div class="de-form-error">
              Please select a time
            </div>
          </ng-container>

          <ng-container *ngIf="error">
            <div class="de-form-error">
              {{ error }}
            </div>
          </ng-container>
        </div>

        <div class="d-flex justify-content-center mb-sm-3">
          <div class="w-100 pb-4 pb-sm-0">
            <button type="submit" class="de-primary-btn w-100"
                    (click)="startGroupOrder()" [disabled]="deadline.invalid || loading">
              <ng-container *ngIf="loading; else notLoading">
                <div class="de-btn-loader"></div>
              </ng-container>
              <ng-template #notLoading>
                Start Group Order
              </ng-template>
            </button>
          </div>
        </div>
      </div>

    </form>
  </div>
</div>
<!--</app-route-modal-frame>-->
