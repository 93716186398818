import { Injectable } from '@angular/core';
import { CardTerminalProvider } from '../../providers/card-terminal-provider.interface';
import { Observable, switchMap, map, of, throwError, interval, from } from 'rxjs';
import { StripeApiService } from './stripe-api.service';
import { CardTerminalCartDisplayInfo } from '../../interfaces/card-terminal-cart-display-info.interface';
import { DiscoverResult, ErrorResponse, ISdkManagedPaymentIntent, ISetReaderDisplayRequest, Reader } from '@stripe/terminal-js';
import { KioskConfig } from '../directus/interfaces/kiosk-config.interface';
import { ToastService } from '../../services/toast.service';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class StripeProviderService implements CardTerminalProvider {
  reader: Reader;
  terminalID: string;
  private currentPaymentIntentSecret: string;
  private currentPaymentAmount: number;
  private transactionCancelled = false;

  constructor(
    private api: StripeApiService,
    private toast: ToastService
  ) {
    this.api.initializeSDK().subscribe();
  }

  connectToReader(): Observable<any> {
    if (!this.reader) {
      return throwError(new Error('No reader found'));
    }
    return this.api.connectReader(this.reader);
  }

  processSale(amount: number): Observable<{ id: string; description: string }> {
    if (!this.api.assignedReader) {
      throw new Error('No reader connected');
    }
    return from(this.resetTerminalConnection()).pipe(
      switchMap(() => {
        // Check if the current payment intent is valid and the amount matches
        if (this.currentPaymentIntentSecret && this.currentPaymentAmount === amount) {
          return this.collectAndProcessPayment(this.currentPaymentIntentSecret);
        }

        // Clear the current payment intent secret if the amount has changed
        this.currentPaymentIntentSecret = null;
        this.currentPaymentAmount = amount;
        return this.api.getPaymentStatus().pipe(
          switchMap(status => {
            if (status === 'waiting_for_input') {
              return this.api.cancelCollectPaymentMethod().pipe(
                switchMap(() => {
                  return this.api.createPaymentIntent(amount).pipe(
                    switchMap(res => {
                      this.currentPaymentIntentSecret = res.secret; // Save the new payment intent secret
                      return this.collectAndProcessPayment(res.secret);
                    })
                  );
                }),
                catchError((err: ErrorResponse) => {
                  return this.api.createPaymentIntent(amount).pipe(
                    switchMap(res => {
                      this.currentPaymentIntentSecret = res.secret; // Save the new payment intent secret
                      return this.collectAndProcessPayment(res.secret);
                    })
                  );
                })
              );
            } else {
              return this.api.createPaymentIntent(amount).pipe(
                switchMap(res => {
                  this.currentPaymentIntentSecret = res.secret; // Save the new payment intent secret
                  return this.collectAndProcessPayment(res.secret);
                })
              );
            }
          })
        );
      })
    );
  }

  private collectAndProcessPayment(secret: string): Observable<{ id: string; description: string }> {
    return this.api.collectPaymentMethod(secret).pipe(
      switchMap((collectRes: { paymentIntent: ISdkManagedPaymentIntent }) => {
        if (this.transactionCancelled) {
          // Reset the cancellation flag
          this.transactionCancelled = false;
          return throwError(() => new Error('Transaction cancelled'));
        }
        return this.api.processPayment(collectRes.paymentIntent).pipe(
          switchMap((payRes: { paymentIntent: ISdkManagedPaymentIntent }) => {
            return this.api.capturePaymentIntent(payRes.paymentIntent.id).pipe(
              map(captureRes => {
                this.currentPaymentIntentSecret = null; // Clear the payment intent secret after successful capture
                return {
                  id: captureRes.id,
                  description: captureRes.description,
                };
              })
            );
          })
        );
      }),
      catchError((err: ErrorResponse) => {
        if (err.error?.message?.includes('collectPaymentMethod is already in progress')) {
          return this.cancelTransaction().pipe(
            switchMap(() => {
              return this.collectAndProcessPayment(secret);
            })
          );
        } else {
          return throwError(err);
        }
      })
    );
  }

  cancelTransaction(): Observable<any> {
    this.transactionCancelled = true;
    return this.api.getConnectionStatus().pipe(
      switchMap(status => {
        if (status !== 'connected') {
          return from(this.resetPinPad());
        } else {
          return this.api.cancelCollectPaymentMethod().pipe(
            tap(() => (this.transactionCancelled = false)),
            catchError(err => {
              this.transactionCancelled = false;
              return throwError(err);
            })
          );
        }
      })
    );
  }

  refundTransaction(transactionId: string, amount: number): Observable<any> {
    return this.api.refundTransaction(transactionId);
  }

  setupKioskConfig(config: KioskConfig): void {
    this.api
      .discoverReaders()
      .pipe(
        map((res: DiscoverResult) => {
          this.reader = res.discoveredReaders.find(reader => reader.id === config.device_address);
          if (this.reader) {
            this.terminalID = this.reader.id;
            this.toast.success('Configuration loaded. Please connect reader.');
          } else {
            this.toast.danger('No reader found for the configured device address');
          }
          return;
        })
      )
      .subscribe();
  }

  displayCart(displayInfo: CardTerminalCartDisplayInfo): Observable<void> {
    const display: ISetReaderDisplayRequest = {
      type: 'cart',
      cart: {
        line_items: displayInfo.items,
        tax: displayInfo.tax,
        total: displayInfo.total,
        currency: 'usd',
      },
    };
    return from(this.resetTerminalConnection()).pipe(
      switchMap(() => {
        return this.api.setReaderDisplay(display).pipe(
          map(() => {
            return;
          })
        );
      })
    );
  }

  resetPinPad(): Observable<void> {
    return this.api.getPaymentStatus().pipe(
      switchMap(status => {
        if (status === 'waiting_for_input') {
          return this.cancelTransaction();
        }
        return this.api.clearReaderDisplay().pipe(
          map(() => {
            return;
          })
        );
      })
    );
  }

  async resetTerminalConnection(): Promise<void> {
    const status = await this.api.getConnectionStatus().toPromise();
    if (status !== 'connected') {
      await this.api.initializeSDK().toPromise();
      if (this.api.assignedReader) {
        const readers = await this.api.discoverReaders().toPromise();
        const reader = readers.discoveredReaders.find(r => r.serial_number === this.api.assignedReader.serial_number);
        if (reader) {
          await this.api.connectReader(reader).toPromise();
        }
      }
    } else {
      return;
    }
  }
}
