import { Component, EventEmitter, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PayInStorePage } from '../pay-in-store/pay-in-store.page';
import { MobileService } from 'src/services/mobile.service';

import { ToastService } from 'src/services/toast.service';
import { DineEngineError } from 'src/interfaces/dineengine-error.interface';
import { Select, Store } from '@ngxs/store';
import { RedeemPointsFromScanner } from '../../../../store/actions/user.actions';
import { animate, style, transition, trigger } from '@angular/animations';
import { Observable } from 'rxjs';
import { VendorSetup } from '../../../../interfaces/vendor.interface';
import { User } from '../../../../interfaces/user.interface';
import { Order } from '../../../../interfaces/order.interface';
import { TextField } from '../../../../vendors/directus/interfaces/text-field.interface';

const enterTransition = transition(':enter', [
  style({
    opacity: 0,
  }),
  animate(
    '0.5s ease-in',
    style({
      opacity: 1,
    })
  ),
]);

const leaveTrans = transition(':leave', [
  style({
    opacity: 1,
  }),
  animate(
    '0.5s ease-out',
    style({
      opacity: 0,
    })
  ),
]);

const fadeIn = trigger('fadeIn', [enterTransition]);

const fadeOut = trigger('fadeOut', [leaveTrans]);

@Component({
  selector: 'app-fab',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.scss'],
  animations: [fadeIn, fadeOut],
})
export class FabComponent {
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.app.textField) textFields$: Observable<TextField>;

  @Input() dUser: User;
  @Input() dOrder: Order;
  @Input() scrolling;
  @Input() isCheckin: boolean;

  qrCode = new EventEmitter();
  redeemsCodeSuccessText = 'Code Redeemed';

  constructor(
    private modalController: ModalController,
    public mobile: MobileService,
    // private navbarService: NavBarService,
    private toast: ToastService,
    private store: Store
  ) {
    this.qrCode.subscribe(qr => {
      this.store
        .dispatch(new RedeemPointsFromScanner(qr))
        .toPromise()
        .then(() => {
          this.modalController.dismiss();
          this.toast.showDismissableIonicToast(
            this.redeemsCodeSuccessText,
            'de-ionic-success-toast',
            8000
          );
        })
        .catch(error => {
          if (error instanceof DineEngineError) {
            if (error.message.includes('range')) {
              this.toast.showDismissableIonicToast(
                'Invalid Receipt Number',
                'de-ionic-error-toast',
                8000
              );
            } else {
              this.toast.showDismissableIonicToast(
                error.message,
                'de-ionic-error-toast',
                8000
              );
            }
          } else {
            this.toast.showDismissableIonicToast(
              error.message,
              'de-ionic-error-toast',
              8000
            );
          }
        });
    });
    this.textFields$.subscribe(textFields => {
      if (textFields) {
        this.redeemsCodeSuccessText = textFields.redeem_code_success_text;
      }
    });
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: PayInStorePage,
      componentProps: {
        value: 123,
        displayUser: this.dUser,
        displayOrder: this.dOrder,
        displayLoc: this.dOrder?.location,
        qrEmitter: this.qrCode,
        isCheckin: this.isCheckin,
      },
      cssClass: 'pay-in-store',
      showBackdrop: false,
      mode: 'ios',
    });
    return await modal.present();
  }
}
