<div class="px-3 pt-3 pb-5">
  <div class="d-flex justify-content-end">
    <i class="bi bi-x-circle d-flex pointer" style="font-size: 30px" (click)="activeModal.close('Close click')"></i>
  </div>
  <div class="d-flex flex-row flex-nowrap pt-4 pt-md-0">
    <div class="grow-1 basis-0 d-sm-block d-none"></div>
    <div class="d-flex flex-row flex-wrap grow-3 basis-0">
      <ng-container *ngIf="branding$ | async as branding">
        <div class="w-100 d-flex justify-content-center">
          <img
            [src]="branding.loyalty_club_logo?.data ? branding.loyalty_club_logo.data.full_url : branding.main_logo.data.full_url"
            style="width: auto; height: 100px"
            alt="Loyalty Club Logo" />
        </div>
      </ng-container>

      <ng-container *ngIf="!showSignUp">
        <div class="d-flex flex-row flex-wrap justify-content-center w-100">
          <h1 class="header-font text-center fw-bold font-size-h1 m-0 w-100" id="cy-login-title">Sign In</h1>

          <ng-container *ngIf="vendorSetup$ | async as vendorSetup">
            <ng-container *ngIf="vendorSetup.user_provider === 'spendgo'">
              <div class="text-center font-size-small w-100 pt-2">
                Sign in using your
                <a href="https://spendgo.com" class="de-primary-link" target="_blank">Spendgo</a>
                account email and password
              </div>
            </ng-container>
          </ng-container>

          <div class="d-flex flex-row justify-content-center w-100 pt-3">
            <div class="">Don't have an account?</div>
            <a type="button" tabindex="0" (click)="showSignUp = !showSignUp; errorMessage = ''"
               (keydown.enter)="showSignUp = !showSignUp; errorMessage = ''"
               class="de-primary-link p-0 m-0 ms-2"> Join Now </a>
          </div>
          <hr class="w-100 my-3 border-dark-subtle" />
        </div>
      </ng-container>

      <ng-container *ngIf="showSignUp">
        <div class="d-flex flex-row flex-wrap justify-content-center w-100">
          <h1 class="header-font text-center fw-bold font-size-h1 m-0 w-100">Join Now</h1>
          <ng-container *ngIf="vendorSetup$ | async as vendorSetup">
            <ng-container *ngIf="vendorSetup.user_provider === 'spendgo'">
              <div class="text-center font-size-small m-0 w-100 pt-2">
                Sign up below by creating a
                <a href="https://spendgo.com" class="de-primary-link font-size-small" target="_blank">Spendgo</a>
                account
              </div>
            </ng-container>
          </ng-container>

          <div class="d-flex flex-row flex-nowrap w-100 pt-3 justify-content-center">
            <div class="">Already have an account?</div>
            <a type="button" tabindex="0" (click)="showSignUp = !showSignUp; errorMessage = ''"
               (keydown.enter)="showSignUp = !showSignUp; errorMessage = ''"
               class="de-primary-link p-0 ms-2"> Sign In </a>
          </div>

          <hr class="w-100 my-3 border-dark-subtle" />
        </div>
      </ng-container>

      <ng-container *ngIf="!showSignUp; else signUp">
        <form class="w-100 login-form" [formGroup]="loginForm">
          <div class="w-100 d-flex flex-column">
            <label for="username" class="w-fit-content">Email address*</label>
            <ion-input
              appAutofill
              type="email"
              name="email"
              autocomplete="email"
              class="form-control"
              id="username"
              formControlName="username"
              placeholder="Email Address"
              [class.border-danger]="username.invalid && username.touched"></ion-input>
            <div class="grow-1 d-flex form-error align-items-center">
              <ng-container *ngIf="username.invalid && username.touched">
                <div *ngIf="username.errors.email" class="text-danger grow-1">Invalid email address.
                </div>
                <div *ngIf="username.errors.required" class="text-danger grow-1">Email address required.</div>
              </ng-container>
            </div>
          </div>
          <div class="w-100 d-flex flex-column">
            <label for="password" class="w-fit-content">Password*</label>
            <ion-input
              appAutofill
              aria-label="password"
              type="password"
              class="form-control"
              name="password"
              id="password"
              formControlName="password"
              [class.border-danger]="passwordSignUp.invalid && passwordSignUp.touched"
              autocomplete="current-password"
              placeholder="Password"></ion-input>
            <div class="grow-1 d-flex form-error align-items-center">
              <ng-container *ngIf="passwordSignUp.invalid && passwordSignUp.touched">
                <div *ngIf="passwordSignUp.errors.required" class="text-danger grow-1">Password required.</div>
              </ng-container>
            </div>
            <div class="d-flex justify-content-end grow-1 text-end mb-4">
              <a
                tabindex="0"
                type="button"
                class="de-primary-link font-size-body-small p-0 m-0"
                routerLink="/user/forgot-password"
                (click)="activeModal.close('Close click')"
                (keydown.enter)="activeModal.close('Close click')">
                Forgot Password?
              </a>
            </div>
          </div>
        </form>
      </ng-container>
      <ng-template #signUp>
        <form class="w-100 sign-up-form d-flex flex-column" [formGroup]="signUpForm">
          <div class="d-flex flex-column flex-md-row w-100">
            <div class="grow-1 d-flex flex-column">
              <label for="firstName" class="w-fit-content">First Name*</label>
              <input
                aria-label="first name"
                type="text"
                class="form-control"
                id="firstName"
                autocomplete="given-name"
                formControlName="firstName"
                placeholder="First Name"
                [class.border-danger]="firstName.invalid && firstName.touched" />
              <div class="grow-1 d-flex form-error align-items-center">
                <ng-container *ngIf="firstName.invalid && firstName.touched">
                  <div *ngIf="firstName.errors.pattern" title="Invalid first name"
                       class="text-danger grow-1">Invalid Name.
                  </div>
                  <div *ngIf="firstName.errors.required" title="First name required"
                       class="text-danger grow-1">First name required.
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="grow-1 d-flex flex-column ms-md-2">
              <label for="lastname" class="w-fit-content">Last Name*</label>
              <input
                aria-label="last name"
                type="text"
                class="form-control"
                id="lastName"
                autocomplete="family-name"
                formControlName="lastName"
                placeholder="Last Name"
                [class.border-danger]="lastName.invalid && lastName.touched" />
              <div class="grow-1 d-flex form-error align-items-center">
                <ng-container *ngIf="lastName.invalid && lastName.touched">
                  <div *ngIf="lastName.errors.pattern" title="Invalid last name"
                       class="text-danger grow-1">Invalid name.
                  </div>
                  <div *ngIf="lastName.errors.required" title="Last name required"
                       class="text-danger grow-1">Last name required.
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <!-- birthday -->
          <div class="d-flex flex-column flex-md-row w-100">
            <div class="grow-1 d-flex flex-column">
              <label class="w-fit-content" for="dOB">Birthday*</label>
              <input
                type="text"
                id="dOB"
                name="dOB"
                class="form-control"
                inputmode="numeric"
                mask="00/00/0000"
                formControlName="dOB"
                placeholder="MM/DD/YYYY"
                aria-label="Enter Birthday"
                autocomplete="bday"
                [class.border-danger]="dOB.invalid && dOB.touched" />

              <div class="grow-1 d-flex form-error align-items-center">
                <ng-container *ngIf="dOB.invalid && dOB.touched">
                  <div *ngIf="dOB.errors.required" title="Birthday required"
                       class="text-danger grow-1">Birthday required.
                  </div>
                  <div *ngIf="dOB.errors.minlength" title="Invalid Birthday"
                       class="text-danger grow-1">Invalid birthday.
                  </div>
                </ng-container>
              </div>
              <ion-modal #dateModal class="condensed">
                <ng-template>
                  <ion-datetime
                    (ionChange)="setBirthdayValue($event)"
                    class="w-100"
                    id="datetime"
                    presentation="date"
                    [showDefaultButtons]="true"
                    [max]="earliestBDayISO"></ion-datetime>
                </ng-template>
              </ion-modal>
            </div>

            <ng-container *ngIf="loyaltyLocations$ | async as locations">
              <div class="form-group m-0 d-flex flex-column grow-1 ms-md-2">
                <label for="typeahead-focus" class="w-fit-content">Favorite Location*</label>
                <input
                  placeholder="Search for your favorite location"
                  aria-label="favorite location"
                  id="typeahead-focus"
                  type="text"
                  class="form-control"
                  [class.border-danger]="favoriteLocation.invalid && favoriteLocation.touched"
                  formControlName="favoriteLocation"
                  [ngbTypeahead]="search"
                  [resultFormatter]="formatter"
                  (focus)="focus$.next($any($event).target.value)"
                  (click)="click$.next($any($event).target.value)"
                  [editable]="false"
                  #instance="ngbTypeahead"
                  [inputFormatter]="formatter"
                  [resultTemplate]="rt"
                  container="body" />
                <div class="grow-1 d-flex form-error align-items-center">
                  <ng-container *ngIf="favoriteLocation.invalid && favoriteLocation.touched">
                    <div *ngIf="favoriteLocation.errors.required" title="Favorite location required"
                         class="text-danger grow-1">
                      Favorite location required.
                    </div>
                  </ng-container>
                </div>
                <ng-template #rt let-r="result" let-t="term">
                  <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
                </ng-template>
              </div>
            </ng-container>
          </div>

          <ng-container *ngIf="additionalUserFields$ | async as additionalFields">
            <ng-container *ngIf="additionalFields.length">
              <div class="d-flex flex-column flex-sm-row flex-wrap w-100 custom-field-group">
                <ng-container *ngFor="let field of additionalFields; index as index">
                  <div class="m-0 d-flex flex-column align-content-start custom-user-field">
                    <label [for]="field.providerFieldName" class="w-fit-content"
                    >{{ field.displayName }}
                      <ng-container *ngIf="field.required">*</ng-container>
                    </label
                    >
                    <ng-container *ngIf="field.inputType.toLowerCase() !== 'date'; else dateInput">
                      <div class="input-group">
                        <input
                          class="form-control cms-input-radius"
                          [class.border-danger]="
                            (field.providerFieldName | getFormControlFromParent: additionalFieldsForm).invalid &&
                            (field.providerFieldName | getFormControlFromParent: additionalFieldsForm).touched
                          "
                          [attr.aria-label]="field.displayName"
                          [formControl]="field.providerFieldName | getFormControlFromParent: additionalFieldsForm"
                          [id]="field.providerFieldName"
                          [type]="field.inputType"
                          [max]="field.maxLength ? field.maxLength : null"
                          [maxLength]="field.maxLength ? field.maxLength : null"
                          [min]="field.minLength ? field.minLength : null"
                          [minLength]="field.minLength ? field.minLength : null"
                          [attr.inputmode]="field.inputMode" />
                      </div>
                      <div class="grow-1 d-flex form-error align-items-center">
                        <ng-container
                          *ngIf="
                            (field.providerFieldName | getFormControlFromParent: additionalFieldsForm).invalid &&
                            (field.providerFieldName | getFormControlFromParent: additionalFieldsForm).touched
                          ">
                          <div
                            *ngIf="(field.providerFieldName | getFormControlFromParent: additionalFieldsForm).errors.required"
                            class="text-danger grow-1">
                            {{ field.displayName }} required.
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                    <ng-template #dateInput>
                      <div class="input-group cms-button-radius">
                        <input
                          class="form-control cms-input-radius"
                          [class.border-danger]="
                            (field.providerFieldName | getFormControlFromParent: additionalFieldsForm).invalid &&
                            (field.providerFieldName | getFormControlFromParent: additionalFieldsForm).touched
                          "
                          [attr.aria-label]="field.displayName"
                          [formControl]="field.providerFieldName | getFormControlFromParent: additionalFieldsForm"
                          [id]="field.providerFieldName"
                          type="text"
                          [attr.inputmode]="field.inputMode"
                          mask="00/00/0000"
                          placeholder="MM/DD/YYYY" />
                        <!--                                                <button class="btn btn-outline-secondary" type="button"-->
                        <!--                                                        [id]="field.providerFieldName.concat('button')">-->
                        <!--                                                    <i class="bi bi-calendar3"></i>-->
                        <!--                                                </button>-->
                      </div>
                      <div class="grow-1 d-flex form-error align-items-center">
                        <ng-container
                          *ngIf="
                            (field.providerFieldName | getFormControlFromParent: additionalFieldsForm).invalid &&
                            (field.providerFieldName | getFormControlFromParent: additionalFieldsForm).touched
                          ">
                          <div
                            *ngIf="(field.providerFieldName | getFormControlFromParent: additionalFieldsForm).errors.required"
                            class="text-danger grow-1">
                            {{ field.displayName }} required.
                          </div>
                        </ng-container>
                      </div>
                      <ion-modal [trigger]="field.providerFieldName.concat('button')"
                                 class="condensed">
                        <ng-template>
                          <ion-datetime
                            (ionChange)="setCustomFieldDateValue($event, field)"
                            class="w-100"
                            presentation="date"
                            [showDefaultButtons]="true"></ion-datetime>
                        </ng-template>
                      </ion-modal>
                    </ng-template>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>

          <div class="d-flex flex-column flex-md-row w-100">
            <div class="w-100 d-flex flex-column">
              <label for="username" class="w-fit-content">Email Address*</label>
              <div class="input-group">
                <input
                  aria-label="email address"
                  type="email"
                  class="form-control cms-input-radius"
                  id="emailAddress"
                  formControlName="emailAddress"
                  autocomplete="email"
                  placeholder="Email Address"
                  [class.border-danger]="emailAddress.invalid && emailAddress.touched" />
              </div>
              <ng-container *ngIf="textFields$ | async as textField">
                <ng-container
                  *ngIf="textField.checkout_account_creation_email_opt_in_copy; else emailDefault">
                  <div class="text-start w-100 font-size-small">
                    {{ textField.checkout_account_creation_email_opt_in_copy }}
                  </div>
                </ng-container>
                <ng-template #emailDefault>
                  <div class="text-start w-100 font-size-small">Send me emails about future deals and
                    features.
                  </div>
                </ng-template>
              </ng-container>
              <div class="grow-1 d-flex form-error align-items-center">
                <ng-container *ngIf="emailAddress.invalid && emailAddress.touched">
                  <div *ngIf="emailAddress.errors.email" title="Invalid email address"
                       class="text-danger grow-1">Invalid email address.
                  </div>
                  <div *ngIf="emailAddress.errors.required" title="Email address required"
                       class="text-danger grow-1">Email address required.
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="w-100 d-flex flex-column ms-md-2">
              <label for="phoneNumber" class="w-fit-content">Phone Number*</label>
              <div class="input-group">
                <input
                  aria-label="phone number"
                  type="tel"
                  class="form-control cms-input-radius"
                  id="phoneNumber"
                  autocomplete="tel-national"
                  placeholder="(_ _ _)_ _ _-_ _ _ _"
                  mask="(000)000-0000"
                  formControlName="phoneNumber"
                  [class.border-danger]="phoneNumber.invalid && phoneNumber.touched" />
              </div>
              <ng-container *ngIf="textFields$ | async as textField">
                <ng-container
                  *ngIf="textField.checkout_account_creation_sms_opt_in_copy; else smsDefault">
                  <div class="text-start w-100 font-size-small">
                    {{ textField.checkout_account_creation_sms_opt_in_copy }}
                  </div>
                </ng-container>
                <ng-template #smsDefault>
                  <div class="text-start w-100 font-size-small">SMS data rates may apply.</div>
                </ng-template>
              </ng-container>
              <div class="grow-1 d-flex form-error align-items-center">
                <ng-container *ngIf="phoneNumber.invalid && phoneNumber.touched">
                  <div *ngIf="phoneNumber.errors.pattern" title="Invalid phone number"
                       class="text-danger grow-1">Invalid number.
                  </div>
                  <div *ngIf="phoneNumber.errors.required" title="Phone number required"
                       class="text-danger grow-1">Phone number required.
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="d-flex flex-column flex-md-row w-100">
            <div class="w-100 d-flex flex-column mb-2">
              <label for="password" class="w-fit-content">Password*</label>
              <input
                aria-label="password"
                type="password"
                class="form-control"
                id="passwordSignUp"
                autocomplete="new-password"
                [class.border-danger]="(passwordSignUp.invalid && passwordSignUp.touched) || signUpForm.errors?.mismatch"
                formControlName="password"
                placeholder="Password"
                #d />
              <!-- Validation feedback -->
              <div class="text-start w-100 font-size-xsmall" style="margin-top: 2px">
                <div [ngClass]="{
      'text-success': passwordSignUp.value?.length >= 10 && !passwordSignUp.errors?.minLength && passwordSignUp.dirty,
      'text-danger': (!passwordSignUp.value && passwordSignUp.touched) || (passwordSignUp.value.length < 10 && passwordSignUp.touched) || passwordSignUp.errors?.minLength
    }">
                  Minimum of 10 characters
                </div>
                <div [ngClass]="{
      'text-success': passwordSignUp.value && !passwordSignUp.errors?.uppercase && passwordSignUp.dirty,
      'text-danger': (!passwordSignUp.value && passwordSignUp.touched) || passwordSignUp.errors?.uppercase
    }">
                  At least 1 uppercase letter
                </div>
                <div [ngClass]="{
      'text-success': passwordSignUp.value && !passwordSignUp.errors?.specialChar && passwordSignUp.dirty,
      'text-danger': (!passwordSignUp.value && passwordSignUp.touched) || passwordSignUp.errors?.specialChar
    }">
                  At least 1 special character
                </div>
                <div *ngIf="signUpForm.errors?.mismatch" [ngClass]="{
      'text-success': !signUpForm.errors?.mismatch && passwordSignUp.dirty && passwordConfirm.dirty,
      'text-danger': signUpForm.errors?.mismatch
    }">
                  Passwords must match.
                </div>
              </div>
            </div>

            <div class="w-100 d-flex flex-column ms-md-2 mb-2">
              <label for="passwordConfirm" class="w-fit-content">Confirm Password*</label>
              <input
                aria-label="confirm password"
                type="password"
                class="form-control"
                id="passwordConfirm"
                autocomplete="new-password"
                [class.border-danger]="(passwordConfirm.invalid && passwordConfirm.touched) || signUpForm.errors?.mismatch"
                formControlName="passwordConfirm"
                placeholder="Confirm Password" />
              <!-- Validation feedback -->
              <div class="text-start w-100 font-size-xsmall" style="margin-top: 2px">
<!--                <div [ngClass]="{-->
<!--      'text-success': passwordConfirm.value?.length >= 10 && !passwordConfirm.errors?.minLength && passwordConfirm.dirty,-->
<!--      'text-danger': (!passwordConfirm.value && passwordConfirm.touched) || (passwordConfirm.value.length < 10 && passwordConfirm.touched) || passwordConfirm.errors?.minLength-->
<!--    }">-->
<!--                  Minimum of 10 characters-->
<!--                </div>-->
<!--                <div [ngClass]="{-->
<!--      'text-success': passwordConfirm.value && !passwordConfirm.errors?.uppercase && passwordConfirm.dirty,-->
<!--      'text-danger': (!passwordConfirm.value && passwordConfirm.touched) || passwordConfirm.errors?.uppercase-->
<!--    }">-->
<!--                  At least 1 uppercase letter-->
<!--                </div>-->
<!--                <div [ngClass]="{-->
<!--      'text-success': passwordConfirm.value && !passwordConfirm.errors?.specialChar && passwordConfirm.dirty,-->
<!--      'text-danger': (!passwordConfirm.value && passwordConfirm.touched) || passwordConfirm.errors?.specialChar-->
<!--    }">-->
<!--                  At least 1 special character-->
<!--                </div>-->
                <div [ngClass]="{
      'text-success': !signUpForm.errors?.mismatch && passwordSignUp.dirty && passwordConfirm.dirty && password.value && password2.value,
      'text-danger': signUpForm.errors?.mismatch
    }">
                  Passwords must match.
                </div>
              </div>
            </div>
          </div>

          <form [formGroup]="signUpForm">
            <div class="mb-3">
              <div class="form-check mb-2">
                <input
                  checked
                  aria-label="I would like to receive email updates, promotions, and important information."
                  class="form-check-input"
                  formControlName="emailOptIn"
                  id="modalEmailOptIn"
                  type="checkbox" />
                <label class="opt-in form-check-label pointer font-size-small" for="modalEmailOptIn">
                  <ng-container *ngIf="textFields$ | async as textField">
                    <ng-container
                      *ngIf="textField.checkout_account_creation_email_opt_in_copy; else emailDefault">
                      {{ textField.checkout_account_creation_email_opt_in_copy }}
                    </ng-container>
                    <ng-template #emailDefault> I would like to receive email updates, promotions,
                      and important information.
                    </ng-template>
                  </ng-container>
                  <div class="text-start w-100 font-size-xsmall mt-2">
                    By checking this box, you consent to receive email messages from us. You can
                    unsubscribe at any time by clicking the
                    unsubscribe link in the footer of our emails.
                  </div>
                </label>
              </div>
            </div>

            <div class="mb-3">
              <div class="form-check w-100 mb-2">
                <input
                  checked
                  aria-label="I would like to receive SMS updates, promotions, and important information."
                  class="form-check-input"
                  formControlName="smsOptIn"
                  id="modalSmsOptIn"
                  type="checkbox" />
                <label class="opt-in form-check-label pointer font-size-small" for="modalSmsOptIn">
                  <ng-container *ngIf="textFields$ | async as textField">
                    <ng-container
                      *ngIf="textField.checkout_account_creation_sms_opt_in_copy; else smsDefault">
                      {{ textField.checkout_account_creation_sms_opt_in_copy }}
                    </ng-container>
                    <ng-template #smsDefault> I would like to receive SMS updates, promotions, and
                      important information.
                    </ng-template>
                  </ng-container>
                  <div class="text-start w-100 font-size-xsmall mt-2">
                    By checking this box, you consent to receive SMS messages from us. Message and
                    data rates may apply depending on your
                    mobile carrier plan.
                  </div>
                </label>
              </div>
            </div>
          </form>

          <div class="w-100 d-flex flex-row justify-content-between align-items-center mb-4 font-size-small">
            <!-- terms and conditions -->
            <ng-container *ngIf="vendorSetup$ | async as vendorSetup">
              <div>
                <ng-container *ngIf="vendorSetup.user_provider === 'spendgo'; else genericTerms"
                              class="font-size-small">
                  By creating an account, I agree to the Spendgo
                  <a href="https://www.spendgo.com/terms" target="_blank"
                     class="de-primary-link font-size-small">Terms & Conditions</a>
                  and
                  <a href="https://www.spendgo.com/privacy" target="_blank"
                     class="de-primary-link font-size-small">Privacy Policy</a>.
                </ng-container>
                <ng-template #genericTerms>
                  <div class="font-size-small">
                    By creating an account I agree to the
                    <a href="/terms-and-conditions" target="_blank"
                       class="de-primary-link font-size-small">Terms & Conditions</a>
                    and
                    <a href="/privacy-policy" target="_blank"
                       class="de-primary-link font-size-small">Privacy Policy</a>.
                  </div>
                </ng-template>
              </div>
            </ng-container>
          </div>
        </form>
      </ng-template>
      <div class="alert alert-danger m-0 text-center w-100 mb-3" *ngIf="errorMessage">{{ errorMessage }}</div>

      <div class="d-flex flex-row flex-nowrap gap-3 w-100 mb-3">
        <button class="de-primary-btn w-100" (click)="loginSignUpClick()">
          <ng-container *ngIf="!loginSignUpLoading">
            <ng-container *ngIf="!showSignUp">Sign In</ng-container>
            <ng-container *ngIf="showSignUp">Join Now</ng-container>
          </ng-container>
          <ng-container *ngIf="loginSignUpLoading">
            <div class="de-btn-loader"></div>
          </ng-container>
        </button>
      </div>

      <ng-container *ngIf="!showSignUp">
        <button (click)="facebookClick()" *ngIf="showFacebookLogin" class="de-facebook-btn fb-btn w-100 mb-3"
                style="min-width: 50%">
          <ng-container *ngIf="!facebookLoading">
            <i class="bi bi-facebook font-size-subheading-2 mx-2"></i>
            Sign In with Facebook
          </ng-container>
          <ng-container *ngIf="facebookLoading">
            <div class="de-btn-loader"></div>
          </ng-container>
        </button>

        <ng-container *ngIf="showAppleSignIn && enableAppleSignIn">
          <button (click)="appleClick()" class="de-apple-btn w-100 mb-3" style="min-width: 50%" type="submit">
            <ng-container *ngIf="!appleLoading">
              <div style="display: grid; place-items: center; grid-template-columns: max-content max-content; grid-gap: 8px">
                <i class="bi bi-apple font-size-subheading-2 mx-2"></i>
                <div>Sign in with Apple</div>
              </div>
            </ng-container>
            <ng-container *ngIf="appleLoading">
              <div class="de-btn-loader"></div>
            </ng-container>
          </button>
        </ng-container>
      </ng-container>

      <div class="d-flex flex-column gap-4 w-100">
        <div class="d-flex flex-row flex-nowrap gap-3 w-100 align-items-center">
          <hr class="w-100 or-hr" />
          <div>or</div>
          <hr class="w-100 or-hr" />
        </div>

        <button class="de-primary-btn-outline w-100" (click)="continueAsGuest()">Continue as Guest</button>
      </div>
    </div>
    <div class="grow-1 basis-0 d-sm-block d-none"></div>
  </div>
</div>
