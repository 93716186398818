<ng-container *ngIf="mainSettings$ | async as mainSettings">
  <div [ngClass]="modeService.mode === 'mobile' ? 'dashboard-container-mobile' : modeService.mode === 'desktop' ? 'dashboard-container-desktop' : 'dashboard-container-improved'">
    <ng-container *ngIf="modeService.mode === 'mobile' && mainSettings.mobile_app_layout === 'classic'">
      <app-fab
        *ngIf="authService.vendorSetup?.loyalty_provider !== 'none' && mainSettings.show_loyalty_checkin"
        [dOrder]="displayOrder"
        [dUser]="displayUser"
        [isCheckin]="authService.isCheckin"
      ></app-fab>
      <div class="w-100 p-3 bg-light border-bottom" style="position: sticky; top: 0; z-index: 1000;">
        <app-order-now-button
        ></app-order-now-button>
      </div>
    </ng-container>
    <ng-container *ngIf="modeService.mode === 'desktop'">
      <app-loyalty-header
      ></app-loyalty-header>
    </ng-container>
    <div class="w-100" [ngStyle]="mobile.isMobile ? {'overflow-x': 'hidden'} : null">
      <router-outlet></router-outlet>
    </div>
    <ng-container *ngIf="modeService.mode === 'desktop'">
      <app-footer *ngIf="showFooter$ | async"></app-footer>
    </ng-container>
  </div>
</ng-container>


