import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TranCloudApiService } from './trancloud-api.service';
import { TranCloudTransactionRequest } from '../models/tran-cloud-request.interface';
import { delay, map, switchMap, take, tap } from 'rxjs/operators';
import { PinPadDeviceService } from './pin-pad-device.service';
import { TranCloudAdmin } from '../models/admin/tran-cloud-admin.interface';
import { ReturnByRecordNo } from '../models/transactions/emv/return-by-record-no.interface';
import { ToastService } from '../../../services/toast.service';
import { CardTerminalProvider } from '../../../providers/card-terminal-provider.interface';
import { KioskConfig } from '../../directus/interfaces/kiosk-config.interface';
import { DirectusClientService } from '../../directus/directus-client.service';
import { DirectusService } from '../../directus/directus.service';
import { CardTerminalCartDisplayInfo } from '../../../interfaces/card-terminal-cart-display-info.interface';

@Injectable({
  providedIn: 'root',
})
export class TrancloudProviderService implements CardTerminalProvider {
  merchantID = 'CHEPRWORT0GP';
  tranDeviceID = 'PT7292160939';
  terminalID;
  private merchantIDKey = 'merchantID';
  private tranDeviceIDKey = 'tranDeviceID';
  private posPackageID = 'EMVUSClient:1.65';
  private sequenceNo = '0010010010';
  private invoiceNo = '001';

  private debug = window.location.href.includes('uxdev');
  private testMode = JSON.parse(localStorage.getItem('testModeSet'));
  private trancloudConfig;

  constructor(
    // @Inject('config') private config: BrandConfig,
    private tranCloudAPI: TranCloudApiService,
    private pinPadDevice: PinPadDeviceService,
    private toast: ToastService,
    private directus: DirectusService
  ) {
    const storedMerchantID = this.getStoredMerchantID();
    if (storedMerchantID) {
      this.merchantID = storedMerchantID;
    }
    const storedTranDeviceID = this.getStoredTranDeviceID();
    if (storedTranDeviceID) {
      this.tranDeviceID = storedTranDeviceID;
    }
    this.directus.getTrancloudConfig().subscribe(trancloudConfig => {
      this.trancloudConfig = trancloudConfig;
    });
  }

  setupKioskConfig(config: KioskConfig): void {
    this.pinPadDevice.updateStoredPinPadAddressType(config.mac_or_ip ? 'IP' : 'MAC');
    this.pinPadDevice.updateStoredPinPadMACAddress(config.device_address);
    this.pinPadDevice.updateStoredPinPadProvider(config.pin_pad_provider);
    this.pinPadDevice.updateStoredPinPadType(config.pin_pad_type);
    this.terminalID = this.pinPadDevice.pinPadMACAddress;
    const locationID = config.location_id.toString();
    const merchantID = this.trancloudConfig.location_ecomm_mids[locationID];
    const trancloudID = this.trancloudConfig.location_ecomm_tids[locationID];
    this.setupTrancloudConfig(merchantID, trancloudID);
    this.initializePad().subscribe(() => {});
  }

  setupTrancloudConfig(merchantID: any, tranDeviceID: any) {
    this.updateStoredMerchantID(merchantID);
    this.updateStoredTranDeviceID(tranDeviceID);
  }

  initializePad(): Observable<any> {
    console.log(this.debug && !this.testMode);
    if (this.debug && !this.testMode) {
      return this.setTestMode().pipe(
        take(1),
        switchMap((testModeRes: any) => {
          this.sequenceNo = testModeRes.RStream.SequenceNo;
          return this.resetPad().pipe(
            delay(1600),
            tap(resetRes => {
              this.sequenceNo = resetRes.RStream.SequenceNo;
            })
          );
        })
      );
    } else {
      return this.resetPad().pipe(
        delay(1600),
        tap(resetRes => {
          this.sequenceNo = resetRes.RStream.SequenceNo;
        })
      );
    }
  }

  creditCardSale(purchaseAmount: string): Observable<any> {
    let newDelimitedAddress = '';
    if (this.pinPadDevice.pinPadAddressType === 'MAC') {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    } else {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    }
    const purchaseAmt = (Math.round(Number(purchaseAmount) * 100) / 100).toFixed(2);
    return this.resetPad().pipe(
      delay(1600),
      switchMap(() => {
        const transRequest: TranCloudTransactionRequest = {
          TStream: {
            Transaction: {
              MerchantID: this.merchantID,
              TerminalID: this.pinPadDevice.terminalID,
              POSPackageID: this.posPackageID,
              TranCode: 'EMVSale',
              CardType: 'Credit',
              SecureDevice: this.pinPadDevice.pinPadType + '_' + this.pinPadDevice.pinPadProvider,
              SequenceNo: this.sequenceNo,
              TranDeviceID: this.tranDeviceID,
              InvoiceNo: this.invoiceNo,
              RefNo: this.invoiceNo,
              RecordNo: 'RecordNumberRequested',
              Frequency: 'OneTime',
              Amount: {
                Purchase: purchaseAmt.toString(),
              },
              // PartialAuth: 'Disallow', // this may be different per device and might need to be in the config
              OKAmount: 'Disallow',
              PinPadMACAddress: this.pinPadDevice.pinPadAddressType === 'MAC' ? newDelimitedAddress : '',
              PinPadIpAddress: this.pinPadDevice.pinPadAddressType === 'IP' ? newDelimitedAddress : '',
              PinPadIpPort: '12000',
            },
          },
        };

        this.invoiceNo += 1;

        return this.tranCloudAPI.processEMVTransaction(transRequest).pipe(
          tap((transactionRes: any) => {
            this.sequenceNo = transactionRes.RStream.SequenceNo;
            this.resetPad().subscribe(() => {});
          })
        );
      })
    );
  }

  creditCardRefund(invoiceNo: string, authCode: string, purchaseAmount: number): Observable<any> {
    let newDelimitedAddress = '';
    if (this.pinPadDevice.pinPadAddressType === 'MAC') {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    } else {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    }
    const purchaseAmt = (Math.round(Number(purchaseAmount) * 100) / 100).toFixed(2);
    return this.resetPad().pipe(
      delay(1600),
      switchMap(() => {
        const transRequest: TranCloudTransactionRequest = {
          TStream: {
            Transaction: {
              MerchantID: this.merchantID,
              TerminalID: this.pinPadDevice.terminalID,
              POSPackageID: this.posPackageID,
              TranCode: 'EMVVoidSale',
              SecureDevice: this.pinPadDevice.pinPadType + '_' + this.pinPadDevice.pinPadProvider,
              InvoiceNo: invoiceNo,
              RefNo: invoiceNo,
              AuthCode: authCode,
              Amount: {
                Purchase: purchaseAmt.toString(),
              },
              SequenceNo: this.sequenceNo,
              TranDeviceID: this.tranDeviceID,
              PinPadMACAddress: this.pinPadDevice.pinPadAddressType === 'MAC' ? newDelimitedAddress : '',
              PinPadIpAddress: this.pinPadDevice.pinPadAddressType === 'IP' ? newDelimitedAddress : '',
              PinPadIpPort: '12000',
            },
          },
        };

        return this.tranCloudAPI.processEMVTransaction(transRequest).pipe(
          tap((transactionRes: any) => {
            this.sequenceNo = transactionRes.RStream.SequenceNo;
            this.resetPad().subscribe(() => {});
          })
        );
      })
    );
  }

  giftCardSale(): Observable<any> {
    let newDelimitedAddress = '';
    if (this.pinPadDevice.pinPadAddressType === 'MAC') {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    } else {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    }
    return this.resetPad().pipe(
      delay(1600),
      switchMap(() => {
        const transRequest: TranCloudTransactionRequest = {
          TStream: {
            Transaction: {
              MerchantID: this.merchantID,
              TerminalID: this.pinPadDevice.terminalID,
              POSPackageID: this.posPackageID,
              TranCode: 'GetPrePaidStripe',
              SecureDevice: this.pinPadDevice.pinPadType + '_' + this.pinPadDevice.pinPadProvider,
              MinLen: '1',
              MaxLen: '20',
              PrePaidStripeTimeout: '30',
              SequenceNo: this.sequenceNo,
              TranDeviceID: this.tranDeviceID,
              PinPadMACAddress: this.pinPadDevice.pinPadAddressType === 'MAC' ? newDelimitedAddress : '',
              PinPadIpAddress: this.pinPadDevice.pinPadAddressType === 'IP' ? newDelimitedAddress : '',
              PinPadIpPort: '12000',
            },
          },
        };

        return this.tranCloudAPI.processEMVTransaction(transRequest).pipe(
          tap((transactionRes: any) => {
            this.sequenceNo = transactionRes.RStream.SequenceNo;
            this.resetPad().subscribe(() => {});
          })
        );
      })
    );
  }

  refundByRecordNo(invoiceNo: string, refNo: string, amount: number, recordNo: string) {
    let newDelimitedAddress = '';
    if (this.pinPadDevice.pinPadAddressType === 'MAC') {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    } else {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    }
    return this.resetPad().pipe(
      delay(1600),
      switchMap(() => {
        const transBody: ReturnByRecordNo = {
          MerchantID: this.merchantID,
          TerminalID: this.pinPadDevice.terminalID,
          POSPackageID: this.posPackageID,
          TranType: 'Credit',
          TranCode: 'ReturnByRecordNo',
          SecureDevice: this.pinPadDevice.pinPadType + '_' + this.pinPadDevice.pinPadProvider,
          InvoiceNo: invoiceNo,
          RefNo: refNo,
          Amount: {
            Purchase: amount.toFixed(2),
          },
          SequenceNo: this.sequenceNo,
          RecordNo: recordNo,
          Frequency: 'OneTime',
          TranDeviceID: this.tranDeviceID,
          PinPadMACAddress: this.pinPadDevice.pinPadAddressType === 'MAC' ? newDelimitedAddress : '',
          PinPadIpAddress: this.pinPadDevice.pinPadAddressType === 'IP' ? newDelimitedAddress : '',
          PinPadIpPort: '12000',
        };
        return this.tranCloudAPI.processEMVTransaction({ TStream: { Transaction: transBody } }).pipe(
          tap((transactionRes: any) => {
            this.sequenceNo = transactionRes.RStream.SequenceNo;
            this.resetPad().subscribe(() => {});
          })
        );
      })
    );
  }

  getServerVersion(): Observable<any> {
    let newDelimitedAddress = '';
    if (this.pinPadDevice.pinPadAddressType === 'MAC') {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    } else {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    }
    return this.resetPad().pipe(
      delay(1600),
      switchMap(() => {
        const transRequest: TranCloudTransactionRequest = {
          TStream: {
            Transaction: {
              MerchantID: this.merchantID,
              TerminalID: this.pinPadDevice.terminalID,
              POSPackageID: this.posPackageID,
              TranCode: 'ServerVersion',
              SecureDevice: this.pinPadDevice.pinPadType + '_' + this.pinPadDevice.pinPadProvider,
              SequenceNo: this.sequenceNo,
              TranDeviceID: this.tranDeviceID,
              PinPadMACAddress: this.pinPadDevice.pinPadAddressType === 'MAC' ? newDelimitedAddress : '',
              PinPadIpAddress: this.pinPadDevice.pinPadAddressType === 'IP' ? newDelimitedAddress : '',
              PinPadIpPort: '12000',
            },
          },
        };

        return this.tranCloudAPI.processEMVTransaction(transRequest).pipe(
          tap((transactionRes: any) => {
            this.sequenceNo = transactionRes.RStream.SequenceNo;
          })
        );
      })
    );
  }

  downloadParams(): Observable<any> {
    const transRequest: TranCloudTransactionRequest = {
      TStream: {
        Admin: {
          MerchantID: this.merchantID,
          TerminalID: this.pinPadDevice.terminalID,
          POSPackageID: this.posPackageID,
          TranCode: 'EMVParamDownload',
          SecureDevice: this.pinPadDevice.pinPadType + '_' + this.pinPadDevice.pinPadProvider,
          SequenceNo: this.sequenceNo,
          TranDeviceID: this.tranDeviceID,
          PinPadMACAddress: this.pinPadDevice.pinPadAddressType === 'MAC' ? this.pinPadDevice.pinPadMACAddress : '',
          PinPadIpAddress: this.pinPadDevice.pinPadAddressType === 'IP' ? this.pinPadDevice.pinPadMACAddress : '',
          PinPadIpPort: '12000',
        },
      },
    };
    return this.tranCloudAPI.processEMVTransaction(transRequest).pipe(
      tap((transactionRes: any) => {
        this.sequenceNo = transactionRes.RStream.SequenceNo;
      })
    );
  }

  resetPad(): Observable<any> {
    let newDelimitedAddress: string;
    if (this.pinPadDevice.pinPadAddressType === 'MAC') {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    } else {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    }
    const transRequest: TranCloudTransactionRequest = {
      TStream: {
        Transaction: {
          MerchantID: this.merchantID,
          TerminalID: this.pinPadDevice.terminalID,
          POSPackageID: this.posPackageID,
          TranCode: 'EMVPadReset',
          SecureDevice: this.pinPadDevice.pinPadType + '_' + this.pinPadDevice.pinPadProvider,
          SequenceNo: this.sequenceNo,
          TranDeviceID: this.tranDeviceID,
          PinPadMACAddress: this.pinPadDevice.pinPadAddressType === 'MAC' ? newDelimitedAddress : '',
          PinPadIpAddress: this.pinPadDevice.pinPadAddressType === 'IP' ? newDelimitedAddress : '',
          PinPadIpPort: '12000',
        },
      },
    };

    return this.tranCloudAPI.processEMVTransaction(transRequest).pipe(
      tap((transactionRes: any) => {
        this.sequenceNo = transactionRes.RStream.SequenceNo;
      })
    );
  }

  setTestMode(): Observable<any> {
    let newDelimitedAddress: string;
    if (this.pinPadDevice.pinPadAddressType === 'MAC') {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    } else {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    }
    const transRequest: TranCloudAdmin = {
      Admin: {
        MerchantID: this.merchantID,
        TerminalID: this.pinPadDevice.terminalID,
        POSPackageID: this.posPackageID,
        TranCode: 'TestMode',
        SecureDevice: this.pinPadDevice.pinPadType + '_' + this.pinPadDevice.pinPadProvider,
        SequenceNo: this.sequenceNo,
        TranDeviceID: this.tranDeviceID,
        PinPadMACAddress: this.pinPadDevice.pinPadAddressType === 'MAC' ? newDelimitedAddress : '',
        PinPadIpAddress: this.pinPadDevice.pinPadAddressType === 'IP' ? newDelimitedAddress : '',
        PinPadIpPort: '12000',
      },
    };

    const request: TranCloudTransactionRequest = {
      TStream: transRequest,
    };

    return this.tranCloudAPI.processEMVTransaction(request).pipe(
      tap((transactionRes: any) => {
        console.log(transactionRes);
        this.sequenceNo = transactionRes.RStream.SequenceNo;
        this.testMode = transactionRes.RStream.CmdStatus !== 'Error';
        localStorage.setItem('testModeSet', JSON.stringify(this.testMode));
      })
    );
  }

  cancelTransaction(): Observable<any> {
    let newDelimitedAddress: string;
    if (this.pinPadDevice.pinPadAddressType === 'MAC') {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    } else {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    }
    const transRequest: TranCloudAdmin = {
      Admin: {
        MerchantID: this.merchantID,
        TerminalID: this.pinPadDevice.terminalID,
        POSPackageID: this.posPackageID,
        // OperatorID: '?',
        // UserTrace: '?',
        TranCode: 'TransactionCancel',
        SecureDevice: this.pinPadDevice.pinPadType + '_' + this.pinPadDevice.pinPadProvider,
        SequenceNo: this.sequenceNo,
        TranDeviceID: this.tranDeviceID,
        PinPadMACAddress: this.pinPadDevice.pinPadAddressType === 'MAC' ? newDelimitedAddress : '',
        PinPadIpAddress: this.pinPadDevice.pinPadAddressType === 'IP' ? newDelimitedAddress : '',
        PinPadIpPort: '12000',
      },
    };

    const request: TranCloudTransactionRequest = {
      TStream: transRequest,
    };

    return this.tranCloudAPI.processEMVTransaction(request).pipe(
      tap((transactionRes: any) => {
        console.log(transactionRes);
        this.sequenceNo = transactionRes.RStream.SequenceNo;
        this.testMode = transactionRes.RStream.CmdStatus !== 'Error';
        localStorage.setItem('testModeSet', JSON.stringify(this.testMode));
      })
    );
  }

  syncPinPad() {
    return this.downloadParams().pipe(
      tap((res: any) => {
        if (res.RStream.DSIXReturnCode === '003002') {
          this.toast.danger('Pin pad busy, please try running a run a reset on the pin pad.', 5000);
        } else if (res.RStream.DSIXReturnCode === '003326') {
          this.toast.danger(
            'Invalid Secure Device Type set at Provider. Please contact Datacap and ask them to set the the Secure Device Type to "Provided By POS".',
            5000
          );
        } else if (res.RStream.DSIXReturnCode === '003328') {
          // tslint:disable-next-line:max-line-length
          this.toast.danger(
            'Datacap Configuration not found or incorrect. Please contact Datacap to verify this devices configuration.',
            5000
          );
        } else if (res.RStream.DSIXReturnCode === '003327') {
          this.toast.danger(
            'Failed on ethernet access. Please verify that your Trancloud and pin pad device are on the network and the network is allowing communication on the correct ports.',
            5000
          );
        } else if (res.RStream.CmdStatus === 'Error') {
          this.toast.danger('Issue downloading configuration, please check that the configuration set in the CMS is correct.', 5000);
        } else if (res.RStream.CmdStatus === 'Success') {
          this.toast.success('Pin Pad Configured.', 5000);
        }
      })
    );
  }

  updateStoredMerchantID(merchantID: string) {
    this.merchantID = merchantID;
    localStorage.setItem(this.merchantIDKey, this.merchantID);
  }

  updateStoredTranDeviceID(tranDeviceID: string) {
    this.tranDeviceID = tranDeviceID;
    localStorage.setItem(this.tranDeviceIDKey, this.tranDeviceID);
  }

  getStoredMerchantID(): string {
    return localStorage.getItem(this.merchantIDKey);
  }

  getStoredTranDeviceID(): string {
    return localStorage.getItem(this.tranDeviceIDKey);
  }

  clearStoredMerchantID() {
    localStorage.removeItem(this.merchantIDKey);
  }

  clearStoredTranDeviceID() {
    localStorage.removeItem(this.tranDeviceIDKey);
  }

  connectToReader(): Observable<any> {
    return this.initializePad();
  }

  processSale(amount: number): Observable<{ id: string; description: string }> {
    return this.creditCardSale((amount / 100).toString()).pipe(
      map(res => {
        return {
          id: res.RStream.RefNo,
          description: res.RStream.ApplicationLabel || res.RStream.EntryMethod,
        };
      })
    );
  }

  refundTransaction(transactionId: string, amount: number): Observable<any> {
    return undefined;
  }

  displayCart(displayInfo: CardTerminalCartDisplayInfo): Observable<void> {
    return of();
  }

  resetPinPad(): Observable<void> {
    return this.resetPad().pipe(map(() => {}));
  }

  // chunkMAC(str: string): string[] {
  //   let ret = [];
  //   let i;
  //   let len;
  //
  //   for (i = 0, len = str.length; i < len; i += 2) {
  //     ret.push(str.substr(i, 2));
  //   }
  //
  //   return ret;
  // }

  // chunkIP(str: string) {
  //   let ret = [];
  //   let i;
  //   let len;
  //
  //   for (i = 0, len = str.length; i < len; i += 3) {
  //     ret.push(str.substr(i, 3));
  //   }
  //
  //   return ret;
  // }
}
