import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, distinctUntilChanged, distinctUntilKeyChanged, filter, map, switchMap } from 'rxjs/operators';
import { OloAPIService } from './olo-api.service';
import { OLOMappingService } from './olo-mapping.service';
import { OrderHistoryProvider } from 'src/providers/order-history-provider.interface';
import { OrderProvider } from 'src/providers/order-provider.interface';
import { MenuProvider } from 'src/providers/menu-provider.interface';
import { LocationProvider } from 'src/providers/location-provider.interface';
import { ImageContentService } from '../directus/content.service';
import { HandoffType } from 'src/interfaces/handoff-type.enum';
import { CardDetails } from 'src/interfaces/card-details.interface';
import { OrderItem, Product } from 'src/interfaces/product.interface';
import { Menu } from 'src/interfaces/menu.interface';
import { User } from 'src/interfaces/user.interface';
import { CreateUserRequest } from './interfaces/create-user-request.interface';
import { Order } from 'src/interfaces/order.interface';
import { Location } from 'src/interfaces/location.interface';
import { Address } from 'src/interfaces/address.interface';
import { Address as OloAddress } from './interfaces/address.interface';
import { AddProductRequest } from './interfaces/add-product-request.interface';
import { Order as OloOrder } from './interfaces/recent-orders-response.interface';
import {
  SubmitOrderRequestWithCreditCardAsGuest,
  SubmitOrderRequestWithCreditCardAsUser,
} from './interfaces/submit-order-request-with-credit-card.interface';
import { OrderItemModifier } from 'src/interfaces/option.interface';
import { RestaurantDeliveryRequest } from './interfaces/restaurant-delivery-request.interface';
import { SavedCard } from 'src/interfaces/saved-card.interface';
import { CateringLink } from 'src/interfaces/catering-link.interface';
import { DirectusService } from '../directus/directus.service';
import { RestaurantMenu } from './interfaces/restaurant-menu.interface';
import {
  SubmitOrderRequestWithGiftCardAsGuest,
  SubmitOrderRequestWithGiftCardAsUser,
} from './interfaces/submit-order-request-with-gift-card.interface';
import {
  SubmitOrderRequestWithSavedCardAsGuest,
  SubmitOrderRequestWithSavedCardAsUser,
} from './interfaces/submit-order-request-with-saved-card.interface';
import { Reward } from 'src/interfaces/reward.interface';
import { AddUpsellItemsRequest } from './interfaces/add-upsell-items-request.interface';
import { Upsells } from 'src/interfaces/upsells.interface';
import moment, { Moment } from 'moment-timezone';
import { UserProvider } from 'src/providers/user-provider.interface';
import { PassResetResponse } from 'src/interfaces/pass-reset-response.interface';
import { SSOLogin } from 'src/interfaces/sso-login.model';
import { CreateAccount } from '../../interfaces/create-account.interface';
import { Cacheable } from 'ts-cacheable';
import { GiftCardItem } from '../../interfaces/gift-card-item.interface';
import { Billingaccount, SubmitOrderMultiPaymentRequest } from './interfaces/submit-order-multi-payment-request.interface copy';
import { PaymentTypes } from '../../interfaces/payment-types.enum';
import { SubmitOrderRequestWithCashAsGuest, SubmitOrderRequestWithCashAsUser } from './interfaces/submit-order-request-with-cash.interface';
import { ToastService } from '../../services/toast.service';
import { from } from 'rxjs';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';

import { Preferences as Storage } from '@capacitor/preferences';
import { UserService } from 'src/services/vendor-config-service/user.service';
import { PaytronixProviderService } from '../paytronix/paytronix-provider.service';
import { environment } from '../../environments/environment';
import {
  SubmitOrderWithPrepaidTransactionAsGuest,
  SubmitOrderWithPrepaidTransactionAsUser,
} from './interfaces/submit-order-with-prepaid-transaction.interface';
import { Billingaccount as SavedBillingAccount } from './interfaces/billing-accounts-response.interface';
import {
  SubmitOrderRequestWithCreditCardTokenAsGuest,
  SubmitOrderRequestWithCreditCardTokenAsUser,
} from './interfaces/submit-order-request-with-credit-card-token.interface';
import { GroupOrderResponse } from './interfaces/group-order.interface';
import { GroupOrder } from '../../interfaces/group-order.interface';
import { Billingscheme } from './interfaces/billing-schemes-and-account-response.interface';
import { Capacitor } from '@capacitor/core';
import { LocalStorageKey } from '../../models/common.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { AddProductsRequest } from './interfaces/add-products-request.interface';
import { Referral } from './interfaces/add-referral-to-basket-request.interface';

@Injectable({
  providedIn: 'root',
})
export class OLOProviderService implements UserProvider, OrderProvider, OrderHistoryProvider, MenuProvider, LocationProvider {
  private basketGuidKey = 'OLOBasketGuid';
  private groupGuidKey = 'OLOGroupGuid';
  private authTokenKey = LocalStorageKey.OLO_AUTH_TOKEN;
  private accessTokenKey = LocalStorageKey.OLO_ACCESS_TOKEN;
  private maintainMenuCacheMs = 15 * 60 * 1000; // 15 minutes

  private showHiddenLocations = false;

  integrationCMSName = 'olo';

  // For Tableside, we need to send some fields that are not natively present in an Olo order.
  customData: Array<{ key: string; value: string }> = [];

  private oloAuthTokenSubject = new BehaviorSubject<string>(null);
  oloAuthToken$ = this.oloAuthTokenSubject.asObservable().pipe(distinctUntilChanged());
  private oloAccessTokenSubject = new BehaviorSubject<string>(null);
  oloAccessToken$ = this.oloAccessTokenSubject.asObservable().pipe(distinctUntilChanged());

  constructor(
    private oloAPI: OloAPIService,
    private mapping: OLOMappingService,
    private contentService: ImageContentService,
    private directusService: DirectusService,
    private userService: UserService,
    private toast: ToastService
  ) {
    this.directusService.getOloConfiguration().subscribe(config => {
      this.showHiddenLocations = config.show_hidden_locations;
    });
    this.ssoSubscribe().subscribe(() => {
      // console.log('sso subscribe');
    });
    // this.oloAuthToken$.subscribe(res => console.log(res))

    this.getAuthToken();
  }

  getResetPasswordCode(email: string): Observable<any> {
    return this.forgotPassword(email);
  }

  ssoSubscribe() {
    return this.userService.getService().pipe(
      switchMap(uService => {
        if (uService) {
          if (uService.getSSOLoginSubject) {
            return uService.getSSOLoginSubject().pipe(
              filter(token => !!token),
              distinctUntilKeyChanged('token'),
              switchMap(token => {
                if (!token) {
                  return of();
                }
                if (uService instanceof PaytronixProviderService) {
                  return uService.getOloSSOToken(token.token).pipe(
                    switchMap(t => {
                      token.token = t.access_token;
                      return this.completeSSOLogin(token);
                    })
                  );
                } else {
                  return this.completeSSOLogin(token);
                }
              })
            );
          } else {
            return of();
          }
        } else {
          return of();
        }
      })
    );
  }

  logInWithFacebook(email: string, accessToken: string, userID: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  connectWithFacebook(email: string, accessToken: string, userID: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  logInWithApple(appleResult: SignInWithAppleResponse, redirectURI: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  connectWithApple(appleResult: SignInWithAppleResponse, redirectURI: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  completeSSOLogin(token: SSOLogin) {
    return this.oloAPI.getBrandLevelSettings().pipe(
      switchMap(brandSettings => {
        let pname;
        if (brandSettings.loginproviders.find(lp => lp.type === 'external') && brandSettings.loginproviders.length === 1) {
          pname = brandSettings.loginproviders.find(lp => lp.type === 'external').provider;
        } else {
          pname = this.mapping.ssoLoginProviderName(token.provider);
        }
        this.storeAccessToken(token.token);
        return this.oloAPI.createOrGetSSOLinkedOLO(pname, token.token, this.getBasketGuid()).pipe(
          switchMap(user => {
            return this.storeAuthToken(user.authtoken);
          }),
          catchError(err => {
            return throwError(this.mapping.ssoLoginError(err));
          })
        );
      })
    );
  }

  completeSSOLoginObservable(token: SSOLogin): Observable<string> {
    return this.oloAPI.getBrandLevelSettings().pipe(
      switchMap(brandSettings => {
        let pname;
        if (brandSettings.loginproviders.find(lp => lp.type === 'external')) {
          pname = brandSettings.loginproviders.find(lp => lp.type === 'external').provider;
        } else {
          pname = this.mapping.ssoLoginProviderName(token.provider);
        }
        return this.oloAPI.createOrGetSSOLinkedOLO(pname, token.token, this.getBasketGuid()).pipe(
          switchMap(user => {
            console.log(user);
            this.storeAuthToken(user.authtoken);
            return of(user.authtoken);
          }),
          catchError((err: any) => {
            return throwError(this.mapping.ssoLoginError(new Error(err)));
          })
        );
      })
    );
  }

  getSSOLoginSubject() {
    return of(null);
  }

  isOauthEnabled(): Observable<boolean> {
    return of(false);
  }

  redirectToOauthPage() {}

  cateringLink(): Observable<CateringLink> {
    // This could change when we get a catering integration
    const link: CateringLink = {
      enabled: false,
      link: '',
      linkGuest: '',
      clientId: '',
    };
    return of(link);
  }

  removeCard(card: SavedCard): Observable<SavedCard> {
    return this.getAuthToken().pipe(
      switchMap(id => {
        return this.oloAPI.deleteUserBillingAccount(id, Number(card.savedCardID)).pipe(
          map(() => {
            return null;
          })
        );
      })
    );
  }

  saveCardAsDefault(card: SavedCard): Observable<SavedCard> {
    return this.getAuthToken().pipe(
      switchMap(id => {
        return this.oloAPI.setUserDefaultCreditCard(id, Number(card.savedCardID)).pipe(
          map(() => {
            return null;
          })
        );
      })
    );
  }

  getSavedCards(userID: string | number, isAccountPage: boolean): Observable<SavedCard[]> {
    return this.getAuthToken().pipe(
      switchMap(token => {
        if (token) {
          if (isAccountPage) {
            return this.oloAPI.getUserBillingAccounts(token).pipe(
              switchMap(res => {
                const creditCards = res.billingaccounts.filter(account => account.accounttype === 'creditcard');
                const giftCards = res.billingaccounts.filter(
                  account =>
                    (account.accounttype !== 'creditcard' && account.accounttype !== 'payinstore') ||
                    account.accounttype.toLowerCase().includes('gift card')
                );
                // tslint:disable-next-line:max-line-length
                return combineLatest(
                  giftCards.length ? giftCards.map(card => this.getSavedGiftCardBalance(token, card)) : [of(undefined)]
                ).pipe(
                  map(cardsWithBalance => {
                    return creditCards
                      .concat(cardsWithBalance)
                      .filter(a => a !== undefined)
                      .map(account => this.mapping.toCreditCard(account));
                  })
                );
              })
            );
          } else {
            return this.oloAPI.getQualifyingUserBillingAccounts(token, this.getBasketGuid()).pipe(
              switchMap(res => {
                const creditCards = res.billingaccounts.filter(account => account.accounttype === 'creditcard');
                const giftCards = res.billingaccounts.filter(
                  account =>
                    (account.accounttype !== 'creditcard' && account.accounttype !== 'payinstore') ||
                    account.accounttype.toLowerCase().includes('gift card')
                );
                // tslint:disable-next-line:max-line-length
                return combineLatest(
                  giftCards.length ? giftCards.map(card => this.getSavedGiftCardBalance(token, card)) : [of(undefined)]
                ).pipe(
                  map(cardsWithBalance => {
                    return creditCards
                      .concat(cardsWithBalance)
                      .filter(a => a !== undefined)
                      .map(account => this.mapping.toCreditCard(account));
                  })
                );
                // return res.billingaccounts.filter(
                //     account => account.accounttype === 'creditcard' || account.balance).map(account => this.mapping.toCreditCard(account));
              })
            );
          }
        } else {
          return of([]);
        }
      })
    );
  }

  private getSavedGiftCardBalance(authtoken: string, account: SavedBillingAccount): Observable<SavedBillingAccount> {
    return this.oloAPI.getUserGiftCardBalance(authtoken, account.accountid).pipe(
      map(res => {
        account.balance = res;
        return account;
      }),
      catchError(() => of(account))
    );
  }

  addCoupon(orderID: string, couponCode: string): Observable<Order> {
    return this.oloAPI.applyCoupon(orderID, couponCode).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(orderID).pipe(
              switchMap(billingSchemes => {
                const order = this.mapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  removeCoupon(orderID: string): Observable<Order> {
    return this.oloAPI.removeCoupon(orderID).pipe(
      switchMap(res => {
        return this.oloAPI.getBasket(orderID).pipe(
          switchMap(basketRes => {
            return this.getLocation(res.vendorid).pipe(
              switchMap(location => {
                return this.oloAPI.getAllBillingSchemesAndAccounts(basketRes.id).pipe(
                  switchMap(billingSchemes => {
                    const order = this.mapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                    return this.contentService.getOrderItemsWithSlugsandImages(order);
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  notifyOfArrival(orderID: string, extraMessage: string): Observable<Order> {
    return this.oloAPI.notifyOfArrival(orderID, extraMessage).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          switchMap(location => {
            const order = this.mapping.orderToOrder(res, location);
            return this.contentService.getOrderItemsWithSlugsandImages(order);
          })
        );
      })
    );
  }

  getLocations(withCalendars?: boolean): Observable<Location[]> {
    return this.oloAPI.getAllParticipatingRestaurants(environment.debug).pipe(
      switchMap(res => {
        const locations = res.restaurants.map(restaurant => this.mapping.restaurantToLocation(restaurant));
        return combineLatest(locations.map(loc => this.contentService.getLocationWithMapIconURL(loc))).pipe(
          switchMap(locs => {
            return combineLatest(locs.map(loc => this.contentService.getSingleLocationWithSlug(loc)));
          })
        );
      })
    );
  }

  getLocation(locationID: number): Observable<Location> {
    return this.oloAPI.getRestaurant(locationID).pipe(
      switchMap(restaurant => {
        const loc = this.mapping.restaurantToLocation(restaurant);
        return this.contentService.getLocationWithMapIconURL(loc).pipe(
          switchMap(location => {
            return this.getLocationWithHours(location).pipe(
              switchMap(locWithHours => {
                return this.contentService.getSingleLocationWithSlug(locWithHours);
              })
            );
          })
        );
      })
    );
  }

  getLocationBySlug(slugID: string): Observable<Location> {
    return this.directusService.getSingleLocationBySlug(slugID).pipe(
      switchMap(locationInfo => {
        return this.getLocation(Number(locationInfo.menu_id));
      })
    );
  }

  getLocationsNear(latitude: number, longitude: number, milesRadius: number, maxResults: number): Observable<Location[]> {
    // tslint:disable-next-line:max-line-length
    return this.oloAPI
      .getNearbyParticipatingRestaurants(latitude, longitude, milesRadius, maxResults, moment().toDate(), moment().add(1, 'week').toDate())
      .pipe(
        switchMap(res => {
          const locations = res.restaurants.map(restaurant => this.mapping.restaurantToLocation(restaurant));
          if (locations.length === 0) {
            return of<Location[]>([]);
          } else {
            return combineLatest(locations.map(loc => this.contentService.getLocationWithMapIconURL(loc))).pipe(
              switchMap(locs => {
                return combineLatest(locs.map(loc => this.contentService.getSingleLocationWithSlug(loc)));
              })
            );
          }
        })
      );
  }

  getLocationtableNumber(tableNumber: number, locationID: number): Observable<string> {
    return of(tableNumber.toString());
  }

  // tslint:disable-next-line: max-line-length
  checkForDelivery(
    restaurantID: number,
    handoffType: HandoffType.delivery | HandoffType.dispatch,
    timeWanted: Date,
    address: Address
  ): Observable<{ canDeliver: boolean; failureReason?: string }> {
    let requestBody: RestaurantDeliveryRequest = {
      handoffmode: handoffType === HandoffType.delivery ? 'delivery' : 'dispatch',
      timewantedmode: 'asap',
      street: address.address1,
      city: address.city,
      zipcode: address.zipCode,
    };
    if (moment(timeWanted).isAfter(moment(), 'minute')) {
      requestBody = {
        ...requestBody,
        timewantedmode: 'advance',
        timewantedutc: moment(timeWanted).utc().format('YYYYMMDD HH:mm'),
      };
    }
    if (requestBody.handoffmode && requestBody.timewantedmode && requestBody.street && requestBody.city && requestBody.zipcode) {
      return this.oloAPI.checkRestaurantDelivery(restaurantID, requestBody).pipe(
        map(res => {
          return {
            canDeliver: res.candeliver,
            failureReason: res.candeliver ? '' : res.message,
          };
        })
      );
    } else {
      return throwError(
        new Error(
          // tslint:disable-next-line:max-line-length
          'Something went wrong, this may not be a valid delivery address. Please try your address again or try entering a different address.'
        )
      );
    }
  }

  logIn(username: string, password: string): Observable<string> {
    return this.oloAPI.authenticateUser(username, password).pipe(
      switchMap(userRes => {
        return this.storeAuthToken(userRes.token).pipe(
          map(() => {
            return userRes.token;
          })
        );
      }),
      catchError(err => {
        return throwError(this.mapping.loginError(err));
      })
    );
  }

  logInWithToken(token: string, redirectURL: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  logOut(userID: string): Observable<string> {
    return this.oloAPI.disableAuthToken(userID).pipe(
      map(userDetails => {
        this.removeAuthToken();
        this.removeAccessToken();
        this.removeBasketGuid();
        return userDetails.authtoken;
      })
    );
  }

  forgotPassword(email: string): Observable<any> {
    return this.oloAPI.startForgotPasswordProcess(email);
  }

  changePassword(email: string, oldPassword: string, newPassword: string): Observable<any> {
    return this.oloAPI.authenticateUser(email, oldPassword).pipe(
      switchMap(userRes => {
        return this.oloAPI.changePassword(userRes.token, oldPassword, newPassword);
      })
    );
  }

  resetPassword(newPassword: string): Observable<PassResetResponse> {
    throw new Error('Method not implemented.');
  }

  // tslint:disable-next-line: max-line-length
  updateUserInfo(user: User): Observable<User> {
    return this.oloAPI.updateUserDetails(user.userID, user.firstName, user.lastName, user.email).pipe(
      switchMap(userDetails => {
        const updatePrefs = {
          marketingsms: String(user.sMSOptIn),
          optin: String(user.emailOptIn),
          upsell: 'true',
          emailreceipts: 'true',
          followups: 'true',
        };
        return this.getAuthToken().pipe(
          switchMap(id => {
            return this.oloAPI.updateUserContactNumber(id, user.phoneNumber).pipe(
              switchMap(newPhone => {
                return this.oloAPI.updateUserCommunicationPreferences(id, updatePrefs).pipe(
                  switchMap(newPrefs => {
                    return this.storeAuthToken(userDetails.authtoken).pipe(
                      map(() => {
                        let newUser = this.mapping.userDetailsToUser(userDetails);
                        newUser = {
                          ...newUser,
                          emailOptIn: newPrefs.optin === 'true',
                          sMSOptIn: newPrefs.marketingsms === 'true',
                          phoneNumber: newPhone.contactdetails,
                        };
                        return newUser;
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  // tslint:disable-next-line: max-line-length
  createAccount(newAccount: CreateAccount): Observable<User> {
    const req: CreateUserRequest = {
      emailaddress: newAccount.email,
      firstname: newAccount.firstName,
      lastname: newAccount.lastName,
      contactnumber: newAccount.phone,
      password: newAccount.password,
      reference: '',
      basketid: null,
    };
    return this.oloAPI.createUser(req).pipe(
      switchMap(userReq => {
        return this.storeAuthToken(userReq.authtoken).pipe(
          switchMap(() => {
            const updatePrefs = {
              marketingsms: String(newAccount.smsOptIn),
              optin: String(newAccount.emailOptIn),
              upsell: 'true',
              emailreceipts: 'true',
              followups: 'true',
            };
            return this.getAuthToken().pipe(
              switchMap(id => {
                return this.oloAPI.updateUserCommunicationPreferences(id, updatePrefs).pipe(
                  map(newPrefs => {
                    let newUser = this.mapping.createUserResponseToUser(userReq);
                    newUser = {
                      ...newUser,
                      emailOptIn: newPrefs.optin === 'true',
                      sMSOptIn: newPrefs.marketingsms === 'true',
                    };
                    return newUser;
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  logInAsGuest(): Observable<string> {
    return of(null);
  }

  getUserInfo(userID: string): Observable<User> {
    return this.getAuthToken().pipe(
      switchMap(id => {
        return this.oloAPI.getUserDetails(id).pipe(
          switchMap(userDetails => {
            return this.oloAPI.getUserContactNumber(id).pipe(
              switchMap(contact => {
                return this.oloAPI.getUserCommunicationPreferences(id).pipe(
                  map(comPrefs => {
                    return {
                      ...this.mapping.userDetailsToUser(userDetails),
                      phoneNumber: contact.contactdetails,
                      emailOptIn: comPrefs.optin === 'true',
                      sMSOptIn: comPrefs.marketingsms === 'true',
                    };
                  })
                );
              })
            );
          }),
          catchError(error => {
            this.removeAuthToken();
            this.removeAccessToken();
            return throwError(error);
          })
        );
      })
    );
  }

  getLoggedInUserInfo(): Observable<User> {
    return this.getAuthToken().pipe(
      switchMap(token => {
        return token && token !== 'null' ? this.getUserInfo(token) : of(null);
      })
    );
  }

  is3rdPartyWrapped(): boolean {
    return false;
  }

  getCurrentOrder(userID: string): Observable<Order> {
    const basketGuid = this.getBasketGuid();
    if (basketGuid) {
      return this.oloAPI.getBasket(basketGuid).pipe(
        switchMap(basketRes => {
          return this.getLocation(basketRes.vendorid).pipe(
            switchMap(location => {
              return this.oloAPI.getAllBillingSchemesAndAccounts(basketGuid).pipe(
                switchMap(billingSchemes => {
                  const order = this.mapping.createBasketResponseToOrder(basketRes, location, billingSchemes.billingschemes);
                  return this.contentService.getOrderItemsWithSlugsandImages(order);
                })
              );
            })
          );
        })
      );
    } else {
      return of(null);
    }
  }

  clearOrder(): Observable<string> {
    this.removeBasketGuid();
    return of(this.getBasketGuid());
  }

  startOrder(userID: string, locationID: string, handoffType: HandoffType, tableNumber?: string, address?: Address): Observable<Order> {
    if (isNaN(locationID as any)) {
      // URL contains a slug
      return this.directusService.getSingleLocationBySlug(locationID).pipe(
        switchMap(locationInfo => {
          return this.startOrder(userID, locationInfo.menu_id, handoffType);
        })
      );
    } else {
      return this.getAuthToken().pipe(
        switchMap(id => {
          return this.oloAPI.createBasket(parseInt(locationID, 10), id).pipe(
            switchMap(basketResponse => {
              this.storeBasketGuid(basketResponse.id);
              if (handoffType === HandoffType.delivery || handoffType === HandoffType.dispatch) {
                return this.setDispatchOrDeliveryAddress(basketResponse.id, userID, address, handoffType).pipe(
                  switchMap(() => {
                    return this.completeOrder(basketResponse, handoffType, userID);
                  })
                );
              } else {
                return this.completeOrder(basketResponse, handoffType, userID);
              }
            })
          );
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.error?.message?.toLowerCase().includes('invalid authentication token')) {
            this.removeAuthToken();
            this.removeAccessToken();
            return this.startOrder(userID, locationID, handoffType, tableNumber, address);
          }
          return throwError(error);
        })
      );
    }
  }

  private completeOrder(basketResponse, handoffType, userID) {
    this.setReferralToken(basketResponse.id);
    if (handoffType !== HandoffType.delivery) {
      return this.oloAPI.setHandoffMethod(basketResponse.id, this.mapping.handoffTypeToHandoffType(handoffType)).pipe(
        switchMap(res => {
          return this.getLocation(res.vendorid).pipe(
            switchMap(location => {
              return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
                switchMap(billingSchemes => {
                  const order = this.mapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                  // if (order.isASAP && !order.location.isOpen) {
                  // tslint:disable-next-line:max-line-length
                  //         return this.setTimePreference(order.orderID, userID, moment(order.earliestReadyTimestamp).add(30, 'minutes').toDate()).pipe(switchMap(updatedOrder => {
                  //             return this.contentService.getOrderItemsWithSlugs(updatedOrder);
                  //         }));
                  // } else {
                  return this.contentService.getOrderItemsWithSlugsandImages(order);
                })
              );
            })
          );
        }),
        catchError(() => {
          return of(null);
        })
      );
    } else {
      return this.getLocation(basketResponse.vendorid).pipe(
        switchMap(location => {
          return this.oloAPI.getAllBillingSchemesAndAccounts(basketResponse.id).pipe(
            switchMap(billingScemes => {
              const order = this.mapping.createBasketResponseToOrder(basketResponse, location, billingScemes.billingschemes);
              // if (order.isASAP && !order.location.isOpen) {
              // tslint:disable-next-line:max-line-length
              //         return this.setTimePreference(order.orderID, userID, moment(order.earliestReadyTimestamp).add(30, 'minutes').toDate()).pipe(switchMap(updatedOrder => {
              //             return this.contentService.getOrderItemsWithSlugs(updatedOrder);
              //         }));
              // } else {
              return this.contentService.getOrderItemsWithSlugsandImages(order);
            })
          );
        })
      );
    }
  }

  private setReferralToken(orderID: string): void {
    this.getRWGToken().subscribe(token => {
      if (token) {
        const referral: Referral = {
          source: 'rwg_token',
          token,
        };
        this.oloAPI.addReferralToBasket(orderID, [referral]).subscribe(() => {
          this.removeRWGToken();
        });
      }
    });
  }

  transferBasket(orderID: string, locationID: string): Observable<Order> {
    if (isNaN(locationID as any)) {
      // URL contains a slug
      return this.directusService.getSingleLocationBySlug(locationID).pipe(
        switchMap(locationInfo => {
          return this.transferBasket(orderID, locationInfo.menu_id);
        })
      );
    } else {
      return this.oloAPI.transferBasketToAnotherRestaurant(orderID, parseInt(locationID, 10)).pipe(
        switchMap(transferResponse => {
          this.storeBasketGuid(transferResponse.basket.id);
          return this.getLocation(transferResponse.basket.vendorid).pipe(
            switchMap(location => {
              return this.oloAPI.getAllBillingSchemesAndAccounts(transferResponse.basket.id).pipe(
                switchMap(billingSchemes => {
                  const order = this.mapping.createBasketResponseToOrder(transferResponse.basket, location, billingSchemes.billingschemes);
                  transferResponse.itemsnottransferred.forEach(item => {
                    this.toast.info(`${item} is not available at this location, it has been removed from your cart`);
                  });
                  return this.contentService.getOrderItemsWithSlugsandImages(order);
                })
              );
            })
          );
        })
      );
    }
  }

  validateOrder(userID: string, locationID: number, orderID: string): Observable<Order> {
    return this.oloAPI.validateBasket(orderID, true).pipe(
      switchMap(res => {
        return this.oloAPI.getBasket(res.basketid).pipe(
          switchMap(basket => {
            return this.getLocation(locationID).pipe(
              switchMap(location => {
                return this.oloAPI.getAllBillingSchemesAndAccounts(res.basketid).pipe(
                  switchMap(billingSchemes => {
                    const order = this.mapping.createBasketResponseToOrder(basket, location, billingSchemes.billingschemes);
                    return this.contentService.getOrderItemsWithSlugsandImages(order);
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  cancelOrder(orderID: string): Observable<Order> {
    return this.oloAPI.cancelOrder(orderID).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          map(location => {
            return this.mapping.orderToOrder(res, location);
          })
        );
      })
    );
  }

  editOrder(orderID: string): Observable<Order> {
    return this.oloAPI.editOrder(orderID).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
              map(billingSchemes => {
                this.storeBasketGuid(res.id);
                return this.mapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
              })
            );
          })
        );
      })
    );
  }

  addToOrder(orderItem: OrderItem, orderID: string, userID: string): Observable<Order> {
    return this.oloAPI.addProductsToBasket(orderID, this.mapping.orderItemToAddProductsBody(orderItem)).pipe(
      switchMap(res => {
        if (res.errors.length) {
          // map all errors into one sentence and throw error
          const error = res.errors.map(e => e.message).join(' ');
          return throwError(error);
        }
        return this.getLocation(res.basket.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(res.basket.id).pipe(
              switchMap(billingSchemes => {
                const order = this.mapping.createBasketResponseToOrder(res.basket, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  updateBasketItem(orderItem: OrderItem, orderID: string, userID: string, quant: number): Observable<Order> {
    return this.oloAPI.updateProductsInBasket(orderID, this.mapping.orderItemToAddProductsBody(orderItem, quant)).pipe(
      switchMap(res => {
        if (res.errors.length) {
          // map all errors into one sentence and throw error
          const error = res.errors.map(e => e.message).join(' ');
          return throwError(error);
        }
        return this.getLocation(res.basket.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(res.basket.id).pipe(
              switchMap(billingSchemes => {
                const order = this.mapping.createBasketResponseToOrder(res.basket, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  removeFromOrder(orderItem: OrderItem, orderID: string, userID: string): Observable<Order> {
    return this.oloAPI.removeProductFromBasket(orderID, Number(orderItem.orderItemID)).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
              switchMap(billingSchemes => {
                const order = this.mapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  setTimePreference(orderID: string, userID: string, time: Date): Observable<Order> {
    return this.oloAPI.getBasket(orderID).pipe(
      switchMap(basket => {
        return this.oloAPI.getRestaurant(basket.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.setBasketTimeWanted(orderID, moment(time).utcOffset(location.utcoffset)).pipe(
              switchMap(basketRes => {
                return this.oloAPI.getAllBillingSchemesAndAccounts(basketRes.id).pipe(
                  switchMap(billingSchemes => {
                    const order = this.mapping.createBasketResponseToOrder(
                      basketRes,
                      this.mapping.restaurantToLocation(location),
                      billingSchemes.billingschemes
                    );
                    return this.contentService.getOrderItemsWithSlugsandImages(order);
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  setManualFire(orderID: string): Observable<Order> {
    return this.oloAPI.setBasketTimeModeToManualFire(orderID).pipe(
      switchMap(basketRes => {
        return this.getLocation(basketRes.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(basketRes.id).pipe(
              switchMap(billingSchemes => {
                const order = this.mapping.createBasketResponseToOrder(basketRes, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  setTimePreferenceToASAP(orderID: string, userID: string): Observable<Order> {
    return this.oloAPI.setBasketTimeModeToASAP(orderID).pipe(
      switchMap(basketRes => {
        return this.getLocation(basketRes.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(basketRes.id).pipe(
              switchMap(billingSchemes => {
                const order = this.mapping.createBasketResponseToOrder(basketRes, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  setHandoffType(orderID: string, userID: string, handoffType: HandoffType): Observable<Order> {
    let oloHandoffType;
    if (typeof handoffType !== 'string') {
      oloHandoffType = this.mapping.handoffTypeToHandoffType(handoffType);
    } else {
      oloHandoffType = this.mapping.handoffTypeToString(handoffType);
    }
    return this.oloAPI.setHandoffMethod(orderID, oloHandoffType).pipe(
      switchMap(basketRes => {
        return this.getLocation(basketRes.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(basketRes.id).pipe(
              switchMap(billingSchemes => {
                const order = this.mapping.createBasketResponseToOrder(basketRes, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  // tslint:disable-next-line: max-line-length
  setDispatchOrDeliveryAddress(orderID: string, userID: string, address: Address, handoffType: HandoffType): Observable<Order> {
    if (userID) {
      return this.getUserInfo(userID).pipe(
        switchMap(() => {
          const oloAddress = {
            city: address.city,
            streetaddress: address.address1,
            building: address.address2 ? address.address2 : null,
            zipcode: address.zipCode,
            // phonenumber: user.phoneNumber,
            isdefault: false,
            specialinstructions: address.specialInstructions ? address.specialInstructions : null,
          } as OloAddress;
          return this.oloAPI.getBasket(orderID).pipe(
            switchMap(() => {
              if (handoffType === HandoffType.delivery) {
                return this.oloAPI.setBasketDeliveryAddress(orderID, oloAddress).pipe(
                  switchMap(res => {
                    return this.getLocation(res.vendorid).pipe(
                      switchMap(location => {
                        return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
                          switchMap(billingSchemes => {
                            const order = this.mapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                            return this.contentService.getOrderItemsWithSlugsandImages(order);
                          })
                        );
                      })
                    );
                  })
                );
              } else {
                return this.oloAPI.setBasketDispatchAddress(orderID, oloAddress).pipe(
                  switchMap(res => {
                    return this.getLocation(res.vendorid).pipe(
                      switchMap(location => {
                        return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
                          switchMap(billingSchemes => {
                            const order = this.mapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                            return this.contentService.getOrderItemsWithSlugsandImages(order);
                          })
                        );
                      })
                    );
                  })
                );
              }
            })
          );
        })
      );
    } else {
      const oloAddress = {
        city: address.city,
        streetaddress: address.address1,
        building: address.address2 ? address.address2 : null,
        zipcode: address.zipCode,
        // phonenumber: '00000000000',
        isdefault: false,
        id: 0,
        specialinstructions: address.specialInstructions ? address.specialInstructions : null,
      } as OloAddress;
      return this.oloAPI.getBasket(orderID).pipe(
        switchMap(() => {
          if (handoffType === HandoffType.delivery) {
            return this.oloAPI.setBasketDeliveryAddress(orderID, oloAddress).pipe(
              switchMap(res => {
                return this.getLocation(res.vendorid).pipe(
                  switchMap(location => {
                    return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
                      switchMap(billingSchemes => {
                        const order = this.mapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                        return this.contentService.getOrderItemsWithSlugsandImages(order);
                      })
                    );
                  })
                );
              })
            );
          } else {
            return this.oloAPI.setBasketDispatchAddress(orderID, oloAddress).pipe(
              switchMap(res => {
                return this.getLocation(res.vendorid).pipe(
                  switchMap(location => {
                    return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
                      switchMap(billingSchemes => {
                        const order = this.mapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                        return this.contentService.getOrderItemsWithSlugsandImages(order);
                      })
                    );
                  })
                );
              })
            );
          }
        })
      );
    }
  }

  checkDeliveryStatus(orderGuid: string, authToken: string): Observable<any> {
    if (authToken) {
      return this.oloAPI.checkOrderDispatchStatus(orderGuid, authToken);
    } else {
      return this.oloAPI.checkGuestOrderDispatchStatus(orderGuid);
    }
  }

  getOrderStatus(orderGuid: string): Observable<Order> {
    return this.oloAPI.checkOrderStatus(orderGuid).pipe(
      switchMap(order => {
        return this.getLocation(order.vendorid).pipe(
          map(location => {
            return this.mapping.orderToOrder(order, location);
          })
        );
      })
    );
  }

  getGiftCardBalance(basketGuid: string, cardNumber: string, pin: string): Observable<GiftCardItem> {
    return this.oloAPI.getAllBillingSchemesAndAccounts(basketGuid).pipe(
      switchMap(schemes => {
        const billingscheme = schemes.billingschemes.find((sche: any) => sche.type === 'giftcard');
        return this.oloAPI.getGiftCardBalance(basketGuid, billingscheme.id, cardNumber, pin).pipe(
          map(gcb => {
            return this.mapping.toGiftCard(gcb, cardNumber, pin);
          })
        );
      })
    );
  }

  setOrderLocation(userID: string, locationID: number, orderID: string): Observable<Order> {
    return this.oloAPI.transferBasketToAnotherRestaurant(orderID, locationID).pipe(
      switchMap(transfer => {
        return this.getLocation(transfer.basket.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(transfer.basket.id).pipe(
              switchMap(billingSchemes => {
                const order = this.mapping.transferBasketResponseToOrder(transfer, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
                // This needs to be addressed why this has an extra function for the slugs
              })
            );
          })
        );
      })
    );
  }

  setTip(userID: string, orderID: string, tipCents: number): Observable<Order> {
    return this.oloAPI.setTip(orderID, tipCents).pipe(
      switchMap(basketRes => {
        return this.getLocation(basketRes.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(basketRes.id).pipe(
              switchMap(billingSchemes => {
                const order = this.mapping.createBasketResponseToOrder(basketRes, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  setBasketCustomfield(orderId: string, customId: number | string, value: any): Observable<Order> {
    return this.oloAPI.setBasketCustomFieldValue(orderId, customId, value).pipe(
      switchMap(basketRes => {
        return this.getLocation(basketRes.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(basketRes.id).pipe(
              switchMap(billingSchemes => {
                const order = this.mapping.createBasketResponseToOrder(basketRes, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  getAvailableOrderDays(locationID: string | number, orderID: string | number): Observable<Date[]> {
    return this.getLocation(Number(locationID)).pipe(
      switchMap(restaurant => {
        const days: Date[] = [];
        while (days.length < restaurant.orderAheadDays) {
          if (Number(restaurant.utcOffset) <= moment().utcOffset() / 60) {
            days.push(moment().add(days.length, 'days').utcOffset(Number(restaurant.utcOffset), true).startOf('day').toDate());
          } else {
            days.push(moment().add(days.length, 'days').startOf('day').toDate());
          }
        }
        return of(days);
      })
    );
  }

  getAvailableOrderTimes(locationID: string | number, orderID: string | number, date: Date): Observable<Moment[]> {
    return this.getLocation(Number(locationID)).pipe(
      switchMap(restaurant => {
        // Convert the passed date to the restaurant's local time before querying the API.
        const localDate = moment(date).utcOffset(Number(restaurant.utcOffset), true);
        return this.oloAPI
          .getAvailableTimeWantedTimes(Number(locationID), String(orderID), localDate.toDate(), Number(restaurant.utcOffset))
          .pipe(
            map(availableTimes => {
              const times: Moment[] = [];
              availableTimes.times.forEach(time => {
                times.push(moment(time.time, 'YYYYMMDD HH:mm').utcOffset(Number(restaurant.utcOffset) * 60, true));
              });
              return times;
            })
          );
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  submitPayment(
    orderID: string,
    cardDetails: CardDetails,
    applyCents: number,
    shouldSave: boolean,
    token: string,
    inst?: string
  ): Observable<Order> {
    return this.getAuthToken().pipe(
      switchMap(id => {
        if (!id) {
          return of([null])
            .pipe(concatMap(item => of(item).pipe(delay(1000))))
            .pipe(
              switchMap(() => {
                return this.submitPaymentAsGuest(orderID, cardDetails, applyCents, shouldSave, token, inst);
              })
            );
        } else {
          // tslint:disable-next-line:max-line-length
          return this.oloAPI.validateBasket(orderID, false).pipe(
            switchMap(order => this.setTableNumber(order.basketid, sessionStorage.getItem('tablenumber'), cardDetails.firstName)),
            switchMap(order => {
              this.customData = [];
              if (cardDetails.paymentType === PaymentTypes.cash) {
                const body = {
                  authtoken: id,
                  billingmethod: 'cash',
                  usertype: 'user',
                  firstname: cardDetails.firstName,
                  lastname: cardDetails.lastName,
                  emailaddress: cardDetails.emailAddress,
                  contactnumber: cardDetails.phoneNumber,
                } as SubmitOrderRequestWithCashAsUser;
                return this.oloAPI.submitOrderWithSinglePayment(order.orderID, body).pipe(
                  switchMap(res => {
                    this.storeBasketGuid('');
                    sessionStorage.setItem('deliveryBasketGuid', res.id);
                    return this.getLocation(res.vendorid).pipe(
                      map(location => {
                        return this.mapping.orderToOrder(res, location);
                      })
                    );
                  })
                );
              } else if (cardDetails.isMultiPayment) {
                // Multi payments
                return this.oloAPI.getAllBillingSchemesAndAccounts(orderID).pipe(
                  switchMap(schemes => {
                    const billingscheme = schemes.billingschemes.find((sche: any) => sche.type === 'giftcard');
                    const billingAccounts: Billingaccount[] = [];
                    cardDetails.multiPaymentBillingAccounts.forEach(ba => {
                      if (ba.billingfields && ba.billingfields.length) {
                        ba.billingschemeid = billingscheme.id;
                        ba.billingmethod = 'storedvalue';
                      } else if (ba.savedcardid) {
                        ba.billingmethod = 'billingaccount';
                      } else if (ba.token && !ba.isDigitalWallet) {
                        ba.billingmethod = 'creditcardtoken';
                      } else if (ba.token && ba.isDigitalWallet) {
                        ba.billingschemeid = this.findDigitalWalletBillingScheme(schemes.billingschemes, true, '');
                        ba.billingmethod = 'digitalwallet';
                      } else {
                        ba.billingmethod = 'creditcard';
                      }
                      const billingField = {
                        billingmethod: ba.billingmethod,
                        amount: Number((ba.amount / 100).toFixed(2)),
                        tipportion: Number((ba.tipportion / 100).toFixed(2)),
                        cardnumber: ba.cardnumber ? ba.cardnumber : null,
                        expiryyear: ba.expiryyear
                          ? String(ba.expiryyear).length === 4
                            ? Number(ba.expiryyear)
                            : Number('20' + ba.expiryyear)
                          : null,
                        expirymonth: ba.expirymonth ? ba.expirymonth : null,
                        cvv: ba.cvv ? ba.cvv : null,
                        streetaddress: ba.streetaddress ? ba.streetaddress : null,
                        streetaddress2: ba.streetaddress2 ? ba.streetaddress2 : null,
                        city: ba.city ? ba.city : null,
                        state: ba.state ? ba.state : null,
                        zip: ba.zip ? ba.zip : null,
                        country: ba.country ? ba.country : null,
                        saveonfile: ba.saveonfile ? String(ba.saveonfile) : null,
                        billingaccountid: ba.savedcardid ? ba.savedcardid : null,
                        billingschemeid: ba.billingschemeid ? ba.billingschemeid : null,
                        billingfields: ba.billingfields ? ba.billingfields : null,
                        token: ba.token ? ba.token : null,
                        cardtype: ba.cardtype ? ba.cardtype : null,
                        cardlastfour: ba.cardlastfour ? ba.cardlastfour : null,
                      } as Billingaccount;
                      billingAccounts.push(billingField);
                    });
                    let body: SubmitOrderMultiPaymentRequest;
                    body = {
                      authtoken: id,
                      usertype: 'user',
                      firstname: cardDetails.firstName,
                      lastname: cardDetails.lastName,
                      emailaddress: cardDetails.emailAddress,
                      contactnumber: cardDetails.phoneNumber,
                      reference: null,
                      orderref: null,
                      customdata: this.customData,
                      billingaccounts: billingAccounts,
                    } as SubmitOrderMultiPaymentRequest;
                    return this.oloAPI.submitOrderWithMultiplePayments(orderID, body).pipe(
                      switchMap(res => {
                        this.storeBasketGuid('');
                        sessionStorage.setItem('deliveryBasketGuid', res.id);
                        return this.getLocation(res.vendorid).pipe(
                          map(location => {
                            return this.mapping.orderToOrder(res, location);
                          })
                        );
                      })
                    );
                  })
                );
              } else {
                return this.oloAPI.getAllBillingSchemesAndAccounts(orderID).pipe(
                  switchMap(schemes => {
                    const billingscheme = schemes.billingschemes.find((sche: any) => sche.type === 'giftcard');
                    // tslint:disable-next-line: max-line-length
                    let body:
                      | SubmitOrderRequestWithCreditCardAsUser
                      | SubmitOrderRequestWithGiftCardAsUser
                      | SubmitOrderRequestWithSavedCardAsUser
                      | SubmitOrderWithPrepaidTransactionAsUser
                      | SubmitOrderRequestWithCreditCardTokenAsUser;
                    if (billingscheme && billingscheme.id && cardDetails.isGiftCard && !cardDetails.isSavedCard) {
                      body = {
                        authtoken: id,
                        billingmethod: 'storedvalue',
                        billingschemeid: billingscheme.id,
                        billingfields: [
                          {
                            name: 'number',
                            value: cardDetails.giftCardNumber.replace(/-/g, ''),
                          },
                        ],
                        usertype: 'user',
                        contactnumber: cardDetails.phoneNumber.replace(/-/g, ''),
                        saveonfile: String(shouldSave),
                        customdata: this.customData,
                      } as SubmitOrderRequestWithGiftCardAsUser;
                      if (cardDetails.pin !== '') {
                        body.billingfields.push({
                          name: 'pin',
                          value: cardDetails.pin,
                        });
                      }
                    } else if (cardDetails.isSavedCard) {
                      body = {
                        authtoken: id,
                        billingmethod: 'billingaccount',
                        billingaccountid: cardDetails.savedCardID,
                        usertype: 'user',
                        contactnumber: cardDetails.phoneNumber.replace(/-/g, ''),
                        saveonfile: 'false',
                        customdata: this.customData,
                      } as SubmitOrderRequestWithSavedCardAsUser;
                    } else if (cardDetails.paymentType === PaymentTypes.prepaid) {
                      body = {
                        authtoken: id,
                        billingmethod: 'prepaid',
                        usertype: 'user',
                        prepaidtransactionid: cardDetails.prepaidID,
                        prepaiddescription: cardDetails.prepaidDescription,
                      } as SubmitOrderWithPrepaidTransactionAsUser;
                    } else if (cardDetails.paymentType === PaymentTypes.creditCardToken) {
                      body = {
                        billingmethod: cardDetails.isDigitalWallet ? 'digitalwallet' : 'creditcardtoken',
                        // tslint:disable-next-line:max-line-length
                        billingschemeid: this.findDigitalWalletBillingScheme(
                          schemes.billingschemes,
                          cardDetails.isDigitalWallet,
                          cardDetails.digitalWalletType
                        ),
                        authtoken: id,
                        usertype: 'user',
                        contactnumber: cardDetails.phoneNumber,
                        token: cardDetails.token,
                        cardtype: cardDetails.cardType,
                        cardlastfour: cardDetails.cardLastFour,
                        expirymonth: Number(cardDetails.expirationMonth),
                        // tslint:disable-next-line:max-line-length
                        expiryyear:
                          String(cardDetails.expirationYear).length === 4
                            ? Number(cardDetails.expirationYear)
                            : Number('20' + cardDetails.expirationYear),
                        city: cardDetails.billingAddress.city,
                        state: cardDetails.billingAddress.state,
                        zip: cardDetails.billingAddress.zipCode,
                        streetaddress: cardDetails.billingAddress.address1,
                        streetaddress2: cardDetails.billingAddress.address2,
                        country: cardDetails.billingAddress.country ? cardDetails.billingAddress.country : 'US',
                        saveonfile: shouldSave ? 'true' : 'false',
                      } as SubmitOrderRequestWithCreditCardTokenAsUser;
                    } else {
                      body = {
                        authtoken: id,
                        billingmethod: 'creditcard',
                        usertype: 'user',
                        saveonfile: shouldSave ? 'true' : 'false',
                        cardnumber: cardDetails.cardNumber,
                        contactnumber: cardDetails.phoneNumber,
                        cvv: cardDetails.cvv,
                        expirymonth: Number(cardDetails.expirationMonth),
                        // tslint:disable-next-line:max-line-length
                        expiryyear:
                          String(cardDetails.expirationYear).length === 4
                            ? Number(cardDetails.expirationYear)
                            : Number('20' + cardDetails.expirationYear),
                        city: cardDetails.billingAddress.city,
                        state: cardDetails.billingAddress.state,
                        zip: cardDetails.billingAddress.zipCode,
                        streetaddress: cardDetails.billingAddress.address1,
                        streetaddress2: cardDetails.billingAddress.address2,
                        country: cardDetails.billingAddress.country ? cardDetails.billingAddress.country : 'US',
                        customdata: this.customData,
                      } as SubmitOrderRequestWithCreditCardAsUser;
                    }
                    const existingMode = sessionStorage.getItem('mode');
                    if (existingMode === 'tableside') {
                      body.receivinguser = {
                        firstname: cardDetails.firstName,
                        lastname: cardDetails.lastName,
                        emailaddress: cardDetails.emailAddress,
                        contactnumber: cardDetails.phoneNumber,
                      };
                    }
                    return this.oloAPI.submitOrderWithSinglePayment(orderID, body).pipe(
                      switchMap(res => {
                        this.storeBasketGuid('');
                        sessionStorage.setItem('deliveryBasketGuid', res.id);
                        return this.getLocation(res.vendorid).pipe(
                          map(location => {
                            return this.mapping.orderToOrder(res, location);
                          })
                        );
                      })
                    );
                  })
                );
              }
            })
          );
        }
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  submitPaymentAsGuest(
    orderID: string,
    cardDetails: CardDetails,
    applyCents: number,
    shouldSave: boolean,
    token: string,
    inst?: string
  ): Observable<Order> {
    return this.oloAPI.validateBasket(orderID, false).pipe(
      switchMap(order => this.setTableNumber(order.basketid, sessionStorage.getItem('tablenumber'), cardDetails.firstName)),
      switchMap(order => {
        this.customData = [];
        if (cardDetails.paymentType === PaymentTypes.cash) {
          const body = {
            billingmethod: 'cash',
            usertype: 'guest',
            firstname: cardDetails.firstName,
            lastname: cardDetails.lastName,
            emailaddress: cardDetails.emailAddress,
            contactnumber: cardDetails.phoneNumber,
            guestoptin: cardDetails.guestOptIn,
          } as SubmitOrderRequestWithCashAsGuest;
          return this.oloAPI.submitOrderWithSinglePayment(order.orderID, body).pipe(
            switchMap(res => {
              this.storeBasketGuid('');
              sessionStorage.setItem('deliveryBasketGuid', res.id);
              return this.getLocation(res.vendorid).pipe(
                map(location => {
                  return this.mapping.orderToOrder(res, location);
                })
              );
            })
          );
        } else if (cardDetails.isMultiPayment) {
          // Multi payments
          return this.oloAPI.getAllBillingSchemesAndAccounts(orderID).pipe(
            switchMap(schemes => {
              const billingscheme = schemes.billingschemes.find((sche: any) => sche.type === 'giftcard');
              const billingAccounts: Billingaccount[] = [];
              cardDetails.multiPaymentBillingAccounts.forEach(ba => {
                if (ba.billingfields && ba.billingfields.length) {
                  ba.billingschemeid = billingscheme.id;
                  ba.billingmethod = 'storedvalue';
                } else if (ba.savedcardid) {
                  ba.billingmethod = 'billingaccount';
                } else if (ba.token && !ba.isDigitalWallet) {
                  ba.billingmethod = 'creditcardtoken';
                } else if (ba.token && ba.isDigitalWallet) {
                  ba.billingschemeid = this.findDigitalWalletBillingScheme(schemes.billingschemes, true);
                  ba.billingmethod = 'digitalwallet';
                } else {
                  ba.billingmethod = 'creditcard';
                }
                const billingField = {
                  billingmethod: ba.billingmethod,
                  amount: Number((ba.amount / 100).toFixed(2)),
                  tipportion: Number((ba.tipportion / 100).toFixed(2)),
                  cardnumber: ba.cardnumber ? ba.cardnumber : null,
                  expiryyear: ba.expiryyear
                    ? String(ba.expiryyear).length === 4
                      ? Number(ba.expiryyear)
                      : Number('20' + ba.expiryyear)
                    : null,
                  expirymonth: ba.expirymonth ? ba.expirymonth : null,
                  cvv: ba.cvv ? ba.cvv : null,
                  streetaddress: ba.streetaddress ? ba.streetaddress : null,
                  streetaddress2: ba.streetaddress2 ? ba.streetaddress2 : null,
                  city: ba.city ? ba.city : null,
                  state: ba.state ? ba.state : null,
                  zip: ba.zip ? ba.zip : null,
                  country: ba.country ? ba.country : null,
                  saveonfile: ba.saveonfile ? ba.saveonfile : null,
                  billingaccountid: ba.savedcardid ? ba.savedcardid : null,
                  billingschemeid: ba.billingschemeid ? ba.billingschemeid : null,
                  billingfields: ba.billingfields ? ba.billingfields : null,
                  token: ba.token ? ba.token : null,
                  cardtype: ba.cardtype ? ba.cardtype : null,
                  cardlastfour: ba.cardlastfour ? ba.cardlastfour : null,
                } as Billingaccount;
                billingAccounts.push(billingField);
              });
              let body: SubmitOrderMultiPaymentRequest;
              body = {
                authtoken: null,
                usertype: 'guest',
                firstname: cardDetails.firstName,
                lastname: cardDetails.lastName,
                emailaddress: cardDetails.emailAddress,
                contactnumber: cardDetails.phoneNumber,
                reference: null,
                orderref: null,
                customdata: this.customData,
                guestoptin: cardDetails.guestOptIn,
                billingaccounts: billingAccounts,
              } as SubmitOrderMultiPaymentRequest;
              return this.oloAPI.submitOrderWithMultiplePayments(orderID, body).pipe(
                switchMap(res => {
                  this.storeBasketGuid('');
                  sessionStorage.setItem('deliveryBasketGuid', res.id);
                  return this.getLocation(res.vendorid).pipe(
                    map(location => {
                      return this.mapping.orderToOrder(res, location);
                    })
                  );
                })
              );
            })
          );
        } else {
          return this.oloAPI.getAllBillingSchemesAndAccounts(orderID).pipe(
            switchMap(schemes => {
              const billingscheme = schemes.billingschemes.find((sche: any) => sche.type === 'giftcard');
              // tslint:disable-next-line: max-line-length
              let body:
                | SubmitOrderRequestWithCreditCardAsGuest
                | SubmitOrderRequestWithGiftCardAsGuest
                | SubmitOrderRequestWithSavedCardAsGuest
                | SubmitOrderWithPrepaidTransactionAsGuest
                | SubmitOrderRequestWithCreditCardTokenAsGuest;
              if (billingscheme && billingscheme.id && cardDetails.isGiftCard) {
                body = {
                  billingmethod: 'storedvalue',
                  billingschemeid: billingscheme.id,
                  billingfields: [
                    {
                      name: 'number',
                      value: cardDetails.giftCardNumber,
                    },
                    {
                      name: 'pin',
                      value: cardDetails.pin,
                    },
                  ],
                  usertype: 'guest',
                  firstname: cardDetails.firstName,
                  lastname: cardDetails.lastName,
                  emailaddress: cardDetails.emailAddress,
                  contactnumber: cardDetails.phoneNumber,
                  customdata: this.customData,
                  guestoptin: cardDetails.guestOptIn,
                } as SubmitOrderRequestWithGiftCardAsGuest;
              } else if (cardDetails.isSavedCard) {
                body = {
                  billingmethod: 'billingaccount',
                  billingaccountid: cardDetails.savedCardID,
                  usertype: 'guest',
                  firstname: cardDetails.firstName,
                  lastname: cardDetails.lastName,
                  emailaddress: cardDetails.emailAddress,
                  contactnumber: cardDetails.phoneNumber,
                  customdata: this.customData,
                  guestoptin: cardDetails.guestOptIn,
                } as SubmitOrderRequestWithSavedCardAsGuest;
              } else if (cardDetails.paymentType === PaymentTypes.prepaid) {
                body = {
                  billingmethod: 'prepaid',
                  usertype: 'guest',
                  firstname: cardDetails.firstName,
                  lastname: cardDetails.lastName,
                  emailaddress: cardDetails.emailAddress,
                  contactnumber: cardDetails.phoneNumber,
                  prepaidtransactionid: cardDetails.prepaidID,
                  prepaiddescription: cardDetails.prepaidDescription,
                  guestoptin: cardDetails.guestOptIn,
                } as SubmitOrderWithPrepaidTransactionAsGuest;
              } else if (cardDetails.paymentType === PaymentTypes.creditCardToken) {
                body = {
                  billingmethod: cardDetails.isDigitalWallet ? 'digitalwallet' : 'creditcardtoken',
                  // tslint:disable-next-line:max-line-length
                  billingschemeid: this.findDigitalWalletBillingScheme(
                    schemes.billingschemes,
                    cardDetails.isDigitalWallet,
                    cardDetails.digitalWalletType
                  ),
                  usertype: 'guest',
                  firstname: cardDetails.firstName,
                  lastname: cardDetails.lastName,
                  emailaddress: cardDetails.emailAddress,
                  contactnumber: cardDetails.phoneNumber,
                  token: cardDetails.token,
                  cardtype: cardDetails.cardType,
                  cardlastfour: cardDetails.cardLastFour,
                  expirymonth: Number(cardDetails.expirationMonth),
                  expiryyear: Number(cardDetails.expirationYear),
                  city: cardDetails.billingAddress.city,
                  state: cardDetails.billingAddress.state,
                  zip: cardDetails.billingAddress.zipCode,
                  streetaddress: cardDetails.billingAddress.address1,
                  streetaddress2: cardDetails.billingAddress.address2,
                  country: cardDetails.billingAddress.country ? cardDetails.billingAddress.country : 'US',
                  guestoptin: cardDetails.guestOptIn,
                } as SubmitOrderRequestWithCreditCardTokenAsGuest;
              } else {
                body = {
                  billingmethod: 'creditcard',
                  usertype: 'guest',
                  firstname: cardDetails.firstName,
                  lastname: cardDetails.lastName,
                  emailaddress: cardDetails.emailAddress,
                  cardnumber: cardDetails.cardNumber,
                  contactnumber: cardDetails.phoneNumber,
                  cvv: cardDetails.cvv,
                  expirymonth: Number(cardDetails.expirationMonth),
                  expiryyear: Number('20' + cardDetails.expirationYear),
                  city: cardDetails.billingAddress.city,
                  state: cardDetails.billingAddress.state,
                  zip: cardDetails.billingAddress.zipCode,
                  streetaddress: cardDetails.billingAddress.address1,
                  streetaddress2: cardDetails.billingAddress.address2,
                  country: cardDetails.billingAddress.country ? cardDetails.billingAddress.country : 'US',
                  customdata: this.customData,
                  guestoptin: cardDetails.guestOptIn,
                } as SubmitOrderRequestWithCreditCardAsGuest;
              }
              return this.oloAPI.submitOrderWithSinglePayment(orderID, body).pipe(
                switchMap(res => {
                  this.storeBasketGuid('');
                  sessionStorage.setItem('deliveryBasketGuid', res.id);
                  return this.getLocation(res.vendorid).pipe(
                    map(location => {
                      return this.mapping.orderToOrder(res, location);
                    })
                  );
                })
              );
            })
          );
        }
      })
    );
  }

  getOrderHistory(userID: string): Observable<Order[]> {
    return this.getAuthToken().pipe(
      switchMap(token => {
        // console.log(token);
        if (token) {
          return this.oloAPI.getUserRecentOrders(token).pipe(
            switchMap(res => {
              if (res.orders.length === 0) {
                return of([]);
              } else {
                return combineLatest(res.orders.map(order => this.getOrderWithLocationWithHours(order.vendorid, order)));
              }
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  fireOrder(orderID: string): Observable<Order> {
    return this.oloAPI.checkOrderStatus(orderID).pipe(
      switchMap(order => {
        return this.oloAPI
          .submitManualFireOrder(orderID, {})
          .pipe(switchMap(res => this.getOrderWithLocationWithHours(order.vendorid, res.order)));
      })
    );
  }
  // tslint:disable-next-line:max-line-length
  reorderFromHistory(authToken: string, externalOrderRef: string, orderID: string, ignoreUnavailableProducts: boolean): Observable<Order> {
    // tslint:disable-next-line:max-line-length
    return this.getAuthToken().pipe(
      switchMap(token => {
        return this.oloAPI.checkOrderStatus(orderID).pipe(
          switchMap(prevOrder => {
            // tslint:disable-next-line:max-line-length
            return this.oloAPI.createBasketFromPreviousOrder(token, externalOrderRef, orderID, ignoreUnavailableProducts).pipe(
              switchMap(basketResponse => {
                this.storeBasketGuid(basketResponse.id);
                if (prevOrder.deliverymode === 'delivery' || prevOrder.deliverymode === 'dispatch') {
                  return this.getLocation(basketResponse.vendorid).pipe(
                    switchMap(location => {
                      const address = {
                        city: prevOrder.deliveryaddress.city,
                        streetaddress: prevOrder.deliveryaddress.streetaddress,
                        zipcode: prevOrder.deliveryaddress.zipcode,
                        // phonenumber: user.phoneNumber,
                        isdefault: false,
                      } as OloAddress;
                      if (prevOrder.deliverymode === 'delivery') {
                        return this.oloAPI.setBasketDeliveryAddress(basketResponse.id, address).pipe(
                          switchMap(basketWithAddress => {
                            return this.oloAPI
                              .setHandoffMethod(basketWithAddress.id, prevOrder.deliverymode === 'dispatch' ? 'dispatch' : 'delivery')
                              .pipe(
                                switchMap(lastOrder => {
                                  return this.oloAPI.getAllBillingSchemesAndAccounts(lastOrder.id).pipe(
                                    switchMap(billingSchemes => {
                                      const order = this.mapping.createBasketResponseToOrder(
                                        lastOrder,
                                        location,
                                        billingSchemes.billingschemes
                                      );
                                      return this.contentService.getOrderItemsWithSlugsandImages(order);
                                    })
                                  );
                                }),
                                catchError(() => {
                                  return of(null);
                                })
                              );
                          })
                        );
                      } else if (prevOrder.deliverymode === 'dispatch') {
                        return this.oloAPI.setBasketDispatchAddress(basketResponse.id, address).pipe(
                          switchMap(basketWithAddress => {
                            return this.oloAPI
                              .setHandoffMethod(basketWithAddress.id, prevOrder.deliverymode === 'dispatch' ? 'dispatch' : 'delivery')
                              .pipe(
                                switchMap(lastOrder => {
                                  return this.oloAPI.getAllBillingSchemesAndAccounts(lastOrder.id).pipe(
                                    switchMap(billingSchemes => {
                                      const order = this.mapping.createBasketResponseToOrder(
                                        lastOrder,
                                        location,
                                        billingSchemes.billingschemes
                                      );
                                      return this.contentService.getOrderItemsWithSlugsandImages(order);
                                    })
                                  );
                                }),
                                catchError(() => {
                                  return of(null);
                                })
                              );
                          })
                        );
                      }
                    })
                  );
                } else {
                  return this.getLocation(basketResponse.vendorid).pipe(
                    switchMap(location => {
                      return this.oloAPI.getAllBillingSchemesAndAccounts(basketResponse.id).pipe(
                        switchMap(billingSchemes => {
                          const order = this.mapping.createBasketResponseToOrder(basketResponse, location, billingSchemes.billingschemes);
                          return this.contentService.getOrderItemsWithSlugsandImages(order);
                        })
                      );
                    })
                  );
                }
              })
            );
          })
        );
      })
    );
  }

  @Cacheable({
    maxCacheCount: 100,
  })
  getProduct(locationID: string, handoffType: HandoffType, menuID: string, categoryID: string, productID: number): Observable<Product> {
    if (isNaN(locationID as any)) {
      // URL contains a slug
      return this.directusService.getSingleLocationBySlug(locationID).pipe(
        switchMap(locationInfo => {
          return this.getProduct(locationInfo.menu_id, handoffType, menuID, categoryID, productID);
        })
      );
    } else {
      return this.getMenuByHandoffType(locationID, handoffType).pipe(
        switchMap(deMenu => {
          return this.oloAPI.getProductModifiersAndOptions(productID).pipe(
            switchMap(res => {
              const prod = this.getProductFromMenu(deMenu, categoryID, productID.toString());
              const product = this.mapping.productModifiersToProduct(prod, res.optiongroups, deMenu.imageBaseUrl);
              return this.contentService.getProductWithImages(product).pipe(
                map(mappedProd => {
                  return mappedProd;
                })
              );
            })
          );
        })
      );
      //   }
      // }
    }
  }

  @Cacheable({
    maxCacheCount: 100,
    maxAge: 5 * 60 * 1000, // 5 minutes
  })
  getMenuByHandoffType(locationID: string, handoffType: HandoffType): Observable<Menu> {
    const oloHandoffType = this.mapping.handoffTypeToHandoffType(handoffType);
    if (isNaN(locationID as any)) {
      // URL contains a slug
      return this.directusService.getSingleLocationBySlug(locationID).pipe(
        switchMap(locationInfo => {
          return this.getMenuByHandoffType(locationInfo.menu_id, handoffType);
        })
      );
    } else {
      return this.directusService.getTextFields().pipe(
        switchMap(textFields => {
          return this.oloAPI.getRestaurantMenu(locationID).pipe(
            switchMap(menu => {
              menu.categories.forEach(cat => {
                cat.products = cat.products.filter(product => !product.unavailablehandoffmodes.includes(oloHandoffType));
              });
              const deMenu = this.mapping.restaurantMenuToMenu(locationID, menu);
              return this.contentService.getMenuWithImages(deMenu);
            })
          );
        })
      );
    }
  }

  // Common rewards functions, need to be non-private to allow access to loyalty providers

  getRewards(userID: string | number, locationID: string): Observable<Reward[]> {
    return this.getAuthToken().pipe(
      switchMap(oloAuth => {
        if (oloAuth) {
          return this.oloAPI.getUsersQualifyingRewards(oloAuth, locationID).pipe(
            map(res => {
              return res.rewards.map(r => this.mapping.toReward(r));
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  redeemReward(reward: Reward): Observable<Order> {
    // console.log('reedem reward')
    return this.oloAPI.applyRewards(this.getBasketGuid(), reward.membershipID, [reward.externalRef.toString()]).pipe(
      switchMap(() => {
        return this.oloAPI.validateBasket(this.getBasketGuid(), false).pipe(
          switchMap(() => {
            return this.oloAPI.getBasket(this.getBasketGuid()).pipe(
              switchMap(basketRes => {
                return this.getLocation(basketRes.vendorid).pipe(
                  switchMap(location => {
                    return this.oloAPI.getAllBillingSchemesAndAccounts(basketRes.id).pipe(
                      switchMap(billingSchemes => {
                        const order = this.mapping.createBasketResponseToOrder(basketRes, location, billingSchemes.billingschemes);
                        return this.contentService.getOrderItemsWithSlugsandImages(order);
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  removeAppliedReward(reward: Reward): Observable<Order> {
    return this.oloAPI.removeAppliedReward(this.getBasketGuid(), Number(reward.rewardID)).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
              switchMap(billingSchemes => {
                const order = this.mapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  getProviderUpsells(basketGuid: string | number): Observable<Upsells> {
    return this.oloAPI.getEligibleUpsellItems(basketGuid).pipe(
      switchMap(res => {
        const upsells = this.mapping.toUpsell(res);
        return this.contentService.getUpsellsWithImages(upsells);
      })
    );
  }

  addProviderUpsell(basketGuid: string, body: AddUpsellItemsRequest): Observable<Order> {
    return this.oloAPI.addUpsellItemsToBasket(basketGuid, body).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
              switchMap(billingSchemes => {
                const order = this.mapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          }),
          catchError(err => {
            return throwError(this.mapping.oloError(err));
          })
        );
      }),
      catchError(err => {
        return throwError(this.mapping.oloError(err));
      })
    );
  }

  private storeBasketGuid(basketGuid: string) {
    sessionStorage.setItem(this.basketGuidKey, basketGuid);
  }

  private getBasketGuid(): string {
    return sessionStorage.getItem(this.basketGuidKey);
  }

  private removeBasketGuid(): void {
    sessionStorage.removeItem(this.basketGuidKey);
  }

  private storeGroupGuid(groupGuid: string) {
    sessionStorage.setItem(this.groupGuidKey, groupGuid);
  }

  private getGroupGuid(): string {
    return sessionStorage.getItem(this.groupGuidKey);
  }

  private removeGroupGuid(): void {
    sessionStorage.removeItem(this.groupGuidKey);
  }

  private storeAccessToken(token: string): Observable<void> {
    return from(Storage.set({ key: this.accessTokenKey, value: token })).pipe(
      map(() => {
        console.log('saving token', token);
        return this.oloAccessTokenSubject.next(token);
      })
    );
  }

  private getAccessToken(): Observable<string> {
    return from(Storage.get({ key: this.accessTokenKey })).pipe(
      map(res => {
        console.log('getting token', res.value);
        const accessToken = res.value;
        const token = accessToken && accessToken !== 'null' ? accessToken : null;
        // console.log(accessToken, token)
        this.oloAccessTokenSubject.next(token);
        return token;
      })
    );
  }

  private removeAccessToken(): void {
    Storage.remove({ key: this.accessTokenKey });
    localStorage.removeItem(this.accessTokenKey);
  }

  private storeAuthToken(token: string): Observable<void> {
    return from(Storage.set({ key: this.authTokenKey, value: token })).pipe(
      map(() => {
        // console.log('saving token', token);
        return this.oloAuthTokenSubject.next(token);
      })
    );
    // localStorage.setItem(this.authTokenKey, token);
  }

  private getAuthToken(): Observable<string> {
    return from(Storage.get({ key: this.authTokenKey })).pipe(
      map(res => {
        // console.log('getting token', res.value);
        const authToken = res.value;
        const token = authToken && authToken !== 'null' ? authToken : null;
        // console.log(authToken, token)
        this.oloAuthTokenSubject.next(token);
        return token;
      })
    );
  }

  private removeAuthToken(): void {
    Storage.remove({ key: this.authTokenKey });
    localStorage.removeItem(this.authTokenKey);
  }

  private toCommaDelimitedOptionsIDs(options: OrderItemModifier[]): string {
    const optionsIDs = [];
    if (options) {
      options.forEach(option => {
        if (!option.quantity) {
          option.quantity = 1;
        }
        for (let i = 0; i < option.quantity; i++) {
          optionsIDs.push(option.optionID);
        }
      });
    }
    return optionsIDs.join(',');
  }

  private getProductFromMenu(menu: Menu, categoryID: string, productID: string): Product {
    const category = menu.categories.find(cat => cat.categoryID === categoryID);
    if (category) {
      return category.products.find(prod => prod.productID === productID);
    } else {
      let product = null;
      for (const catKey in menu.categories) {
        if (menu.categories.hasOwnProperty(catKey) && (!product || product === null)) {
          product = menu.categories[catKey].products.find(prod => prod.productID === productID);
        }
      }
      if (product) {
        return product;
      } else {
        return menu.singleUseProducts?.products.find(prod => prod.productID === productID);
      }
    }
  }

  private getLocationWithHours(location: Location): Observable<Location> {
    // tslint:disable-next-line:max-line-length
    return this.oloAPI.getRestaurantOperatingHoursForThisWeek(Number(location.locationID), location.orderAheadDays).pipe(
      map(calendarRes => {
        return this.mapping.getLocationWithHours(location, calendarRes.calendar);
      }),
      catchError(() => {
        return of(location);
      })
    );
  }

  private getOrderWithLocationWithHours(vendorid: number, order: OloOrder): Observable<Order> {
    return this.getLocation(vendorid).pipe(
      map(location => {
        return this.mapping.orderToOrder(order, location);
      })
    );
  }

  setSpecialInstructions(orderID: string | number, inst: string): Observable<Order> {
    this.customData = [];
    const tableNum = sessionStorage.getItem('tablenumber');
    if (tableNum && tableNum !== '0') {
      this.customData.push({ key: 'Table #', value: tableNum });
    }
    if (inst) {
      this.customData.push({ key: 'Special Instructions', value: inst });
    }
    return this.getCurrentOrder(null);
  }

  private setTableNumber(orderID: string, tableNum: string, customerName: string): Observable<Order> {
    return this.getCurrentOrder(null).pipe(
      switchMap(order => {
        // tslint:disable-next-line:max-line-length
        const tableNumCustomfield = order.location.customFields?.find(
          field => field.applicableHandoffs.length === 1 && field.applicableHandoffs.includes(HandoffType.dineIn)
        );
        console.log('tableNumCustomfield', tableNumCustomfield);
        if (tableNum) {
          if (tableNumCustomfield) {
            return this.setBasketCustomfield(String(orderID), tableNumCustomfield.id, tableNum).pipe(
              switchMap(() => {
                return this.setOnPremiseAndFinish(orderID, [tableNum, ...customerName.split(' ')].join(''));
              })
            );
          } else {
            return this.setOnPremiseAndFinish(orderID, [tableNum, ...customerName.split(' ')].join(''));
          }
        } else {
          return of(order);
        }
      })
    );
  }

  private setOnPremiseAndFinish(orderID: string, tableNum: string): Observable<Order> {
    return this.oloAPI.setBasketOnPremiseDetails(String(orderID), tableNum).pipe(
      switchMap(basket => {
        return this.getCurrentOrder(null);
      }),
      catchError(() => {
        return this.getCurrentOrder(null);
      })
    );
  }

  deleteAccount(userID: string): Observable<boolean> {
    return this.getAuthToken().pipe(
      switchMap(id => {
        return this.oloAPI.deleteAccount(id).pipe(
          map((response: any) => {
            console.log(response);
            return true;
          })
        );
      })
    );
  }

  addDonation(basketGuid: string, id: number, amount: number): Observable<Order> {
    return this.oloAPI.addDonation(basketGuid, id, amount).pipe(
      switchMap((res: any) => {
        console.log(res);
        return this.getLocation(res.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
              switchMap(billingSchemes => {
                const order = this.mapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  getCurrentGroupOrder(restaurantID: number, basketGuid: string, name: string): Observable<GroupOrder> {
    return this.getAuthToken().pipe(
      switchMap(id => {
        return this.getLocation(restaurantID).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(basketGuid).pipe(
              switchMap(billingSchemes => {
                const groupID = this.getGroupGuid();
                if (groupID) {
                  return this.oloAPI.getGroupOrder(groupID, id, true, basketGuid).pipe(
                    switchMap((response: GroupOrderResponse) => {
                      return this.oloAPI.getBasket(response.basket.id).pipe(
                        map(basket => {
                          response.basket = basket;
                          return this.mapping.groupOrderResponseToOrder(
                            response,
                            location,
                            billingSchemes.billingschemes,
                            name === response.ownername
                          );
                        })
                      );
                    })
                  );
                } else {
                  return of(null);
                }
              })
            );
          })
        );
      })
    );
  }

  getGroupOrder(groupID: string, name: string, basketID: string): Observable<GroupOrder> {
    return this.getAuthToken().pipe(
      switchMap(id => {
        return this.oloAPI.getGroupOrder(groupID, id, true, basketID).pipe(
          switchMap((response: GroupOrderResponse) => {
            return this.getLocation(response.basket.vendorid).pipe(
              switchMap(location => {
                return this.oloAPI.getBasket(response.basket.id).pipe(
                  switchMap(basket => {
                    response.basket = basket;
                    return this.oloAPI.getAllBillingSchemesAndAccounts(response.basket.id).pipe(
                      map(billingSchemes => {
                        return this.mapping.groupOrderResponseToOrder(
                          response,
                          location,
                          billingSchemes.billingschemes,
                          name === response.ownername
                        );
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  joinGroupOrder(groupGuid: string, name: string, basketID: string): Observable<GroupOrder> {
    this.storeGroupGuid(groupGuid);
    return this.getGroupOrder(groupGuid, name, basketID).pipe(
      map(groupOrder => {
        this.storeBasketGuid(groupOrder.order.orderID);
        return groupOrder;
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  startGroupOrder(
    restaurantID: number,
    basketGuid: string,
    deadline: Date,
    members: string[],
    note: string,
    name: string
  ): Observable<GroupOrder> {
    return this.getAuthToken().pipe(
      switchMap(id => {
        return this.getLocation(restaurantID).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(basketGuid).pipe(
              switchMap(billingSchemes => {
                // tslint:disable-next-line:max-line-length
                return this.oloAPI.createGroupOrder(id, restaurantID, basketGuid, deadline, note).pipe(
                  switchMap((response: GroupOrderResponse) => {
                    // tslint:disable-next-line:max-line-length
                    const order = this.mapping.groupOrderResponseToOrder(
                      response,
                      location,
                      billingSchemes.billingschemes,
                      name === response.ownername
                    );
                    this.storeGroupGuid(order.groupOrderID);
                    return this.oloAPI.sendGroupOrderInvites(order.groupOrderID, id, members).pipe(
                      map(() => {
                        return order;
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  updateGroupOrder(
    restaurantID: number,
    basketGuid: string,
    groupGuid: string,
    deadline: Date,
    members: string[],
    note: string,
    name: string
  ): Observable<GroupOrder> {
    return this.getAuthToken().pipe(
      switchMap(id => {
        return this.getLocation(restaurantID).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(basketGuid).pipe(
              switchMap(billingSchemes => {
                // tslint:disable-next-line:max-line-length
                return this.oloAPI.updateGroupOrder(id, groupGuid, deadline, note).pipe(
                  switchMap((response: GroupOrderResponse) => {
                    // tslint:disable-next-line:max-line-length
                    const order = this.mapping.groupOrderResponseToOrder(
                      response,
                      location,
                      billingSchemes.billingschemes,
                      name === response.ownername
                    );
                    this.storeGroupGuid(order.groupOrderID);
                    return this.oloAPI.sendGroupOrderInvites(order.groupOrderID, id, members).pipe(
                      map(() => {
                        return order;
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  clearGroupOrder(): Observable<any> {
    this.removeGroupGuid();
    return of(null);
  }

  // tslint:disable-next-line:max-line-length
  private findDigitalWalletBillingScheme(billingSchemes: Billingscheme[], isDigitalWallet = false, type?: string): number | undefined {
    if (!isDigitalWallet) {
      return undefined;
    }
    if (type) {
      console.log('type', type);
      return billingSchemes.find(scheme => scheme.name === (type.includes('apple') ? 'Apple Pay' : 'Google Pay'))?.id;
    } else {
      return billingSchemes.find(scheme => scheme.name === (Capacitor.getPlatform() === 'ios' ? 'Apple Pay' : 'Google Pay'))?.id;
    }
  }

  private getRWGToken(): Observable<string | null> {
    // first check if expired
    return from(Storage.get({ key: LocalStorageKey.GOOGLE_RWG_TOKEN_EXPIRATION })).pipe(
      switchMap(expiration => {
        if (expiration.value) {
          const exp = moment(expiration.value);
          if (moment().isBefore(exp)) {
            return from(Storage.get({ key: LocalStorageKey.GOOGLE_RWG_TOKEN })).pipe(
              map(token => {
                return token.value;
              })
            );
          }
          return of(null);
        }
        return of(null);
      })
    );
  }

  private removeRWGToken(): void {
    Storage.remove({ key: LocalStorageKey.GOOGLE_RWG_TOKEN });
    Storage.remove({ key: LocalStorageKey.GOOGLE_RWG_TOKEN_EXPIRATION });
  }
}
