import { DistanceConfirmationComponent } from './distance-confirmation/distance-confirmation.component';
import { LocationsSearchComponent } from './locations-search/locations-search.component';
import {SimpleMapComponent} from './simple-map/simple-map.component';
import {MobileLocationListComponent} from './location-list/mobile-location-list/mobile-location-list.component';
import {DesktopLocationListComponent} from './location-list/desktop-location-list/desktop-location-list.component';
import {MobileLocationCardComponent} from './location-card/mobile-location-card/mobile-location-card.component';
import {DesktopLocationCardComponent} from './location-card/desktop-location-card/desktop-location-card.component';
import { CurbsideInfoFormComponent } from './curbside-info-form/curbside-info-form.component';
import { DeliveryInfoFormComponent } from './delivery-info-form/delivery-info-form.component';

export const components = [DistanceConfirmationComponent, LocationsSearchComponent, SimpleMapComponent, MobileLocationListComponent, DesktopLocationListComponent, DistanceConfirmationComponent, CurbsideInfoFormComponent, DeliveryInfoFormComponent];

export * from './distance-confirmation/distance-confirmation.component';
export * from './locations-search/locations-search.component';
export * from './simple-map/simple-map.component';
export * from './location-list/mobile-location-list/mobile-location-list.component';
export * from './location-list/desktop-location-list/desktop-location-list.component';
export * from './curbside-info-form/curbside-info-form.component';
export * from './delivery-info-form/delivery-info-form.component';
