<div class="p-3 pb-5">
    <div class="d-flex justify-content-end">
        <i class="bi bi-x-circle d-flex pointer" style="font-size: 30px;" (click)="activeModal.close('Close click')"></i>
    </div>
    <div class="d-flex flex-row flex-nowrap">
        <div class="grow-1 basis-0 d-sm-block d-none"></div>
        <div class="grow-3 basis-0 d-flex flex-row flex-wrap">
            <ng-container *ngIf="branding$ | async as branding">
                <div class="pb-4 d-flex justify-content-center pt-4 pt-md-0 w-100">
                    <img class="logo" alt="logo" *ngIf="branding.favicon"
                         [src]="branding.favicon.data.full_url">
                </div>
            </ng-container>
            <div class="d-flex justify-content-center w-100 mb-3">
                <h1 class="fw-bold font-size-h1 m-0 text-center">Are you sure?</h1>
            </div>
            <div class="w-100 d-flex justify-content-center mb-3">
                <div class="header-underline"></div>
            </div>
            <div class="w-100 d-flex flex-row flex-nowrap mb-3">
                <div class="w-100 text-center">
                    The selected location is <strong>{{ distance }} miles</strong> away, are you sure you would like to order from this
                    location?
                </div>
            </div>
            <div class="d-flex flex-row flex-wrap w-100">
                <div class="text-center w-100 mb-3">
                    <button class="de-primary-btn px-4 w-100" (click)="continueClicked()">
                        <span>Continue</span>
                    </button>
                </div>
                <div class="text-center w-100">
                    <a class="de-secondary-link pointer" (click)="cancelClicked()">
                        Cancel
                    </a>
                </div>
            </div>
        </div>
        <div class="grow-1 basis-0 d-sm-block d-none"></div>
    </div>
</div>
