import { Injectable } from '@angular/core';
import { User } from 'src/interfaces/user.interface';
import { UserLogInResponse } from './interfaces/user-log-in-response.model';
import { AccountInfoFields, UserInfoFields, UserInfoResponse } from './interfaces/user-info-response.model';
import { DineEngineError } from 'src/interfaces/dineengine-error.interface';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AccountHistoryResponse,
  TransactionHistoryDetail,
  TransactionHistoryTransaction,
  TransactionHistoryWalletInfo,
} from './interfaces/account-history-response.model';
import { HistoryEvent, RewardsDifference } from 'src/interfaces/history-event.interface';
import { AccountInfoResponse, BalanceInformation } from './interfaces/account-info-response.model';
import { Balance, RewardsBalances } from 'src/interfaces/rewards-balances.interface';
import { RewardsBalanceAmount } from 'src/interfaces/rewards-balance-amount.interface';
import moment from 'moment-timezone';
import { ItemConfig, SaleConfigResponse, ShippingOption, Style } from './interfaces/sale-config-response.interface';
import { CardDesign, CardValues, GiftCardConfig, GiftCardItem, GiftCardOrder, ShippingMethod } from '@modules/gift-card/models';
import { CalculatePriceRequest, OrderItemGroup } from './interfaces/calculate-price-request.interface';
import { ExecuteSaleRequest } from './interfaces/execute-sale-request.interface';
import { PurchaseableReward, Variation } from '../../interfaces/purchaseable-reward.interface';
import {
  ItemConfig as AccountItemConfig,
  SaleConfigForAccountResponse,
  WebSaleProgramType,
} from './interfaces/sale-config-for-account.interface';
import { OrderItemGroup as AccountOrderItemGroup } from './interfaces/execute-sale-for-account.interface';
import { RechargeOrder } from '../../interfaces/recharge-order.interface';
import { RechargeRequest } from './interfaces/recharge-request.interface';
import { BalanceTransferErrorCode, BalanceTransferServiceError } from './interfaces/balance-transfer-service.interface';
import { Reward } from '../../interfaces/reward.interface';
import { LoyaltyReward } from '../../interfaces/loyalty-reward.interface';
import { Location } from './interfaces/get-nearby-locations-response.interface';
import { LoyaltyLocation } from '../../interfaces/location.interface';
import { Message } from './interfaces/get-notifications-for-guest-by-printed-card-number-response.interface';
import { InboxMessage } from '../../interfaces/inbox-message.interface';
import { PaytronixConfiguration } from '../directus/interfaces/paytronix-configuration.interface';
import { CalculatePriceResponse } from './interfaces/calculate-price-response.interface';
import { Wallets } from './interfaces/get-reward-details.model';

@Injectable({
  providedIn: 'root',
})
export class PaytronixMappingService {
  toUser(user: UserLogInResponse): User {
    return {
      email: user.username,
      orderingTokenProvider: 'paytronix',
    } as User;
  }

  infoToUser(resp: UserInfoResponse, info: AccountInfoResponse, loyaltyLocations: LoyaltyLocation[]): User {
    const fields: UserInfoFields = resp.fields;
    const account: AccountInfoFields = info.fields;
    const id = resp.accountIds[resp.accountIds.length - 1];
    const cardNum = resp.primaryCardNumbers[resp.primaryCardNumbers.length - 1];
    if (id) {
      sessionStorage.setItem('PaytronixAccountID', String(id));
    }
    if (cardNum) {
      sessionStorage.setItem('PaytronixCardNum', String(cardNum));
    }
    const user = {
      birthday: fields.dateOfBirth ? moment(fields.dateOfBirth, 'YYYY-MM-DD').toDate() : null,
      emailOptIn: fields.optIn || false,
      sMSOptIn: fields.textCampaignOptIn || false,
      firstName: fields.firstName || '',
      lastName: fields.lastName || '',
      email: fields.email || info.username || '',
      phoneNumber: fields.mobilePhone || '',
      userID: id ? String(id) : sessionStorage.getItem('PaytronixAccountID'),
      cardNumber: cardNum ? cardNum : sessionStorage.getItem('PaytronixCardNum'),
      orderingTokenProvider: 'paytronix',
      isGuest: false,
      connectedWithFacebook: !!info.fields.externalAccounts.find(
        exAccount => exAccount.integration.identifier === 'xhDE_Ea4QhsfC8h3pUtHJnLZ9lRXYgysJVXnR4XNO0'
      ),
      connectedWithApple: !!info.fields.externalAccounts.find(
        exAccount => exAccount.integration.identifier === 'DW7zZ0kF0YSPUroq5H4jnm3j7RpfK9t9BB6T0sgSK8'
      ),
      userAsBarcode: cardNum ? cardNum : sessionStorage.getItem('PaytronixCardNum'),
      userAsQrCode: cardNum ? cardNum : sessionStorage.getItem('PaytronixCardNum'),
      providerSpecificFields: fields,
    } as unknown as User;
    if (account.favoriteStore) {
      user.favoriteLocation = loyaltyLocations.find(l => l.locationID === account.favoriteStore.code);
    }
    return user;
  }

  accountBalanceToRewardsBalances(paytronixAccount: AccountInfoResponse, threshold: number, homeWallet: string): RewardsBalances {
    let rewardsDollars = 0;
    if (paytronixAccount.rewardBalances && paytronixAccount.rewardBalances.length) {
      paytronixAccount.rewardBalances.forEach(rewardBalance => {
        if (!isNaN(Number(rewardBalance.balance))) {
          if (rewardBalance.balance.toString().indexOf('.') !== -1) {
            rewardsDollars += Number(rewardBalance.balance);
          }
        }
      });
    }
    let pointsDollars = 0;
    if (paytronixAccount.pointBalances && paytronixAccount.pointBalances.length) {
      paytronixAccount.pointBalances.forEach(pointBalance => {
        if (!isNaN(Number(pointBalance.balance)) && pointBalance.name === 'Points') {
          pointsDollars += Number(pointBalance.balance);
        }
      });
    }
    const balances: Balance[] = [];
    if (paytronixAccount.pointBalances && paytronixAccount.pointBalances.length) {
      paytronixAccount.pointBalances.forEach(balance => {
        balances.push({
          name: balance.name,
          longName: balance.longName,
          shortName: balance.shortName,
          value: Number(balance.balance),
          code: balance.walletCode,
          imageURL: balance.imageCode,
          description: balance.description,
          // tslint:disable-next-line:max-line-length
          expirations: balance.expirations?.map(expiration => ({
            expirationDate: moment(expiration.expirationDate, 'YYYY-MM-DD').toDate(),
            amount: expiration.amount,
          })),
        });
      });
    }
    return {
      points: pointsDollars,
      bankedRewards: rewardsDollars,
      pointsUnit: 'points',
      pointsThreshold: threshold,
      rewardAmounts: this.accountInfoToRewardsAmounts(paytronixAccount),
      storedValueCents: paytronixAccount.storedValueBalance?.balance ? Number(paytronixAccount.storedValueBalance?.balance) * 100 : null,
      pointBalances: balances,
      // homeBalance: balances[0],
      homeBalance: balances.find(balance => String(balance.code) === String(homeWallet)),
      tier: {
        name: paytronixAccount.tierLabel,
        code: paytronixAccount.tierCode,
      },
    };
  }

  // tslint:disable-next-line:max-line-length
  rewardsBalancesToRewards(
    rewardBalances: BalanceInformation[],
    saleConfig: SaleConfigForAccountResponse,
    baseImageURL: string,
    rewwrdsDetails: Wallets[]
  ): LoyaltyReward[] {
    const rewards: LoyaltyReward[] = [];
    rewardBalances.forEach(balance => {
      const details = rewwrdsDetails.find(wallet => wallet.walletCode === balance.walletCode);
      saleConfig.program?.itemConfigs?.forEach((itemConfig: AccountItemConfig) => {
        if (itemConfig.onCardRewardWalletCode === balance.walletCode) {
          if (balance.expirations.length && balance.expirations.length > 1) {
            balance.expirations.forEach(expiry => {
              rewards.push(<LoyaltyReward>{
                name: balance.name,
                description: balance.description,
                expDate: moment(expiry.expirationDate, 'YYYY-MM-DD').toDate(),
                quantity: parseInt(expiry.amount, 10),
                imageURL: balance.imageCode ? balance.imageCode : baseImageURL + itemConfig.imageUrl,
                externalReferenceID: String(balance.walletCode),
                applicablePOSIDs: details?.categoryItemCodes.filter(item => item.itemType === 'Item').map(item => item.categoryCode),
              });
            });
            // get total quantity from expirations
            const totalQuantity = balance.expirations.map(expiry => parseInt(expiry.amount, 10)).reduce((a, b) => a + b, 0);
            if (totalQuantity < Number(balance.balance)) {
              rewards.push(<LoyaltyReward>{
                name: balance.name,
                description: balance.description,
                expDate: null,
                quantity: parseInt(balance.balance, 10) - totalQuantity,
                imageURL: balance.imageCode ? balance.imageCode : baseImageURL + itemConfig.imageUrl,
                externalReferenceID: String(balance.walletCode),
                applicablePOSIDs: details?.categoryItemCodes.filter(item => item.itemType === 'Item').map(item => item.categoryCode),
              });
            }
          } else {
            rewards.push(<LoyaltyReward>{
              name: balance.name,
              description: balance.description,
              expDate: balance.expirations?.length ? moment(balance.expirations[0].expirationDate, 'YYYY-MM-DD').toDate() : null,
              quantity: parseInt(balance.balance, 10),
              imageURL: balance.imageCode ? balance.imageCode : baseImageURL + itemConfig.imageUrl,
              externalReferenceID: String(balance.walletCode),
              applicablePOSIDs: details?.categoryItemCodes.filter(item => item.itemType === 'Item').map(item => item.categoryCode),
            });
          }
        }
      });
    });
    rewardBalances.forEach(balance => {
      const details = rewwrdsDetails.find(wallet => wallet.walletCode === balance.walletCode);
      const balanceFound = rewards.find(reward => {
        return reward.name === balance.name;
      });
      if (!balanceFound) {
        if (balance.expirations.length && balance.expirations.length > 1) {
          balance.expirations.forEach(expiry => {
            rewards.push(<LoyaltyReward>{
              name: balance.name,
              description: balance.description,
              expDate: moment(expiry.expirationDate, 'YYYY-MM-DD').toDate(),
              quantity: parseInt(expiry.amount, 10),
              imageURL: balance.imageCode ? balance.imageCode : null,
              externalReferenceID: String(balance.walletCode),
              applicablePOSIDs: details?.categoryItemCodes.filter(item => item.itemType === 'Item').map(item => item.categoryCode),
            });
          });
          // get total quantity from expirations
          const totalQuantity = balance.expirations.map(expiry => parseInt(expiry.amount, 10)).reduce((a, b) => a + b, 0);
          if (totalQuantity < Number(balance.balance)) {
            rewards.push(<LoyaltyReward>{
              name: balance.name,
              description: balance.description,
              expDate: null,
              quantity: parseInt(balance.balance, 10) - totalQuantity,
              imageURL: balance.imageCode ? balance.imageCode : null,
              externalReferenceID: String(balance.walletCode),
              applicablePOSIDs: details?.categoryItemCodes.filter(item => item.itemType === 'Item').map(item => item.categoryCode),
            });
          }
        } else {
          rewards.push(<LoyaltyReward>{
            name: balance.name,
            description: balance.description,
            expDate: balance.expirations?.length ? moment(balance.expirations[0].expirationDate, 'YYYY-MM-DD').toDate() : null,
            quantity: parseInt(balance.balance, 10),
            imageURL: balance.imageCode ? balance.imageCode : null,
            externalReferenceID: String(balance.walletCode),
            applicablePOSIDs: details?.categoryItemCodes.filter(item => item.itemType === 'Item').map(item => item.categoryCode),
          });
        }
      }
    });
    return rewards;
  }

  accountInfoToRewardsAmounts(paytronixAccount: AccountInfoResponse): RewardsBalanceAmount[] {
    let rewardsAmounts: RewardsBalanceAmount[] = [];
    let pointsAmounts: RewardsBalanceAmount[] = [];
    if (paytronixAccount.rewardBalances && paytronixAccount.rewardBalances.length) {
      paytronixAccount.rewardBalances.forEach(rewardBalances => {
        rewardsAmounts = rewardsAmounts.concat(this.balanceInformationToRewardsAmounts(rewardBalances, 'Dollars'));
      });
    }
    if (paytronixAccount.pointBalances && paytronixAccount.pointBalances.length) {
      paytronixAccount.pointBalances.forEach(pointBalances => {
        pointsAmounts = pointsAmounts.concat(this.balanceInformationToRewardsAmounts(pointBalances, 'Points'));
      });
    }
    const allAmounts = rewardsAmounts.concat(pointsAmounts);
    return allAmounts && allAmounts.length ? allAmounts : null;
  }

  balanceInformationToRewardsAmounts(balanceInfo: BalanceInformation, type: string): RewardsBalanceAmount[] {
    if (!isNaN(Number(balanceInfo.balance))) {
      if (type === 'Dollars') {
        if (balanceInfo.balance.toString().indexOf('.') === -1) {
          type = 'Reward';
        }
      }
    } else {
      type = 'Error';
    }
    let rewardsAmount: RewardsBalanceAmount[] = [];
    if (balanceInfo && balanceInfo.expirations && balanceInfo.expirations.length) {
      rewardsAmount = balanceInfo.expirations.map(expiry => {
        return {
          type,
          description: type === 'Reward' ? balanceInfo.name : type,
          amount: expiry.amount,
          expDate: moment(expiry.expirationDate, 'YYYY-MM-DD').toDate(),
        };
      });
    }
    return rewardsAmount;
  }

  accountHistoryToHistoryEvents(paytronixHistory: AccountHistoryResponse): HistoryEvent[] {
    // tslint:disable-next-line:max-line-length
    paytronixHistory.transactions = paytronixHistory.transactions.filter(
      t => t.transactionType !== 'Balance Inquiry' && t.transactionType !== 'Identify Customer'
    );
    return paytronixHistory.transactions.map(t => {
      const historyEvent: HistoryEvent = {
        type: t.transactionType,
        title: '',
        description: '',

        rewardsEarned: this.transactionDetailsToRewardsEarned(t, paytronixHistory.walletInfo),
        rewardsUsed: this.transactionDetailsToRewardsUsed(t, paytronixHistory.walletInfo),

        // tslint:disable-next-line:max-line-length
        pointsOff:
          t.details && t.details.length
            ? parseInt(
                t.details.reduce(
                  (td, d) =>
                    ({
                      redeemed: String(parseInt(td.redeemed, 10) + parseInt(d.redeemed, 10)),
                    }) as TransactionHistoryDetail
                ).redeemed,
                10
              )
            : 0,
        dollarsOff: 0,
        // tslint:disable-next-line:max-line-length
        pointsEarned:
          t.details && t.details.length
            ? parseInt(
                t.details.reduce(
                  (td, d) =>
                    ({
                      accrued: String(parseInt(td.accrued, 10) + parseInt(d.accrued, 10)),
                    }) as TransactionHistoryDetail
                ).accrued,
                10
              )
            : 0,
        dollarsEarned: 0,
        timeStamp: moment.utc(moment(t.datetime, 'YYYY-MM-DD HH:mm:ss Z')).local().toDate(),
      };
      return historyEvent;
    });
  }

  // tslint:disable-next-line:max-line-length
  transactionDetailsToRewardsEarned(
    transaction: TransactionHistoryTransaction,
    historyWallets: TransactionHistoryWalletInfo[]
  ): RewardsDifference[] {
    const rewardDifferences: RewardsDifference[] = [];
    transaction.details.forEach((trasactionDetail: TransactionHistoryDetail) => {
      historyWallets.forEach((wallet: TransactionHistoryWalletInfo) => {
        if (trasactionDetail.walletCode === wallet.walletCode) {
          if (parseFloat(trasactionDetail.accrued) !== 0) {
            const rewardDifference: RewardsDifference = {
              label: wallet.name,
              quantity: trasactionDetail.accrued,
              isExpiration: transaction.transactionType === 'Campaign Expiration',
            };
            rewardDifferences.push(rewardDifference);
          }
        }
      });
    });
    return rewardDifferences;
  }

  // tslint:disable-next-line:max-line-length
  transactionDetailsToRewardsUsed(
    transaction: TransactionHistoryTransaction,
    historyWallets: TransactionHistoryWalletInfo[]
  ): RewardsDifference[] {
    const rewardDifferences: RewardsDifference[] = [];
    transaction.details.forEach((trasactionDetail: TransactionHistoryDetail) => {
      historyWallets.forEach((wallet: TransactionHistoryWalletInfo) => {
        if (trasactionDetail.walletCode === wallet.walletCode) {
          if (parseFloat(trasactionDetail.redeemed) !== 0) {
            const rewardDifference: RewardsDifference = {
              label: wallet.name,
              quantity: trasactionDetail.redeemed,
              isExpiration: transaction.transactionType === 'Campaign Expiration',
            };
            rewardDifferences.push(rewardDifference);
          }
        }
      });
    });
    return rewardDifferences;
  }

  loginError(err: Error, isFacebook = false): DineEngineError {
    const defMsg = 'The username and/or password entered is incorrect.';
    let msg = err.message;
    if (err instanceof HttpErrorResponse) {
      switch (err.status) {
        case 401:
          {
            switch (
              err.error.errorCode // https://developers.paytronix.com/errors.html
            ) {
              case 'authentication.no_matching_guests':
                msg = defMsg;
                break;
              case 'authentication.could_not_authenticate':
                msg = isFacebook
                  ? "It doesn't look like you've connected your account to Facebook. Please sign in or create a new account and connect your account to Facebook."
                  : defMsg;
                break;
              default:
                msg = defMsg;
            }
          }
          break;
        case 520:
          msg = defMsg;
          break;
      }
    }
    return new DineEngineError(msg);
  }

  saleConfigResponseToEGiftConfig(saleConfig: SaleConfigResponse): GiftCardConfig {
    let giftCardConfig: GiftCardConfig = {
      id: null,
      cardDesigns: [],
      cardValues: null,
      shippingMethods: [],
    };
    saleConfig.program.itemConfigs.forEach((itemConfig: ItemConfig) => {
      const cardValues: CardValues = {
        initialPrice: itemConfig.initialPrice,
        upperLimit: itemConfig.priceRangeHigh,
        lowerLimit: itemConfig.priceRangeLow,
        priceIntervals: itemConfig.priceList,
        allowCustomerChoice: itemConfig.customPriceEnabled,
      };
      const cardDesigns: CardDesign[] = [];
      itemConfig.styles.forEach((style: Style) => {
        const cardDesign: CardDesign = {
          id: style.code,
          label: style.label,
          description: style.description,
          imageURL: style.imageUrl,
        };
        cardDesigns.push(cardDesign);
      });
      giftCardConfig = {
        id: itemConfig.code,
        cardDesigns,
        cardValues,
        shippingMethods: [],
      };
    });
    return giftCardConfig;
  }

  saleConfigResponseToGiftCardConfig(saleConfig: SaleConfigResponse, oauthURL: string): GiftCardConfig {
    let giftCardConfig: GiftCardConfig = {
      id: null,
      cardDesigns: [],
      cardValues: null,
      shippingMethods: [],
    };
    saleConfig.program.itemConfigs.forEach((itemConfig: ItemConfig) => {
      const cardValues: CardValues = {
        initialPrice: itemConfig.initialPrice,
        upperLimit: itemConfig.priceRangeHigh,
        lowerLimit: itemConfig.priceRangeLow,
        priceIntervals: itemConfig.priceList,
        allowCustomerChoice: itemConfig.customPriceEnabled,
      };
      const cardDesigns: CardDesign[] = [
        {
          id: itemConfig.code,
          label: itemConfig.label,
          description: itemConfig.label,
          imageURL: oauthURL + itemConfig.thumbnailImageUrl,
        },
      ];
      giftCardConfig = {
        id: itemConfig.code,
        cardDesigns,
        cardValues,
        shippingMethods: [],
      };
    });
    const shippingMethods: ShippingMethod[] = [];
    saleConfig.program.shippingOptions.forEach((shippingOption: ShippingOption) => {
      const shippingMethod: ShippingMethod = {
        label: shippingOption.label,
        code: shippingOption.code,
        price: shippingOption.price,
      };
      shippingMethods.push(shippingMethod);
    });
    giftCardConfig.shippingMethods = shippingMethods;
    return giftCardConfig;
  }

  giftCardOrderToCalculatePriceRequest(giftCardOrder: GiftCardOrder, ptxSettings: PaytronixConfiguration): CalculatePriceRequest {
    const priceRequest: CalculatePriceRequest = {
      authentication: null,
      merchantId: null,
      programType: giftCardOrder.orderType,
      orderItemGroups: [],
      cardTemplateCode:
        giftCardOrder.orderType === WebSaleProgramType.EGIFT
          ? ptxSettings.egift_card_template_code
          : ptxSettings.physical_gift_card_template_code,
    };
    const group: OrderItemGroup = {
      groupNumber: 1,
      recipient: {
        firstName: giftCardOrder.shippingInfo?.firstName ?? 'Gift',
        lastName: giftCardOrder.shippingInfo?.lastName ?? 'Order',
        email: giftCardOrder.shippingInfo?.emailAddress ?? 'test@test.com',
        phone: String(giftCardOrder.shippingInfo?.phoneNumber).replace(/\D/g, '') ?? '5555555555',
      },
      deliveryDate: null,
      orderItems: [],
      personalizedFrom: null,
      personalizedTo: null,
      personalizedMessage: null,
    };
    giftCardOrder.giftCards.forEach((item: GiftCardItem, i: number) => {
      group.orderItems.push({
        itemNumber: i + 1,
        code: item.id,
        styleCode: item.design,
        quantity: item.quantity,
        value: item.value,
      });
      group.deliveryDate = item.deliveryDate;
      group.personalizedTo = item.recipient;
      group.personalizedFrom = item.sender;
      group.personalizedMessage = item.message;
    });
    priceRequest.orderItemGroups.push(group);
    if (giftCardOrder.shippingInfo) {
      priceRequest.shippingOptionCode = giftCardOrder.shippingInfo.shippingMethod;
    }
    return priceRequest;
  }

  calculatePriceResponseImageMapping(
    calculatePriceResponse: CalculatePriceResponse,
    ptxSettings: PaytronixConfiguration
  ): CalculatePriceResponse {
    return {
      ...calculatePriceResponse,
      order: {
        ...calculatePriceResponse.order,
        promotionItems: !calculatePriceResponse.order.promotionItems
          ? null
          : calculatePriceResponse.order.promotionItems.map(promotionItem => {
              return {
                ...promotionItem,
                imageURL: promotionItem.imageUrl.includes('http')
                  ? promotionItem.imageUrl
                  : ptxSettings.oauth_base_url + promotionItem.imageUrl,
              };
            }),
      },
    };
  }

  giftCardOrderToExecuteSaleRequest(
    giftCardOrder: GiftCardOrder,
    calculateResponse: CalculatePriceResponse,
    ptxSettings: PaytronixConfiguration
  ): ExecuteSaleRequest {
    const saleRequest: ExecuteSaleRequest = {
      authentication: null,
      merchantId: null,
      cardTemplateCode:
        giftCardOrder.orderType === WebSaleProgramType.EGIFT
          ? ptxSettings.egift_card_template_code
          : ptxSettings.physical_gift_card_template_code,
      programType: giftCardOrder.orderType,
      billingContact: {
        firstName: giftCardOrder.billingInfo.firstName,
        lastName: giftCardOrder.billingInfo.lastName,
        email: giftCardOrder.billingInfo.emailAddress,
        phone: String(giftCardOrder.billingInfo.phoneNumber),
      },
      billingAddress: {
        street: giftCardOrder.billingInfo.address.streetAddress,
        street2: giftCardOrder.billingInfo.address.suiteNo,
        city: giftCardOrder.billingInfo.address.city,
        stateProvince: giftCardOrder.billingInfo.address.state,
        postalCode: String(giftCardOrder.billingInfo.address.zipCode),
        country: giftCardOrder.billingInfo.address.country,
        phone: String(giftCardOrder.billingInfo.phoneNumber),
      },
      shippingSameAsBilling: false,
      shippingContact: giftCardOrder.shippingInfo
        ? {
            firstName: giftCardOrder.shippingInfo.firstName,
            lastName: giftCardOrder.shippingInfo.lastName,
            email: giftCardOrder.shippingInfo.emailAddress,
            phone: String(giftCardOrder.shippingInfo.phoneNumber),
          }
        : null,
      shippingAddress: giftCardOrder.shippingInfo
        ? {
            street: giftCardOrder.shippingInfo.address.streetAddress,
            street2: giftCardOrder.shippingInfo.address.suiteNo,
            city: giftCardOrder.shippingInfo.address.city,
            stateProvince: giftCardOrder.shippingInfo.address.state,
            postalCode: String(giftCardOrder.shippingInfo.address.zipCode),
            country: 'US',
            phone: String(giftCardOrder.shippingInfo.phoneNumber),
          }
        : null,
      shippingOptionCode: giftCardOrder.shippingInfo ? giftCardOrder.shippingInfo.shippingMethod : null,
      paymentMethod: {
        paymentMethodType: 'SPREEDLY_TOKEN',
        token: giftCardOrder.paymentInfo.paymentToken,
        nickname: 'spreedly',
        tokenType: 'credit_card',
      },
      totalPrice: calculateResponse.order.totalPrice,
      promotionItemCount: calculateResponse.order.promotionItemCount,
      orderItemGroups: [],
    };
    const group: OrderItemGroup = {
      groupNumber: 1,
      recipient: {
        firstName: giftCardOrder.shippingInfo ? giftCardOrder.shippingInfo.firstName : giftCardOrder.billingInfo.firstName,
        lastName: giftCardOrder.shippingInfo ? giftCardOrder.shippingInfo.lastName : giftCardOrder.billingInfo.lastName,
        email: giftCardOrder.shippingInfo ? giftCardOrder.shippingInfo.emailAddress : giftCardOrder.billingInfo.emailAddress,
        phone: giftCardOrder.shippingInfo
          ? String(giftCardOrder.shippingInfo.phoneNumber).replace(/\D/g, '')
          : String(giftCardOrder.billingInfo.phoneNumber).replace(/\D/g, ''),
      },
      deliveryDate: null,
      orderItems: [],
      personalizedFrom: null,
      personalizedTo: null,
      personalizedMessage: null,
    };
    let total = 0;
    giftCardOrder.giftCards.forEach((item: GiftCardItem, i: number) => {
      group.orderItems.push({
        itemNumber: i + 1,
        code: item.id,
        styleCode: item.design,
        quantity: item.quantity,
        value: Number(item.value),
      });
      total += Number(item.value) * item.quantity;
      group.deliveryDate = item.deliveryDate;
      group.personalizedTo = item.recipient;
      group.personalizedFrom = item.sender;
      group.personalizedMessage = item.message;
    });
    saleRequest.totalPrice = (
      Number(saleRequest.totalPrice) + (giftCardOrder.shippingInfo ? giftCardOrder.shippingInfo.shippingPrice : 0)
    ).toFixed(2);
    saleRequest.orderItemGroups.push(group);
    return saleRequest;
  }

  giftCardDetailsToRechargeRequest(rechargeOrder: RechargeOrder): RechargeRequest {
    return {
      authentication: 'b2b',
      merchantId: null,
      printedCardNumber: rechargeOrder.cardNumber,
      amount: rechargeOrder.amount,
      paymentMethod: {
        paymentMethodType: 'SPREEDLY_TOKEN',
        token: rechargeOrder.paymentDetails.paymentToken,
        nickname: 'spreedly',
        tokenType: 'credit_card',
      },
      address: {
        street: rechargeOrder.address.street,
        street2: rechargeOrder.address.street2,
        city: rechargeOrder.address.city,
        stateProvince: rechargeOrder.address.stateProvince,
        postalCode: rechargeOrder.address.zipCode,
        country: rechargeOrder.address.country,
      },
    };
  }

  itemConfigToPurchaseableReward(itemConfig: AccountItemConfig, baseImageURL: string): PurchaseableReward {
    return {
      rewardID: String(itemConfig.code),
      name: itemConfig.label,
      description: itemConfig.description,
      pointCost: Number(itemConfig.price),
      imageURL: baseImageURL + itemConfig.imageUrl,
      variations: itemConfig.styles ? itemConfig.styles.map(style => this.styleToVariation(style, baseImageURL)) : null,
    };
  }

  styleToVariation(style: Style, baseImageURL: string): Variation {
    return {
      variationID: style.code,
      name: style.label,
      description: style.description,
      imageURL: baseImageURL + style.imageUrl,
    };
  }

  purchaseableRewardToOrderItemGroup(reward: PurchaseableReward, variation?: Variation): AccountOrderItemGroup {
    return {
      groupNumber: 1,
      recipient: null,
      deliveryDate: null,
      orderItems: [
        {
          itemNumber: 1,
          code: reward.rewardID,
          styleCode: variation ? variation.variationID : null,
          quantity: 1,
        },
      ],
      personalizedFrom: null,
      personalizedTo: null,
      personalizedMessage: null,
    };
  }

  balanceTransferErrorToDEError(error: Error | BalanceTransferServiceError): DineEngineError {
    const deError: DineEngineError = {
      name: 'Transfer Error',
      message: 'message' in error ? error.message : 'Unknown error',
    };
    if ('result' in error && 'errorCode' in error && 'errorMessage' in error) {
      switch (error.errorCode) {
        case BalanceTransferErrorCode.SYSTEM_ERROR:
          deError.message = error.errorMessage;
          break;
        case BalanceTransferErrorCode.INVALID_FROM_CARD:
          deError.message = 'The card number you entered is invalid';
          break;
        case BalanceTransferErrorCode.INVALID_TO_CARD:
          deError.message = 'We were unable to transfer the balance to your loyalty account';
          break;
        case BalanceTransferErrorCode.INACTIVE_FROM_ACCOUNT:
          deError.message = 'The account linked to the card you entered is inactive';
          break;
        case BalanceTransferErrorCode.INACTIVE_FROM_CARD:
          deError.message = 'The card number you entered is inactive';
          break;
        case BalanceTransferErrorCode.REGISTERED_FROM_CARD:
          deError.message = 'The card number you entered is registered with another account';
          break;
        case BalanceTransferErrorCode.INACTIVE_TO_ACCOUNT:
          deError.message = 'Your account is inactive';
          break;
        case BalanceTransferErrorCode.INACTIVE_TO_CARD:
          deError.message = 'Your account is inactive';
          break;
        case BalanceTransferErrorCode.NO_TRANSFERABLE_BALANCES:
          deError.message = 'The card you entered does not have any transferable balances';
          break;
        case BalanceTransferErrorCode.ALL_BALANCES_NOT_TRANSFERABLE:
          deError.message = 'We could not transfer all of the balances from the card you entered';
          break;
      }
    }
    return deError;
  }

  ptxStoreToLoyaltyLocation(store: Location): LoyaltyLocation {
    return {
      locationID: store.code,
      name: store.name,
      address: {
        address1: store.address.address1,
        address2: store.address.address2,
        city: store.address.city,
        state: store.address.stateProvince,
        zipCode: store.address.postalCode,
        country: store.address.country,
        latitude: store.latitude,
        longitude: store.longitude,
      },
    };
  }

  ptxNotificationToInboxMessage(message: Message, readIDs: string[]): InboxMessage {
    return {
      messageID: message.code,
      body: message.message,
      title: message.senderName ? `From: ${message.senderName}` : 'New Message',
      imageURL: message.pullImageLink,
      date: moment(message.date, 'YYYY-MM-DD').toDate(),
      read: readIDs.includes(message.code),
    };
  }
}
