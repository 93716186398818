import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { ProxyBaseURLService } from '../../services/proxy-base-url.service';
import {switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaytronixHttpService {

  private vendorName = 'paytronix';

  constructor(private http: HttpClient, private urlService: ProxyBaseURLService) { }

  get<T>(resource: string, params?: HttpParams): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
      return this.getHeaders().pipe(switchMap(headers => {
        return this.http.get<T>(baseURL + resource, { headers, params });
      }));
    }));
  }

  getWithBody<T>(resource: string, body: any): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
      return this.getHeaders().pipe(switchMap(headers => {
        return this.http.request<T>('GET', baseURL + resource, {
          body: JSON.stringify(body),
          headers,
          observe: 'body'
        });
      }));
    }));
  }

  put<T>(resource: string, body: any): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
      return this.getHeaders().pipe(switchMap(headers => {
        return this.http.put<T>(baseURL + resource, body, { headers });
      }));
    }));
  }

  post<T>(resource: string, body: any): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
      return this.getHeaders().pipe(switchMap(headers => {
        return this.http.post<T>(baseURL + resource, body, { headers });
      }));
    }));
  }

  patch<T>(resource: string, body: any): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
      return this.getHeaders().pipe(switchMap(headers => {
        return this.http.patch<T>(baseURL + resource, body, { headers });
      }));
    }));
  }

  delete<T>(resource: string, params?: HttpParams): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
      return this.getHeaders().pipe(switchMap(headers => {
        return this.http.delete<T>(baseURL + resource, { headers, params });
      }));
    }));
  }

  private getHeaders(): Observable<HttpHeaders> {
    if (sessionStorage.getItem('olo-mode')) {
      return of(new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Paytronix-Mode': sessionStorage.getItem('olo-mode'),
      }));
    } else {
      return of(new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }));
    }
  }
}
