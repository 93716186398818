import {Pipe, PipeTransform} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {TextField} from '../vendors/directus/interfaces/text-field.interface';
import {ModeService} from '../services/mode.service';
import {Order} from '../interfaces/order.interface';

@Pipe({
    name: 'filterBrandNameFromLocation'
})

export class FilterBrandNameFromLocationPipe implements PipeTransform {

    @Select(state => state.app.textField) textField$: Observable<TextField>;

    filterTags: string[];

    constructor() {
        this.textField$.subscribe(textFields => {
            if (textFields) {
                this.filterTags = textFields.filter_brand_name_from_location;
            }
        });
    }

    transform(value: string): string {
        let newName = value;
        this.filterTags.forEach((tag: string) => {
            newName = newName.replace(tag, '');
        });
        return newName;
    }
}
