<div class="modify-modal" [class.kiosk]="mode.mode === 'kiosk'" *ngIf="isRootOptionGroup; else nestedModifierGroup">
  <ng-container *ngIf="product$ | async">
    <div class="scroll-content" id="modify-scroll">
      <!-- desktop -->
      <div
        class="d-none d-md-flex flex-row flex-nowrap pb-3 top-header-section bg-light">
        <ng-container *ngIf="displayProduct?.thumbnailImageURL">
          <div class="p-3">
            <img
              *ngIf="displayProduct?.thumbnailImageURL"
              class="image d-flex flex-row"
              [src]="displayProduct?.thumbnailImageURL" alt="{{ displayProduct?.name }}" />
          </div>
        </ng-container>

        <div class="p-3 d-flex flex-row grow-1">
          <div class="product-description text-start">
            <div
              class="d-flex justify-content-left header-font fw-bold font-size-subheading-1">
              {{ displayProduct?.name }}
            </div>
            <div class="d-flex justify-content-left font-size-body-small">
              {{ displayProduct?.longDesc }}
            </div>
          </div>
        </div>

        <div class="d-flex mb-auto pt-3 pe-3">
          <i
            class="bi bi-x-circle modal-exit-button"
            ngbAutofocus
            (click)="this.closeModal.emit()"></i>
        </div>
      </div>

      <!-- mobile/tablet -->
      <ng-container>
        <div class="d-md-none bg-light product-title-header">
          <div>
            <!-- <div *ngIf="displayProduct?.thumbnailImageURL">
                                <img style="width: 100%; max-height: 200px; object-fit: cover; object-position: center;"
                                    [src]="displayProduct?.thumbnailImageURL">
                            </div> -->
          </div>
          <div class="p-3">
            <div class="product-description text-start d-flex flex-column gap-2">
              <div class="d-flex flex-row flex-nowrap gap-2">
                <div
                  class="d-flex justify-content-left header-font fw-bold font-size-subheading-1 xt-uppercase grow-6 basis-0">
                  {{ displayProduct?.name }}
                </div>
                <div class="d-flex justify-content-end">
                  <i
                    class="bi bi-x-circle modal-exit-button"
                    (click)="this.closeModal.emit()"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="displayProduct?.longDesc">
          <div
            class="d-flex d-md-none justify-content-left px-3 pb-3 border-bottom bg-light">
            {{ displayProduct?.longDesc }}
          </div>
        </ng-container>
      </ng-container>

      <!-- <div *ngIf="checkIfSelectedMods()" class="mod-scroll-container">
                <div class="mods-selected">
                    <app-selected-mods-scroll [productName]="displayProduct.name"
                        [selectedOptions]="sortSelectedByTime()" (clickedOption)="optionClicked($event)">
                    </app-selected-mods-scroll>
                </div>
            </div> -->
      <app-option-selector
        (clickedOption)="optionClicked($event, 'forward')"
        [optionGroups]="
          isModifierFlow ? displayProduct?.optionGroups : (displayOptionGroups$ | async)
        "
        [displayOptionGroups]="(displayOptionGroups$ | async)"
        [currencyCode]="currencyCode$ | async"
        [isLoading]="isLoading"
        [isModal]="true"
        [isModifierFlow]="isModifierFlow"
        [selectedOptions]="selectedOptions"
        [product]="displayProduct"></app-option-selector>

      <ng-container
        *ngIf="
          displayOrder?.location.supportsRecipientName ||
          displayOrder?.location.supportsSpecialInstructions
        ">
        <ng-container *ngIf="mode.mode !== 'kiosk'">
          <form [formGroup]="productDetails">
            <div class="d-flex flex-column gap-3 p-3">
              <ng-container *ngIf="displayOrder?.location.supportsRecipientName">
                <div class="d-flex flex-column">
                  <label for="recipient">Who is this for?</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="recipient"
                    id="recipient"
                    name="recipient"
                    maxlength="32"
                    placeholder="First name, last initial (optional)" />
                </div>
              </ng-container>
              <ng-container
                *ngIf="displayOrder?.location.supportsSpecialInstructions">
                <div class="d-flex flex-column">
                  <label for="specInstr">Special Instructions</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="specInstr"
                    name="specInstr"
                    id="specInstr"
                    [maxlength]="
                    displayOrder?.location.specialInstructionsMaxLength
                  "
                    placeholder="Special Instructions" />
                </div>
              </ng-container>
            </div>
          </form>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="mode.mode !== 'kiosk'">
        <ng-container *ngIf="mainSettings$ | async as settings">
          <ng-container *ngIf="settings.show_nutrition_key">
            <ng-container *ngIf="displayProduct?.nutritionInfo">
              <app-mobile-nutrition-card [nutritionInfo]="displayProduct?.nutritionInfo"></app-mobile-nutrition-card>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <div class="p-3 m-0 w-100 bottom-submit-bar border-top gap-3 shadow">
      <div class="p-0 d-flex flex-nowrap align-items-center">
        <div class="crementers align-items-center">
          <i
            (click)="decrementClicked()"
            *ngIf="productquant !== (displayProduct?.minQuantity ?? 1)"
            class="bi bi-dash-circle text-dark qty_selector h-100 remove d-flex align-items-center"></i>
          <i
            (click)="decrementClicked()"
            *ngIf="productquant === (displayProduct?.minQuantity ?? 1)"
            class="bi bi-slash-circle text-muted qty_selector h-100 qty-one remove d-flex align-items-center"></i>

          <div
            class="number bg-white d-flex align-items-center text-center justify-content-center h-100 p-3">
            {{ productquant }}
          </div>
          <i
            (click)="incrementClicked()"
            class="bi bi-plus-circle qty_selector text-dark h-100 add d-flex align-items-center"></i>
        </div>
      </div>

      <div class="w-100 p-0">
        <button
          (click)="checkIfAlcohol()"
          *ngIf="!basketProdID"
          [class.ki-buttons]="mode.mode === 'kiosk'"
          [ngClass]="{ disabledButton: !canAddToOrder }"
          class="de-primary-btn w-100"
          expand="block">
          <div *ngIf="productLoading" class="de-btn-loader"></div>
          <div id="modifySubmit" *ngIf="!productLoading">Add to Cart</div>
        </button>
        <button
          (click)="updateItem()"
          *ngIf="basketProdID"
          [ngClass]="{ disabledButton: !canAddToOrder }"
          class="de-primary-btn w-100"
          expand="block">
          <div *ngIf="productLoading" class="de-btn-loader"></div>
          <div id="modifySubmit" *ngIf="!productLoading">Update Item</div>
        </button>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #nestedModifierGroup>
  <div class="modify-modal" *ngIf="currentOption">
    <ng-container *ngIf="product$ | async; else loading">
      <div class="scroll-content" id="modify-scroll">
        <div class="p-3 bg-light back-header">
          <div class="d-flex align-items-center justify-content-start gap-2">
            <i
              class="d-flex bi bi-arrow-left-short back-arrow"
              (click)="
                saveChoices(currentOption); modifyAnimation('backward')
              "></i>
            <small>{{ parentItem?.name }}</small>
            <div *ngIf="displayBottom" class="ms-auto">
              <div class="d-flex justify-content-end">
                <i
                  class="bi bi-x-circle modal-exit-button"
                  (click)="this.closeModal.emit()"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="p-3 bg-light border-bottom">
          <div class="product-description d-flex flex-column gap-2 text-start">
            <div
              class="d-flex justify-content-left header-font fw-bold font-size-subheading-1">
              {{ currentOption?.name }}
            </div>
            <div class="d-flex justify-content-left">
              {{ currentOption?.description }}
            </div>
          </div>
        </div>
        <!-- <div *ngIf="checkIfSelectedMods()" class="mod-scroll-container">
                    <div class="mods-selected">
                        <app-selected-mods-scroll [productName]="displayProduct.name"
                            [selectedOptions]="sortSelectedByTime()" (clickedOption)="optionClicked($event)">
                        </app-selected-mods-scroll>
                    </div>
                </div> -->
        <app-option-selector
          (clickedOption)="optionClicked($event, 'forward')"
          [optionGroups]="currentOption.optionGroups"
          [displayOptionGroups]="(displayOptionGroups$ | async)"
          [isLoading]="isLoading"
          [isModal]="true"
          [isModifierFlow]="isModifierFlow"
          [selectedOptions]="selectedOptions"
          [product]="displayProduct"></app-option-selector>
      </div>
      <div
        class="px-3 py-3 m-0 w-100 bottom-submit-bar border border-top"
        *ngIf="displayBottom">
        <div class="w-100 p-0">
          <button
            (click)="saveChoices(currentOption); modifyAnimation('backward')"
            [ngClass]="{
              disabledButton: !menuService.isOptionValid(
                currentOption,
                ((displayOptionGroups$ | async) ?? [])
              )
            }"
            class="de-primary-btn w-100"
            expand="block">
            <div *ngIf="productLoading" class="de-btn-loader"></div>
            <div id="modifySubmit" *ngIf="!productLoading">Save Choices</div>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #loading>
  <div
    class="de-btn-loader-secondary"
    style="
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    "></div>
</ng-template>
