import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  Injector,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { animate, style, transition, trigger } from '@angular/animations';
import { createCustomElement } from '@angular/elements';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subscription } from 'rxjs';
import { filter, take, tap, withLatestFrom } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import * as Sentry from '@sentry/browser';
import { Config, MenuController, ModalController, NavController } from '@ionic/angular';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Browser } from '@capacitor/browser';
import { Preferences as Storage } from '@capacitor/preferences';
import { SetUserSavedAddress } from 'src/store/actions/location.actions';
import { InitializeOrder } from 'src/store/actions/order.actions';
import { AppStateModel } from '../store/state/app.state';
import { LogOut } from '../store/actions/user.actions';
import { UtilityService } from '@modules/utility/services/utility.service';
import { HookService } from '@modules/utility/services/hook.service';
import { OrderTypeService } from '@modules/cart/services/order-type.service';
import { UserLocationService } from '@modules/locations/services/user-locations.service';
import { UserService } from '../services/vendor-config-service/user.service';
import { MobileService } from '../services/mobile.service';
import { DirectusService } from '../vendors/directus/directus.service';
import { ProjectKeyService } from '../services/project-key.service';
import { ModeService } from '../services/mode.service';
import { AnalyticsService } from './services/analytics/analytics.service';

import { fadeAnimation } from '../animations/page-transition.animation';

import { HomeMenuComponent } from '@modules/brochure/components/home-menu/home-menu.component';
import { HomeReorderComponent } from '@modules/brochure/components/home-reorder/home-reorder.component';
import { InstafeedComponent } from '@modules/brochure/components/instafeed/instafeed.component';
import { FeaturedItemsComponent } from '@modules/brochure/components/featured-items/featured-items.component';

import { MainSettings } from '../vendors/directus/interfaces/main-settings.interface';
import { Branding } from 'src/vendors/directus/interfaces/branding.interface';
import { ThemeColor } from '../vendors/directus/interfaces/theme-color.interface';
import { CustomPage } from '../vendors/directus/interfaces/custom-page.interface';
import { Order } from '../interfaces/order.interface';
import { User } from '../interfaces/user.interface';
import { Location } from '../interfaces/location.interface';
import { VendorSetup } from '../interfaces/vendor.interface';
import { NavigationService } from '@modules/navigation/services';
import { Capacitor } from '@capacitor/core';
import { StaticLocationsComponent } from '@modules/brochure/containers/static-locations/static-locations.component';
import { AppmassiveUpdaterComponent, OnboardingSlidesComponent } from '@common/components';
import { bounceAnimation, fadeInOnEnterAnimation } from 'angular-animations';
import { PendingRequestsService } from '@app/services/pending-requests.service';
import { GlobalStateModel } from '../store/state.model';
import { GroupOrder } from '../interfaces/group-order.interface';
import { TextField } from '../vendors/directus/interfaces/text-field.interface';
import { OnlineStatusService } from '@modules/utility/services';
import { DeviceDetectionService } from '../services/device-detection.service';
import { InboxMessage } from '../interfaces/inbox-message.interface';
import { NotificationService } from '../services/notification.service';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { ISavedAddress } from '@modules/locations/models/saved-address.interface';
import { ThemeService } from '../services/theme.service';
import { BrandingService } from '../services/branding.service';
import { LocalStorageKey } from '../models/common.enum';
import moment from 'moment-timezone';
import { HttpParams } from '@angular/common/http';
import { App, URLOpenListenerEvent } from '@capacitor/app';

const { version: appVersion } = require('../../package.json');

declare const FB: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  animations: [
    fadeAnimation,
    trigger('fade', [
      transition('void => active', [
        // using status here for transition
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1, height: '*' })),
      ]),
      transition('* => void', [animate(500, style({ opacity: 0, height: '0' }))]),
    ]),
    fadeInOnEnterAnimation(),
    bounceAnimation(),
  ],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
  @Select((state: GlobalStateModel) => state.app)
  app$: Observable<AppStateModel>;
  @Select((state: GlobalStateModel) => state.order.order)
  order$: Observable<Order>;
  @Select((state: GlobalStateModel) => state.user.user) user$: Observable<User>;
  @Select((state: GlobalStateModel) => state.app.branding)
  branding$: Observable<Branding>;
  @Select((state: GlobalStateModel) => state.app.vendorSetup)
  vendorSetup$: Observable<VendorSetup>;
  @Select((state: GlobalStateModel) => state.app.theme) theme$: Observable<ThemeColor[]>;
  @Select((state: GlobalStateModel) => state.app.mainSettings)
  mainSettings$: Observable<MainSettings>;
  @Select((state: GlobalStateModel) => state.app.customPages)
  customPages$: Observable<CustomPage[]>;
  @Select((state: GlobalStateModel) => state.user.thirdPartyWrapped)
  thirdPartyWrapped$: Observable<boolean>;
  @Select((state: GlobalStateModel) => state.order.groupOrder)
  groupOrder$: Observable<GroupOrder>;
  @Select((state: GlobalStateModel) => state.app.textField)
  textFields$: Observable<TextField>;
  @Select((state: GlobalStateModel) => state.user.inboxMessages)
  inboxMessages$: Observable<InboxMessage[]>;
  @Select((state: GlobalStateModel) => state.app.loading) loading$: Observable<boolean>;

  private orderLoadingSubject = new BehaviorSubject<boolean>(true);
  orderLoading$ = this.orderLoadingSubject.asObservable();
  private brandingLoadingSubject = new BehaviorSubject<boolean>(true);
  brandingLoading$ = this.brandingLoadingSubject.asObservable();
  private stylesheetLoadingSubject = new BehaviorSubject<boolean>(true);
  stylesheetLoading$ = this.stylesheetLoadingSubject.asObservable();
  private onboardingLoadingSubject = new BehaviorSubject<boolean>(true);
  onboardingLoading$ = this.onboardingLoadingSubject.asObservable();

  private subs: Subscription[] = [];

  displayBranding: Branding;
  displayOrder: Order;
  displayUser: User;
  displayLoc: Location;
  version = '';
  projectKey = '';
  loading = true;
  keyboardOpen = false;
  orderType = 'pickup';
  hamburgerMenuLink = '/locations';
  tableside = 'tableside';
  deliveryToggleLoading = false;
  noBrochure = true;
  showingMore = false;
  is3rdWrapper = false;
  atLeastOneMore = false;
  width = window.innerWidth;
  downloading = false;
  doneLoading = false;
  capacitor = Capacitor; // for template

  keyboardShowing = false;
  animationState = true;

  currentGroupOrder: GroupOrder;

  win: any = null;

  isFinishedDownloading = false;

  constructor(
    public modeService: ModeService,
    public mobile: MobileService,
    private navCtrl: NavController,
    private config: Config,
    public router: Router,
    public menu: MenuController,
    private title: Title,
    private meta: Meta,
    public route: ActivatedRoute,
    private modalController: ModalController,
    private ngZone: NgZone,
    public util: UtilityService,
    private hook: HookService,
    private userService: UserService,
    private directus: DirectusService,
    private keyService: ProjectKeyService,
    private store: Store,
    private orderTypeService: OrderTypeService,
    private userLoc: UserLocationService,
    private injector: Injector,
    private analytics: AnalyticsService,
    private navigation: NavigationService,
    public pendingRequests: PendingRequestsService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    public onlineStatusService: OnlineStatusService,
    public deviceDetection: DeviceDetectionService,
    public notificationService: NotificationService,
    private theme: ThemeService,
    private branding: BrandingService,
    private renderer: Renderer2
  ) {
    this.setupListeners();
  }

  setupListeners() {
    if (Capacitor.getPlatform() !== 'web') {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.ngZone.run(() => {
          const slug = event.url.split('/').pop();
          if (slug) {
            const { url, queryParams } = this.util.parseOutQueryParamsFromInternalLink(slug);
            // if (queryParams && queryParams['location']) {
            //   this.navigation.navigateToMenuPage(queryParams['location']);
            // }
            if (queryParams && queryParams['coupon']) {
              const key = 8675309;
              const decodedXorStr = this.util.base64DecodeXOR(queryParams['coupon'], key);
              sessionStorage.setItem('coupon', decodedXorStr);
            }
            if (queryParams && queryParams['reward']) {
              sessionStorage.setItem('reward', queryParams['reward']);
            }
            if (queryParams && queryParams['product']) {
              sessionStorage.setItem('product', queryParams['product']);
            }
            if (queryParams && queryParams['category']) {
              sessionStorage.setItem('category', queryParams['category']);
            }
            this.router.navigateByUrl(url);
          }
        });
      });
    } else {
      const slug = window.location.href.split('/').pop();
      const { queryParams } = this.util.parseOutQueryParamsFromInternalLink(slug);
      if (queryParams && queryParams['coupon']) {
        const key = 8675309;
        const decodedXorStr = this.util.base64DecodeXOR(queryParams['coupon'], key);
        sessionStorage.setItem('coupon', decodedXorStr);
      }
      if (queryParams && queryParams['reward']) {
        sessionStorage.setItem('reward', queryParams['reward']);
      }
      if (queryParams && queryParams['product']) {
        sessionStorage.setItem('product', queryParams['product']);
      }
      if (queryParams && queryParams['category']) {
        sessionStorage.setItem('category', queryParams['category']);
      }
    }
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams && queryParams['coupon']) {
        const key = 8675309;
        const decodedXorStr = this.util.base64DecodeXOR(queryParams['coupon'], key);
        sessionStorage.setItem('coupon', decodedXorStr);
      }
      if (queryParams && queryParams['reward']) {
        sessionStorage.setItem('reward', queryParams['reward']);
      }
      if (queryParams && queryParams['product']) {
        sessionStorage.setItem('product', queryParams['product']);
        if (this.store.selectSnapshot((state: GlobalStateModel) => state.order.order)) {
          this.navigation.navigateToMenuPage(this.store.selectSnapshot((state: GlobalStateModel) => state.order.order).location.locationID);
        }
      }
      if (queryParams && queryParams['category']) {
        if (this.store.selectSnapshot((state: GlobalStateModel) => state.order.order)) {
          this.navigation.navigateToMenuPage(
            this.store.selectSnapshot((state: GlobalStateModel) => state.order.order).location.locationID,
            queryParams['category']
          );
        } else {
          sessionStorage.setItem('category', queryParams['category']);
        }
      }
    });
  }

  async ngOnInit() {
    this.onlineStatusService.checkNetworkStatus();
    if (Capacitor.getPlatform() === 'android') {
      await this.performImmediateUpdate();
    }

    if (Capacitor.getPlatform() !== 'web') {
      const updaterModal = await this.modalController.create({
        component: AppmassiveUpdaterComponent,
        keyboardClose: false,
        animated: true,
        mode: 'ios',
        backdropDismiss: false,
      });
      updaterModal.present();
    }

    // Loading
    this.setLoaders();

    // Native
    this.setupCapacitorKeyboard();

    // Builder Elements
    this.createCustomElements(this.injector);

    // Bootstrap App
    this.keyService.getProjectKey().subscribe(key => {
      this.projectKey = key;
      this.initializeApp();
    });

    this.directus.getInitialData();
    const root = document.documentElement;
    root.addEventListener('brochureLink', (event: CustomEvent) => {
      this.router.navigate([event.detail]);
    });

    root.addEventListener('dynamicMenuLink', () => {
      this.order$.pipe(take(1)).subscribe((order: Order) => {
        if (order) {
          const menuID = order?.location?.slugURL ? order?.location?.slugURL : order?.location?.locationID;
          this.navigation.navigateToMenuPage(menuID);
        } else {
          this.setNewOrderType();
        }
      });
    });

    this.app$.pipe(filter(x => x.navbarSettings !== null)).subscribe(app => {
      document.documentElement.style.setProperty(
        '--heightoffset',
        app.navbarSettings.disable_hamburger_menu && this.width > 1100 ? '40px' : '0px'
      );
    });
    this.router.routeReuseStrategy.shouldReuseRoute = (future, current) => {
      if (future.params.hasOwnProperty('custom') && current.params.hasOwnProperty('custom')) {
        return future.params.custom === current.params.custom;
      } else {
        return false;
      }
    };
    this.order$.subscribe(order => {
      this.displayOrder = order;
      if (order) {
        this.displayLoc = order.location;
        this.hamburgerMenuLink = '/menu/' + (order?.location?.slugURL ? order?.location?.slugURL : order?.location?.locationID);
      } else {
        this.hamburgerMenuLink = '/locations';
      }
    });

    this.user$.subscribe(user => {
      this.userService.getService().subscribe(uService => {
        this.displayUser = user;
        if (uService.is3rdPartyWrapped) {
          this.is3rdWrapper = uService.is3rdPartyWrapped();
        } else {
          this.is3rdWrapper = false;
        }
      });
    });

    this.subs.push(
      this.branding$.subscribe(branding => {
        if (branding) {
          this.displayBranding = branding;
        }
      })
    );
    this.subs.push(
      this.vendorSetup$.subscribe(setup => {
        if (setup) {
          if (setup.payment_processing_provider === 'datacap') {
            this.setupDatacap();
          }
        }
      })
    );
    this.customPages$.pipe(filter(cp => cp !== null)).subscribe(pages => {
      this.atLeastOneMore = pages.some(page => {
        return page.show_on_side_menu && page.hide_under_more;
      });
    });
    this.groupOrder$.subscribe(groupOrder => (this.currentGroupOrder = groupOrder));

    this.setRWGToken();

    this.updateBodyClass();
  }

  setRWGToken() {
    const url = window.location.href;
    let httpParams;
    if (url.includes('?')) {
      httpParams = new HttpParams({ fromString: url.split('?')[1] });
    }

    if (httpParams && httpParams.get('rwg_token')) {
      Storage.set({ key: LocalStorageKey.GOOGLE_RWG_TOKEN, value: httpParams.get('rwg_token') });
      Storage.set({ key: LocalStorageKey.GOOGLE_RWG_TOKEN_EXPIRATION, value: moment().add(30, 'days').startOf('day').toISOString() });
    }
  }

  ngAfterViewInit() {
    this.win = this.util.nativeWindow;
    this.app$
      .pipe(
        filter(a => a.branding !== null),
        take(1)
      )
      .subscribe(() => {
        this.setupCapacitorMisc();
        this.brandingLoadingSubject.next(false);
      });
    this.customPages$
      .pipe(
        filter(cp => cp !== null),
        withLatestFrom(this.mainSettings$)
      )
      .subscribe(([cp, ms]) => {
        this.noBrochure = cp.find(page => page.slug === 'home' && page.status === 'published') ? false : ms.mobile_app_layout === 'classic';
      });
  }

  ngAfterViewChecked() {
    document.documentElement.style.setProperty('--keyboard-offset', '0px');
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  initializeApp() {
    if (this.modeService.mode !== 'tableside') {
      // tslint:disable-next-line:max-line-length
      this.store
        .select(state => state.user.user)
        .pipe(
          filter(u => u !== null),
          take(1)
        )
        .subscribe((user: User) => this.setOrderInit(user));
    } else {
      this.orderLoadingSubject.next(false);
    }
    this.version = appVersion;
    this.mainSettings$.pipe(filter(ms => ms !== null)).subscribe(config => {
      this.configSEOData(config);
      this.analytics.initializeAnalytics(config);
      if (config.fb_app_id) {
        this.initFacebookLogin(config.fb_app_id);
      }
      if (config.use_accessibe && Capacitor.getPlatform() === 'web') {
        this.setupAccessibe();
      }
    });
    window.addEventListener('keyboardDidHide', () => (this.keyboardOpen = false));
    window.addEventListener('keyboardDidShow', () => (this.keyboardOpen = true));
  }

  setOrderInit(userState: User) {
    if (this.modeService.mode === 'kiosk' || this.modeService.mode === 'tableside') {
      this.orderLoadingSubject.next(false);
    }
    this.store
      .dispatch(new InitializeOrder(userState.userID))
      .toPromise()
      .then(state => {
        if (state && state.order && !state.order.order) {
          const savedAddress = this.userLoc.getSavedAddresses();
          if (savedAddress && savedAddress.length) {
            this.store.dispatch(new SetUserSavedAddress(savedAddress));
          }
        } else {
          const savedAddress = this.userLoc.getSavedAddresses();
          if (savedAddress && savedAddress.length) {
            this.store.dispatch(new SetUserSavedAddress(savedAddress));
          }
        }
      });
  }

  configSEOData(config: MainSettings) {
    this.title.setTitle(config.title);
    if (config.seo_title) {
      this.meta.addTags([{ name: 'title', content: config.seo_title }]);
    }
    if (config.seo_description) {
      this.meta.addTags([{ name: 'description', content: config.seo_description }]);
    }
  }

  initFacebookLogin(facebookAppId: string) {
    // init facebook sdk
    FacebookLogin.initialize({ appId: facebookAppId });
    // load facebook sdk script
    ((d, s, id) => {
      // tslint:disable-next-line:prefer-const one-variable-per-declaration
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  setupAccessibe() {
    const emptyScript = document.getElementById('AccessibeScript');
    if (emptyScript) {
      emptyScript.remove();
    }
    const script = this._renderer2.createElement('script');
    script.id = 'AccessibeScript';
    script.src = '/assets/scripts/accessibe-loader.js';
    this._renderer2.appendChild(this._document.body, script);
  }

  setupDatacap() {
    this.directus.getDatacapSettings().subscribe(settings => {
      const domain = settings.sandbox_mode ? 'token-cert.dcap.com' : 'token.dcap.com';
      const dcapToken = document.createElement('script');
      dcapToken.src = 'https://' + domain + '/v1/client';
      document.getElementsByTagName('head')[0].appendChild(dcapToken);
    });
  }

  navigateTo(route: () => void | string) {
    if (typeof route === 'function') {
      route();
    } else {
      this.menu.close();
      this.router.navigateByUrl(route);
    }
  }

  openFeedbackForm() {
    this.menu.close();
    Sentry.showReportDialog({
      eventId: Sentry.captureMessage('User Submission'),
    });
  }

  setNewOrderType() {
    this.orderTypeService.setNewOrderType(false);
  }

  startOrder(order: ISavedAddress) {
    this.orderTypeService.startOrderFromSaved(order, false);
  }

  startDeliveryOrder() {
    this.orderTypeService.startDeliveryOrder();
  }

  startPickupOrder() {
    this.deliveryToggleLoading = true;
    this.orderTypeService.startPickupOrder().subscribe(
      () => {
        this.deliveryToggleLoading = false;
      },
      () => {
        this.deliveryToggleLoading = false;
      }
    );
  }

  startCurbsideOrder() {
    this.deliveryToggleLoading = true;
    this.orderTypeService.startCurbsideOrder().subscribe(
      () => {
        this.deliveryToggleLoading = false;
      },
      () => {
        this.deliveryToggleLoading = false;
      }
    );
  }

  startDriveThruOrder() {
    this.deliveryToggleLoading = true;
    this.orderTypeService.startDriveThruOrder().subscribe(
      () => {
        this.deliveryToggleLoading = false;
      },
      () => {
        this.deliveryToggleLoading = false;
      }
    );
  }

  startDineInOrder() {
    this.deliveryToggleLoading = true;
    this.orderTypeService.startDineInOrder().subscribe(
      () => {
        this.deliveryToggleLoading = false;
      },
      () => {
        this.deliveryToggleLoading = false;
      }
    );
  }

  logout() {
    this.store.dispatch(new LogOut());
  }

  linkButtonClicked(link: string) {
    this.util.handleDynamicLink(link);
  }

  pageClicked(page) {
    if (!page.content?.length && page?.cta_link) {
      Browser.open({ url: page.cta_link });
      this.menu.close();
    } else {
      this.router.navigate([page.slug]);
      this.menu.close();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth;
    this.app$.pipe(filter(x => x.navbarSettings !== null)).subscribe(app => {
      document.documentElement.style.setProperty(
        '--heightoffset',
        app.navbarSettings.disable_hamburger_menu && this.width > 1100 ? '40px' : '0px'
      );
    });
  }

  setupCapacitorKeyboard() {
    if (Capacitor.getPlatform() !== 'web') {
      Keyboard.addListener('keyboardWillShow', info => this.ngZone.run(() => (this.keyboardShowing = true)));
      Keyboard.addListener('keyboardDidShow', info => this.ngZone.run(() => (this.keyboardShowing = true)));
      Keyboard.addListener('keyboardWillHide', () => this.ngZone.run(() => (this.keyboardShowing = false)));
      Keyboard.addListener('keyboardDidHide', () => this.ngZone.run(() => (this.keyboardShowing = false)));
    }
  }

  createCustomElements(injector: Injector) {
    const MenuElement = createCustomElement(HomeMenuComponent, { injector });
    customElements.define('dineengine-menu', MenuElement);
    const OrderElement = createCustomElement(HomeReorderComponent, {
      injector,
    });
    customElements.define('dineengine-orders', OrderElement);
    const InstaElement = createCustomElement(InstafeedComponent, { injector });
    customElements.define('dineengine-instafeed', InstaElement);
    const FeaturedItemsElement = createCustomElement(FeaturedItemsComponent, {
      injector,
    });
    customElements.define('dineengine-featured-items', FeaturedItemsElement);
    const StaticLocationsElement = createCustomElement(StaticLocationsComponent, { injector });
    customElements.define('dineengine-static-locations', StaticLocationsElement);
  }

  setupCapacitorMisc() {
    if (Capacitor.getPlatform() !== 'web') {
      Keyboard.setResizeMode({
        mode: KeyboardResize.Native,
      });
      const hideInApp = document.createElement('style');
      hideInApp.innerText = '.hide-in-app{display:none;}';
      document.head.append(hideInApp);
      StatusBar.setStyle({
        style: this.mobile.isMobile ? Style.Dark : Style.Light,
      });
      sessionStorage.setItem('olo-mode', Capacitor.getPlatform());
    } else {
      const hideInDesktop = document.createElement('style');
      hideInDesktop.innerText = '.hide-in-browser{display:none;}';
      document.head.append(hideInDesktop);
    }
  }

  setLoaders() {
    combineLatest([this.brandingLoading$, this.user$]).subscribe(([branding, user]) => {
      setTimeout(() => {
        this.loading = !branding || !user;
        if (Capacitor.getPlatform() !== 'web') {
          this.loading = false;
        }
        this.doneLoading = true;
      }, 600);
    });
  }

  animationDone() {
    this.animationState = !this.animationState;
  }

  private performImmediateUpdate = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (result.immediateUpdateAllowed) {
      await AppUpdate.performImmediateUpdate();
    }
  };

  updateBodyClass() {
    if (this.modeService.mode === 'kiosk') {
      this._renderer2.addClass(document.body, 'kiosk');
    } else {
      this._renderer2.removeClass(document.body, 'kiosk');
    }
  }
}
