import { Address } from '../../interfaces/address.interface';
import { OrderItem, Product } from '../../interfaces/product.interface';
import { Order } from '../../interfaces/order.interface';
import { HandoffType } from '../../interfaces/handoff-type.enum';
import { Reward } from '../../interfaces/reward.interface';
import { CardDetails } from '../../interfaces/card-details.interface';
import { Moment } from 'moment-timezone';

export class InitializeOrder {
  static readonly type = '[Order] Initialize Order';

  constructor(public userID: string) {}
}

export class SetOrCreateOrder {
  static readonly type = '[Order] Set or Create Order';

  constructor(
    public locationID: string,
    public handoffType: HandoffType
  ) {}
}

export class SetOrderReadyTime {
  static readonly type = '[Order] Set Order Ready Time';

  constructor(public readyTime: Moment | 'asap') {}
}

export class SetManualFireOrder {
  static readonly type = '[Order] Set Manual Fire Order';

  constructor() {}
}

export class UpdateDelivery {
  static readonly type = '[Order] Update Delivery';

  constructor(public address: Address) {}
}

export class AddToOrder {
  static readonly type = '[Order] Add to Order';

  constructor(
    public item: OrderItem,
    public isUpsellPage: boolean = false
  ) {}
}

export class AddSingleUseItemToOrder {
  static readonly type = '[Order] Add Single Use Item to Order';

  constructor(public item: OrderItem) {}
}

export class RemoveFromOrder {
  static readonly type = '[Order] Remove from Order';

  constructor(public item: OrderItem) {}
}

export class UpdateOrderItem {
  static readonly type = '[Order] Update Order Item';

  constructor(
    public item: OrderItem,
    public quantity: number
  ) {}
}

export class UpdateBasketItem {
  static readonly type = '[Order] Update Basket Item';

  constructor(
    public product: OrderItem,
    public quantity: number
  ) {}
}

export class ClearOrder {
  static readonly type = '[Order] Clear Order';

  constructor() {}
}

export class CancelOrder {
  static readonly type = '[Order] Cancel Order';

  constructor(public order: Order) {}
}

export class EditOrder {
  static readonly type = '[Order] Edit Order';

  constructor(public order: Order) {}
}

export class ReorderPastOrder {
  static readonly type = '[Order] Reorder Past Order';

  constructor(public order: Order) {}
}

export class StartOrderForDelivery {
  static readonly type = '[Order] Start Order for Delivery';

  constructor(
    public locationID: string,
    public handoffType: HandoffType,
    public address: Address
  ) {}
}

export class CreateNewOrder {
  static readonly type = '[Order] Create New Order';

  constructor(
    public locationID: string,
    public handoffType: HandoffType,
    public address: Address,
    public tableNumber?: string
  ) {}
}

export class SetHandoffType {
  static readonly type = '[Order] Set Handoff Type';

  constructor(public handoffType: HandoffType) {}
}

export class AddTip {
  static readonly type = '[Order] Add Tip';

  constructor(public tipAmount: number) {}
}

export class AddPromoCode {
  static readonly type = '[Order] Add Promo Code';

  constructor(public promoCode: string) {}
}

export class RemovePromoCode {
  static readonly type = '[Order] Remove Promo Code';

  constructor() {}
}

export class CheckUpsell {
  static readonly type = '[Order] Check Upsells';

  constructor(public orderID: string) {}
}

export class CheckProductUpsell {
  static readonly type = '[Order] Check Product Upsells';

  constructor(public productID: string) {}
}

export class GetProviderUpsells {
  static readonly type = '[Order] Get Provider Upsells';

  constructor(public orderID: string) {}
}

export class AddProviderUpsell {
  static readonly type = '[Order] Add Provider Upsell';

  constructor(
    public orderID: string,
    public body: any
  ) {}
}

export class SetCustomField {
  static readonly type = '[Order] Set Custom Field';

  constructor(
    public customID: string,
    public value: string
  ) {}
}

export class CheckGiftCardBalance {
  static readonly type = '[Order] Check Gift Card Balance';

  constructor(
    public cardNumber: string,
    public pin: string
  ) {}
}

export class ValidateOrder {
  static readonly type = '[Order] Validate Order';

  constructor() {}
}

export class RedeemReward {
  static readonly type = '[Order] Redeem Reward';

  constructor(public reward: Reward) {}
}

export class RemoveReward {
  static readonly type = '[Order] Remove Reward';

  constructor(public reward: Reward) {}
}

export class StageReward {
  static readonly type = '[Order] Stage Reward';

  constructor(public reward: Reward) {}
}

export class SubmitPayment {
  static readonly type = '[Order] Submit Payment';

  // tslint:disable-next-line:max-line-length
  constructor(
    public cardDetails: CardDetails,
    public specialInstructions: string,
    public applyCents: number,
    public savePayment: boolean,
    public token: string
  ) {}
}

export class SetPreviousOrder {
  static readonly type = '[Order] Set Previous Order';

  constructor(public orderID: string) {}
}

export class CheckDeliveryStatus {
  static readonly type = '[Order] Check Delivery Status';

  constructor(public orderID: string) {}
}

export class NotifyOfArrival {
  static readonly type = '[Order] Notify of Arrival';

  constructor(
    public orderID: string,
    public message: string
  ) {}
}

export class StartOrderTracking {
  static readonly type = '[Order] Start Order Tracking';

  constructor(
    public order: Order,
    public customerDetails: CardDetails
  ) {}
}

export class SetCurrencyCode {
  static readonly type = '[Order] Set Currency Code';

  constructor(public order: Order) {}
}

export class UpdateToCurrentBasket {
  static readonly type = '[Order] Update to Most Current Basket';

  constructor(public userID: string) {}
}

export class GetGroupOrder {
  static readonly type = '[Order] Get Group Order';

  constructor(
    public groupID: string,
    public name: string,
    public basketID: string
  ) {}
}

export class GetCurrentGroupOrder {
  static readonly type = '[Order] Get Current Group Order';

  constructor(public name: string) {}
}

export class StartGroupOrder {
  static readonly type = '[Order] Start Group Order';

  constructor(
    public note: string,
    public members: string[],
    public deadline: Date,
    public name: string
  ) {}
}

export class UpdateGroupOrder {
  static readonly type = '[Order] Update Group Order';

  constructor(
    public note: string,
    public members: string[],
    public deadline: Date,
    public name: string
  ) {}
}

export class JoinGroupOrder {
  static readonly type = '[Order] Join Group Order';

  constructor(
    public groupID: string,
    public name: string,
    public basketID: string
  ) {}
}

export class CancelGroupOrder {
  static readonly type = '[Order] Cancel Group Order';

  constructor() {}
}

export class AddDonation {
  static readonly type = '[Order] Add Donation';

  constructor(
    public id: number,
    public amount: number
  ) {}
}

export class SetAvailableOrderDays {
  static readonly type = '[Order] Set Available Order Days';

  constructor() {}
}

export class SetAvailableOrderTimes {
  static readonly type = '[Order] Set Available Order Times';

  constructor(public date: Date) {}
}

export class FireOrder {
  static readonly type = '[Order] Fire Order';

  constructor() {}
}
