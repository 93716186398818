import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { Order } from 'src/interfaces/order.interface';
import { User } from 'src/interfaces/user.interface';
import { Location as DineEngineLocation } from '../../../../../interfaces/location.interface';

import { Address } from '../../../../../interfaces/address.interface';
import { DirectusService } from 'src/vendors/directus/directus.service';
import { Branding } from 'src/vendors/directus/interfaces/branding.interface';
import { ThemeColor } from 'src/vendors/directus/interfaces/theme-color.interface';
import { AnnouncementService } from '@modules/brochure/services/announcement.service';
import { VendorSetup } from 'src/interfaces/vendor.interface';
import { HandoffType } from '../../../../../interfaces/handoff-type.enum';
import { Select, Store } from '@ngxs/store';
import { SetRouteBack, UpdateTitle } from '../../../../../store/actions/app.actions';
import { SetLocationByID, SetLocationBySlug } from '../../../../../store/actions/location.actions';
import { CreateNewOrder } from '../../../../../store/actions/order.actions';
import { MetaService } from '../../../../../services/meta.service';
import { NavigationService } from '@modules/navigation/services';
import { OrderTypeService } from '@modules/cart/services/order-type.service';
import { SentryService } from '@common/services';
import { GlobalStateModel } from '../../../../../store/state.model';

@Component({
  selector: 'app-location-details',
  template: '',
})
export class LocationDetailsComponent implements OnInit, OnDestroy {
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.location.deliveryAddress) deliveryAddress$: Observable<Address>;
  @Select(state => state.app.branding) branding$: Observable<Branding>;
  @Select(state => state.app.theme) theme$: Observable<ThemeColor[]>;
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.location.selectedLocation) selectedLocation$: Observable<DineEngineLocation>;

  private subs: Subscription[] = [];

  // Page details for SEO
  title = 'location details';

  // Display variables for view
  displayOrder: Order;
  displayUser: User;
  displayBranding: Branding;
  displayTheme: ThemeColor[];
  displayVendorSetup: VendorSetup;
  loc: DineEngineLocation;
  deliveryAddress: Address;

  locationLoading: string;

  loading: any;
  view = 'individual';
  cmsLoc;
  timeSelection: any;
  restaurantID: string;
  preferences;
  iconUrl: string;
  loadMap = false;
  backgroundImageUrl;
  mapOptions: google.maps.MapOptions = {
    clickableIcons: false,
    gestureHandling: 'none',
    disableDefaultUI: true,
    styles: [
      {
        featureType: 'poi',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
  };
  center: google.maps.LatLngLiteral;
  infoWindowOptions: google.maps.InfoWindowOptions = {};
  errorText = 'If you change to this location you will have to re-add your items to your cart. Do you still want to switch locations?';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private navigation: NavigationService,
    // private navbarService: NavBarService,
    // private pageService: LocationDetailsPageService,
    private orderType: OrderTypeService,
    private directus: DirectusService,
    private announcementService: AnnouncementService,
    private store: Store,
    private meta: MetaService,
    private sentry: SentryService
  ) {}

  ngOnInit() {
    this.store.dispatch(new UpdateTitle(this.title));
    this.store.dispatch(new SetRouteBack('/locations'));
    // Path parameter data
    this.subs.push(
      this.route.params.subscribe(pathParams => {
        if (pathParams.id) {
          this.restaurantID = pathParams.id;
          if (isNaN(pathParams.id)) {
            this.store.dispatch(new SetLocationBySlug(this.restaurantID)).subscribe({
              next: (state: GlobalStateModel) => {
                if (state.location.selectedLocation.isHidden) {
                  this.navigation.navigateTo404Page();
                }
              },
              error: () => {
                this.navigation.navigateTo404Page();
              },
            });
            // this.pageService.getLocationFromSlug(this.restaurantID);
          } else {
            this.store.dispatch(new SetLocationByID(this.restaurantID)).subscribe({
              next: (state: GlobalStateModel) => {
                if (state.location.selectedLocation.isHidden) {
                  this.navigation.navigateTo404Page();
                }
              },
              error: () => {
                this.navigation.navigateTo404Page();
              },
            });
            // this.pageService.getLocationFromId(this.restaurantID);
          }
        }
      })
    );
    // this.subs.push(of(typeof(google)).subscribe(googs => {
    //     if (googs !== 'undefined') {
    //         this.loadMap = true;
    //     }
    // }))
    // Navbar service data
    this.subs.push(
      this.order$.subscribe(order => {
        if (order) {
          this.displayOrder = order;
        }
      })
    );
    this.subs.push(
      this.user$.subscribe(user => {
        if (user) {
          this.displayUser = user;
        } else {
          this.displayUser = null;
        }
      })
    );
    this.subs.push(
      this.deliveryAddress$.subscribe(add => {
        if (add) {
          this.deliveryAddress = add;
        }
      })
    );
    this.subs.push(
      this.branding$.subscribe(branding => {
        if (branding) {
          this.displayBranding = branding;
        }
      })
    );
    this.subs.push(
      this.theme$.subscribe(theme => {
        if (theme) {
          this.displayTheme = theme;
          this.backgroundImageUrl = `url(${this.displayTheme[0]?.default_location_image?.data?.full_url})`;
        }
      })
    );
    this.subs.push(
      this.vendorSetup$.subscribe(setup => {
        if (setup) {
          this.displayVendorSetup = setup;
        }
      })
    );
    // Page service data
    this.subs.push(
      this.selectedLocation$.subscribe(location => {
        if (location) {
          console.log(location);
          this.loc = location;
          this.center = this.createCenter();
          this.infoWindowOptions = {
            ...this.infoWindowOptions,
            content: `<div>${this.loc.name}</div>`,
          };
          this.loadCmsLocByName(location.name);
          this.addMarker();
        }
      })
    );
    this.announcementService.adjustLocationsMargin('locDetCol');
    this.announcementService.adjustLocationsPadding('detMapCol');
  }

  ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  // UI Functions
  orderNowButtonClicked(loc) {
    this.locationLoading = loc.locationID;
    this.orderType.startOrder(loc, true, HandoffType.pickup, false).then(
      () => {
        this.locationLoading = '';
      },
      () => {
        this.locationLoading = '';
      }
    );
    // TODO: Right now every basket transfer is to result in a basket wipe. When we want to revisit this, start with the above code.
    // tslint:disable-next-line:max-line-length
    // if (this.displayOrder && this.preferences === this.displayOrder.handoffType && loc.locationID === this.displayOrder.location.locationID) {
    //     // No transfer needed, it's all the same.
    //     this.navigation.navigateToMenuPage(this.restaurantID);
    // } else if (!this.displayOrder) {
    //     // Also no transfer needed, there's nothing to transfer from.
    //     this.navigation.navigateToMenuPage(this.restaurantID);
    // } else {
    //     document.getElementById('basketRemovalPopupC').style.display = 'block';
    // }
  }

  /**
   * After user has given their permission to wipe their basket and transfer locations.
   */
  proceedWithBasketTransfer() {
    if (!this.loc.supportsPickup) {
      this.preferences = HandoffType.curbside;
    } else {
      this.preferences = HandoffType.pickup;
    }
    this.store
      .dispatch(new CreateNewOrder(this.loc.locationID, this.preferences, this.deliveryAddress))
      .toPromise()
      .then(() => {
        document.getElementById('basketRemovalPopupC').style.display = 'none';
        const restaurantID = this.loc.slugURL ? this.loc.slugURL : this.loc.locationID;
        this.navigation.navigateToMenuPage(restaurantID);
      });
  }

  keepOldLocation() {
    document.getElementById('basketRemovalPopupC').style.display = 'none';
    // tslint:disable-next-line:max-line-length
    const restaurantID = this.displayOrder.location.slugURL ? this.displayOrder.location.slugURL : this.displayOrder.location.locationID;
    this.navigation.navigateToMenuPage(restaurantID);
  }

  hideTransferPrompt() {
    document.getElementById('basketRemovalPopupC').style.display = 'none';
  }

  // State stuff and old calls to be removed

  loadCmsLocByName(name) {
    if (this.loc.galleryURL) {
      this.getLocBgImg();
    } else {
      this.directus.getSingleLocation(name).subscribe(location => {
        this.cmsLoc = location;
        this.sentry.setTransactionName(location.location_name);
        this.meta.manualUpdate(
          {
            title: location.location_name,
            description: location.seo_description,
            keywords: null,
          },
          true
        );
        setTimeout(() => {
          (window as any).prerenderReady = true;
        }, 1000);
        this.getLocBgImg();
      });
    }
  }

  // Helper Functions
  // TODO: Abstract some of these into services

  addMarker() {
    this.iconUrl = this.displayBranding.location_pin_icon.data.full_url;
  }

  getLocBgImg() {
    console.log(this.loc);
    const galleryURL = this.loc.galleryURL;
    if (galleryURL) {
      this.backgroundImageUrl = "url('" + galleryURL + "')";
    } else {
      const loc = this.cmsLoc;
      if (loc && loc.location_image && loc.location_image.data && loc.location_image.data.full_url) {
        const url = loc.location_image.data.full_url;
        if (url) {
          this.backgroundImageUrl = "url('" + url + "')";
        }
        return;
      }
    }
  }

  createMarkerOptions() {
    if (this.iconUrl) {
      return {
        icon: {
          scaledSize: {
            width: 60,
            height: 60,
          },
          url: this.iconUrl,
        },
      } as google.maps.MarkerOptions;
    }
  }

  createCenter() {
    if (this.loc) {
      return {
        lat: this.loc.address.latitude,
        lng: this.loc.address.longitude,
      } as google.maps.LatLngLiteral;
    }
  }

  getTypeOfGoogle() {
    return typeof google;
  }
}
