import { Injectable } from '@angular/core';

import { catchError, concatMap, filter, map, mergeMap, switchMap, take, toArray } from 'rxjs/operators';
import { combineLatest, from, iif, Observable, of, throwError } from 'rxjs';
import { Action, State, StateContext, Store } from '@ngxs/store';

import {
  AddDonation,
  AddPromoCode,
  AddProviderUpsell,
  AddSingleUseItemToOrder,
  AddTip,
  AddToOrder,
  CancelGroupOrder,
  CancelOrder,
  CheckDeliveryStatus,
  CheckGiftCardBalance,
  CheckProductUpsell,
  CheckUpsell,
  ClearOrder,
  CreateNewOrder,
  EditOrder,
  FireOrder,
  GetCurrentGroupOrder,
  GetGroupOrder,
  InitializeOrder,
  JoinGroupOrder,
  NotifyOfArrival,
  RedeemReward,
  RemoveFromOrder,
  RemovePromoCode,
  RemoveReward,
  ReorderPastOrder,
  SetAvailableOrderDays,
  SetAvailableOrderTimes,
  SetCurrencyCode,
  SetCustomField,
  SetHandoffType,
  SetOrCreateOrder,
  SetOrderReadyTime,
  SetPreviousOrder,
  StageReward,
  StartGroupOrder,
  StartOrderForDelivery,
  StartOrderTracking,
  SubmitPayment,
  UpdateBasketItem,
  UpdateDelivery,
  UpdateGroupOrder,
  UpdateOrderItem,
  UpdateToCurrentBasket,
  ValidateOrder,
} from '../actions/order.actions';
import { SetMenu } from '../actions/menu.actions';
import { DeleteAccount, InitializeUser, LogOut, UpdateAppliedRewards } from '../actions/user.actions';

import { OrderService } from '../../services/vendor-config-service/order.service';
import { LocationService } from '../../services/vendor-config-service/location.service';
import { UserService } from '../../services/vendor-config-service/user.service';
import { OrderHistoryService } from '../../services/vendor-config-service/order-history.service';
import { DirectusService } from '../../vendors/directus/directus.service';
import { MenuService } from '../../services/vendor-config-service/menu.service';
import { LoyaltyService } from '../../services/vendor-config-service/loyalty.service';
import { PersonicaProviderService } from '../../vendors/personica/personica-provider.service';
import { AddressService } from '@modules/utility/services/address.service';
import { CustomerTrackingService } from '../../services/vendor-config-service/customer-tracking.service';
import { AnalyticsService } from '@app/services/analytics/analytics.service';

import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';

import { HandoffType } from '../../interfaces/handoff-type.enum';

import { Order } from '../../interfaces/order.interface';
import { User } from '../../interfaces/user.interface';
import { Upsells } from '../../interfaces/upsells.interface';
import { MainSettings } from '../../vendors/directus/interfaces/main-settings.interface';
import { Menu } from '../../interfaces/menu.interface';
import { GiftCardItem } from '../../interfaces/gift-card-item.interface';
import { Reward } from '../../interfaces/reward.interface';
import { produce } from 'immer';
import { Capacitor } from '@capacitor/core';
import { Dialog } from '@capacitor/dialog';
import { UtilityService } from '@modules/utility/services';
import { VendorSetup } from '../../interfaces/vendor.interface';
import { GroupOrder } from '../../interfaces/group-order.interface';
import { SentryService } from '@common/services';
import { UserStateModel } from './user.state';
import { GlobalStateModel } from '../state.model';
import { Router } from '@angular/router';
import { OrderItem } from '../../interfaces/product.interface';
import { CardDetails } from '../../interfaces/card-details.interface';
import moment from 'moment-timezone';
import { ModeService } from '../../services/mode.service';
import { Moment } from 'moment-timezone';
import { OrderProvider } from '../../providers/order-provider.interface';
import { LocalStorageKey } from '../../models/common.enum';
import { Preferences } from '@capacitor/preferences';
import { ToastService } from '../../services/toast.service';
import { NavigationService } from '@modules/navigation/services';
import { MobileService } from '../../services/mobile.service';

export interface OrderStateModel {
  order: Order;
  upsells: Upsells;
  previousOrder: Order;
  deliveryStatus: any;
  stagedReward: Reward;
  currencyCode: string;
  groupOrder: GroupOrder;
  availableOrderDays: Date[];
  availableOrderTimes: Moment[];
}

@State<OrderStateModel>({
  name: 'order',
  defaults: {
    order: null,
    upsells: null,
    previousOrder: null,
    deliveryStatus: null,
    stagedReward: null,
    currencyCode: 'USD',
    groupOrder: null,
    availableOrderDays: null,
    availableOrderTimes: null,
  },
})
@Injectable()
export class OrderState {
  groupCheckInterval: NodeJS.Timeout;
  private allowedTracking: boolean;

  constructor(
    private store: Store,
    private orderService: OrderService,
    private locationsService: LocationService,
    private orderHistoryService: OrderHistoryService,
    private directus: DirectusService,
    private menuService: MenuService,
    private loyaltyService: LoyaltyService,
    private oneSignal: OneSignal,
    private customerTrackingService: CustomerTrackingService,
    private analytics: AnalyticsService,
    private utility: UtilityService,
    private sentry: SentryService,
    private router: Router,
    private route: Router,
    private mode: ModeService,
    private navigation: NavigationService,
    private toast: ToastService,
    private mobileService: MobileService
  ) {}

  @Action(InitializeOrder)
  initializeOrder(ctx: StateContext<OrderStateModel>, action: InitializeOrder) {
    return this.store
      .select(state => state.user.user)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((user: User) => {
          const userName = !user.isGuest ? user.firstName + ' ' + user.lastName : sessionStorage.getItem('guestName');
          if (user) {
            sessionStorage.setItem('guestName', userName);
          }
          return this.orderService.getService().pipe(
            switchMap(oService => {
              return oService.getCurrentOrder(action.userID).pipe(
                switchMap(order => {
                  if (order) {
                    return oService.getCurrentGroupOrder(parseInt(order.location.locationID, 10), order.orderID, userName).pipe(
                      map(groupOrder => {
                        if (!this.groupCheckInterval) {
                          this.groupCheckInterval = setInterval(() => {
                            this.store.dispatch(new GetCurrentGroupOrder(userName));
                          }, 30000);
                        }
                        this.store.dispatch(new SetCurrencyCode(order));
                        this.store.dispatch(new SetMenu(order.location.locationID, order.handoffType));
                        if (order.appliedRewards && order.appliedRewards[0]) {
                          this.store.dispatch(new UpdateAppliedRewards(order.appliedRewards[0]));
                        }
                        this.analytics.analyticsOnOrderCreation(order);
                        this.sentry.setBasketID(order.orderID);
                        return of(
                          ctx.patchState({
                            order,
                            groupOrder,
                          })
                        );
                      })
                    );
                  } else {
                    // this.store.dispatch(new SetOrCreateOrder(action.userID, 'pickup'));
                    return of(
                      ctx.patchState({
                        order: null,
                        groupOrder: null,
                      })
                    );
                  }
                })
              );
            })
          );
        })
      );
  }

  @Action(SetOrCreateOrder)
  setOrCreateOrder(ctx: StateContext<OrderStateModel>, action: SetOrCreateOrder) {
    return this.store
      .select(state => state.user.user)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((user: User) => {
          return this.orderService.getService().pipe(
            switchMap(oService => {
              const userID = user ? user.userID : null;
              return oService.getCurrentOrder(userID).pipe(
                switchMap(order => {
                  if (order) {
                    if (order.location.locationID === action.locationID || order.location.slugURL === action.locationID) {
                      if (order.handoffType !== action.handoffType) {
                        return oService.setHandoffType(order.orderID, userID, action.handoffType).pipe(
                          map(newOrder => {
                            this.analytics.analyticsOnOrderCreation(newOrder);
                            this.sentry.setBasketID(newOrder.orderID);
                            this.store.dispatch(new SetCurrencyCode(newOrder));
                            return ctx.patchState({
                              order: newOrder,
                            });
                          })
                        );
                      } else {
                        this.analytics.analyticsOnOrderCreation(order);
                        this.sentry.setBasketID(order.orderID);
                        this.store.dispatch(new SetCurrencyCode(order));
                        return of(
                          ctx.patchState({
                            order,
                          })
                        );
                      }
                    } else {
                      return oService.setHandoffType(order.orderID, userID, action.handoffType).pipe(
                        switchMap(newOrder => {
                          return oService.transferBasket(newOrder.orderID, action.locationID).pipe(
                            map(newNewOrder => {
                              this.analytics.analyticsOnOrderCreation(newNewOrder);
                              this.sentry.setBasketID(newNewOrder.orderID);
                              this.store.dispatch(new SetCurrencyCode(newNewOrder));
                              return ctx.patchState({
                                order: newNewOrder,
                              });
                            }),
                            catchError(error => {
                              if (error.error.code === 9 && error.error.num === 9) {
                                return oService.startOrder(userID, action.locationID, action.handoffType).pipe(
                                  switchMap(() => {
                                    // tslint:disable-next-line:max-line-length
                                    return oService.setHandoffType(newOrder.orderID, userID, action.handoffType).pipe(
                                      map(newNewOrder => {
                                        this.analytics.analyticsOnOrderCreation(newNewOrder);
                                        this.sentry.setBasketID(newOrder.orderID);
                                        this.store.dispatch(new SetCurrencyCode(newNewOrder));
                                        return ctx.patchState({
                                          order: newNewOrder,
                                        });
                                        // return newNewOrder;
                                      })
                                    );
                                  })
                                );
                              } else {
                                return throwError('Error');
                              }
                            })
                          );
                        })
                      );
                    }
                  } else {
                    let tableNum = '0';
                    if (localStorage.getItem('tablenumber') !== null) {
                      tableNum = localStorage.getItem('tablenumber');
                    }
                    return this.locationsService.getService().pipe(
                      switchMap(lService => {
                        if (isNaN(action.locationID as any)) {
                          return lService.getLocationBySlug(action.locationID).pipe(
                            switchMap(loc => {
                              return oService
                                .startOrder(
                                  userID,
                                  action.locationID,
                                  loc.supportsPickup ? HandoffType.pickup : HandoffType.curbside,
                                  tableNum
                                )
                                .pipe(
                                  map(newOrder => {
                                    this.analytics.analyticsOnOrderCreation(newOrder);
                                    this.sentry.setBasketID(newOrder.orderID);
                                    this.store.dispatch(new SetCurrencyCode(newOrder));
                                    return ctx.patchState({
                                      order: newOrder,
                                    });
                                    // return newOrder;
                                  })
                                );
                            })
                          );
                        } else {
                          return lService.getLocation(action.locationID).pipe(
                            switchMap(loc => {
                              return oService
                                .startOrder(
                                  userID,
                                  action.locationID,
                                  loc.supportsPickup ? HandoffType.pickup : HandoffType.curbside,
                                  tableNum
                                )
                                .pipe(
                                  map(newOrder => {
                                    this.analytics.analyticsOnOrderCreation(newOrder);
                                    this.sentry.setBasketID(newOrder.orderID);
                                    this.store.dispatch(new SetCurrencyCode(newOrder));
                                    return ctx.patchState({
                                      order: newOrder,
                                    });
                                    // return newOrder;
                                  })
                                );
                            })
                          );
                        }
                      })
                    );
                  }
                })
              );
            })
          );
        })
      );
  }

  @Action(SetOrderReadyTime)
  setOrderReadyTime(ctx: StateContext<OrderStateModel>, action: SetOrderReadyTime) {
    return this.store
      .selectOnce(state => state.user.user)
      .pipe(
        switchMap((user: User) => {
          return this.orderService.getService().pipe(
            switchMap(oService => {
              const userID = user ? user.userID : null;

              if (action.readyTime === 'asap') {
                return oService.setTimePreferenceToASAP(ctx.getState().order.orderID, userID).pipe(
                  map(
                    orderRes => {
                      return ctx.patchState({
                        order: orderRes,
                      });
                      // return orderRes;
                    },
                    catchError(() => {
                      return oService
                        .setTimePreference(ctx.getState().order.orderID, userID, ctx.getState().order.earliestReadyTimestamp)
                        .pipe(
                          map(orderRes => {
                            return ctx.patchState({
                              order: orderRes,
                            });
                            // return orderRes;
                          })
                        );
                    })
                  )
                );
              } else {
                return oService.setTimePreference(ctx.getState().order.orderID, userID, action.readyTime.toDate()).pipe(
                  map(orderRes => {
                    return ctx.patchState({
                      order: orderRes,
                    });
                    // return orderRes;
                  })
                );
              }
            })
          );
        })
      );
  }

  @Action(UpdateDelivery)
  updateDelivery(ctx: StateContext<OrderStateModel>, action: UpdateDelivery) {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        return this.store
          .select(state => state.user)
          .pipe(
            filter(u => u !== null),
            take(1),
            switchMap((uInfo: User) => {
              return this.store
                .select(state => state.order.order)
                .pipe(
                  filter(u => u !== null),
                  take(1),
                  switchMap((order: Order) => {
                    return oService
                      .setDispatchOrDeliveryAddress(order.orderID, uInfo ? uInfo.userID : null, action.address, order.handoffType)
                      .pipe(
                        map(newOrder => {
                          return ctx.patchState({
                            order: newOrder,
                          });
                        })
                      );
                  })
                );
            })
          );
      })
    );
  }

  @Action(AddToOrder)
  addToOrder(ctx: StateContext<OrderStateModel>, action: AddToOrder) {
    return this.store
      .select(state => state.user.user)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((user: User) => {
          return this.store
            .select(state => state.order.order)
            .pipe(
              filter(u => u !== null),
              take(1),
              switchMap((order: Order) => {
                return this.menuService.getService().pipe(
                  switchMap(mService => {
                    return mService
                      .getProduct(
                        order.location.locationID,
                        order.handoffType,
                        action.item.menuID,
                        action.item.categoryID,
                        action.item.productID,
                        false
                      )
                      .pipe(
                        switchMap(menuProduct => {
                          const duplicateItem = this.utility.checkForDuplicateProduct(order, action.item, menuProduct);
                          if (duplicateItem) {
                            this.analytics.analyticsOnAddToCart(
                              action.item,
                              this.store.selectSnapshot((state: GlobalStateModel) => state.order.order).location
                            );
                            return this.store.dispatch(new UpdateOrderItem(duplicateItem, duplicateItem.quantity + action.item.quantity));
                          }
                          return this.orderService.getService().pipe(
                            switchMap(oService => {
                              return oService.addToOrder(action.item, order.orderID, user ? user.userID : null).pipe(
                                map(newOrder => {
                                  this.analytics.analyticsOnAddToCart(
                                    action.item,
                                    this.store.selectSnapshot((state: GlobalStateModel) => state.order.order).location
                                  );
                                  if (newOrder) {
                                    const timestamp = Math.floor(Date.now() / 1000);
                                    this.oneSignal.sendTags({
                                      cart_update: timestamp,
                                      product_name: action.item.name,
                                      product_image: action.item.standardImageURL,
                                    });
                                    const options = {
                                      name: 'add_to_cart',
                                      params: {
                                        currency: 'USD',
                                        items: [
                                          {
                                            item_id: action.item.productID,
                                            item_name: action.item.name,
                                            price: action.item.totalCents / 100,
                                          },
                                        ],
                                      },
                                    };
                                    this.analytics.firebaseGenericLog(options);
                                    this.checkIfItemIsRewardItemAndApplyRewardIfFound(action.item, ctx);
                                    return ctx.patchState({
                                      order: newOrder,
                                    });
                                  }
                                })
                              );
                            })
                          );
                        })
                      );
                  })
                );
              })
            );
        })
      );
  }

  @Action(AddSingleUseItemToOrder)
  addSingleUseItemToOrder(ctx: StateContext<OrderStateModel>, action: AddSingleUseItemToOrder) {
    return this.store
      .selectOnce(state => state.user.user)
      .pipe(
        switchMap((user: User) => {
          return this.orderService.getService().pipe(
            switchMap(oService => {
              return oService.addToOrder(action.item, ctx.getState().order.orderID, user.userID).pipe(
                map(newOrder => {
                  return ctx.patchState({
                    order: newOrder,
                  });
                })
              );
            })
          );
        })
      );
  }

  @Action(RemoveFromOrder)
  removeFromOrder(ctx: StateContext<OrderStateModel>, action: RemoveFromOrder) {
    return this.store
      .select(state => state.user.user)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((user: User) => {
          return this.store
            .select(state => state.order.order)
            .pipe(
              filter(u => u !== null),
              take(1),
              switchMap((order: Order) => {
                return this.orderService.getService().pipe(
                  switchMap(oService => {
                    return oService.removeFromOrder(action.item, order.orderID, user ? user.userID : null).pipe(
                      map(newOrder => {
                        if (newOrder) {
                          if (newOrder.items.length > 0) {
                            const timestamp = Math.floor(Date.now() / 1000);
                            this.oneSignal.sendTags({
                              cart_update: timestamp,
                              product_name: action.item.name,
                              product_image: action.item.standardImageURL,
                            });
                          } else {
                            this.oneSignal.sendTags({
                              cart_update: '',
                              product_name: '',
                              product_image: '',
                            });
                          }
                          const options = {
                            name: 'remove_from_cart',
                            params: {
                              currency: 'USD',
                              items: [
                                {
                                  item_id: action.item.nameSlug,
                                  item_name: action.item.name,
                                  price: action.item.totalCents / 100,
                                  quantity: action.item.quantity,
                                  currency: 'USD',
                                },
                              ],
                            },
                          };
                          this.analytics.firebaseGenericLog(options);
                          this.analytics.analyticsOnRemoveFromCart(
                            action.item,
                            this.store.selectSnapshot((state: GlobalStateModel) => state.order.order).location
                          );
                          ctx.dispatch(new UpdateAppliedRewards(null));
                          return ctx.patchState({
                            order: newOrder,
                          });
                        }
                      })
                    );
                  })
                );
              })
            );
        })
      );
  }

  @Action(UpdateOrderItem)
  updateOrderItem(ctx: StateContext<OrderStateModel>, action: UpdateOrderItem) {
    return this.store
      .select(state => state.user.user)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((user: User) => {
          return this.store
            .select(state => state.order.order)
            .pipe(
              filter(u => u !== null),
              take(1),
              switchMap((order: Order) => {
                return this.orderService.getService().pipe(
                  switchMap(oService => {
                    return oService.updateBasketItem(action.item, order.orderID, user ? user.userID : null, action.quantity).pipe(
                      map(newOrder => {
                        if (newOrder) {
                          return ctx.patchState({
                            order: newOrder,
                          });
                        }
                      })
                    );
                  })
                );
              })
            );
        })
      );
  }

  @Action(UpdateBasketItem)
  updateBasketItem(ctx: StateContext<OrderStateModel>, action: UpdateBasketItem) {
    return this.store
      .select(state => state.user.user)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((user: User) => {
          return this.store
            .select(state => state.order.order)
            .pipe(
              filter(u => u !== null),
              take(1),
              switchMap((order: Order) => {
                return this.orderService.getService().pipe(
                  switchMap(oService => {
                    return oService.updateBasketItem(action.product, order.orderID, user ? user.userID : null, action.quantity).pipe(
                      map(orderRes => {
                        return ctx.patchState({
                          order: orderRes,
                        });
                      })
                    );
                  })
                );
              })
            );
        })
      );
  }

  @Action(ClearOrder)
  clearOrder(ctx: StateContext<OrderStateModel>, action: ClearOrder) {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        return oService.clearOrder().pipe(
          map(() => {
            return ctx.patchState({
              order: null,
            });
          })
        );
      })
    );
  }

  @Action(CancelOrder)
  cancelOrder(ctx: StateContext<OrderStateModel>, action: CancelOrder) {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        return oService.cancelOrder(action.order.orderID).pipe(
          map(orderRes => {
            return orderRes;
          })
        );
      })
    );
  }

  @Action(EditOrder)
  editOrder(ctx: StateContext<OrderStateModel>, action: EditOrder) {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        return oService.editOrder(action.order.orderID).pipe(
          map(orderRes => {
            return ctx.patchState({
              order: orderRes,
            });
          })
        );
      })
    );
  }

  @Action(ReorderPastOrder)
  reorderPastOrder(ctx: StateContext<OrderStateModel>, action: ReorderPastOrder) {
    return this.store
      .select(state => state.user.user)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((user: User) => {
          return this.orderHistoryService.getService().pipe(
            take(1),
            switchMap(ohService => {
              return ohService.reorderFromHistory(user.userID, action.order.orderReference, action.order.orderID, true).pipe(
                map(reorder => {
                  return ctx.patchState({
                    order: reorder,
                  });
                })
              );
            })
          );
        })
      );
  }

  @Action(StartOrderForDelivery)
  startOrderForDelivery(ctx: StateContext<OrderStateModel>, action: StartOrderForDelivery) {
    return this.store
      .select(state => state.user.user)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((user: User) => {
          return this.orderService.getService().pipe(
            take(1),
            switchMap(oService => {
              return this.store
                .select(state => state.order.order)
                .pipe(
                  filter(u => u !== null),
                  take(1),
                  switchMap((order: Order) => {
                    if (order) {
                      if (order.location.locationID === action.locationID) {
                        return oService
                          .setDispatchOrDeliveryAddress(order.orderID, user ? user.userID : null, action.address, action.handoffType)
                          .pipe(
                            map(newOrder => {
                              this.analytics.analyticsOnOrderCreation(newOrder);
                              this.sentry.setBasketID(newOrder.orderID);
                              this.store.dispatch(new SetCurrencyCode(newOrder));
                              return ctx.patchState({
                                order: newOrder,
                              });
                            })
                          );
                      } else {
                        return oService
                          .setHandoffType(
                            order.orderID,
                            user.userID,
                            order.location.supportsPickup ? HandoffType.pickup : HandoffType.curbside
                          )
                          .pipe(
                            catchError(error => {
                              console.log(error);
                              throwError(error);
                              return this.store.dispatch(new CreateNewOrder(action.locationID, action.handoffType, action.address));
                            }),
                            switchMap(order2 => {
                              return oService.transferBasket(order2.orderID, action.locationID).pipe(
                                catchError(error => {
                                  console.log(error);
                                  throwError(error);
                                  return this.store.dispatch(new CreateNewOrder(action.locationID, action.handoffType, action.address));
                                }),
                                switchMap(newOrder => {
                                  return oService
                                    .setDispatchOrDeliveryAddress(
                                      newOrder.orderID,
                                      user ? user.userID : null,
                                      action.address,
                                      action.handoffType
                                    )
                                    .pipe(
                                      map(finalOrder => {
                                        this.analytics.analyticsOnOrderCreation(finalOrder);
                                        this.sentry.setBasketID(newOrder.orderID);
                                        this.store.dispatch(new SetCurrencyCode(finalOrder));
                                        return ctx.patchState({
                                          order: finalOrder,
                                        });
                                      }),
                                      catchError(error => {
                                        // transfer back to original location
                                        // reset old delivery address
                                        return oService.transferBasket(order.orderID, order.location.locationID).pipe(
                                          switchMap(transferOrder => {
                                            return oService
                                              .setDispatchOrDeliveryAddress(
                                                transferOrder.orderID,
                                                user ? user.userID : null,
                                                order.deliveryAddress,
                                                order.handoffType
                                              )
                                              .pipe(
                                                switchMap(finalOrder => {
                                                  this.analytics.analyticsOnOrderCreation(finalOrder);
                                                  this.sentry.setBasketID(finalOrder.orderID);
                                                  this.store.dispatch(new SetCurrencyCode(finalOrder));
                                                  ctx.patchState({
                                                    order: finalOrder,
                                                  });
                                                  return throwError(error);
                                                })
                                              );
                                          })
                                        );
                                      })
                                    );
                                })
                              );
                            })
                          );
                      }
                    } else {
                      return oService.startOrder(user ? user.userID : null, action.locationID, HandoffType.pickup).pipe(
                        switchMap(newOrder => {
                          return oService
                            .setDispatchOrDeliveryAddress(newOrder.orderID, user ? user.userID : null, action.address, action.handoffType)
                            .pipe(
                              map(finalOrder => {
                                this.analytics.analyticsOnOrderCreation(finalOrder);
                                this.sentry.setBasketID(finalOrder.orderID);
                                this.store.dispatch(new SetCurrencyCode(finalOrder));
                                return ctx.patchState({
                                  order: finalOrder,
                                });
                              })
                            );
                        })
                      );
                    }
                  })
                );
            })
          );
        })
      );
  }

  @Action(CreateNewOrder)
  createNewOrder(ctx: StateContext<OrderStateModel>, action: CreateNewOrder) {
    return this.store
      .select(state => state.user.user)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((user: User) => {
          return this.orderService.getService().pipe(
            take(1),
            switchMap(oService => {
              return oService
                .startOrder(user ? user.userID : null, action.locationID, action.handoffType, action.tableNumber, action.address)
                .pipe(
                  switchMap(newOrder => {
                    if (action.handoffType === HandoffType.delivery || action.handoffType === HandoffType.dispatch) {
                      return oService
                        .setDispatchOrDeliveryAddress(newOrder.orderID, user ? user.userID : null, action.address, action.handoffType)
                        .pipe(
                          map(finalOrder => {
                            this.analytics.analyticsOnOrderCreation(finalOrder);
                            this.sentry.setBasketID(finalOrder.orderID);
                            this.store.dispatch(new SetCurrencyCode(finalOrder));
                            return ctx.patchState({
                              order: finalOrder,
                            });
                          })
                        );
                    } else {
                      this.analytics.analyticsOnOrderCreation(newOrder);
                      this.sentry.setBasketID(newOrder.orderID);
                      this.store.dispatch(new SetCurrencyCode(newOrder));
                      return of(
                        ctx.patchState({
                          order: newOrder,
                        })
                      );
                    }
                  })
                );
            })
          );
        })
      );
  }

  @Action(SetHandoffType)
  setHandoffType(ctx: StateContext<OrderStateModel>, action: SetHandoffType) {
    return this.store
      .select(state => state.user.user)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((user: User) => {
          return this.store
            .select(state => state.order.order)
            .pipe(
              filter(u => u !== null),
              take(1),
              switchMap((order: Order) => {
                return this.orderService.getService().pipe(
                  switchMap(oService => {
                    if (order) {
                      return oService.setHandoffType(order.orderID, user ? user.userID : null, action.handoffType).pipe(
                        map(orderRes => {
                          return ctx.patchState({
                            order: orderRes,
                          });
                        })
                      );
                    }
                  })
                );
              })
            );
        })
      );
  }

  @Action(AddTip)
  addTip(ctx: StateContext<OrderStateModel>, action: AddTip) {
    return this.store
      .select(state => state.user.user)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((user: User) => {
          return this.store
            .select(state => state.order.order)
            .pipe(
              filter(u => u !== null),
              take(1),
              switchMap((order: Order) => {
                return this.orderService.getService().pipe(
                  switchMap(oService => {
                    return oService.setTip(user ? user.userID : null, order.orderID, action.tipAmount).pipe(
                      map(orderRes => {
                        return ctx.patchState({
                          order: orderRes,
                        });
                      })
                    );
                  })
                );
              })
            );
        })
      );
  }

  @Action(AddPromoCode)
  addPromoCode(ctx: StateContext<OrderStateModel>, action: AddPromoCode) {
    return this.store
      .select(state => state.order.order)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((order: Order) => {
          return this.orderService.getService().pipe(
            switchMap(oService => {
              return oService.addCoupon(order.orderID, action.promoCode).pipe(
                map(orderRes => {
                  return ctx.patchState({
                    order: orderRes,
                  });
                })
              );
            })
          );
        })
      );
  }

  @Action(RemovePromoCode)
  removePromoCode(ctx: StateContext<OrderStateModel>, action: RemovePromoCode) {
    return this.store
      .select(state => state.order.order)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((order: Order) => {
          return this.orderService.getService().pipe(
            switchMap(oService => {
              return oService.removeCoupon(order.orderID).pipe(
                map(orderRes => {
                  return ctx.patchState({
                    order: orderRes,
                  });
                })
              );
            })
          );
        })
      );
  }

  @Action(CheckUpsell)
  checkUpsell(ctx: StateContext<OrderStateModel>, action: CheckUpsell) {
    return this.store
      .select(state => state.app.mainSettings)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((mainSettings: MainSettings) => {
          if (mainSettings.use_provider_upsells) {
            return this.menuService.getService().pipe(
              switchMap(mService => {
                return mService.getProviderUpsells(action.orderID).pipe(
                  map((pUpsells: Upsells) => {
                    if (pUpsells && pUpsells.items) {
                      return ctx.patchState({
                        upsells: pUpsells,
                      });
                    }
                  })
                );
              })
            );
          } else {
            return this.directus.getContentProducts().pipe(
              switchMap(cProducts => {
                const cUpsells = cProducts.filter(p => p.is_upsell);
                const cItemsWithChildUpsells = cProducts.filter(p => p.upsell_if_not_in_cart.length > 0);
                return this.store
                  .select(state => state.order.order)
                  .pipe(
                    filter(o => o !== null),
                    take(1),
                    switchMap((order: Order) => {
                      if (cItemsWithChildUpsells.length > 0) {
                        cItemsWithChildUpsells.forEach(cItem => {
                          if (!order.items.find(i => i.nameSlug === cItem.name_slug)) {
                            cItem.upsell_if_not_in_cart.forEach(upsell => {
                              if (!cUpsells.find(c => c.name_slug === upsell.upsell_products.name_slug)) {
                                cUpsells.unshift(upsell.upsell_products);
                              }
                            });
                          }
                        });
                      }
                      if (cUpsells.length > 0) {
                        return this.store
                          .select(state => state.menu.menu)
                          .pipe(
                            filter(u => u !== null),
                            take(1),
                            switchMap((menu: Menu) => {
                              const pUpsells = [];
                              menu.categories.forEach(cat => {
                                cat.products.forEach(prod => {
                                  cUpsells.forEach(cUp => {
                                    if (cUp.name === prod.name) {
                                      pUpsells.push(prod);
                                    }
                                  });
                                });
                              });
                              return this.menuService.getService().pipe(
                                switchMap(mService => {
                                  return combineLatest(
                                    pUpsells.map(pUp => {
                                      return mService
                                        .getProduct(
                                          order.location.locationID,
                                          order.handoffType,
                                          pUp.menuID,
                                          pUp.categoryID,
                                          pUp.productID,
                                          false
                                        )
                                        .pipe(
                                          map(product => {
                                            return produce(product, draft => {
                                              // tslint:disable-next-line:max-line-length
                                              draft.requiresModification =
                                                draft.optionGroups &&
                                                draft.optionGroups.some(og => this.utility.checkIfModificationRequired(og));
                                              draft.canModify = draft.optionGroups && draft.optionGroups.length > 0;
                                            });

                                            // return product;
                                          })
                                        );
                                    })
                                  ).pipe(
                                    take(1),
                                    map(pUpsells2 => {
                                      return ctx.patchState({
                                        upsells: {
                                          items: pUpsells2,
                                        },
                                      });
                                    })
                                  );
                                })
                              );
                            })
                          );
                      } else {
                        return of(
                          ctx.patchState({
                            upsells: {
                              items: [],
                            },
                          })
                        );
                      }
                    })
                  );
              })
            );
          }
        })
      );
  }

  @Action(CheckProductUpsell)
  checkProductUpsell(ctx: StateContext<OrderStateModel>, action: CheckProductUpsell) {
    return this.directus.getSingleProductByID(action.productID).pipe(
      switchMap(cProduct => {
        const cUpsells = cProduct.upsell_ids;
        if (cUpsells && cUpsells.length > 0) {
          return this.store
            .select(state => state.menu.menu)
            .pipe(
              filter(u => u !== null),
              take(1),
              switchMap((menu: Menu) => {
                const pUpsells = [];
                menu.categories.forEach(cat => {
                  cat.products.forEach(prod => {
                    cUpsells.forEach(cUp => {
                      if (cUp.productId === prod.name) {
                        pUpsells.push(prod);
                      }
                    });
                  });
                });
                return this.store
                  .select(state => state.order.order)
                  .pipe(
                    filter(u => u !== null),
                    take(1),
                    switchMap((order: Order) => {
                      return this.menuService.getService().pipe(
                        switchMap(mService => {
                          return combineLatest(
                            pUpsells.map(pUp => {
                              return mService
                                .getProduct(order.location.locationID, order.handoffType, pUp.menuID, pUp.categoryID, pUp.productID, false)
                                .pipe(
                                  map(product => {
                                    return product;
                                  })
                                );
                            })
                          ).pipe(
                            take(1),
                            map(pUpsells2 => {
                              return ctx.patchState({
                                upsells: {
                                  items: pUpsells2,
                                },
                              });
                            })
                          );
                        })
                      );
                    })
                  );
              })
            );
        } else {
          return of(
            ctx.patchState({
              upsells: {
                items: [],
              },
            })
          );
        }
      })
    );
  }

  @Action(AddProviderUpsell)
  addProviderUpsell(ctx: StateContext<OrderStateModel>, action: AddProviderUpsell) {
    return this.menuService.getService().pipe(
      switchMap(mService => {
        return mService.addProviderUpsell(action.orderID, action.body).pipe(
          map(orderRes => {
            return ctx.patchState({
              order: orderRes,
            });
          })
        );
      })
    );
  }

  @Action(SetCustomField)
  setCustomField(ctx: StateContext<OrderStateModel>, action: SetCustomField) {
    return this.store
      .select(state => state.order.order)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((order: Order) => {
          return this.orderService.getService().pipe(
            switchMap(oService => {
              return oService.setBasketCustomfield(order.orderID, action.customID, action.value).pipe(
                map(orderRes => {
                  return ctx.patchState({
                    order: orderRes,
                  });
                })
              );
            })
          );
        })
      );
  }

  @Action(CheckGiftCardBalance)
  checkGiftCardBalance(ctx: StateContext<OrderStateModel>, action: CheckGiftCardBalance): Observable<GiftCardItem> {
    return this.store
      .select(state => state.order.order)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((order: Order) => {
          return this.orderService.getService().pipe(
            switchMap(oService => {
              return oService.getGiftCardBalance(order.orderID, action.cardNumber, action.pin);
            })
          );
        })
      );
  }

  @Action(ValidateOrder)
  validateOrder(ctx: StateContext<OrderStateModel>, action: ValidateOrder) {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        return this.store
          .select(state => state.order.order)
          .pipe(
            filter(u => u !== null),
            take(1),
            switchMap((order: Order) => {
              return this.store
                .select(state => state.user.user)
                .pipe(
                  filter(u => u !== null),
                  take(1),
                  switchMap((user: User) => {
                    return oService.validateOrder(user ? user.userID : null, order.location.locationID, order.orderID).pipe(
                      map(orderRes => {
                        // if (orderRes.appliedRewards && orderRes.appliedRewards.length > 0) {
                        //   this.store.dispatch(new UpdateAppliedRewards(orderRes.appliedRewards[0]));
                        // }
                        return ctx.patchState({
                          order: orderRes,
                        });
                      })
                    );
                  })
                );
            })
          );
      })
    );
  }

  @Action(RedeemReward)
  redeemReward(ctx: StateContext<OrderStateModel>, action: RedeemReward) {
    const order = ctx.getState().order;
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        const areRewardsApplied = order.appliedRewards && order.appliedRewards.length > 0;
        if (areRewardsApplied || (lService instanceof PersonicaProviderService && order.appliedCouponCode)) {
          return lService.removeAppliedReward(order.appliedRewards[0]).pipe(
            switchMap(() => {
              return this.redeemAndValidateOrRemove(ctx, action);
            })
          );
        } else {
          return this.redeemAndValidateOrRemove(ctx, action).pipe(
            catchError(err => {
              return this.store
                .selectOnce(state => state.user.user)
                .pipe(
                  switchMap((user: User) => {
                    return ctx
                      .dispatch(new UpdateToCurrentBasket(user.userID ? user.userID : null))
                      .pipe(switchMap(() => ctx.dispatch(new UpdateAppliedRewards(action.reward)).pipe(switchMap(() => throwError(err)))));
                  })
                );
            })
          );
        }
      })
    );
  }

  redeemAndValidateOrRemove(ctx: StateContext<OrderStateModel>, action: RedeemReward): Observable<any> {
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        return lService.redeemReward(action.reward).pipe(
          switchMap(orderRes => {
            return of(
              ctx.patchState({
                order: orderRes,
              })
            );
          }),
          catchError(err => {
            return this.store
              .selectOnce(state => state.user.user)
              .pipe(
                switchMap((user: User) => {
                  return this.store.dispatch(new UpdateToCurrentBasket(user.userID ? user.userID : null)).pipe(
                    switchMap(() => {
                      return this.store
                        .selectOnce(state => state.order.order)
                        .pipe(
                          switchMap((order: Order) => {
                            const appliedReward = order.appliedRewards.length > 0 ? order.appliedRewards[0] : action.reward;
                            return lService.removeAppliedReward(appliedReward).pipe(
                              switchMap(orderRes2 => {
                                ctx.patchState({
                                  order: orderRes2,
                                });
                                return throwError(err);
                              })
                            );
                          })
                        );
                    })
                  );
                })
              );
          })
        );
      })
    );
  }

  @Action(RemoveReward)
  removeReward(ctx: StateContext<OrderStateModel>, action: RemoveReward) {
    const order = ctx.getState().order;
    return this.loyaltyService.getService().pipe(
      switchMap(lService => {
        // tslint:disable-next-line:max-line-length
        if (
          (order.appliedRewards && order.appliedRewards.length > 0) ||
          (lService instanceof PersonicaProviderService && order.appliedCouponCode)
        ) {
          return lService.removeAppliedReward(order.appliedRewards[0]).pipe(
            map(orderRes => {
              ctx.patchState({
                order: orderRes,
              });
              return ctx.dispatch(new UpdateAppliedRewards(action.reward));
            })
          );
        } else {
          return ctx.dispatch(new UpdateAppliedRewards(action.reward));
        }
      })
    );
  }

  @Action(StageReward)
  stageReward(ctx: StateContext<OrderStateModel>, action: StageReward) {
    if (action.reward && (action.reward.applicableGlobalIDs || action.reward.applicablePOSIDs)) {
      this.checkMenuForRewardItemAndRouteIfFoundAndNotInOrder(
        action.reward.applicableGlobalIDs ?? [],
        ctx,
        action.reward.applicablePOSIDs ?? []
      );
    }
    ctx.patchState({
      stagedReward: null,
    });
    return ctx.patchState({
      stagedReward: action.reward,
    });
  }

  @Action(SubmitPayment)
  submitPayment(ctx: StateContext<OrderStateModel>, action: SubmitPayment) {
    return this.store
      .select(state => state.order.order)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((order: Order) => {
          this.analytics.firebasePaymentDetails(order, action.cardDetails);
          this.analytics.oneSignalGetCurrentTags();
          return this.store
            .select(state => state.user.user)
            .pipe(
              filter(u => u !== null),
              take(1),
              switchMap((user: User) => {
                return this.orderService.getService().pipe(
                  switchMap(oService => {
                    const vendorSetup: VendorSetup = this.store.selectSnapshot(state => state.app.vendorSetup);
                    return oService.setSpecialInstructions(order.orderID, action.specialInstructions).pipe(
                      mergeMap(order2 =>
                        iif(
                          () =>
                            !!(
                              order2.isASAP &&
                              order2.handoffType !== HandoffType.dispatch &&
                              order2.handoffType !== HandoffType.delivery &&
                              vendorSetup.customer_tracking_provider === 'radar' &&
                              Capacitor.getPlatform() !== 'web'
                            ),

                          this.trackingAsk(order2, oService),
                          of(order2)
                        )
                      ),
                      switchMap(() => {
                        if (!user || user.isGuest) {
                          // tslint:disable-next-line:max-line-length
                          return oService
                            .submitPaymentAsGuest(order.orderID, action.cardDetails, action.applyCents, action.savePayment, action.token)
                            .pipe(
                              map(prevOrder => {
                                this.analytics.oneSignalUserInfo(action.cardDetails);
                                Preferences.remove({ key: LocalStorageKey.GOOGLE_RWG_TOKEN });
                                return this.submitPaymentSuccess(prevOrder, order, action.cardDetails, ctx);
                              })
                            );
                        } else {
                          return oService
                            .submitPayment(order.orderID, action.cardDetails, action.applyCents, action.savePayment, action.token)
                            .pipe(
                              map(prevOrder => {
                                Preferences.remove({ key: LocalStorageKey.GOOGLE_RWG_TOKEN });
                                return this.submitPaymentSuccess(prevOrder, order, action.cardDetails, ctx);
                              })
                            );
                        }
                      })
                    );
                  })
                );
              })
            );
        })
      );
  }

  submitPaymentSuccess(previousOrder: Order, order: Order, cardDetails: CardDetails, stateContext: StateContext<OrderStateModel>) {
    this.analytics.analyticsOnSubmit(previousOrder, order, stateContext.getState().order.location);
    // tslint:disable-next-line:max-line-length
    if (
      (previousOrder.handoffType === HandoffType.pickup ||
        previousOrder.handoffType === HandoffType.curbside ||
        previousOrder.handoffType === HandoffType.driveThru) &&
      order.isASAP
    ) {
      this.store.dispatch(new StartOrderTracking(previousOrder, cardDetails));
    }
    return stateContext.patchState({
      previousOrder,
    });
  }

  trackingAsk(order: Order, oService: OrderProvider): Observable<Order> {
    return this.customerTrackingService.getService().pipe(
      switchMap(ctService => {
        if (ctService) {
          return from(
            Dialog.confirm({
              title: 'Send Location Updates to Restaurant?',
              message: 'By sending your location to the restaurant, we can make sure your order is ready for you when you arrive.',
              okButtonTitle: 'Yes',
              cancelButtonTitle: 'No',
            })
          ).pipe(
            switchMap(result => {
              if (result.value) {
                this.allowedTracking = true;
                return oService.setManualFire(order.orderID);
              } else {
                this.allowedTracking = false;
                return of(order);
              }
            })
          );
        } else {
          return of(order);
        }
      })
    );
  }

  @Action(SetPreviousOrder)
  setPreviousOrder(ctx: StateContext<OrderStateModel>, action: SetPreviousOrder) {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        return oService.getOrderStatus(action.orderID).pipe(
          map(orderRes => {
            return ctx.patchState({
              previousOrder: orderRes,
            });
          })
        );
      })
    );
  }

  @Action(CheckDeliveryStatus)
  checkDeliveryStatus(ctx: StateContext<OrderStateModel>, action: CheckDeliveryStatus) {
    return this.store
      .select(state => state.user.user)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((user: User) => {
          return this.orderService.getService().pipe(
            switchMap(oService => {
              return oService.checkDeliveryStatus(action.orderID, user.userID).pipe(
                map(devStatus => {
                  return ctx.patchState({
                    deliveryStatus: devStatus,
                  });
                })
              );
            })
          );
        })
      );
  }

  @Action(NotifyOfArrival)
  notifyOfArrival(ctx: StateContext<OrderStateModel>, action: NotifyOfArrival) {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        return oService.notifyOfArrival(action.orderID, action.message).pipe(
          map(orderRes => {
            return ctx.patchState({
              previousOrder: orderRes,
            });
          })
        );
      })
    );
  }

  @Action(StartOrderTracking)
  startOrderTracking(ctx: StateContext<OrderStateModel>, action: StartOrderTracking) {
    return this.customerTrackingService.getService().pipe(
      switchMap(ctService => {
        if (ctService) {
          if (Capacitor.getPlatform() !== 'web') {
            if (this.allowedTracking === true) {
              return ctService.connectOrder(action.order, action.customerDetails).pipe(
                switchMap(connected => {
                  if (connected) {
                    return ctService.startOrderTracking();
                  } else {
                    return of();
                  }
                })
              );
            } else if (this.allowedTracking === false) {
              return of();
            }
            return from(
              Dialog.confirm({
                title: 'Send Location Updates to Restaurant?',
                message: 'By sending your location to the restaurant, we can make sure your order is ready for you when you arrive.',
                okButtonTitle: 'Yes',
                cancelButtonTitle: 'No',
              })
            ).pipe(
              switchMap(result => {
                if (result.value) {
                  return ctService.connectOrder(action.order, action.customerDetails).pipe(
                    switchMap(connected => {
                      if (connected) {
                        return ctService.startOrderTracking();
                      } else {
                        return of();
                      }
                    })
                  );
                } else {
                  return of();
                }
              })
            );
          } else {
            return ctService.connectOrder(action.order, action.customerDetails).pipe(
              switchMap(connected => {
                if (connected) {
                  return ctService.startOrderTracking();
                } else {
                  return of();
                }
              })
            );
          }
        } else {
          return of();
        }
      })
    );
  }

  @Action(SetCurrencyCode)
  setCurrencyCode(ctx: StateContext<OrderStateModel>, action: SetCurrencyCode) {
    if (action?.order?.location?.address?.country) {
      switch (action?.order?.location?.address?.country) {
        case 'CA':
          return ctx.patchState({
            currencyCode: 'CAD',
          });
        default:
          return ctx.patchState({
            currencyCode: 'USD',
          });
      }
    } else {
      return ctx.patchState({
        currencyCode: 'USD',
      });
    }
  }

  @Action(UpdateToCurrentBasket)
  updateToCurrentBasket(ctx: StateContext<OrderStateModel>, action: UpdateToCurrentBasket) {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        return oService.getCurrentOrder(action.userID).pipe(
          map(order => {
            return ctx.patchState({
              order,
            });
          })
        );
      })
    );
  }

  @Action(GetGroupOrder)
  getGroupOrder(ctx: StateContext<OrderStateModel>, action: GetGroupOrder) {
    // return this.store.select(state => state.order.order).pipe(filter(u => u !== null), take(1), switchMap((currentOrder: Order) => {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        return oService.getGroupOrder(action.groupID, action.name, action.basketID).pipe(
          map(groupOrder => {
            return ctx.patchState({
              groupOrder,
              // order: groupOrder.order
            });
          })
        );
      })
    );
    // }));
  }

  @Action(GetCurrentGroupOrder)
  getCurrentGroupOrder(ctx: StateContext<OrderStateModel>, action: GetCurrentGroupOrder) {
    return this.store
      .select(state => state.order.order)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((currentOrder: Order) => {
          return this.orderService.getService().pipe(
            switchMap(oService => {
              // tslint:disable-next-line:max-line-length
              return oService.getCurrentGroupOrder(parseInt(currentOrder.location?.locationID, 10), currentOrder.orderID, action.name).pipe(
                map(groupOrder => {
                  if (!this.groupCheckInterval) {
                    this.groupCheckInterval = setInterval(() => {
                      this.store.dispatch(new GetCurrentGroupOrder(action.name));
                    }, 30000);
                  }
                  if (groupOrder) {
                    return ctx.patchState({
                      groupOrder,
                      order: groupOrder.order,
                    });
                  } else {
                    return ctx.patchState({
                      groupOrder: null,
                    });
                  }
                })
              );
            })
          );
        })
      );
  }

  @Action(StartGroupOrder)
  startGroupOrder(ctx: StateContext<OrderStateModel>, action: StartGroupOrder) {
    return this.store
      .select(state => state.order.order)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((currentOrder: Order) => {
          return this.orderService.getService().pipe(
            switchMap(oService => {
              // tslint:disable-next-line:max-line-length
              return oService
                .startGroupOrder(
                  parseInt(currentOrder.location.locationID, 10),
                  currentOrder.orderID,
                  action.deadline,
                  action.members,
                  action.note,
                  action.name
                )
                .pipe(
                  map(groupOrder => {
                    return ctx.patchState({
                      groupOrder,
                    });
                  })
                );
            })
          );
        })
      );
  }

  @Action(UpdateGroupOrder)
  updateGroupOrder(ctx: StateContext<OrderStateModel>, action: UpdateGroupOrder) {
    return this.store
      .select(state => state.order.order)
      .pipe(
        filter(u => u !== null),
        take(1),
        switchMap((currentOrder: Order) => {
          // tslint:disable-next-line:max-line-length
          return this.store
            .select(state => state.order.groupOrder)
            .pipe(
              filter(u => u !== null),
              take(1),
              switchMap((currentGroupOrder: GroupOrder) => {
                return this.orderService.getService().pipe(
                  switchMap(oService => {
                    // tslint:disable-next-line:max-line-length
                    return oService
                      .updateGroupOrder(
                        parseInt(currentOrder.location.locationID, 10),
                        currentOrder.orderID,
                        currentGroupOrder.groupOrderID,
                        action.deadline,
                        action.members,
                        action.note,
                        action.name
                      )
                      .pipe(
                        map(groupOrder => {
                          return ctx.patchState({
                            groupOrder,
                          });
                        })
                      );
                  })
                );
              })
            );
        })
      );
  }

  @Action(JoinGroupOrder)
  joinGroupOrder(ctx: StateContext<OrderStateModel>, action: JoinGroupOrder) {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        return oService.joinGroupOrder(action.groupID, action.name, action.basketID).pipe(
          map(groupOrder => {
            return ctx.patchState({
              groupOrder,
              order: groupOrder.order,
            });
          })
        );
      })
    );
  }

  @Action(CancelGroupOrder)
  cancelGroupOrder(ctx: StateContext<OrderStateModel>, action: CancelGroupOrder) {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        return oService.clearGroupOrder().pipe(
          switchMap(() => {
            clearInterval(this.groupCheckInterval);
            return from(ctx.getState().order.items.filter(i => i.guestName === localStorage.getItem('guestName'))).pipe(
              concatMap(item => {
                return oService.removeFromOrder(item, ctx.getState().groupOrder.order.orderID, null);
              }),
              toArray(),
              map(() => {
                return ctx.patchState({
                  groupOrder: null,
                  order: null,
                });
              })
            );
          })
        );
      })
    );
  }

  @Action(AddDonation)
  addDonation(ctx: StateContext<OrderStateModel>, action: AddDonation) {
    return this.store
      .select(state => state.order.order)
      .pipe(
        filter(o => o !== null),
        take(1),
        switchMap((currentOrder: Order) => {
          return this.orderService.getService().pipe(
            switchMap(oService => {
              return oService.addDonation(currentOrder.orderID, action.id, action.amount).pipe(
                map(order => {
                  return ctx.patchState({
                    order,
                  });
                })
              );
            })
          );
        })
      );
  }

  @Action(SetAvailableOrderDays)
  setAvailableOrderDays(ctx: StateContext<OrderStateModel>, action: SetAvailableOrderDays) {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        return oService.getAvailableOrderDays(ctx.getState().order.location.locationID, ctx.getState().order.orderID).pipe(
          map(days => {
            return ctx.patchState({
              availableOrderDays: days,
            });
          })
        );
      })
    );
  }

  @Action(SetAvailableOrderTimes)
  setAvailableOrderTimes(ctx: StateContext<OrderStateModel>, action: SetAvailableOrderTimes) {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        return oService.getAvailableOrderTimes(ctx.getState().order.location.locationID, ctx.getState().order.orderID, action.date).pipe(
          map(times => {
            if (times.length && moment(action.date).isSame(moment(), 'day') && ctx.getState().order.location.isOpen) {
              ctx.dispatch(new SetOrderReadyTime('asap'));
            } else if (times[0] && this.mode.mode !== 'tableside' && this.mode.mode !== 'kiosk') {
              ctx.dispatch(
                new SetOrderReadyTime(
                  moment(times[moment(ctx.getState().order.earliestReadyTimestamp).isSame(moment(times[0]), 'minute') ? 1 : 0]).utcOffset(
                    ctx.getState().order.location.utcOffset,
                    true
                  )
                )
              );
            } else {
              ctx.dispatch(new SetOrderReadyTime('asap'));
            }
            return ctx.patchState({
              availableOrderTimes: times,
            });
          })
        );
      })
    );
  }

  @Action(FireOrder)
  fireOrder(ctx: StateContext<OrderStateModel>, action: FireOrder) {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        return oService.fireOrder(ctx.getState().previousOrder.orderID).pipe(
          map(order => {
            return ctx.patchState({
              previousOrder: order,
            });
          })
        );
      })
    );
  }

  private checkMenuForRewardItemAndRouteIfFoundAndNotInOrder(
    rewardItems: string[],
    ctx: StateContext<OrderStateModel>,
    posIDs: string[]
  ): void {
    if (ctx.getState().order) {
      const menu = this.store.selectSnapshot((state: GlobalStateModel) => state.menu.menu);
      if (menu) {
        const category = menu.categories.find(c =>
          c.products.find(p => rewardItems.includes(p.globalID) || posIDs.filter(value => p.loyaltyPOSIDs.includes(value)).length)
        );
        if (category) {
          const product = category.products.find(
            p => rewardItems.includes(p.globalID) || posIDs.filter(value => p.loyaltyPOSIDs.includes(value)).length
          );
          if (product && !ctx.getState().order.items.some(i => i.productID === product.productID)) {
            this.toast.info(`To use your reward, please add ${product.name} to your order.`);
            this.router.navigate(['menu', ctx.getState().order.location.locationID, category.categoryID, product.productID]);
          }
        } else if (!this.route.url.includes('checkout')) {
          if (this.mobileService.isMobile) {
            this.navigation.navigateToCartPage(ctx.getState().order.orderID);
          } else {
            this.navigation.navigateToMenuPage(ctx.getState().order.location.locationID);
          }
        }
      }
    }
  }

  private checkIfItemIsRewardItemAndApplyRewardIfFound(item: OrderItem, ctx: StateContext<OrderStateModel>): void {
    if (ctx.getState().stagedReward) {
      const menu = this.store.selectSnapshot((state: GlobalStateModel) => state.menu.menu);
      if (menu) {
        const category = menu.categories.find(c => c.products.find(p => p.productID === item.productID));
        if (category) {
          const product = category.products.find(p => p.productID === item.productID);
          if (product) {
            if (ctx.getState().stagedReward.applicableGlobalIDs?.includes(product.globalID)) {
              this.store.dispatch([new RedeemReward(ctx.getState().stagedReward), new StageReward(null)]);
            }
          }
        }
      }
    }
  }
}
