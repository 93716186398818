<ng-container *ngIf="vendorSetup$ | async as vendorSetup">
    <div class="px-3 pt-3 pb-5 position-relative">
        <div class="d-flex justify-content-end" style="position: sticky; top: 1rem;">
            <i class="bi bi-x-circle d-flex pointer bg-white rounded-circle" style="font-size: 30px;"
               (click)="activeModal.close('Close click')"></i>
        </div>
        <div class="d-flex flex-row flex-nowrap justify-content-center">
            <div class="grow-1 d-flex flex-column align-items-center" style="max-width: 550px;">

                <ng-container *ngIf="!orderType; else deliveryInfo">
                    <ng-container *ngIf="branding$ | async as branding">
                        <div class="pb-4 d-flex justify-content-center pt-4 pt-md-0">
                            <img class="logo" alt="logo" *ngIf="branding.favicon"
                                 [src]="branding.favicon.data.full_url">
                        </div>
                    </ng-container>

                    <div class="w-100 modal-heading m-0 header-font py-2">
                        <h1 class="font-size-h1 m-0 fw-bold">Select Order Type</h1>
                    </div>
                    <div class="w-100 d-flex justify-content-center pb-4">
                        <div class="header-underline m-0"></div>
                    </div>
                    <div class="d-flex flex-column w-100 gap-3">
                        <ng-container *ngIf="vendorSetup.pickup_provider !== 'none'">
                            <div class="text-center w-100">
                                <button class="de-primary-btn color-button handoff-btn" expand="full"
                                        (click)="typeSelected($event,0)">
                                    <ng-container *ngIf="!pickupLoading"><span>Pickup</span></ng-container>
                                    <ng-container *ngIf="pickupLoading">
                                        <div class="de-btn-loader"></div>
                                    </ng-container>
                                </button>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="vendorSetup.delivery_provider !== 'none'">
                            <div class="text-center w-100">
                                <button class="de-primary-btn color-button handoff-btn" expand="block"
                                        (click)="typeSelected($event, 1)">
                                    <span>Delivery</span>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>

        <ng-template #deliveryInfo>
            <ng-container *ngIf="!chooseDelivery else chooseDeliveryLocation">
                <div class="d-flex flex-column align-items-center w-100">
                    <ng-container *ngIf="branding$ | async as branding">
                        <div class="pb-4 d-flex justify-content-center pt-4 pt-md-0">
                            <img class="logo" alt="logo" *ngIf="branding.favicon"
                                 [src]="branding.favicon.data.full_url">
                        </div>
                    </ng-container>
                    <div class="w-100 modal-heading m-0 header-font py-2">
                        <h1 class="font-size-h1 m-0 fw-bold">Delivery Info</h1>
                    </div>
                    <div class="w-100 d-flex justify-content-center pb-4">
                        <div class="header-underline m-0"></div>
                    </div>
                    <form class="w-100 d-flex justify-content-center" [formGroup]="deliveryForm">
                        <div class="w-100 delivery-info-fields d-flex flex-column">
                            <div class="w-100 pb-3">
                                <div>Delivery Address*</div>
                                <ng-container *ngIf="provider === 'google'">
                                    <input type="text" #autocompletemodal id="autocompletemodal"
                                           ngx-google-places-autocomplete
                                           (onAddressChange)="placeChanged($event)" formControlName="deliveryAddress"
                                           placeholder="Delivery Address"
                                           class="form-control de-light de-border de-input w-100"
                                           animated="true" show-cancel-button no-padding
                                           [options]="autocompleteOptions"/>
                                </ng-container>
                                <ng-container *ngIf="provider !== 'google'">
                                    <input type="text" formControlName="deliveryAddress" placeholder="Delivery Address"
                                           class="form-control de-light de-border de-input w-100" animated="true"
                                           show-cancel-button
                                           no-padding/></ng-container>
                            </div>
                            <div class="w-100 pb-3">
                                <div>Delivery Address Line 2</div>
                                <input placeholder="Suite, apartment, building, floor, etc."
                                       class="form-control de-light de-border de-input w-100"
                                       formControlName="secondDeliveryAddress">
                            </div>
                            <div class="w-100 pb-3">
                                <div>Delivery Instructions</div>
                                <textarea placeholder="Please leave any instructions for your driver..."
                                          class="form-control de-light de-border de-inputw-100"
                                          formControlName="deliveryInstructions"
                                          maxlength="{{deliveryInstructionsLength}}"></textarea>
                            </div>

                            <div class="w-100 d-flex flex-row flex-wrap font-size-body-small"
                                 *ngIf="displayOrder?.handoffType === handoffs.delivery || displayOrder?.handoffType === handoffs.dispatch">
                                <div class="w-100 pm-3">Changing your delivery address may cause your order to be
                                    delivered from a different
                                    store.
                                </div>
                                <div class="w-100 mb-3">We cannot guarantee that all items can be transferred to your
                                    new
                                    location.
                                </div>
                            </div>

                            <div class="w-100 h-auto text-danger form-error mt-0 mb-3" *ngIf="displayError">
                                {{displayError}}
                            </div>

                            <div class="w-100">
                                <ng-container *ngIf="mainSettings$ | async as mainSettings">
                                    <ng-container
                                            *ngIf="!mainSettings.allow_users_to_pick_delivery_location else allowDeliverySelection">
                                        <button *ngIf="!isLoading" class="de-primary-btn color-button w-100"
                                                [disabled]="deliveryForm.invalid || !fullAddress" expand="block"
                                                (click)="startDeliveryOrder()">
                                            <div>Start Order</div>
                                        </button>
                                        <button *ngIf="isLoading" class="de-primary-btn primary-btn w-100"
                                                color="primary" expand="block">
                                            <div class="de-btn-loader"></div>
                                        </button>
                                    </ng-container>
                                    <ng-template #allowDeliverySelection>
                                        <button *ngIf="!isLoading" class="de-primary-btn color-button w-100"
                                                [disabled]="deliveryForm.invalid || !fullAddress"
                                                (click)="checkAddress()">
                                            <ng-container *ngIf="!fullAddress">Check Delivery Address</ng-container>
                                            <ng-container *ngIf="fullAddress">Select Location</ng-container>
                                        </button>
                                        <button *ngIf="isLoading" class="de-primary-btn color-button w-100">
                                            <div class="de-btn-loader"></div>
                                        </button>
                                    </ng-template>
                                </ng-container>
                            </div>


                            <div class="w-100 text-center d-flex justify-content-center" *ngIf="displayError">
                                <a type="button" tabindex="0" *ngIf="!isLoading"
                                   class="text-center dark-link mt-3"
                                   (click)="typeSelected($event, 0)"
                                   (keydown.enter)="typeSelected($event, 0)">
                                    Pickup Instead
                                </a>
                            </div>

                        </div>
                    </form>
                </div>
            </ng-container>
        </ng-template>

        <ng-template #chooseDeliveryLocation>
            <div class="w-100 d-flex flex-column align-items-center grow-3 basis-0">

                <ng-container *ngIf="branding$ | async as branding">
                    <div class="pb-4 d-flex justify-content-center pt-4 pt-md-0">
                        <img class="logo" alt="logo" *ngIf="branding.favicon"
                             [src]="branding.favicon.data.full_url">
                    </div>
                </ng-container>

                <div class="w-100 modal-heading m-0 header-font py-2">
                    <h1 class="font-size-h1 m-0 fw-bold pt-4 pt-md-0">Choose Delivery From</h1>
                </div>
                <div class="w-100 d-flex justify-content-center pb-4">
                    <div class="header-underline m-0"></div>
                </div>
                <div class="d-flex flex-column pb-3">
                    <div class="fw-bold delivery-address w-100">Your Delivery Address - <span
                            (click)="chooseDelivery = false" class="changeAddress">Change</span>
                    </div>
                    <div class="w-100">{{fullAddress.addressComponents.address1}}</div>
                    <div class="w-100" *ngIf="address2.value">{{address2.value}}</div>
                    <div class="w-100">{{fullAddress.addressComponents.city}}
                        , {{fullAddress.addressComponents.state}} {{fullAddress.addressComponents.zipCode}}</div>
                </div>
                <div class="w-100">
                    <ng-container *ngIf="deliveryLocations$ | async as deliveryLocations">
                        <hr class="border-top my-3">
                        <ng-container
                                *ngFor="let location of deliveryLocations; index as i; first as first; last as last">


                            <ng-container *ngIf="location.canDeliver">
                                <div [class.mb-3]="!last">
                                    <app-desktop-location-card
                                            *ngIf="location.isPrivate === false"
                                            (orderNowButtonClick)="deliveryLocationSelect(location, $event)"
                                            [location]="location"
                                            [isCurrentLocation]="selectedLocation && location === selectedLocation"
                                            [displayOrderButton]="location.isLive"
                                            [locationLoading]="isLoading? location.locationID: null"
                                            [showLocationInfoButton]="false"
                                            (hoveredIndex)="cardHovered(i)"
                                            [cardEmphasis]="cardEmphasis"
                                    >
                                    </app-desktop-location-card>
                                </div>
                                <!--                                <div class="pointer bg-white border p-3"-->
                                <!--                                     style="display: grid; grid-template-columns: 1fr auto; place-items: center start; border-radius: var(&#45;&#45;cms-card-radius);"-->
                                <!--                                     (click)="deliveryLocationSelect(location)">-->
                                <!--                                    <div>-->
                                <!--                                        <div class="fw-bold">{{location.name}}</div>-->
                                <!--                                        <ng-container *ngIf="location.isOpen else closed">-->
                                <!--                                            <div><span class="badge bg-success">OPEN</span>&nbsp;<span-->
                                <!--                                                    class="badge bg-info">{{ ({-->
                                <!--                                                longitude: location.address.longitude,-->
                                <!--                                                latitude: location.address.latitude-->
                                <!--                                            } | geoDistance: {-->
                                <!--                                                longitude: fullAddress.addressComponents.longitude,-->
                                <!--                                                latitude: fullAddress.addressComponents.latitude-->
                                <!--                                            }) | number: '1.0-0' }} mi</span></div>-->
                                <!--                                        </ng-container>-->
                                <!--                                        <ng-template #closed>-->
                                <!--                                            <div><span class="badge bg-danger">CLOSED</span>&nbsp;<span-->
                                <!--                                                    class="badge bg-info">{{ ({-->
                                <!--                                                longitude: location.address.longitude,-->
                                <!--                                                latitude: location.address.latitude-->
                                <!--                                            } | geoDistance: {-->
                                <!--                                                longitude: fullAddress.addressComponents.longitude,-->
                                <!--                                                latitude: fullAddress.addressComponents.latitude-->
                                <!--                                            }) | number: '1.0-0' }} mi</span></div>-->
                                <!--                                        </ng-template>-->
                                <!--                                        <small class="d-block">{{location.address.address1}}</small>-->
                                <!--                                        <small class="d-block">{{location.address.city}}-->
                                <!--                                            , {{location.address.state}} {{location.address.zipCode}}</small>-->
                                <!--                                        <ng-container *ngIf="location.supportsDelivery else dispatchHours">-->
                                <!--                                            <small class="d-block">{{location.deliveryHours[0].start | date: 'shortTime'}}-->
                                <!--                                                - {{location.deliveryHours[0].end | date: 'shortTime'}}</small>-->
                                <!--                                        </ng-container>-->
                                <!--                                        <ng-template #dispatchHours>-->
                                <!--                                            <small class="d-block">{{location.dispatchHours[0].start | date: 'shortTime'}}-->
                                <!--                                                - {{location.dispatchHours[0].end | date: 'shortTime'}}</small>-->
                                <!--                                        </ng-template>-->
                                <!--                                    </div>-->
                                <!--                                    <div style="height: 60px; width: 60px; display: grid; place-items: center"-->
                                <!--                                         *ngIf="(!isLoading || location.locationID !== selectedLocation?.locationID) else startingOrder">-->
                                <!--                                        <i class="bi bi-chevron-right font-size-subheading-1"-->
                                <!--                                           style="color: var(&#45;&#45;ion-color-primary);"></i>-->
                                <!--                                    </div>-->
                                <!--                                    <ng-template #startingOrder>-->
                                <!--                                        <div style="height: 60px; width: 60px; display: grid; place-items: center">-->
                                <!--                                            <div class="de-btn-loader-secondary"></div>-->
                                <!--                                        </div>-->
                                <!--                                    </ng-template>-->
                                <!--                                </div>-->
                                <!--                                <hr class="border-top my-3">-->
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-template>
    </div>
</ng-container>
