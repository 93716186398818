<ng-container *ngIf="(mainSettings$ | async) as mainSettings">
  <ng-container *ngIf="(order$ | async) as order">
    <ng-container *ngIf="!(allProductsAlwaysAvailable$ | async) || !(groupOrder$ | async)">
      <div class="order-controls text-start" [ngClass]="{'border-bottom bg-white': isMobileView}">
        <ng-container *ngIf="modeService.mode === 'mobile'">
          <ng-container *ngIf="!(groupOrder$ | async) as groupOrder">
            <div class="start-group-order mt-3 mb-3 mb-lg-0">
              <ng-container *ngIf="order?.location?.supportsGroupOrders && mainSettings?.show_group_order && isMenu">
                <button type="button" class="de-secondary-btn group-order-prompt cursor-pointer" (click)="startGroupOrder()">
                  <i class="group-icon">🧑‍🤝‍🧑</i>
                  <span class="prompt-text">Group Order</span>
                </button>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!(allProductsAlwaysAvailable$ | async)">
          <app-menu-limit-toggle class="mt-3 mb-0 mb-3 mb-lg-0"></app-menu-limit-toggle>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
