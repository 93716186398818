import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Location as DineEngineLocation} from '../../../../../interfaces/location.interface';
import {GeocodingService} from '../../../services/geocoding.service';
import {Geolocation} from '@capacitor/geolocation';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DistanceConfirmationComponent} from '@modules/locations/components';
import {Order} from '../../../../../interfaces/order.interface';

@Component({
  selector: 'app-desktop-location-card',
  templateUrl: './desktop-location-card.component.html',
  styleUrls: ['./desktop-location-card.component.scss']
})
export class DesktopLocationCardComponent implements OnInit {

  private _location: DineEngineLocation;

  @Input() set location(loc: DineEngineLocation) {
    this._location = loc;
    this.setTimes();
    this.determineHandoff();

  }
  get location(): DineEngineLocation {
    return this._location;
  }
  @Input() isCurrentLocation = false;
  @Input() displayOrderButton = true;
  @Input() displayInfoButton = true;
  @Input() displayCateringButton = false;
  @Input() isLoading = false;
  @Input() pageFrom: string;
  @Input() editHandoff;
  @Input() locationLoading;
  @Input() cardEmphasis: boolean;
  @Input() locationList = true;
  @Input() showLocationInfoButton = true;

  @Output() orderNowButtonClick = new EventEmitter<any>();
  @Output() locationInfoButtonClick = new EventEmitter<any>();
  @Output() changeHandoffTypeClick = new EventEmitter<any>();
  @Output() hoveredIndex = new EventEmitter<number>();
  @Output() leftCard = new EventEmitter<any>();

  pickupStart: Date;
  pickupEnd: Date;
  deliveryStart: Date;
  handoffMethods: string;
  deliveryEnd: Date;
  displayConfirmation: Order;

  distance;

  constructor(
    private geo: GeocodingService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    if (this.pageFrom === 'locations') {
      this.distance = parseInt(sessionStorage.getItem(this._location.locationID), 10);
    }
  }

  orderNowButtonClicked(event: any) {
    event.stopPropagation();
    if (this._location.isLive) {
      if (this.distance > 25) {
        const modalRef = this.modalService.open(DistanceConfirmationComponent, {centered: true});
        modalRef.componentInstance.distance = this.distance;
        modalRef.componentInstance.continueEmit.subscribe(() => {
          this.orderNowButtonClick.emit();
          this.modalService.dismissAll();
        });
      } else {
        this.orderNowButtonClick.emit();
      }
    }
  }

  locationInfoButtonClicked(event: any) {
    event.stopPropagation();
    if (this.location.supportsPickup || this.location.supportsCurbside) {
      this.locationInfoButtonClick.emit();
    }
  }

  cateringClicked(event: any) {
    event.stopPropagation();
  }

  private setTimes() {
    if (!this.location) { return; }
    const now = new Date();
    if (this.location.pickupHours) {
      let pickupHours = this.location.pickupHours.find(hours => this.doesOverlap(hours.start, hours.end, now));
      if (!pickupHours) {
        pickupHours = this.location.pickupHours.find(hours => this.isSameDay(hours.start, now));
      }
      if (!pickupHours) {
        this.pickupStart = null;
        this.pickupEnd = null;
      } else {
        this.pickupStart = pickupHours.start;
        this.pickupEnd = pickupHours.end;
      }
    }
    if (this.location.dispatchHours) {
      let deliveryHours = this.location.dispatchHours.find(hours => this.doesOverlap(hours.start, hours.end, now));
      if (!deliveryHours) {
        deliveryHours = this.location.pickupHours.find(hours => this.isSameDay(hours.start, now));
      }
      if (!deliveryHours) {
        this.deliveryStart = null;
        this.deliveryEnd = null;
      } else {
        this.deliveryStart = deliveryHours.start;
        this.deliveryEnd = deliveryHours.end;
      }
    }
    if (this.pageFrom === 'details') {
      Geolocation.getCurrentPosition({enableHighAccuracy: true, timeout: 500}).then(pos => {
        this.distance = this.geo.findDistance(
            this.location.address.latitude,
            this.location.address.longitude,
            pos.coords.latitude,
            pos.coords.longitude
        );
        this.distance = this.geo.precisionRound(this.distance, 1);
      }).catch(err => {
        console.log('Geolocation Error:', err);
      });
    }
  }

  private doesOverlap(start: Date, end: Date, query: Date): boolean {
    return new Date(start).getTime() <= new Date(query).getTime() && new Date(end).getTime() > new Date(query).getTime();
  }

  private isSameDay(dt1: Date, dt2: Date): boolean {
    return new Date(dt1).getDay() === new Date(dt2).getDay();
  }

  // TODO: This should be a pipe
  private determineHandoff() {
    this.handoffMethods = 'Offers ';
    const methods = [];
    if (this.location.supportsPickup) {
      methods.push('Pickup');
    }
    if (this.location.supportsCurbside) {
      methods.push('Curbside');
    }
    if (this.location.supportsDispatch || this.location.supportsDelivery) {
      methods.push('Delivery');
    }
    if (this.location.supportsDriveThru) {
      methods.push('Drive-Thru');
    }
    if (this.location.supportsTableside) {
      methods.push('Dine-In');
    }
    if (this.location.cateringLink) {
      methods.push('Catering');
    }
    if (methods.length === 1) {
      this.handoffMethods += methods[0];
    } else if (methods.length === 2) {
      this.handoffMethods += methods[0] + ' & ' + methods[1];
    } else if (methods.length >= 3) {
      methods.forEach((method: string, index: number) => {
        if (index < methods.length - 2) {
          this.handoffMethods += method + ', ';
        }
      });
      this.handoffMethods += methods[methods.length - 2] + ' & ' + methods[methods.length - 1];
    }
  }

  changeHandoffTypeClicked() {
    this.orderNowButtonClick.emit();
  }

  listCardLeft() {
    this.leftCard.emit();
  }

  cardHovered() {
    this.hoveredIndex.emit();
  }

  openNavigation(event: any) {
    event.stopPropagation();
    const preppedAddress = encodeURIComponent(this._location.address.address1.replace(/[ ]+/g, '+'));
    if (window) {
      window.open(
          'https://www.google.com/maps/dir/Current+Location/' + preppedAddress +
          '+' + this._location.address.state + '+' +
          this._location.address.zipCode, '_blank',
          'noopener=yes');
    } else {
      document.location.href = 'https://www.google.com/maps/dir/Current+Location/' + preppedAddress +
          '+' + this._location.address.state + '+' +
          this._location.address.zipCode;
    }
  }

}
