import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProxyBaseURLService } from '../../services/proxy-base-url.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PunchhHttpService {

    private vendorName = 'punchh';

    private punchhMobileAccessTokenKey = 'PunchhMobileAccessToken';

    private headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'accept-language': 'en'
    });

    constructor(private http: HttpClient, private urlService: ProxyBaseURLService) { }

    get<T>(resource: string, mobileAPI?: boolean, accessToken?: string): Observable<T> {
        this.resetHeaders();
        if (mobileAPI) {
            this.headers = this.headers.append('X-Punchh-API', 'mobile');
            if (accessToken) {
                this.headers = this.headers.append('Authorization', 'Bearer ' + accessToken);
            }
        }
        return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
            return this.http.get<T>(baseURL + resource, { headers: this.headers });
        }));
    }

    getWithBody<T>(resource: string, body: any, mobileAPI: boolean): Observable<T> {
        this.resetHeaders();
        if (mobileAPI) {
            this.headers = this.headers.append('X-Punchh-API', 'mobile');
        }
        return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
            return this.http.request<T>('GET', baseURL + resource, {
                body: JSON.stringify(body),
                headers: this.headers,
                observe: 'body'
            });
        }));
    }

    put<T>(resource: string, body: any): Observable<T> {
        this.resetHeaders();
        return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
            return this.http.put<T>(baseURL + resource, body, { headers: this.headers });
        }));
    }

    post<T>(resource: string, body: any, mobileAPI: boolean, accessToken?: string): Observable<T> {
        this.resetHeaders();
        if (mobileAPI) {
            this.headers = this.headers.append('X-Punchh-API', 'mobile');
            if (accessToken) {
                this.headers = this.headers.append('Authorization', 'Bearer ' + accessToken);
            }
        }
        return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
            return this.http.post<T>(baseURL + resource, body, { headers: this.headers });
        }));
    }

    patch<T>(resource: string, body: any): Observable<T> {
        this.resetHeaders();
        return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
            return this.http.patch<T>(baseURL + resource, body, { headers: this.headers });
        }));
    }

    delete<T>(resource: string, mobileAPI?: boolean, accessToken?: string, body?: any): Observable<T> {
        this.resetHeaders();
        if (mobileAPI) {
          this.headers = this.headers.append('X-Punchh-API', 'mobile');
          if (accessToken) {
            this.headers = this.headers.append('Authorization', 'Bearer ' + accessToken);
          }
        }
        if (body) {
            return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
                return this.http.request<T>('delete', baseURL + resource, { headers: this.headers, body });
            }));
        } else {
            return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
                return this.http.delete<T>(baseURL + resource, { headers: this.headers });
            }));
        }
    }

    private resetHeaders() {
        this.headers = new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'accept-language': 'en'
        });
    }
}
