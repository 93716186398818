import { OnboardingSlidesComponent } from './onboarding-slides/onboarding-slides.component';
import { RouteModalFrameComponent } from './route-modal-frame/route-modal-frame.component';
import { PageLoaderComponent } from './page-loader/page-loader.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { DrawerWrapperComponent } from './drawer-wrapper/drawer-wrapper.component';
import { CustomIconComponent } from './custom-icon/custom-icon.component';
import { ShowForRoleComponent } from './show-for-role/show-for-role.component';
import { CreditCardTypeComponent } from './credit-card-type/credit-card-type.component';
import { ActivityEventDetailsComponent } from './activity-event-details/activity-event-details.component';
import {PromotionalContentComponent} from '@common/components/promotional-content/promotional-content.component';
import { ActivityEventCardComponent } from './activity-event-card/activity-event-card.component';
import { LoyaltyTierProgressComponent } from './loyalty-tier-progress/loyalty-tier-progress.component';
import { OrderTypePickerComponent } from './order-type-picker/order-type-picker.component';
import { PastOrdersListComponent } from './past-orders-list/past-orders-list.component';
import { PastOrderCardComponent } from './past-order-card/past-order-card.component';
import { OrderNowButtonComponent } from './order-now-button/order-now-button.component';
import { PromotionCardComponent } from './promotion-card/promotion-card.component';
import { HomeBalanceCardComponent } from './home-balance-card/home-balance-card.component';
import { DatetimePickerComponent } from './datetime-picker/datetime-picker.component';
import { AppmassiveUpdaterComponent } from './appmassive-updater/appmassive-updater.component';
import { RecaptchaComponent } from './recaptcha/recaptcha.component';

// tslint:disable-next-line:max-line-length
export const components = [OnboardingSlidesComponent, RouteModalFrameComponent, PageLoaderComponent, SubscriptionComponent, DrawerWrapperComponent, CustomIconComponent, ShowForRoleComponent, CreditCardTypeComponent, ActivityEventDetailsComponent, PromotionalContentComponent, ActivityEventCardComponent, LoyaltyTierProgressComponent, OrderTypePickerComponent, PastOrdersListComponent, PastOrderCardComponent, OrderNowButtonComponent, PromotionCardComponent, HomeBalanceCardComponent, DatetimePickerComponent, AppmassiveUpdaterComponent, RecaptchaComponent];

export * from './onboarding-slides/onboarding-slides.component';
export * from './route-modal-frame/route-modal-frame.component';
export * from './page-loader/page-loader.component';
export * from './subscription/subscription.component';
export * from './drawer-wrapper/drawer-wrapper.component';
export * from './custom-icon/custom-icon.component';
export * from './show-for-role/show-for-role.component';
export * from './credit-card-type/credit-card-type.component';
export * from './activity-event-details/activity-event-details.component';
export * from './promotional-content/promotional-content.component';
export * from './activity-event-card/activity-event-card.component';
export * from './loyalty-tier-progress/loyalty-tier-progress.component';
export * from './order-type-picker/order-type-picker.component';
export * from './past-orders-list/past-orders-list.component';
export * from './past-order-card/past-order-card.component';
export * from './order-now-button/order-now-button.component';
export * from './promotion-card/promotion-card.component';
export * from './home-balance-card/home-balance-card.component';
export * from './datetime-picker/datetime-picker.component';
export * from './appmassive-updater/appmassive-updater.component';
export * from './recaptcha/recaptcha.component';
