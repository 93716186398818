import { Component, Input, Output, EventEmitter } from '@angular/core';
import { OptionGroup } from 'src/interfaces/option-group.interface';
import { Option } from 'src/interfaces/option.interface';
import { Product } from 'src/interfaces/product.interface';

@Component({
  selector: 'app-option-selector',
  templateUrl: './option-selector.component.html',
  styleUrls: ['./option-selector.component.scss'],
})
export class OptionSelectorComponent {
  @Input() optionGroups: OptionGroup[];
  @Input() displayOptionGroups: OptionGroup[];
  @Input() isModifierFlow: boolean;
  @Input() isLoading: boolean;
  @Input() isModal: boolean;
  @Input() product: Product;
  @Input() currencyCode: string;
  @Output() clickedOption = new EventEmitter<{ option: Option; quantity: number }>();
  @Input() selectedOptions: Option[];

  optionSubGroup = null;

  optionClicked(event: { option: Option; quantity: number }) {
    this.clickedOption.emit(event);
  }

  getSelectedAmount(index: number) {
    const optiongroup = this.optionGroups[index];
    const opFilter = optiongroup.options.filter(op => op.isSelected);
    return opFilter.length;
  }

  optionGroupTrackBy(index: number, optionGroup: OptionGroup) {
    return `${optionGroup.optionGroupID}-${index}`;
  }
}
