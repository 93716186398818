import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { DirectusService } from 'src/vendors/directus/directus.service';

// Models for the view
import { Category } from 'src/interfaces/category.interface';
import { Menu } from 'src/interfaces/menu.interface';
import { Order } from 'src/interfaces/order.interface';
import { User } from 'src/interfaces/user.interface';
import { TextField } from 'src/vendors/directus/interfaces/text-field.interface';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { filter, tap } from 'rxjs/operators';
import { HandoffTypeService } from '@modules/cart/services/handoff-type.service';
import { Select, Store } from '@ngxs/store';
import { SetLoading, SetRouteBack, SwitchMenuView, UpdateTitle } from '../../../../../store/actions/app.actions';
import { MobileService } from '../../../../../services/mobile.service';
import { SetMenu } from '../../../../../store/actions/menu.actions';
import { NavbarSettings } from '../../../../../vendors/directus/interfaces/navbar-settings.interface';
import { MetaService } from '../../../../../services/meta.service';
import { ThemeColor } from '../../../../../vendors/directus/interfaces/theme-color.interface';
import { NavigationService } from '@modules/navigation/services';
import { Branding } from '../../../../../vendors/directus/interfaces/branding.interface';
import { MenuService } from '@modules/menu/services';
import { MainSettings } from '../../../../../vendors/directus/interfaces/main-settings.interface';
import { SubscriptionComponent, UserRoles } from '@common/components';
import { CartService } from '@modules/cart/services';
import { SentryService } from '@common/services';
import { PromotionalContent } from '../../../../../vendors/directus/interfaces/promotional-content.interface';
import { GlobalStateModel } from '../../../../../store/state.model';
import { ModeService } from '../../../../../services/mode.service';

@Component({
  selector: 'app-menu',
  template: '',
})
export class MenuComponent extends SubscriptionComponent implements OnDestroy, OnInit {
  @Select(state => state.menu.menu) menu$: Observable<Menu>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.app.textField) textFields$: Observable<TextField>;
  @Select(state => state.app.navbarSettings) navbarSettings$: Observable<NavbarSettings>;
  @Select(state => state.app.theme) theme$: Observable<ThemeColor[]>;
  @Select(state => state.app.branding) branding$: Observable<Branding>;
  @Select(state => state.order.currencyCode) currencyCode$: Observable<string>;
  @Select(state => state.menu.showFullMenu) showFullMenu$: Observable<boolean>;
  @Select(state => state.menu.allProductsAlwaysAvailable) allProductsAlwaysAvailable$: Observable<boolean>;
  @Select(state => state.app.mainSettings) settings$: Observable<MainSettings>;
  @Select(state => state.app.promotionalContent) promotionalContent$: Observable<PromotionalContent>;
  @Select((state: GlobalStateModel) => state.app.menuView) menuView$: Observable<string>;

  @ViewChild('modifyModal') modifyModalRef: TemplateRef<any>;

  // Page details for SEO
  title = 'menu';
  page;
  cartPromotionalText: SafeHtml;
  cartPromotionalImage;
  cartPromotionalImageMobile;
  catPromotionalImage;
  catPromotionalImageMobile;
  catPromotionalText: SafeHtml;
  menuPromotionalImage;
  menuPromotionalImageMobile;
  menuPromotionalText: SafeHtml;
  // Display variables for view
  displayOrder: Order;
  displayUser: User = null;
  displayCategories: Category[];
  displayBranding: Branding;
  isLoading = false;
  textFields: TextField;
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  gridColSize = null;
  singleColCard = false;
  hidePrice = true;
  showMenuDisplayToggle = false;
  skeletons = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  descriptionText = ''; // Going to assume that it shouldn't be used on this page for now.
  roles = UserRoles;
  private menu: Menu;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private navigation: NavigationService,
    private directus: DirectusService,
    private sanitizer: DomSanitizer,
    private handoffTypeService: HandoffTypeService,
    private store: Store,
    private mobile: MobileService,
    private meta: MetaService,
    public menuService: MenuService,
    public cartService: CartService,
    private sentry: SentryService,
    protected mode: ModeService
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new UpdateTitle(this.title));
    this.store.dispatch(new SetRouteBack(this.mobile.isMobile ? '' : '/locations'));
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
      this.gridCheck();
    });
    this.menu$.pipe(
      tap(() => {
        this.gridCheck();
      })
    );
    this.subs.push(
      this.route.params.subscribe((pathParams: any) => {
        this.menuService.checkMenuParameters(pathParams);
      }),
      this.order$.subscribe(order => {
        if (order) {
          this.displayOrder = order;
          this.menuService.selectedHandoffType = order.handoffType;
          this.sentry.setTransactionName(
            `${order.location.name} : ${order.location.address.city}, ${order.location.address.state} ${order.location.address.zipCode}`
          );
          this.meta.manualUpdate({
            title: `${order.location.name} : ${order.location.address.city}, ${order.location.address.state} ${order.location.address.zipCode}`,
            description: `Online ordering menu for ${order.location.name} located at ${order.location.address.address1} ${order.location.address.city}, ${order.location.address.state} ${order.location.address.zipCode}`,
            keywords: null,
          });
          setTimeout(() => {
            (window as any).prerenderReady = true;
          }, 1000);
          this.store.dispatch(new SetMenu(order.location.locationID, this.menuService.selectedHandoffType));
        }
      }),
      this.menu$.pipe(filter(m => m !== null)).subscribe(menu => {
        this.menu = menu;
        if (sessionStorage.getItem('product')) {
          const category = menu.categories.find(cat => cat.products.some(prod => prod.globalID === sessionStorage.getItem('product')));
          if (category) {
            const product = category.products.find(prod => prod.globalID === sessionStorage.getItem('product'));
            this.navigation.navigateToMenuPage(
              this.menuService.restaurantID,
              category.nameSlug ?? category.categoryID,
              product.nameSlug ?? product.productID,
              'modify'
            );
          }
          sessionStorage.removeItem('product');
        }
        if (sessionStorage.getItem('category')) {
          const category = menu.categories.find(
            cat => cat.categoryID === sessionStorage.getItem('category') || cat.nameSlug === sessionStorage.getItem('category')
          );
          if (category) {
            this.navigation.navigateToMenuPage(this.menuService.restaurantID, category.nameSlug ?? category.categoryID);
          }
          sessionStorage.removeItem('category');
        }
      }),
      this.user$.pipe(filter(u => u !== null)).subscribe(user => {
        this.displayUser = user;
      }),
      this.textFields$.pipe(filter(tf => tf !== null)).subscribe(textFields => {
        this.textFields = textFields;
      }),
      this.navbarSettings$.pipe(filter(ns => ns !== null)).subscribe(navbar => {
        this.hidePrice = !navbar.show_price_in_cart;
      }),
      this.promotionalContent$.pipe(filter(pc => !!pc)).subscribe(pages => {
        this.page = pages[0];
        if (!this.page || (!this.page?.category_card_background && this.page?.category_card_background_mobile)) {
          this.catPromotionalText = this.sanitizer.bypassSecurityTrustHtml('');
        } else {
          this.catPromotionalImage = this.page?.category_card_background?.data.full_url;
          this.catPromotionalImageMobile = this.page?.category_card_background_mobile?.data.full_url;
          this.catPromotionalText = this.sanitizer.bypassSecurityTrustHtml(this.page?.category_card_promotion);
        }
        if (!this.page || (!this.page?.cart_promotion_background && !this.page?.cart_promotion_background_mobile)) {
          this.cartPromotionalText = this.sanitizer.bypassSecurityTrustHtml('');
        } else {
          this.cartPromotionalText = this.sanitizer.bypassSecurityTrustHtml(this.page?.cart_promotion_text);
          this.cartPromotionalImage = this.page?.cart_promotion_background?.data.full_url;
          this.cartPromotionalImageMobile = this.page?.cart_promotion_background_mobile?.data.full_url;
        }
        if (!this.page || (!this.page?.menu_promotion_background && !this.page?.menu_promotion_background_mobile)) {
          this.menuPromotionalText = this.sanitizer.bypassSecurityTrustHtml('');
        } else {
          this.menuPromotionalText = this.sanitizer.bypassSecurityTrustHtml(this.page?.menu_promotion_text);
          this.menuPromotionalImage = this.page?.menu_promotion_background?.data.full_url;
          this.menuPromotionalImageMobile = this.page?.menu_promotion_background_mobile?.data.full_url;
        }
      }),
      this.theme$.pipe(filter(t => t !== null)).subscribe(colors => {
        this.showMenuDisplayToggle = colors[0].menu_display_toggle ? colors[0].menu_display_toggle : false;
      }),
      this.branding$.subscribe(branding => {
        if (branding) {
          this.displayBranding = branding;
        }
      })
    );
  }

  // Gridcheck for promotional cards
  gridCheck() {
    const width = window.innerWidth;
    if (this.displayCategories) {
      const catCount = this.displayCategories.length - this.displayCategories.filter(cat => cat.isHidden).length;
      if (width < 992) {
        this.gridColSize = 12;
        this.singleColCard = true;
      } else if (992 <= width && width < 1200) {
        this.singleColCard = false;
        const num = catCount / 2;
        if (!Number.isInteger(num)) {
          this.gridColSize = 6;
        } else {
          this.gridColSize = null;
        }
      } else if (width >= 1200) {
        this.singleColCard = false;
        const num = catCount / 4;
        if (Number.isInteger(num)) {
          this.gridColSize = null;
        } else if (num.toString().split('.')[1] === '25') {
          this.gridColSize = 9;
        } else if (num.toString().split('.')[1] === '5') {
          this.gridColSize = 6;
        } else if (num.toString().split('.')[1] === '75') {
          this.gridColSize = 3;
        }
      }
    }
  }

  clickCart() {
    document.getElementById('cartButton').click();
  }

  updateMenuDisplay(display: 'list' | 'card') {
    this.store.dispatch(new SwitchMenuView(display));
  }

  scrollToTop(element: HTMLElement) {
    element.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  categoryTrackBy(index: number, category: Category): string {
    return `${index}${category.name}${category.categoryID}`;
  }
}
