import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { merge, Observable, Subject } from 'rxjs';
import { Branding } from '../../../../vendors/directus/interfaces/branding.interface';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import moment from 'moment-timezone';
import { SetLoyaltyLocations } from '../../../../store/actions/user.actions';
import { debounceTime, distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs/operators';
import { MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';
import { CreateAccount } from '../../../../interfaces/create-account.interface';
import { Device } from '@capacitor/device';
import { NgbActiveModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ModeService } from 'src/services/mode.service';
import { Location, LoyaltyLocation } from '../../../../interfaces/location.interface';
import { GlobalStateModel } from '../../../../store/state.model';
import { TextField } from '../../../../vendors/directus/interfaces/text-field.interface';
import { IonDatetimeCustomEvent, ModalOptions } from '@ionic/core';
import { ToastService } from '../../../../services/toast.service';
import { ModalController, PickerController } from '@ionic/angular';
import { VendorSetup } from '../../../../interfaces/vendor.interface';
import { UserField } from '../../../../interfaces/user-field';
import { RecaptchaComponent } from '@common/components';
import { RecaptchaConfiguration } from '../../../../vendors/directus/interfaces/recaptcha-configuration.interface';

@Component({
  selector: 'app-loyalty-login-modal',
  templateUrl: './loyalty-login-modal.component.html',
  styleUrls: ['./loyalty-login-modal.component.scss'],
})
export class LoyaltyLoginModalComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.user.additionalUserFields)
  additionalUserFields$: Observable<UserField[]>;
  @Input() loyaltyLocations: LoyaltyLocation[] = null;

  constructor(
    private store: Store,
    public activeModal: NgbActiveModal,
    public modeService: ModeService,
    private toast: ToastService,
    private pickerController: PickerController
  ) {}

  get username() {
    return this.loginForm.get('username');
  }
  get password() {
    return this.loginForm.get('password');
  }

  get firstName() {
    return this.signUpForm.get('firstName');
  }
  get lastName() {
    return this.signUpForm.get('lastName');
  }
  get dOB() {
    return this.signUpForm.get('dOB');
  }
  get phoneNumber() {
    return this.signUpForm.get('phoneNumber');
  }
  get smsOptIn() {
    return this.signUpForm.get('smsOptIn');
  }
  get emailAddress() {
    return this.signUpForm.get('emailAddress');
  }
  get emailOptIn() {
    return this.signUpForm.get('emailOptIn');
  }
  get passwordSignUp() {
    return this.signUpForm.get('password');
  }
  get passwordConfirm() {
    return this.signUpForm.get('passwordConfirm');
  }
  get favoriteLocation() {
    return this.signUpForm.get('favoriteLocation');
  }

  @Select(state => state.app.branding) branding$: Observable<Branding>;
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.app.mainSettings)
  mainSettings$: Observable<MainSettings>;
  @Select(state => state.user.loyaltyLocations) loyaltyLocations$: Observable<LoyaltyLocation[]>;
  @Select((state: GlobalStateModel) => state.app.textField)
  textFields$: Observable<TextField>;
  @Select((state: GlobalStateModel) => state.app.recaptchaConfiguration) recaptchaConfiguration$: Observable<RecaptchaConfiguration>;

  @Output() signedIn = new EventEmitter<{
    username: string;
    password: string;
  }>();
  @Output() signedUp = new EventEmitter<CreateAccount & { additionalFields: UserField[] }>();
  @Output() continuedAsGuest = new EventEmitter<any>();
  @Output() facebookLogin = new EventEmitter<any>();
  @Output() appleSignIn = new EventEmitter<any>();
  @Output() closeModalClicked = new EventEmitter<any>();

  @ViewChild('recaptchaRef') recaptchaComponent: RecaptchaComponent;
  signInPath: 'username' | 'facebook' | 'apple' = 'username';

  errorMessage = '';
  enableAppleSignIn = false;
  showAppleSignIn = false;
  showFacebookLogin = false;
  facebookLoading = false;
  appleLoading = false;
  loginSignUpLoading = false;

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  showSignUp = false;
  earliestBDay = moment().subtract(13, 'years').subtract(1, 'day').toDate();
  earliestBDayISO = this.earliestBDay.toISOString().split('T')[0];

  loginForm = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.email, Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
  });

  signUpForm = new UntypedFormGroup(
    {
      firstName: new UntypedFormControl('', [Validators.required, Validators.pattern('^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\\]]{1,}$')]),
      lastName: new UntypedFormControl('', [Validators.required, Validators.pattern('^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\\]]{1,}$')]),
      dOB: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      phoneNumber: new UntypedFormControl('', [Validators.required]),
      smsOptIn: new UntypedFormControl(true),
      emailAddress: new UntypedFormControl('', [Validators.required, Validators.email]),
      emailOptIn: new UntypedFormControl(true),
      password: new UntypedFormControl('', [Validators.required, this.passwordValidator()]),
      passwordConfirm: new UntypedFormControl('', [Validators.required, this.passwordValidator()]),
      favoriteLocation: new UntypedFormControl(null),
    },
    this.passwordMatchValidator
  );
  additionalFieldsForm = new UntypedFormGroup({});

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  recaptchaConfiguration: RecaptchaConfiguration;

  formatter = (result: Location) => result.name;

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      withLatestFrom(this.loyaltyLocations$),
      map(([term, loyaltyLocations]) => {
        return !term ? loyaltyLocations : loyaltyLocations.filter(location => location.name.toLowerCase().includes(term.toLowerCase()));
      })
    );
  };

  ngOnInit(): void {
    this.recaptchaConfiguration$.subscribe(config => {
      this.recaptchaConfiguration = config;
    });
    this.additionalUserFields$.subscribe(fields => {
      if (fields && fields.length > 0) {
        fields.forEach(field => {
          const formControl = new UntypedFormControl('', field.required ? [Validators.required] : []);
          this.additionalFieldsForm.addControl(field.providerFieldName, formControl);
        });
      }
    });
    this.store.dispatch(new SetLoyaltyLocations());
    this.loginForm.valueChanges.subscribe(() => (this.errorMessage = ''));
    this.mainSettings$.pipe(filter(ms => ms !== null)).subscribe(ms => {
      this.enableAppleSignIn = ms.enable_sign_in_with_apple;
      if (ms.fb_app_id) {
        this.showFacebookLogin = true;
      }
    });
    const interval = setInterval(() => {
      const order = this.store.selectSnapshot((state: GlobalStateModel) => state.order.order);
      if (order && this.instance) {
        (document.getElementById('typeahead-focus') as HTMLInputElement).value = order.location.name;
        clearInterval(interval);
      }
    }, 250);
    Device.getInfo().then(info => {
      this.showAppleSignIn = info.platform === 'ios' && info.platform === 'ios' && parseInt(info.osVersion, 10) > 13;
    });
    this.loyaltyLocations$.pipe(filter(ll => !!ll)).subscribe(locations => {
      if (locations && locations.length) {
        this.favoriteLocation.setValidators([Validators.required]);
      }
    });
    this.loginForm.valueChanges.subscribe(() => (this.errorMessage = ''));
    this.signUpForm.valueChanges.subscribe(() => (this.errorMessage = ''));
  }

  setBirthdayValue(event: IonDatetimeCustomEvent<{ value: string }>) {
    const fullDateTimeString = event?.detail?.value;

    if (fullDateTimeString) {
      // Parse the original date string
      const dateObject = new Date(fullDateTimeString);

      if (dateObject > this.earliestBDay) {
        this.toast.danger('You must be at least 13 years old to create an account.');
        return;
      }

      // Extract date components
      const year = dateObject.getFullYear();
      const month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-indexed
      const day = ('0' + dateObject.getDate()).slice(-2);

      // Format the date as MM-DD-YYYY
      const reformattedDate = `${month}${day}${year}`;

      // Set the reformatted date to your form control or variable
      this.dOB.setValue(reformattedDate);

      // Log the modified value
      console.log(fullDateTimeString);
      console.log(this.dOB.value);
    } else {
      console.error('Invalid or undefined date value');
    }
  }

  setCustomFieldDateValue(event: IonDatetimeCustomEvent<HTMLInputElement>, field: UserField) {
    const fullDateTimeString = event?.detail?.value;

    if (fullDateTimeString) {
      // Parse the original date string
      const dateObject = new Date(fullDateTimeString);

      // Extract date components
      const year = dateObject.getFullYear();
      const month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-indexed
      const day = ('0' + dateObject.getDate()).slice(-2);

      // Format the date as MM-DD-YYYY
      const reformattedDate = `${month}${day}${year}`;

      // Set the reformatted date to your form control or variable
      this.additionalFieldsForm.controls[field.providerFieldName].setValue(reformattedDate);
    } else {
      console.error('Invalid or undefined date value');
    }
  }

  passwordMatchValidator(g: UntypedFormGroup) {
    return g.get('password').value === g.get('passwordConfirm').value ? null : { mismatch: true };
  }

  tokenReceived(token: string) {
    sessionStorage.setItem('recaptchaToken', token);
    switch (this.signInPath) {
      case 'username':
        if (this.showSignUp) {
          this.signUp();
        } else {
          this.login();
        }
        break;
      case 'facebook':
        this.facebookLogin.emit();
        break;
      case 'apple':
        this.appleSignIn.emit();
        break;
    }
  }

  loginSignUpClick() {
    if (this.loginSignUpLoading) {
      return;
    }
    if (this.showSignUp) {
      this.signUpForm.markAllAsTouched();
      if (!this.signUpForm.valid) {
        return;
      }
      this.signInPath = 'username';
      if (this.recaptchaConfiguration?.enabled) {
        this.recaptchaComponent.execute();
      } else {
        this.signUp();
      }
    } else {
      this.loginForm.markAllAsTouched();
      if (!this.loginForm.valid) {
        return;
      }
      this.signInPath = 'username';
      if (this.recaptchaConfiguration?.enabled) {
        this.recaptchaComponent.execute();
      } else {
        this.login();
      }
    }
  }

  login() {
    this.signedIn.emit({
      username: this.username.value,
      password: this.password.value,
    });
  }

  signUp() {
    this.errorMessage = '';
    if (moment(this.dOB.value, 'MMDDYYYY').isAfter(moment(this.earliestBDay))) {
      this.errorMessage = 'You must be at least 13 years old to create an account.';
      return;
    }
    const additionalFields = this.store
      .selectSnapshot((state: GlobalStateModel) => state.user.additionalUserFields)
      .map(field => {
        return {
          ...field,
          value: this.additionalFieldsForm.get(field.providerFieldName).value,
        };
      });
    this.signedUp.emit({
      email: this.emailAddress.value,
      dob: moment(this.dOB.value, 'MMDDYYYY').format('YYYY-MM-DD'),
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      password: this.passwordSignUp.value,
      phone: this.phoneNumber.value,
      emailOptIn: this.emailOptIn.value,
      smsOptIn: this.smsOptIn.value,
      favoriteLocation: this.favoriteLocation.value,
      additionalFields: additionalFields,
    });
  }

  facebookClick() {
    if (this.facebookLoading) {
      return;
    }
    this.signInPath = 'facebook';
    if (this.recaptchaConfiguration?.enabled) {
      this.recaptchaComponent.execute();
    } else {
      this.facebookLogin.emit();
    }
  }

  appleClick() {
    if (this.appleLoading) {
      return;
    }
    this.signInPath = 'apple';
    if (this.recaptchaConfiguration?.enabled) {
      this.recaptchaComponent.execute();
    } else {
      this.appleSignIn.emit();
    }
  }

  closeModal() {
    this.closeModalClicked.emit();
    this.activeModal.close('Close click');
  }

  continueAsGuest() {
    this.continuedAsGuest.emit();
  }

  async openPicker() {
    const picker = await this.pickerController.create({
      columns: [
        {
          name: 'location',
          options: this.store
            .selectSnapshot((state: GlobalStateModel) => state.user.loyaltyLocations)
            .map(location => {
              return {
                text: location.name,
                value: location,
              };
            }),
        },
      ],
      buttons: this.pickerButtons,
    });
    await picker.present();
  }

  public pickerButtons = [
    {
      text: 'Cancel',
      role: 'cancel',
    },
    {
      text: 'Confirm',
      handler: value => {
        console.log(`You selected:`, value);
        this.favoriteLocation.setValue(value.location.value);
      },
    },
  ];

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.value as string;
      const errors: ValidationErrors = {};

      if (!password) {
        return null; // if no password, no need to validate
      }

      // Check for minimum length
      if (password.length < 10) {
        errors['minLength'] = 'Password must be at least 10 characters long';
      }

      // Check for uppercase letter
      const uppercasePattern = /[A-Z]/;
      if (!uppercasePattern.test(password)) {
        errors['uppercase'] = 'Password must contain at least one uppercase letter';
      }

      // Check for special character
      const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;
      if (!specialCharPattern.test(password)) {
        errors['specialChar'] = 'Password must contain at least one special character';
      }

      // If no errors, return null, otherwise return the errors object
      return Object.keys(errors).length > 0 ? errors : null;
    };
  }
}
