<div class="ingredient-sides mb-3 px-3">
  <span class="ingredient-sides-selected">
    Choose a Side:
<!--    <span class="selected">-->
<!--      <ng-container *ngIf="!optionSubGroup; else subOptionGroupName">-->
<!--        <ng-container *ngTemplateOutlet="optionGroupName"></ng-container>-->
<!--      </ng-container>-->
<!--    </span>-->
  </span>
  <span class="ingredient-sides-shell">
    <ng-container *ngIf="!optionSubGroup; else subOptionGroupChoices">
      <ng-container *ngTemplateOutlet="optionGroupChoices"></ng-container>
    </ng-container>
  </span>
</div>

<ng-template #subOptionGroupName>
  <ng-container *ngFor="let childOption of optionSubGroup.subOptions">
    <ng-container *ngIf="childOption.option.optionID === selectedSubOption?.optionID">
      {{ childOption.name }}
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #optionGroupName>
  <ng-container *ngFor="let childOption of optionGroup.options">
    <ng-container *ngIf="childOption.optionID === selectedOption?.optionID">
      {{ childOption.name }}
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #subOptionGroupChoices>
  <ng-container *ngFor="let childOption of optionSubGroup.subOptions">
    <ng-container *ngIf="childOption.name === 'Left Half'">
      <button type="button" class="side side-left" (click)="$event.stopPropagation(); subOptionClicked(childOption.option)"
        [class.active]="childOption.option.optionID === selectedSubOption?.optionID">
      </button>
    </ng-container>
  </ng-container>
  <ng-container *ngFor="let childOption of optionSubGroup.subOptions">
    <ng-container *ngIf="childOption.name === 'Whole Pie'">
      <button type="button" class="side side-whole" (click)="$event.stopPropagation(); subOptionClicked(childOption.option)"
        [class.active]="childOption.option.optionID === selectedSubOption?.optionID">
      </button>
    </ng-container>
  </ng-container>
  <ng-container *ngFor="let childOption of optionSubGroup.subOptions">
    <ng-container *ngIf="childOption.name === 'Right Half'">
      <button type="button" class="side side-right" (click)="$event.stopPropagation(); subOptionClicked(childOption.option)"
        [class.active]="childOption.option.optionID === selectedSubOption?.optionID">
      </button>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #optionGroupChoices>
  <button type="button" class="side side-left" (click)="$event.stopPropagation(); optionClicked(optionGroup.options[0])"
    [class.active]="optionGroup.options[0].optionID === selectedOption?.optionID">
  </button>
  <button type="button" class="side side-whole" (click)="$event.stopPropagation(); optionClicked(optionGroup.options[1])"
    [class.active]="optionGroup.options[1].optionID === selectedOption?.optionID">
  </button>
  <button type="button" class="side side-right" (click)="$event.stopPropagation(); optionClicked(optionGroup.options[2])"
    [class.active]="optionGroup.options[2].optionID === selectedOption?.optionID">
  </button>
</ng-template>
