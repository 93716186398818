<ng-container *ngIf="mobile.isMobile">
  <ng-container *ngIf="vendorSetup$ | async as vendorSetup">
    <div class="fab-wrapper fab-wrapper-horizontal" (click)="presentModal()">
      <div class="fab-icon-horizontal">
        <div class="fab-icon d-flex align-items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-qr-code-scan"
               viewBox="0 0 16 16">
            <path
              d="M0 .5A.5.5 0 0 1 .5 0h3a.5.5 0 0 1 0 1H1v2.5a.5.5 0 0 1-1 0v-3Zm12 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V1h-2.5a.5.5 0 0 1-.5-.5ZM.5 12a.5.5 0 0 1 .5.5V15h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5Zm15 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 .5-.5ZM4 4h1v1H4V4Z"/>
            <path d="M7 2H2v5h5V2ZM3 3h3v3H3V3Zm2 8H4v1h1v-1Z"/>
            <path d="M7 9H2v5h5V9Zm-4 1h3v3H3v-3Zm8-6h1v1h-1V4Z"/>
            <path
              d="M9 2h5v5H9V2Zm1 1v3h3V3h-3ZM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8H8Zm2 2H9V9h1v1Zm4 2h-1v1h-2v1h3v-2Zm-4 2v-1H8v1h2Z"/>
            <path d="M12 9h2V8h-2v1Z"/>
          </svg>
        </div>
      </div>
      <div class="fw-bold" style="margin-right: 12px">
        <ng-container *ngIf="!isCheckin">
          <ng-container *ngIf="textFields$ | async as textField">
            {{textField.loyalty_fab_name}}
          </ng-container>
        </ng-container>
        <ng-container *ngIf="isCheckin">CHECK-IN</ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
