import {Component, Input} from '@angular/core';
import {Order} from 'src/interfaces/order.interface';
import { MobileService } from 'src/services/mobile.service';
import {Router} from '@angular/router';
import {HandoffType} from '../../../../../interfaces/handoff-type.enum';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {AppStateModel} from '../../../../../store/state/app.state';

@Component({
  selector: 'app-desktop-nav-order-type',
  templateUrl: './nav-order-type.component.html',
  styleUrls: ['./nav-order-type.component.scss']
})
export class DesktopNavOrderTypeComponent {
  @Select(state => state.app) app$: Observable<AppStateModel>;
  @Input() order: Order;
  handoffs = HandoffType;
  constructor(
    public mobile: MobileService,
    public router: Router
  ) {}
}
