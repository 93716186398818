import { Injectable } from '@angular/core';
import { SpendgoMappingService } from './spendgo-mapping.service';
import { SpendgoAPIService } from './spendgo-api.service';
import { UserProvider } from '../../providers/user-provider.interface';
import { LoyaltyProvider } from '../../providers/loyalty-provider.interface';
import { SSOLogin } from '../../interfaces/sso-login.model';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { PassResetResponse } from '../../interfaces/pass-reset-response.interface';
import { User } from '../../interfaces/user.interface';
import { CreateAccount } from '../../interfaces/create-account.interface';
import { CateringLink } from '../../interfaces/catering-link.interface';
import { RewardsBalances } from '../../interfaces/rewards-balances.interface';
import { HistoryEvent } from '../../interfaces/history-event.interface';
import { Reward } from '../../interfaces/reward.interface';
import { DollarReward } from '../../interfaces/dollar-reward.interface';
import { CheckInCode } from '../../interfaces/check-in-code.interface';
import { from } from 'rxjs';
import { Preferences as Storage } from '@capacitor/preferences';
import moment from 'moment-timezone';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SignInMemberRequest, SignInMemberResponse } from './interfaces/sign-in-member.interface';
import { RefreshTokenResponse } from './interfaces/refresh-token.interface';
import { ResetPasswordRequest } from './interfaces/reset-password.interface';
import { UpdateMemberRequest } from './interfaces/update-member.interface';
import { RetrieveMemberRequest, RetrieveMemberResponse } from './interfaces/retrieve-member.interface';
import { CreateMemberRequest, CreateMemberResponse, MemberAccountStatus } from './interfaces/create-member.interface';
import { RetrieveMemberBalanceRequest, RetrieveMemberBalanceResponse } from './interfaces/retrieve-member-balance.interface';
import { OrderService } from '../../services/vendor-config-service/order.service';
import { OLOProviderService } from '../olo/olo-provider.service';
import { Offer } from '../../interfaces/offer.interface';
import { SSOProvider } from '../../interfaces/sso-provider.enum';
import { DineEngineError } from '../../interfaces/dineengine-error.interface';
import {
  RetrieveMemberAccountStatus,
  RetrieveMemberStatusRequest,
  RetrieveMemberStatusResponse,
} from './interfaces/retrieve-member-status.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { Device } from '@capacitor/device';
import { PurchaseableReward, Variation } from '../../interfaces/purchaseable-reward.interface';
import { GiftCard } from '../../interfaces/giftcard.interface';
import { LocalStorageKey } from '../../models/common.enum';
import { InboxMessage } from '../../interfaces/inbox-message.interface';
import { UserField } from '../../interfaces/user-field';

@Injectable({
  providedIn: 'root',
})
export class SpendgoProviderService implements UserProvider, LoyaltyProvider {
  private memberIDKey = LocalStorageKey.SPENDGO_MEMBER_ID;
  private accessTokenKey = LocalStorageKey.SPENDGO_ACCESS_TOKEN;
  private accessTokenExpirationKey = LocalStorageKey.SPENDGO_ACCESS_TOKEN_EXPIRATION;
  private refreshTokenKey = LocalStorageKey.SPENDGO_REFRESH_TOKEN;
  private refreshTokenExpirationKey = LocalStorageKey.SPENDGO_REFRESH_TOKEN_EXPIRATION;

  private unavailableError = throwError(() => new Error('Functionality Unavailable'));

  private ssoLoginSubject = new BehaviorSubject<SSOLogin>(null);
  ssoLogin$ = this.ssoLoginSubject.asObservable();

  constructor(
    private mapping: SpendgoMappingService,
    private api: SpendgoAPIService,
    private orderService: OrderService
  ) {}

  getResetPasswordCode(email: string): Observable<any> {
    return this.forgotPassword(email);
  }

  // USER

  getSSOLoginSubject(): Observable<SSOLogin> {
    return this.ssoLogin$;
  }

  logIn(username: string, password: string): Observable<string> {
    const lookupBody: RetrieveMemberStatusRequest = {
      email: username,
      check_email_validated_status: true,
    };
    return this.api.retrieveMemberStatus(lookupBody).pipe(
      switchMap((lookupRes: RetrieveMemberStatusResponse) => {
        return from(Device.getId()).pipe(
          switchMap(deviceID => {
            return from(Device.getInfo()).pipe(
              switchMap(deviceInfo => {
                if (lookupRes.status === RetrieveMemberAccountStatus.WAITING_FOR_EMAIL_VALIDATION) {
                  const error: DineEngineError = {
                    message: 'Your account email is still pending verification. Please check your inbox for the verification email.',
                    name: 'Waiting for Email Verification',
                  };
                  return throwError(error);
                } else if (lookupRes.status === RetrieveMemberAccountStatus.NOT_FOUND) {
                  const error: DineEngineError = {
                    message: 'An account with this email could not be found.',
                    name: 'Member Not Found',
                  };
                  return throwError(error);
                } else {
                  const body: SignInMemberRequest = {
                    value: username,
                    password,
                    device_id: deviceID.identifier,
                    os_name: deviceInfo.operatingSystem,
                    os_version: deviceInfo.osVersion,
                    manufacturer: deviceInfo.manufacturer,
                  };
                  return this.api.signInMember(body).pipe(
                    switchMap((res: SignInMemberResponse) => {
                      return this.handleTokenResponse(res).pipe(
                        switchMap(() => {
                          return this.getMemberID();
                        })
                      );
                    }),
                    catchError((err: HttpErrorResponse) => {
                      return throwError(this.loginError(err));
                    })
                  );
                }
              })
            );
          })
        );
      })
    );
  }

  private loginError(err: HttpErrorResponse): DineEngineError {
    switch (err.status) {
      case 401:
        return {
          name: 'Incorrect Info Entered',
          message: 'Email or Password Incorrect',
        };
      case 404:
        return {
          name: 'Member Not Found',
          message: 'An account with this email could not be found',
        };
      default:
        return {
          name: 'Unknown Error',
          message: err.message,
        };
    }
  }

  logOut(userID: string): Observable<string> {
    return this.getAccessToken().pipe(
      switchMap((accessToken: string) => {
        return this.api.signOut(accessToken).pipe(
          switchMap(_ => {
            return this.clearTokens().pipe(
              map(() => {
                return null;
              })
            );
          })
        );
      })
    );
  }

  logInWithToken(token: string, redirectURL: string): Observable<string> {
    return this.unavailableError;
  }

  logInWithFacebook(email: string, accessToken: string, userID: string): Observable<any> {
    return this.unavailableError;
  }

  connectWithFacebook(email: string, accessToken: string, userID: string): Observable<any> {
    return this.unavailableError;
  }

  logInWithApple(appleResult: SignInWithAppleResponse, redirectURI: string): Observable<string> {
    return this.unavailableError;
  }

  connectWithApple(appleResult: SignInWithAppleResponse, redirectURI: string): Observable<any> {
    return this.unavailableError;
  }

  forgotPassword(email: string): Observable<any> {
    const body: ResetPasswordRequest = {
      email,
    };
    return this.api.resetPassword(body).pipe(
      catchError(error => {
        const err: DineEngineError = {
          name: 'Unknown/Invalid Email',
          message:
            'The email you entered could not be found in our system. If you believe this is an error, please check your spelling and try again.',
        };
        return throwError(err);
      })
    );
  }

  changePassword(email: string, oldPassword: string, newPassword: string): Observable<any> {
    return this.unavailableError;
  }

  resetPassword(newPassword: string): Observable<PassResetResponse> {
    return this.unavailableError;
  }

  // tslint:disable-next-line:max-line-length
  updateUserInfo(user: User): Observable<User> {
    return this.getAccessToken().pipe(
      switchMap((accessToken: string) => {
        const body: UpdateMemberRequest = {
          spendgo_id: String(user.userID),
          phone: user.phoneNumber,
          email: user.email,
          first_name: user.firstName,
          last_name: user.lastName,
          email_opt_in: user.emailOptIn,
          sms_opt_in: user.sMSOptIn,
        };
        return this.api.updateMember(body, accessToken).pipe(
          switchMap(() => {
            const retrieveBody: RetrieveMemberRequest = {
              spendgo_id: String(user.userID),
            };
            return this.api.retrieveMember(retrieveBody, accessToken).pipe(
              map((res: RetrieveMemberResponse) => {
                return this.mapping.retrieveMemberResponseToUser(res);
              })
            );
          })
        );
      })
    );
  }

  createAccount(newAccount: CreateAccount, additionalFields: UserField[]): Observable<User> {
    const body: CreateMemberRequest = {
      email: newAccount.email,
      dob: newAccount.dob,
      first_name: newAccount.firstName,
      last_name: newAccount.lastName,
      password: newAccount.password,
      phone: newAccount.phone,
      email_opt_in: newAccount.emailOptIn,
      sms_opt_in: newAccount.smsOptIn,
    };
    if (additionalFields && additionalFields.length > 0) {
      additionalFields.forEach(field => {
        body[field.providerFieldName] = field.value;
      });
    }
    return this.api.createMember(body).pipe(
      switchMap((createRes: CreateMemberResponse) => {
        if (createRes.status === MemberAccountStatus.WAITING_FOR_VERIFICATION) {
          const error: DineEngineError = {
            message:
              'Your account has been created, but you must verify your email before you can login. Please check your inbox for the verification email',
            name: 'Waiting for Email Verification',
          };
          return throwError(error);
        }
        return this.logIn(newAccount.email, newAccount.password).pipe(
          switchMap(() => {
            return this.getUserInfo(createRes.id);
          })
        );
      }),
      catchError(err => {
        if (err instanceof DineEngineError) {
          return throwError(err);
        } else {
          return throwError(this.mapping.spendgoErrorToDineEngineError(err));
        }
      })
    );
  }

  logInAsGuest(): Observable<string> {
    return of(null);
  }

  getUserInfo(userID: string): Observable<User> {
    return this.getAccessToken().pipe(
      switchMap((accessToken: string) => {
        const body: RetrieveMemberRequest = {
          spendgo_id: String(userID),
        };
        return this.api.retrieveMember(body, accessToken).pipe(
          map((res: RetrieveMemberResponse) => {
            return this.mapping.retrieveMemberResponseToUser(res);
          })
        );
      })
    );
  }

  getLoggedInUserInfo(): Observable<User> {
    return this.getAccessToken().pipe(
      switchMap((accessToken: string) => {
        if (accessToken) {
          return this.getMemberID().pipe(
            switchMap(userID => {
              const body: RetrieveMemberRequest = {
                spendgo_id: String(userID),
              };
              return this.api.retrieveMember(body, accessToken).pipe(
                map((res: RetrieveMemberResponse) => {
                  this.ssoLoginSubject.next({
                    provider: SSOProvider.spendgo,
                    token: accessToken,
                  });
                  return this.mapping.retrieveMemberResponseToUser(res);
                })
              );
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  is3rdPartyWrapped(): boolean {
    return false;
  }

  isOauthEnabled(): Observable<boolean> {
    return of(false);
  }

  redirectToOauthPage() {}

  cateringLink(): Observable<CateringLink> {
    return this.unavailableError;
  }

  // LOYALTY

  getPointsBalance(userID: string | number): Observable<RewardsBalances> {
    return this.getAccessToken().pipe(
      switchMap((accessToken: string) => {
        const body: RetrieveMemberBalanceRequest = {
          spendgo_id: String(userID),
        };
        return this.api.retrieveMemberBalance(body, accessToken).pipe(
          map((balances: RetrieveMemberBalanceResponse) => {
            return this.mapping.retrieveMemberBalanceRequestToRewardsBalances(balances);
          })
        );
      })
    );
  }

  getOffers(userID: string | number): Observable<Offer[]> {
    return of([]);
  }

  getLoyaltyActivity(): Observable<HistoryEvent[]> {
    return of([]);
  }

  earnPoints(userID: string | number): Observable<any> {
    return this.unavailableError;
  }

  getRewards(userID: string | number, locationID: string | number): Observable<Reward[]> {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        return oService.getCurrentOrder(userID).pipe(
          switchMap(order => {
            if (order && oService instanceof OLOProviderService) {
              return oService.getRewards(userID, String(locationID));
            } else {
              return this.getAccessToken().pipe(
                switchMap((accessToken: string) => {
                  const body: RetrieveMemberBalanceRequest = {
                    spendgo_id: String(userID),
                  };
                  return this.api.retrieveMemberBalance(body, accessToken).pipe(
                    map(response => {
                      return response.rewards.map(reward => this.mapping.retrieveMemberBalanceRequestRewardToDEReward(reward));
                    })
                  );
                })
              );
            }
          })
        );
      })
    );
  }

  redeemReward(reward: Reward): Observable<any> {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        if (oService instanceof OLOProviderService) {
          return oService.redeemReward(reward);
        } else {
          return this.unavailableError;
        }
      })
    );
  }

  redeemInStoreReward(reward: Reward): Observable<Reward> {
    return this.unavailableError;
  }

  redeemBankedPoints(points: number): Observable<Reward> {
    return this.unavailableError;
  }

  voidBankedPoints(reward: DollarReward): Observable<any> {
    return this.unavailableError;
  }

  removeAppliedReward(reward: Reward): Observable<any> {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        if (oService instanceof OLOProviderService) {
          return oService.removeAppliedReward(reward);
        } else {
          return this.unavailableError;
        }
      })
    );
  }

  redeemPointsFromScanner(barCode: string): Observable<any> {
    return this.unavailableError;
  }

  checkInAtStore(): Observable<CheckInCode> {
    return this.unavailableError;
  }

  getPurchaseableRewards(): Observable<PurchaseableReward[]> {
    return of(null);
  }

  purchaseReward(reward: PurchaseableReward, variation?: Variation): Observable<boolean> {
    return of(false);
  }

  getLoyaltyLocations(): Observable<any> {
    return this.unavailableError;
  }

  canTransferGiftCardToAccount(): Observable<boolean> {
    return of(false);
  }

  transferGiftCardBalanceToAccount(from: GiftCard): Observable<{ success: boolean }> {
    return this.unavailableError;
  }

  getLoyaltyStoredValueCardInfo(userID: string): Observable<GiftCard> {
    return this.unavailableError;
  }

  private handleTokenResponse(response: SignInMemberResponse | RefreshTokenResponse): Observable<void> {
    return this.storeAccessToken(response.access_token).pipe(
      switchMap(() => {
        return this.storeAccessTokenExpiration(response.access_token_expires_on).pipe(
          switchMap(() => {
            return this.storeRefreshToken(response.refresh_token).pipe(
              switchMap(() => {
                return this.storeRefreshTokenExpiration(response.refresh_token_expires_on).pipe(
                  switchMap(() => {
                    return this.storeMemberID(response.spendgo_id);
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  private clearTokens(): Observable<void> {
    return this.deleteAccessToken().pipe(
      switchMap(() => {
        return this.deleteAccessTokenExpiration().pipe(
          switchMap(() => {
            return this.deleteRefreshToken().pipe(
              switchMap(() => {
                return this.deleteRefreshTokenExpiration().pipe(
                  switchMap(() => {
                    return this.deleteMemberID();
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  private getMemberID(): Observable<string> {
    return from(Storage.get({ key: this.memberIDKey })).pipe(
      map(data => {
        return data.value;
      })
    );
  }

  private storeMemberID(id: string): Observable<void> {
    return from(Storage.set({ key: this.memberIDKey, value: id }));
  }

  private deleteMemberID(): Observable<void> {
    return from(Storage.remove({ key: this.memberIDKey }));
  }

  private getAccessToken(): Observable<string> {
    return this.getAccessTokenExpiration().pipe(
      switchMap((expiration: moment.Moment) => {
        // is right now before the expiration time?
        if (expiration && moment().isBefore(expiration)) {
          // return from storage
          return from(Storage.get({ key: this.accessTokenKey })).pipe(
            map(data => {
              return data.value;
            })
          );
        } else {
          // get refresh token expiration
          return this.getRefreshTokenExpiration().pipe(
            switchMap(refreshExpiration => {
              // is refresh token still valid?
              if (refreshExpiration && moment().isBefore(refreshExpiration)) {
                // get refresh token
                return this.getRefreshToken().pipe(
                  switchMap(refreshToken => {
                    // refresh token through API
                    return this.api.refreshToken(refreshToken).pipe(
                      switchMap(newTokens => {
                        // save all new token values
                        return this.handleTokenResponse(newTokens).pipe(
                          switchMap(() => {
                            // rerun this function with new expirations
                            return this.getAccessToken();
                          })
                        );
                      })
                    );
                  })
                );
              } else {
                // current token and refresh token both expired, user must sign in again
                return of(null);
              }
            })
          );
        }
      })
    );
  }

  private storeAccessToken(token: string): Observable<void> {
    return from(Storage.set({ key: this.accessTokenKey, value: token }));
  }

  private deleteAccessToken(): Observable<void> {
    return from(Storage.remove({ key: this.accessTokenKey }));
  }

  private getAccessTokenExpiration(): Observable<moment.Moment> {
    return from(Storage.get({ key: this.accessTokenExpirationKey })).pipe(
      map(data => {
        if (data.value) {
          return moment.utc(data.value);
        } else {
          return moment();
        }
      })
    );
  }

  private storeAccessTokenExpiration(expiration: number): Observable<void> {
    return from(
      Storage.set({
        key: this.accessTokenExpirationKey,
        value: moment(expiration).utc().format(),
      })
    );
  }

  private deleteAccessTokenExpiration(): Observable<void> {
    return from(Storage.remove({ key: this.accessTokenExpirationKey }));
  }

  private getRefreshToken(): Observable<string> {
    return from(Storage.get({ key: this.refreshTokenKey })).pipe(
      map(data => {
        return data.value;
      })
    );
  }

  private storeRefreshToken(token: string): Observable<void> {
    return from(Storage.set({ key: this.refreshTokenKey, value: token }));
  }

  private deleteRefreshToken(): Observable<void> {
    return from(Storage.remove({ key: this.refreshTokenKey }));
  }

  private getRefreshTokenExpiration(): Observable<moment.Moment> {
    return from(Storage.get({ key: this.refreshTokenExpirationKey })).pipe(
      map(data => {
        if (data.value) {
          return moment.utc(data.value);
        } else {
          return moment();
        }
      })
    );
  }

  private storeRefreshTokenExpiration(expiration: number): Observable<void> {
    return from(
      Storage.set({
        key: this.refreshTokenExpirationKey,
        value: moment(expiration).utc().format(),
      })
    );
  }

  private deleteRefreshTokenExpiration(): Observable<void> {
    return from(Storage.remove({ key: this.refreshTokenExpirationKey }));
  }

  deleteAccount(userID: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  deleteMessage(userID: string, messageID: string): Observable<InboxMessage[]> {
    return of([]);
  }

  getMessages(userID: string): Observable<InboxMessage[]> {
    return of([]);
  }

  markMessageAsRead(userID: string, messageID: string): Observable<InboxMessage[]> {
    return of([]);
  }
}
