<ng-container *ngIf="textFields$ | async as textFields">
  <div class="mobile-modal-header-close">
    <div class="header-title header-font fw-bold">{{ textFields.earn_page_title }}</div>
    <button class="back-button" (click)="close()">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
      </svg>
    </button>
  </div>
  <ion-content class="mobile-modal-content h-100">
    <ng-container *ngIf="!isCheckin else checkIn">
      <div class="d-flex flex-column gap-3 p-3">
        <h2 class="header-font fw-bold text-center">
          {{ textFields.earn_page_heading }}
        </h2>

        <div class="code-container">

          <div class="program-details-icon" *ngIf="(mobileAppSettings$ | async)?.loyalty_program_details">
            <button type="button" class="program-details-button" (click)="openInstructions()">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-question-circle"
                   viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path
                    d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
              </svg>
            </button>
          </div>

          <div class="d-flex flex-column gap-2">
            <h5 class="header-font user-name">
              {{displayUser.firstName}} {{displayUser.lastName}}
            </h5>
            <p>
              {{displayUser.phoneNumber | phone}}
            </p>
            <p>
              Loyalty Number: {{displayUser.userAsBarcode}}
            </p>
          </div>

          <!--  replace with dynamic qr code-->
          <div class="d-flex justify-content-center">
            <div class="loyalty-qr-code">
              <qrcode
                  *ngIf="displayMode === displayModes.qr"
                  [width]="160"
                  [qrdata]="displayUser.userAsQrCode"
                  [level]="'H'"
                  (click)="!changeCode ? switchDisplayMode() : null"
              ></qrcode>
              <ngx-ivy-barcode
                  *ngIf="displayMode === displayModes.barcode"
                  [bc-display-value]="false"
                  bc-element-type="svg"
                  [bc-height]="140"
                  [bc-value]="displayUser.userAsBarcode"
                  (click)="!changeCode ? switchDisplayMode() : null"
              ></ngx-ivy-barcode>
            </div>
          </div>

          <ng-container *ngIf="textFields.earn_page_description; else defaultInstructions">
            <div class="container" [innerHTML]="textFields.earn_page_description | sanitizeHtml"></div>
          </ng-container>
          <ng-template #defaultInstructions>
            <p class="text-center">
              Tap to change between QR and Barcode
            </p>
            <p class="text-center">
              Scan this code or provide your phone number at the register to earn points.
            </p>
            <p class="text-center">
              Code scanning may not be available at all locations. Please provide phone number or email address to team member if code
              scanning is not available.
            </p>
          </ng-template>

        </div>
        <ng-container *ngIf="enableQR">
          <form [formGroup]="barcodeForm">
              <div class="form-group flex-grow-1">
                <ng-container *ngIf="textFields$ | async as textFields">
                  <label for="barcodeInput" class="fw-bold">{{ textFields.scan_barcode_title_copy }}</label>
                  <div class="input-group"><input formControlName="manualBarcode" class="form-control" id="barcodeInput"
                            [placeholder]="textFields.scan_barcode_placeholder_copy">
                    <div class="input-group-append">
                      <button class="de-primary-btn" type="button" (click)="manuallySubmitBarcode()">Apply</button>
                    </div>
                  </div>
                </ng-container>
              </div>
            <div class="invalid-feedback">

            </div>
          </form>
        </ng-container>
      </div>
    </ng-container>
  </ion-content>
</ng-container>

<ng-template #checkIn>
  <ng-container *ngIf="checkInCode$ | async as checkInCode else checkingIn">
    <h1 class="header-font text-uppercase m-0">In-Store Check-In</h1>
    <div class="my-3" style="font-size: 64px">{{checkInCode.code}}</div>
    <p class="fst-italic m-0">Give this code to your server to receive points for this order.</p>
    <ng-container *ngIf="checkInCode.expirationDate">
      <div>Code Expires At:</div>
      <div>{{checkInCode.expirationDate | date: 'short'}}</div>
    </ng-container>
  </ng-container>
  <ng-template #checkingIn>
    <div class="w-100 h-100" style="display: grid; place-items: center">
      <div style="display: grid; place-items: center; grid-template-rows: 50px auto; width: max-content;">
        <div class="de-btn-loader-secondary"></div>
        <div>Checking you in...</div>
      </div>
    </div>
  </ng-template>
</ng-template>
