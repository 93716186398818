<div class="row mt-4 mx-2">
    <div class="col-12">
        <div class="card p-2">
            <div class="card-title">
                <div class="row de-no-padding de-bottom-border px-3">
                    <div class="text-start fw-bold header-font p-3">
                        Rewards
                    </div>
                </div>
            </div>
            <div class="card-body mt-n4">
                <ng-container *ngIf="!areRewardsLoading; else rewardsLoading">
                    <ion-row class="reward-container-desktop">
                        <ion-col *ngFor="let reward of rewards" size="12" class="p-0">
                            <app-reward-card
                              [currencyCode]="currencyCode$ | async"
                              [reward]="reward"
                              [canApply]="!areRewardsSelecting"
                              (clickedRedeem)="clickedRedeemedReward(reward)"
                              (clickedRemove)="clickedRemovedReward(reward)"
                              [loadingReward]="loadingReward"
                            ></app-reward-card>
                        </ion-col>
                    </ion-row>
                </ng-container>
                <ng-template #rewardsLoading>
                    <div style="text-align: center; padding: 8vh 2vw">
                        <ion-spinner name="crescent"></ion-spinner>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
