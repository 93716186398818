<ng-container *ngIf="!isCart; else cartReward">
  <ng-container *ngIf="reward; else emptyReward">
    <div class="card h-100 position-relative cms-card-radius">
      <div class="position-relative img-border-inherit">

        <ng-container *ngIf="reward.description">
          <ng-container *ngIf="!mobile.isMobile; else mobileInfo">
            <button type="button" class="product-card-info"
                    [ngbTooltip]="reward.description">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path
                        d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
            </button>
          </ng-container>

          <ng-template #mobileInfo>
            <button type="button" class="product-card-info"
                    (click)="openInfoModal($event);">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path
                        d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
            </button>
          </ng-template>
        </ng-container>

        <!--      <ng-container *ngTemplateOutlet="customizeButton"></ng-container>-->

        <ng-template #infoModal>
          <h5 class="px-3 pt-3 header-font m-0 fw-bold">{{reward.name}}</h5>
          <div class="px-3 pb-3 pt-2">{{reward.description}}</div>
        </ng-template>

        <ng-container
          *ngIf="
            reward.imageURL ||
              (branding$ | async)?.default_reward_background_image?.data
                ?.full_url;
            else noImage
          ">
          <img
            [src]="
              reward.imageURL
                ? reward.imageURL
                : (branding$ | async).default_reward_background_image?.data
                    ?.full_url
            "
            class="card-img-top w-100 img-border-inherit"
            [alt]="reward.name" />
        </ng-container>
        <ng-template #noImage>
          <img
            src="assets/img/600x600.png"
            class="card-img-top w-100 img-border-inherit"
            [alt]="reward.name" />
          <div
            class="position-absolute w-100 h-100 img-border-inherit"
            style="
              display: grid;
              place-items: center;
              top: 0;
              background-color: var(--ion-color-card-background-color);
            ">
            <div class="no-image-text font-size-body-large">
              {{ reward.name }}
            </div>
          </div>
        </ng-template>
        <div
          class="mt-auto mt-sm-0 d-flex flex-column justify-content-start position-absolute"
          style="left: 1rem; bottom: 1rem">
          <ng-container *ngIf="!disabled">
            <ng-container
              *ngIf="
                !(loadingReward === reward.rewardID && reward.rewardID !== null)
              ">
              <ng-container *ngIf="!reward.isApplied && !isStaged">
                <button
                  type="button"
                  class="fw-bold pointer de-primary-btn font-size-body d-flex flex-row flex-nowrap text-nowrap align-items-center"
                  (click)="redeemClicked()">
                  Redeem<i
                    class="ms-2 bi bi-plus-circle font-size-body-large"></i>
                </button>
              </ng-container>
              <ng-container *ngIf="reward.isApplied || isStaged">
                <button
                  type="button"
                  class="fw-bold pointer de-danger-btn font-size-body d-flex flex-row flex-nowrap text-nowrap align-items-center"
                  (click)="removeClicked()">
                  Remove<i
                    class="ms-2 bi bi-dash-circle font-size-body-large"></i>
                </button>
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="
                loadingReward === reward.rewardID && reward.rewardID !== null
              ">
              <button
                type="button"
                class="d-flex flex-row flex-nowrap text-nowrap gap-1 de-primary-btn align-items-center">
                <div class="fw-bold">Loading</div>
                <i class="bi bi-arrow-repeat spinning font-size-body-large"></i>
              </button>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="card-footer d-flex flex-row flex-nowrap h-100 px-3">
        <div class="flex-grow-1">
          <div
            class="font-size-body-large header-font fw-bold text-wrap reward-name">
            {{ reward.name }}
          </div>
          <div *ngIf="reward.expDate" class="font-size-body-small text-nowrap">
            Expires: {{ reward.expDate | date: 'shortDate' }}
          </div>
        </div>
        <div class="ms-3" *ngIf="reward.quantity">
          <div class="quantity-pill fw-bold font-size-body-small">
            x{{ reward.quantity }}
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="rewards-card"-->
    <!--         [style.background-image]="(reward.imageURL ? reward.imageURL : (branding$ | async).default_reward_background_image?.data?.full_url) | rewardCardBackground">-->

    <!--      <h4 class="header-font fw-bold m-0 reward-name">-->
    <!--        {{reward.name}}-->
    <!--        <ng-container *ngIf="reward.rewardID === 'banked' && !reward.isDollarReward">-->
    <!--          : {{displayBalances.bankedRewards | currency: currencyCode}}-->
    <!--        </ng-container>-->
    <!--      </h4>-->
    <!--      <small class="fw-bold" *ngIf="reward.expDate">Expires: {{reward.expDate | date: 'M/d/yy'}}</small>-->
    <!--      <div class="mt-auto mt-sm-0 d-flex flex-column gap-1">-->
    <!--        <ng-container *ngIf="!disabled">-->
    <!--          <ng-container *ngIf="!(loadingReward === reward.rewardID && reward.rewardID !== null)">-->
    <!--            <ng-container *ngIf="!reward.isApplied && !isStaged">-->
    <!--              <button type="button" class="fw-bold pointer de-secondary-btn font-size-body d-flex flex-row flex-nowrap text-nowrap align-items-center gap-2"-->
    <!--                   (click)="redeemClicked()">Redeem<i class="bi bi-plus-circle font-size-body-large"></i>-->
    <!--              </button>-->
    <!--            </ng-container>-->
    <!--            <ng-container *ngIf="reward.isApplied || isStaged">-->
    <!--              <button type="button"-->
    <!--                      class="fw-bold pointer de-danger-btn font-size-body d-flex flex-row flex-nowrap text-nowrap align-items-center gap-2"-->
    <!--                      (click)="removeClicked()">Remove<i class="bi bi-dash-circle font-size-body-large"></i>-->
    <!--              </button>-->
    <!--            </ng-container>-->
    <!--          </ng-container>-->
    <!--          <ng-container *ngIf="loadingReward === reward.rewardID && reward.rewardID !== null">-->
    <!--            <button type="button" class="d-flex flex-row flex-nowrap text-nowrap gap-1 de-secondary-btn align-items-center">-->
    <!--              <div class="fw-bold">Loading</div>-->
    <!--              <i class="bi bi-arrow-repeat spinning font-size-body-large"></i></button>-->
    <!--          </ng-container>-->
    <!--        </ng-container>-->
    <!--      </div>-->
    <!--    </div>-->
  </ng-container>

  <ng-template #emptyReward>
    <div class="rewards-card-empty">
      <small class="text-center text-muted">
        Place orders to earn points and rewards.
      </small>
      <div
        class="d-flex flex-column align-items-center justify-content-center h-100 w-100 text-muted"
        style="gap: 0.5rem">
        <ng-container *ngIf="theme$ | async as theme">
          <app-custom-icon
            class=""
            default="bi-award"
            customIconKey="rewards_navigation_icon"
            size="48"></app-custom-icon>
        </ng-container>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #cartReward>
  <ng-container *ngIf="reward.isApplied && !isStaged; else stagedCartReward">
    <ng-container *ngIf="order$ | async as order">
      <div class="d-flex flex-row flex-nowrap align-items-center gap-2">
        <div class="reward-cart-card">
          <ng-container *ngIf="mobileAppSettings$ | async as mobileAppSettings">
            <ng-container *ngIf="mobileAppSettings.active_rewards_icon">
              <div
                class="reward-cart-icon-shell"
                style="height: 34px; width: 34px">
                <img
                  class="reward-cart-icon"
                  [src]="
                    mobileAppSettings.active_rewards_icon?.data
                      ? mobileAppSettings.active_rewards_icon.data.full_url
                      : mobileAppSettings.active_rewards_icon.data.full_url
                  " />
              </div>
            </ng-container>
          </ng-container>

          <ng-container
            *ngIf="
              order?.appliedCouponCents && order?.appliedRewards.length > 0
            ">
            <div class="d-flex flex-nowrap gap-3 flex-grow-1 px-2">
              <div class="fees-small applied-reward-name">
                {{ order.appliedRewards[0].name }}
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="!disabled">
            <ng-container
              *ngIf="
                !(loadingReward === reward.rewardID && reward.rewardID !== null)
              ">
              <ng-container *ngIf="reward.isApplied || isStaged">
                <div
                  class="fw-bold pointer h-auto font-size-body d-flex flex-row flex-nowrap align-items-center gap-2 pe-2"
                  (click)="removeClicked()">
                  <i class="bi bi-dash-circle font-size-body-large d-flex"></i>
                </div>
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="
                loadingReward === reward.rewardID && reward.rewardID !== null
              ">
              <div
                class="d-flex flex-row flex-nowrap gap-1 h-auto align-items-center pe-2">
                <div class="fw-bold"></div>
                <i
                  class="bi bi-arrow-repeat spinning font-size-body-large d-flex"></i>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <ng-container
          *ngIf="order?.appliedCouponCents && order?.appliedRewards.length > 0">
          <div class="d-flex flex-nowrap gap-3">
            <div class="fees-small-right text-nowrap fw-bold">
              -
              {{
                order?.appliedCouponCents / 100
                  | currency: (currencyCode$ | async)
              }}
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #stagedCartReward>
    <div class="d-flex flex-row flex-nowrap align-items-center gap-2">
      <div class="reward-cart-card-staged">
        <ng-container *ngIf="mobileAppSettings$ | async as mobileAppSettings">
          <ng-container *ngIf="mobileAppSettings.active_rewards_icon">
            <div
              class="reward-cart-icon-shell"
              style="height: 34px; width: 34px">
              <img
                class="reward-cart-icon"
                [src]="
                  mobileAppSettings.active_rewards_icon?.data
                    ? mobileAppSettings.active_rewards_icon.data.full_url
                    : mobileAppSettings.active_rewards_icon.data.full_url
                " />
            </div>
          </ng-container>
        </ng-container>

        <div class="d-flex flex-nowrap gap-3 flex-grow-1 px-2">
          <div class="fees-small applied-reward-name">
            {{ reward.name }}
          </div>
        </div>

        <ng-container *ngIf="!disabled">
          <ng-container
            *ngIf="
              !(loadingReward === reward.rewardID && reward.rewardID !== null)
            ">
            <ng-container *ngIf="reward.isApplied || isStaged">
              <div
                class="fw-bold pointer h-auto font-size-body d-flex flex-row flex-nowrap align-items-center gap-2 pe-2"
                (click)="unstageReward()">
                <i class="bi bi-dash-circle font-size-body-large d-flex"></i>
              </div>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="
              loadingReward === reward.rewardID && reward.rewardID !== null
            ">
            <div
              class="d-flex flex-row flex-nowrap gap-1 h-auto align-items-center pe-2">
              <div class="fw-bold"></div>
              <i
                class="bi bi-arrow-repeat spinning font-size-body-large d-flex"></i>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-template>
</ng-template>
