<ng-container *ngIf="!isUpsellPage else isUpsell">
  <ng-container *ngIf="category$ | async as category">
    <ng-container *ngIf="category.show_category_banner else noImage">
      <ng-container *ngIf="category.standardImageURL;
                  else noImage">
        <div class="p-3">
          <img
            class="img-border-inherit cms-card-radius w-100 h-auto"
            [alt]="category.name"
            [src]="category.standardImageURL
                  " />
          <ng-container *ngIf="description">
            <div class=" w-100 description-text text-start text-lg-center mt-2">
              <div>{{ description ? description : '' }}</div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
    <ng-template #noImage>
      <div class="d-flex flex-row flex-nowrap menu-header" [class.py-lg-3]="mode.mode !== 'kiosk'">
        <div class="grow-3 basis-0 p-3">
          <div class="d-flex flex-row flex-wrap justify-content-center align-items-center h-100">
            <h1 class="header-font fw-bold m-0 text-start text-lg-center w-100" [class.font-size-h1]="mode.mode !== 'kiosk'"
                [class.font-size-subheading-1]="mode.mode === 'kiosk'">
              {{ title ? title : 'Loading Menu...' }}
            </h1>
            <ng-container *ngIf="description">
              <div class=" w-100 description-text text-start text-lg-center mt-2">
                <div>{{ description ? description : '' }}</div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>

<ng-template #isUpsell>
  <div class="py-4 d-flex flex-row flex-nowrap">
    <!--  <div class="grow-1 basis-0"></div>-->
    <div class="grow-3 basis-0">
      <div class="d-flex flex-row flex-wrap align-items-center h-100">
        <h1 class="header-font font-size-h1 fw-bold m-0">
          {{ title ? title : 'Loading Menu...'}}
        </h1>
        <ng-container *ngIf="description">
          <div class=" w-100 description-text text-center mt-2">
            <div>{{ description ? description : '' }}</div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="grow-1 basis-0 d-flex justify-content-end">
      <ng-container *ngIf="mode.mode !== 'kiosk'">
      <button type="button" *ngIf="isUpsellPage" class="continue-checkout de-secondary-btn m-0 w-fit-content" id="continue-checkout"
              (click)="continueClicked()">Continue to Checkout
      </button>
      </ng-container>
    </div>
  </div>
</ng-template>
