import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Product } from '../../../../interfaces/product.interface';
import { ProductComponent } from '../../containers/product/base/product.component';
import { filter, take } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { UpdateTitle } from '../../../../store/actions/app.actions';
import { Option } from '../../../../interfaces/option.interface';
import { AddToOrder, CheckProductUpsell, UpdateOrderItem } from '../../../../store/actions/order.actions';
import { Capacitor } from '@capacitor/core';
import { Haptics, NotificationType } from '@capacitor/haptics';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '../../../../store/state.model';
import { User } from '../../../../interfaces/user.interface';
import { GroupOrder } from '../../../../interfaces/group-order.interface';

@Component({
  selector: 'app-modify-modal',
  templateUrl: './modify-modal.component.html',
  styleUrls: ['./modify-modal.component.scss'],
  host: {
    style: 'width:100%; position:relative;',
  },
})
export class ModifyModalComponent extends ProductComponent implements OnInit, AfterViewInit {
  @Select((state: GlobalStateModel) => state.user.user) user$: Observable<User>;
  @Select(state => state.order.groupOrder) groupOrder$: Observable<GroupOrder>;

  @Input() isLoading: boolean;
  @Input() basketProdID;

  @Output() closeModal = new EventEmitter<any>();

  pos = { top: 0, left: 0, x: 0, y: 0 };
  scrollBar = document.getElementById('mod-scroll-section');
  guestName: string = null;
  isGroupOrder = false;

  ngOnInit() {
    combineLatest([
      this.order$.pipe(
        filter(order => order !== null),
        take(1)
      ),
      this.product$.pipe(filter(prod => prod !== null)),
    ]).subscribe(([order, product]) => {
      const productCopy = JSON.parse(JSON.stringify(product)) as Product;
      this.menuService.recursivelySetSelectedOptions(productCopy);
      this.displayProduct = productCopy;
      this.title = product.name;
      this.store.dispatch(new UpdateTitle(this.title));
      this.directus.getContentProducts().subscribe(prod => {
        this.directusProd = prod.find(p => this.toSlug(p.name) === this.toSlug(this.displayProduct.name));
        this.isModifierFlow = !!this.directusProd.modifier_wizard_flow;
        this.setDisplayOptionGroups(this.getDisplayOptionGroups(this.displayProduct));
        this.displayAdjustedPrice = this.displayProduct.priceCents + this.getSelectionsPrice(this._displayOptionGroups);
        this.canAddToOrder = this.getCanAddToOrderStatus(this._displayOptionGroups);
        this.getModifierGroupTypes(this.directusProd);
      });
      this.displayOrder = order;
      if (this.basketProdID) {
        this.productID = this.basketProdID;
        const orderItem = order.items.find((basketProd: any) => basketProd.orderItemID === this.productID);
        if (orderItem && orderItem.options) {
          this.menuService.recursivelySetSelectedOptions(
            productCopy,
            orderItem.options.map(op => op)
          );
        }
        this.productquant = orderItem.quantity;
      }
    });
    this.groupOrder$.subscribe(groupOrder => {
      if (groupOrder && groupOrder.order) {
        this.isGroupOrder = true;
      }
    });
    this.user$.subscribe(user => {
      if (user && !user?.isGuest) {
        this.guestName = user.firstName + ' ' + user.lastName;
      } else {
        this.guestName = sessionStorage.getItem('guestName');
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const scrollSection = document.getElementById('modify-scroll');
      if (scrollSection) {
        scrollSection.scrollTop = 0;
      }
    }, 0);
  }

  addToCart() {
    this.productLoading = true;
    this.canAddToOrder = false;
    this.errorMessage = '';
    const selectedOptions: Option[] = this.menuService.getSelectedOptions(this._displayOptionGroups);
    const orderItem = this.menuService.toOrderItem(
      this.displayProduct,
      selectedOptions,
      this.productquant,
      null,
      this.productDetails.get('recipient').value ? this.productDetails.get('recipient').value : this.isGroupOrder ? this.guestName : null,
      this.productDetails.get('specInstr').value
    );
    this.store
      .dispatch(new AddToOrder(orderItem))
      .toPromise()
      .then(() => {
        if (Capacitor.getPlatform() !== 'web') {
          Haptics.notification({
            type: NotificationType.Success,
          })
            .then()
            .catch(console.log);
        }
        this.toast.success(`${this.displayProduct.name} added to order`);
        if (this.mode.mode === 'tableside') {
          this.store
            .dispatch(new CheckProductUpsell(orderItem.name))
            .toPromise()
            .then(newState => {
              if (newState.order.upsells && newState.order.upsells.items.length > 0) {
                this.navigation.navigateToUpsellPage().then(() => this.closeModal.emit());
              } else {
                console.log(newState.order.order.location.locationID, this.menuService.categoryNameSlug);
                // tslint:disable-next-line:max-line-length
                this.navigation
                  .navigateToMenuPage(newState.order.order.location.locationID, this.menuService.categoryNameSlug)
                  .then(() => this.closeModal.emit());
                this.productLoading = false;
              }
            });
        } else {
          this.closeModal.emit();
        }
      })
      .catch(error => {
        this.errorMessage = this.errorService.productError(error);
        this.toast.danger(this.errorMessage);
        this.canAddToOrder = true;
        this.productLoading = false;
      });
  }

  updateItem() {
    this.productLoading = true;
    this.canAddToOrder = false;
    this.errorMessage = '';
    const selectedOptions: Option[] = this.menuService.getSelectedOptions(this._displayOptionGroups);
    const orderItem = this.menuService.toOrderItem(
      this.displayProduct,
      selectedOptions,
      this.productquant,
      this.productID.toString(),
      this.productDetails.get('recipient').value,
      this.productDetails.get('specInstr').value
    );
    this.store
      .dispatch(new UpdateOrderItem(orderItem, this.productquant))
      .toPromise()
      .then(() => {
        this.toast.success('Item updated');
        this.closeModal.emit();
        this.productLoading = false;
      })
      .catch(error => {
        this.errorMessage = this.errorService.productError(error);
        this.toast.danger(this.errorMessage);
        this.canAddToOrder = true;
        this.productLoading = false;
      });
  }
}
