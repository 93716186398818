import {Component, OnDestroy, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Order } from 'src/interfaces/order.interface';
import { User } from 'src/interfaces/user.interface';
import {Observable, Subscription} from 'rxjs';
import { DirectusService } from 'src/vendors/directus/directus.service';
import { Router } from '@angular/router';
import {CustomPage} from '../../../../../vendors/directus/interfaces/custom-page.interface';
import {Select, Store} from '@ngxs/store';
import {SetRouteBack, UpdateTitle} from '../../../../../store/actions/app.actions';
import {filter} from 'rxjs/operators';
import {MetaService} from '../../../../../services/meta.service';
import {HtmlParserService} from '@modules/brochure/services/html-parser.service';
import {DynamicElementsService} from '@modules/brochure/services/dynamic-elements.service';
import {NavbarSettings} from '../../../../../vendors/directus/interfaces/navbar-settings.interface';
import {GlobalStateModel} from '../../../../../store/state.model';
import {MainSettings} from '../../../../../vendors/directus/interfaces/main-settings.interface';
import {Capacitor} from '@capacitor/core';
import {SentryService} from '@common/services';

@Component({
  selector: 'app-custom-page',
  template: '',
})
export class CustomPageComponent implements OnInit, OnDestroy {

  @Select(state => state.app.customPages) customPages$: Observable<CustomPage[]>;
  @Select(state => state.app.navbarSettings) navbarSettings$: Observable<NavbarSettings>;


  private subs: Subscription[] = [];

  displayOrder: Order;
  displayUser: User;
  content: string;
  header_image;
  page: CustomPage;
  cta_text;
  cta_link;
  customPage;
  error = false;

  constructor(
    private sanitizer: DomSanitizer,
    // private navbarService: NavBarService,
    // private pageService: CustomPagePageService,
    private router: Router,
    private directus: DirectusService,
    private store: Store,
    private meta: MetaService,
    private htmlParser: HtmlParserService,
    private dynamicElements: DynamicElementsService,
    private sentry: SentryService
  ) {}

  ngOnInit() {
    this.store.dispatch(new UpdateTitle('Loading...'));
    this.store.dispatch(new SetRouteBack(''));
    this.subs.push(this.customPages$.pipe(filter(c => c !== null)).subscribe(pages => {
      this.page = pages.filter(p => {
        if (!p.slug) { return false; }
        const regex = /[^\w\s]/gi;
        return (
          encodeURIComponent(p.slug.replace(regex, '')).replace(
            regex,
            ''
          ) === location.pathname.replace(regex, '')
        );
      })[0];
      if (!this.page) {
        this.error = true;
        this.content = '';
      } else {
        this.content = this.htmlParser.getBody(this.page.content);
        setTimeout(() => {
          this.dynamicElements.startMonitoring();
        }, 500);
        this.store.dispatch(new UpdateTitle(this.page.title));
        this.sentry.setTransactionName(this.page.seo_title);
        this.meta.manualUpdate({
          title: this.page.seo_title,
          description: this.page.seo_description,
          keywords: this.page.seo_keywords
        });
        const mainSettings: MainSettings = this.store.selectSnapshot((state: GlobalStateModel) => state.app.mainSettings);
        if (mainSettings && mainSettings.mobile_app_layout === 'improved' && Capacitor.getPlatform() !== 'web') {
          this.store.dispatch(new SetRouteBack('/more'));
        } else {
          this.store.dispatch(new SetRouteBack(''));
        }
        setTimeout(() => {
          (window as any).prerenderReady = true;
        }, 1000);
        this.header_image = this.page.header_image;
        this.cta_text = this.page.cta_text;
        this.cta_link = this.page.cta_link;
      }
    }));

    this.customPage = this.directus.getCustomPages();
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  backButton(nav) {
    // // this.store.dispatch(new RemoveNavStack());
    // this.navCtrl.navigateBack(nav[nav.length - 1]);
    setTimeout(() => {
      // this.store.dispatch(new RemoveNavStack());
    }, 1);
  }

  buttonRoute() {
    if (this.cta_link) {
      if (this.cta_link.includes('http')) {
        // Will not open in Flutter App without new tab
        if (window) {
          window.open(this.cta_link, '_blank', 'noopener=yes');
        } else {
          document.location.href = this.cta_link;
        }
      } else {
        this.router.navigate([this.cta_link]);
      }
    }
  }
}
