<ng-container
  *ngIf="
    displayMenu &&
    displayMenu.categories &&
    displayMenu.categories.length > 0 &&
    displayTheme
  ">
  <div
    class="w-100 container-lg px-0 px-lg-3 mx-0 mx-lg-auto"
    style="padding-top: 64px; padding-bottom: 64px"
    [@fadeInOut]>
    <div class="w-100 mb-3" [class.px-3]="mobile.isMobile">
      <div
        class="text-start fw-bold header-font"
        style="font-size: 24px">
        Our Menu
      </div>
      <ng-container *ngIf="mainSettings">
        <div class="text-start">{{ mainSettings.slogan }}</div>
      </ng-container>
    </div>

    <ng-container *ngIf="!isMobile; else mobileCarousel">
      <div
        class="w-100"
        style="
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          grid-gap: 16px;
        ">
        <ng-container
          *ngFor="let category of displayMenu.categories; index as index">
          <ng-container *ngIf="index < 8">
            <div
              style="
                display: grid;
                grid-template-rows: max-content auto;
                border-radius: var(--cms-card-radius);
                cursor: pointer;
              "
              class="de-border box-shadow"
              [@fadeInOut]
              (click)="goToCategory(category)">
              <ng-container
                *ngIf="
                  category.thumbnailImageURL || category.standardImageURL;
                  else noImage
                ">
                <img
                  class="img-border-inherit"
                  [alt]="category.name"
                  [src]="
                    category.thumbnailImageURL
                      ? category.thumbnailImageURL
                      : category.standardImageURL
                  " />
              </ng-container>
              <ng-template #noImage>
                <div
                  class="w-100 h-auto img-border-inherit"
                  style="position: relative">
                  <img
                    src="../../../../assets/img/600x600.png"
                    class="img-border-inherit"
                    [alt]="category.name"
                    [style.background-color]="
                      displayTheme?.card_background_color
                    " />
                  <div
                    class="w-100 text-center"
                    style="
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    "
                    [style.color]="displayTheme.card_text_color">
                    {{ category.name | uppercase }}
                  </div>
                </div>
              </ng-template>
              <div
                style="
                  display: grid;
                  padding: 8px;
                  grid-template-rows: auto;
                  background-color: var(--ion-color-light);
                  border-bottom-left-radius: inherit;
                  border-bottom-right-radius: inherit;
                ">
                <div class="header-font fw-bold">
                  {{ category.name }}
                </div>
                <!--                  <div>{{category.description | truncate: 30}}</div>-->
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #mobileCarousel>
      <ng-container *ngIf="!menuLoading">
        <!--          <carousel [cellWidth]="300" [margin]="16" [height]="168">-->
        <div
          class="px-3 pb-2 me-3"
          style="display: grid; grid-gap: 16px; overflow-x: scroll; width: 100vw;"
          [style.grid-template-columns]="
            'repeat(' + displayMenu.categories.length + ', 250px)'
          ">
          <ng-container
            *ngFor="let category of displayMenu.categories; index as index">
            <div style="width: 250px" [@fadeInOut]>
              <div
                style="
                  display: grid;
                  grid-template-rows: max-content auto;
                  border-radius: var(--cms-card-radius);
                "
                class="de-border box-shadow"
                (click)="goToCategory(category)">
                <ng-container
                  *ngIf="
                    category.thumbnailImageURL || category.standardImageURL;
                    else noImage
                  ">
                  <img
                    class="img-border-inherit"
                    [alt]="category.name"
                    [src]="
                      category.thumbnailImageURL
                        ? category.thumbnailImageURL
                        : category.standardImageURL
                    " />
                </ng-container>
                <ng-template #noImage>
                  <div
                    class="w-100 h-auto img-border-inherit"
                    style="position: relative">
                    <img
                      src="../../../../assets/img/600x600.png"
                      class="img-border-inherit"
                      [alt]="category.name"
                      [style.background-color]="
                        displayTheme.card_background_color
                      " />
                    <div
                      class="w-100 text-center"
                      style="
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                      "
                      [style.color]="displayTheme.card_text_color">
                      {{ category.name | uppercase }}
                    </div>
                  </div>
                </ng-template>
                <div
                  style="
                    display: grid;
                    padding: 8px;
                    grid-template-rows: auto;
                    background-color: var(--ion-color-light);
                    border-bottom-left-radius: inherit;
                    border-bottom-right-radius: inherit;
                  ">
                  <div class="header-font fw-bold">
                    {{ category.name }}
                  </div>
                  <!--                    <div>{{category.description | truncate: 30}}</div>-->
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <!--          </carousel>-->
      </ng-container>
    </ng-template>
  </div>
</ng-container>
