import { Component, Input } from '@angular/core';
import { NutritionInfo } from 'src/interfaces/nutrition-info.interface';

@Component({
  selector: 'app-desktop-nutrition-card',
  templateUrl: './nutrition-card.component.html',
  styleUrls: ['./nutrition-card.component.scss']
})
export class DesktopNutritionCardComponent {

  @Input() nutritionInfo: NutritionInfo;

}
