<ng-container
        *ngIf="promotionalImage || promotionalImageMobile">
  <div class="promotion-area w-100">
    <div class="promotion-shell">
      <ng-container *ngIf="promotionalImage">
        <div [innerHTML]="promotionalText" class="d-none d-md-flex promotion-item"></div>
        <div class="d-none d-md-flex">
          <img class="promotion-image" [src]="promotionalImage" alt="promotional image">
        </div>
      </ng-container>
      <ng-container *ngIf="promotionalImageMobile">
        <div [innerHTML]="promotionalText" class="d-flex d-md-none promotion-item"></div>
        <div class="d-flex d-md-none">
          <img class="promotion-image" [src]="promotionalImageMobile" alt="mobile promotional image">
        </div>
      </ng-container>
      <ng-container *ngIf="promotionalImage && !promotionalImageMobile">
        <div [innerHTML]="promotionalText" class="d-flex d-md-none promotion-item"></div>
        <div class="d-flex d-md-none">
          <img class="promotion-image" [src]="promotionalImage" alt="promotional image">
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
