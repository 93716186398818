import { Injectable } from '@angular/core';
import { CardTerminalProvider } from '../../providers/card-terminal-provider.interface';
import { Observable, switchMap, map, of, throwError, interval, from, timer } from 'rxjs';
import { StripeApiService } from './stripe-api.service';
import { CardTerminalCartDisplayInfo } from '../../interfaces/card-terminal-cart-display-info.interface';
import { DiscoverResult, ErrorResponse, ISdkManagedPaymentIntent, ISetReaderDisplayRequest, Reader } from '@stripe/terminal-js';
import { KioskConfig } from '../directus/interfaces/kiosk-config.interface';
import { ToastService } from '../../services/toast.service';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class StripeProviderService implements CardTerminalProvider {
  terminalID: string;
  private currentPaymentIntentSecret: string;
  private currentPaymentAmount: number;
  private transactionCancelled = false;

  constructor(
    private api: StripeApiService,
    private toast: ToastService
  ) {
    this.api.initializeSDK().subscribe();
  }

  connectToReader(): Observable<any> {
    if (!this.api.assignedReader) {
      if (localStorage.getItem('kioskConfig')) {
        return this.setupKioskConfig(JSON.parse(localStorage.getItem('kioskConfig')), false).pipe(
          switchMap(() => {
            return timer(1000).pipe(switchMap(() => this.connectToReader()));
          })
        );
      } else {
        return throwError(new Error('No reader found'));
      }
    } else {
      return this.api.connectReader(this.api.assignedReader).pipe(switchMap(() => this.api.disconnectReader()));
    }
  }

  processSale(amount: number): Observable<{ id: string; description: string }> {
    return this.resetSDK().pipe(
      switchMap(() => {
        if (!this.api.assignedReader) {
          if (localStorage.getItem('kioskConfig')) {
            return this.setupKioskConfig(JSON.parse(localStorage.getItem('kioskConfig')), false).pipe(() => {
              // Wait 1 second before recursively calling processSale
              return timer(500).pipe(switchMap(() => this.processSale(amount)));
            });
          } else {
            return throwError(new Error('No reader found'));
          }
        }
        return from(this.api.connectReader(this.api.assignedReader)).pipe(
          switchMap(() => {
            // Check if the current payment intent is valid and the amount matches
            if (this.currentPaymentIntentSecret && this.currentPaymentAmount === amount) {
              return this.collectAndProcessPayment(this.currentPaymentIntentSecret);
            }

            // Clear the current payment intent secret if the amount has changed
            this.currentPaymentIntentSecret = null;
            this.currentPaymentAmount = amount;
            return this.api.getPaymentStatus().pipe(
              switchMap(status => {
                if (status === 'waiting_for_input') {
                  return this.api.cancelCollectPaymentMethod().pipe(
                    switchMap(() => {
                      return this.api.createPaymentIntent(amount).pipe(
                        switchMap(res => {
                          this.currentPaymentIntentSecret = res.secret; // Save the new payment intent secret
                          return this.collectAndProcessPayment(res.secret).pipe(
                            tap(async () => await this.api.disconnectReader().toPromise())
                          );
                        })
                      );
                    }),
                    catchError((err: ErrorResponse) => {
                      return this.api.createPaymentIntent(amount).pipe(
                        switchMap(res => {
                          this.currentPaymentIntentSecret = res.secret; // Save the new payment intent secret
                          return this.collectAndProcessPayment(res.secret).pipe(
                            tap(async () => await this.api.disconnectReader().toPromise())
                          );
                        })
                      );
                    })
                  );
                } else {
                  return this.api.createPaymentIntent(amount).pipe(
                    switchMap(res => {
                      this.currentPaymentIntentSecret = res.secret; // Save the new payment intent secret
                      return this.collectAndProcessPayment(res.secret).pipe(tap(async () => await this.api.disconnectReader().toPromise()));
                    })
                  );
                }
              })
            );
          })
        );
      })
    );
  }

  private collectAndProcessPayment(secret: string): Observable<{ id: string; description: string }> {
    // console.log('Collecting and processing payment');
    return this.api.collectPaymentMethod(secret).pipe(
      switchMap((collectRes: { paymentIntent: ISdkManagedPaymentIntent }) => {
        // console.log('Payment method collected');
        if (this.transactionCancelled) {
          // console.log('Transaction cancelled');
          // Reset the cancellation flag
          this.transactionCancelled = false;
          return throwError(() => new Error('Transaction cancelled'));
        }
        // console.log('Processing payment');
        return this.api.processPayment(collectRes.paymentIntent).pipe(
          switchMap((payRes: { paymentIntent: ISdkManagedPaymentIntent }) => {
            // console.log('Payment processed');
            return this.api.capturePaymentIntent(payRes.paymentIntent.id).pipe(
              map(captureRes => {
                this.currentPaymentIntentSecret = null; // Clear the payment intent secret after successful capture
                return {
                  id: captureRes.id,
                  description: captureRes.description,
                };
              })
            );
          })
        );
      }),
      catchError((err: ErrorResponse) => {
        if (this.transactionCancelled) {
          // Reset the cancellation flag
          this.transactionCancelled = false;
          return throwError(() => ({ message: 'Transaction canceled', code: 'Select new payment method' }));
        }
        if (err.error?.message?.includes('collectPaymentMethod is already in progress')) {
          return this.cancelTransaction().pipe(
            switchMap(() => {
              return this.collectAndProcessPayment(secret);
            })
          );
        } else {
          return throwError(err);
        }
      })
    );
  }

  cancelTransaction(): Observable<any> {
    this.transactionCancelled = true;

    return this.resetSDK().pipe(
      switchMap(() => {
        if (this.api.assignedReader) {
          return this.api.connectReader(this.api.assignedReader);
        } else {
          of();
        }
      }),
      switchMap(() => this.api.getConnectionStatus()),
      switchMap(status => {
        if (status !== 'connected') {
          // If not connected, reset the pin pad
          return from(this.resetPinPad()).pipe(
            switchMap(() => this.clearTerminal()),
            tap(async () => await this.api.disconnectReader().toPromise())
          );
        } else {
          // If connected, cancel the payment method collection
          return this.api.cancelCollectPaymentMethod().pipe(
            switchMap(() => this.clearTerminal()),
            tap(async () => await this.api.disconnectReader().toPromise()),
            catchError(err => {
              // Handle cancellation errors if necessary
              return throwError(err);
            })
          );
        }
      }),
      tap(() => {
        // Reset the cancellation flag after successful operation
        this.transactionCancelled = false;
      }),
      catchError(err => {
        // Reset the cancellation flag in case of an error
        this.transactionCancelled = false;
        return throwError(err);
      })
    );
  }

  /**
   * Clears the terminal screen.
   * Implement this method based on your terminal's API or SDK.
   */
  private clearTerminal(): Observable<void> {
    // Example implementation; replace with actual terminal clearing logic
    return from(this.api.clearReaderDisplay()).pipe(
      map(() => {
        // Terminal cleared successfully
      }),
      catchError(err => {
        // Handle terminal clearing errors if necessary
        return throwError(err);
      })
    );
  }

  refundTransaction(transactionId: string, amount: number): Observable<any> {
    return this.api.refundTransaction(transactionId);
  }

  setupKioskConfig(config: KioskConfig, showMessages = true): Observable<void> {
    localStorage.setItem('kioskConfig', JSON.stringify(config));
    return this.api.discoverReaders().pipe(
      map((res: DiscoverResult) => {
        this.api.assignedReader = res.discoveredReaders.find(reader => reader.id === config.device_address);
        if (this.api.assignedReader) {
          this.terminalID = this.api.assignedReader.id;
          if (showMessages) {
            this.toast.success('Configuration loaded. Reader ready for use');
          }
        } else {
          this.toast.danger('No reader found for the configured device address');
        }
        return;
      })
    );
  }

  displayCart(displayInfo: CardTerminalCartDisplayInfo): Observable<void> {
    const display: ISetReaderDisplayRequest = {
      type: 'cart',
      cart: {
        line_items: displayInfo.items,
        tax: displayInfo.tax,
        total: displayInfo.total,
        currency: 'usd',
      },
    };
    return this.resetSDK().pipe(
      switchMap(() => {
        if (!this.api.assignedReader) {
          if (localStorage.getItem('kioskConfig')) {
            return this.setupKioskConfig(JSON.parse(localStorage.getItem('kioskConfig')), false).pipe(() => {
              // Wait 1 second before recursively calling processSale
              return timer(500).pipe(switchMap(() => this.displayCart(displayInfo)));
            });
          } else {
            return throwError(new Error('No reader found'));
          }
        }
        return from(this.api.connectReader(this.api.assignedReader)).pipe(
          switchMap(() => {
            return this.api.setReaderDisplay(display).pipe(
              map(() => {
                return;
              }),
              tap(() => this.api.disconnectReader())
            );
          })
        );
      })
    );
  }

  resetPinPad(): Observable<void> {
    return this.api.getPaymentStatus().pipe(
      switchMap(status => {
        if (status === 'waiting_for_input') {
          return this.cancelTransaction();
        }
        return this.api.clearReaderDisplay().pipe(
          map(() => {
            return;
          })
        );
      })
    );
  }

  resetSDK(): Observable<void> {
    return this.api.initializeSDK().pipe(switchMap(() => timer(500).pipe(map(() => null))));
  }
}
