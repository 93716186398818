import { NavbarKioskComponent } from './navbar-kiosk/navbar-kiosk.component';
import { FooterComponent } from './footer/footer.component';
import { AdditionalDesktopLinksComponent } from './additional-desktop-links/additional-desktop-links.component';
import { TabBarComponent } from './tab-bar/tab-bar.component';

export const components = [NavbarKioskComponent, FooterComponent, AdditionalDesktopLinksComponent, TabBarComponent];

export * from './navbar-kiosk/navbar-kiosk.component';
export * from './footer/footer.component';
export * from './additional-desktop-links/additional-desktop-links.component';
export * from './tab-bar/tab-bar.component';
