  <div class="w-100">
    <ng-container *ngIf="displayPreviousOrders && displayPreviousOrders.length > 0" >
      <div class="w-100 wrapper" style="">
        <div class="w-100 container" style="padding: 64px 0" >
          <div class="w-100" [class.px-3]="isMobile">
            <div class="text-start fw-bold header-font" style="font-size: 24px">Order Again</div>
            <div class="text-start">Reorder one of your recent orders</div>
          </div>
          <div style="display: grid; grid-gap: 16px">
            <div class="w-100 my-3" [class.reorder-container-mobile]="isMobile" [class.reorder-container-desktop]="!isMobile">

              <ng-container *ngIf="pastOrders$ | async as pastOrders">
                <ng-container *ngIf="pastOrders.length > 0">
                  <!-- (clickedAddToOrder)="addToOrder(order)" -->
                  <app-reorder-card
                          (cancelOrder)="cancelOrderClicked(pastOrders[0])"
                          (clickedReorder)="reorder(pastOrders[0])"
                          (clickedViewConfirmation)="viewConfirmation(pastOrders[0])"
                          (editOrder)="editOrderClicked(pastOrders[0])"
                          [isLoading]="isLoading"
                          [order]="pastOrders[0] || null"
                          [class.d-none]="!pastOrders[0] && isMobile"
                  ></app-reorder-card>
                  <app-reorder-card
                          (cancelOrder)="cancelOrderClicked(pastOrders[1])"
                          (clickedReorder)="reorder(pastOrders[1])"
                          (clickedViewConfirmation)="viewConfirmation(pastOrders[1])"
                          (editOrder)="editOrderClicked(pastOrders[1])"
                          [isLoading]="isLoading"
                          [order]="pastOrders[1] || null"
                          [class.d-none]="!pastOrders[1] && isMobile"
                  ></app-reorder-card>
                  <app-reorder-card
                          (cancelOrder)="cancelOrderClicked(pastOrders[2])"
                          (clickedReorder)="reorder(pastOrders[2])"
                          (clickedViewConfirmation)="viewConfirmation(pastOrders[2])"
                          (editOrder)="editOrderClicked(pastOrders[2])"
                          [isLoading]="isLoading"
                          [order]="pastOrders[2] || null"
                          [class.d-none]="!pastOrders[2] && isMobile"
                  ></app-reorder-card>
                  <app-reorder-card
                          (cancelOrder)="cancelOrderClicked(pastOrders[3])"
                          (clickedReorder)="reorder(pastOrders[3])"
                          (clickedViewConfirmation)="viewConfirmation(pastOrders[3])"
                          (editOrder)="editOrderClicked(pastOrders[3])"
                          [isLoading]="isLoading"
                          [order]="pastOrders[3] || null"
                          [class.d-none]="!pastOrders[3] && isMobile"
                  ></app-reorder-card>
                  <app-reorder-card
                          (cancelOrder)="cancelOrderClicked(pastOrders[4])"
                          (clickedReorder)="reorder(pastOrders[4])"
                          (clickedViewConfirmation)="viewConfirmation(pastOrders[4])"
                          (editOrder)="editOrderClicked(pastOrders[4])"
                          [isLoading]="isLoading"
                          [order]="pastOrders[4] || null"
                          [class.d-none]="!pastOrders[4] && isMobile"
                  ></app-reorder-card>
                </ng-container>
              </ng-container>
            </div>
<!--            <button class="mx-auto de-secondary-btn mt-2" [class.w-100]="isMobile" style="min-width: 200px"-->
<!--                    [routerLink]="previousOrdersRoute">View Past Orders-->
<!--            </button>-->

          </div>
        </div>
      </div>
    </ng-container>
  </div>
