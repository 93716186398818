<ng-container *ngIf="(mainSettings$ | async) as settings">
    <ng-container *ngIf="settings.show_main_loader">
        <ng-container *ngIf="loading && isFinishedDownloading">
            <ng-container *ngTemplateOutlet="loader"></ng-container>
        </ng-container>

        <ng-container *ngIf="loading$ | async">
            <ng-container *ngTemplateOutlet="loader"></ng-container>
        </ng-container>

        <ng-template #loader>
            <ng-container *ngIf="capacitor.getPlatform() === 'web'">
                <div class="app-loader-container">
                    <ng-container *ngIf="branding$ | async as branding else defaultLoader">
                        <div class="w-100 h-100" style="max-height: 100px; max-width: 100px;"
                             *ngIf="branding.favicon?.data?.full_url else defaultLoader">
                            <img src="{{branding.favicon.data.full_url}}" class="w-100 h-100"
                                 style="object-fit: contain"
                                 alt="logo"
                                 [@bounce]="animationState" (@bounce.done)="animationDone()">
                        </div>
                    </ng-container>
                    <ng-template #defaultLoader>
                        <div class="spinner">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </ng-template>
                </div>
            </ng-container>
        </ng-template>
    </ng-container>
</ng-container>

<app-toast aria-live="polite" id="appToast" aria-atomic="true"></app-toast>

<!--<ng-container *ngIf="deviceDetection.isSupportedBrowser() | async else unsupportedBrowserPage">-->
<ng-container *ngIf="onlineStatusService.networkStatus; else offlinePage">
    <ng-container *ngIf="(app$ | async) as app">
        <ng-container *ngIf="vendorSetup$ | async as vendorSetup">
            <ng-container
                    *ngIf="vendorSetup.menu_provider !== 'none' || vendorSetup.order_provider !== 'none'; else constructionPage">
                <ng-container *ngIf="modeService.mode !== 'kiosk'">
                    <ng-container
                            *ngIf="!(app.mainSettings.mobile_app_layout === 'improved' && capacitor.getPlatform() !== 'web')">
                        <ng-container *ngIf="mobile.isMobile; else desktopMenu">
                            <ion-menu *ngIf="modeService.mode !== tableside" #appMenu side="start" type="overlay"
                                      class="nav-scroll"
                                      content-id="main-content" style="z-index: 20000; height: 100vh;">
                                <ion-content class="mobile-nav-scroll">
                                    <div style="height: 100%; display:grid; grid-template-rows: auto auto 1fr; overflow-y: auto; background-color: var(--nav-background-color)">
                                        <div>
                                            <div class="container-fluid p-0 m-0"
                                                 style="padding-top: env(safe-area-inset-top) !important;">
                                                <div class="row p-3 m-0 d-flex align-items-top justify-content-left">
                                                    <div class="col-11 p-0 d-flex align-items-center justify-content-left">
                                                        <ng-container
                                                                *ngIf="order$ | async as order else mainLogoMobile">
                                                            <ng-container
                                                                    *ngIf="order.location.conceptLogoURL else mainLogoMobile">
                                                                <img [src]="order.location.conceptLogoURL"
                                                                     alt="Company logo"
                                                                     style="max-width: 60%; max-height: 3.5rem; height: auto;">
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-template #mainLogoMobile>
                                                            <img [src]="(app$ | async).branding?.main_logo.data.full_url"
                                                                 alt="Company logo"
                                                                 style="max-width: 60%; max-height: 3.5rem; height: auto;">
                                                        </ng-template>
                                                    </div>

                                                    <div class="col-1 p-0 d-flex align-items-top justify-content-end">
                                                        <a type="button" tabindex="0" (click)="this.menu.close()"
                                                           (keydown.enter)="this.menu.close()">
                                                            <i class="bi bi-x-circle"
                                                               style="font-size:32px; cursor:pointer;"
                                                               [ngStyle]="{'color':(app$ | async).navbarSettings?.close_button_color}"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="m-0 de-border"></div>
                                            <ng-container *ngIf="vendorSetup.user_provider !== 'none'">
                                                <ng-container
                                                        *ngIf="(user$ | async)?.isGuest else mobileLoggedInTitle">
                                                    <div class="container-fluid p-0 m-0">
                                                        <div class="row m-0 px-3 pb-2 pt-3 d-flex align-items-center">
                                                            <h5 class="col-12 p-0 m-0 fw-bold"
                                                                style="color: var(--nav-text-color);">
                                                                Welcome,</h5>
                                                            <div class="col-12 p-0 pt-2 m-0"
                                                                 style="font-size:16px; color: var(--nav-text-color);">
                                                                Please log in
                                                                to start earning
                                                                rewards
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #mobileLoggedInTitle>
                                                <div class="container-fluid p-0 m-0">
                                                    <div class="row m-0 px-3 pb-2 pt-3 d-flex align-items-center">
                                                        <h5 class="col-12 p-0 m-0 fw-bold"
                                                            style="color: var(--nav-text-color);">
                                                            {{this.is3rdWrapper ?
                                                            'Welcome' :
                                                            'Hello, ' + (user$ | async)?.firstName}}
                                                        </h5>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-container *ngIf="vendorSetup.user_provider !== 'none'">
                                                <ng-container *ngIf="!(thirdPartyWrapped$ | async)">
                                                    <ng-container
                                                            *ngIf="!(currentGroupOrder && currentGroupOrder?.role === 'member')">
                                                        <ng-container *ngIf="(user$ | async)?.isGuest">
                                                            <ng-container *ngIf="!this.is3rdWrapper">
                                                                <div class="container-fluid p-0 m-0">
                                                                    <a type="button" tabindex="0"
                                                                       class="row m-0 px-3 py-2 d-flex align-items-center text-decoration-none"
                                                                       style="cursor:pointer"
                                                                       (click)="navigateTo('/user/login')"
                                                                       (keydown.enter)="navigateTo('/user/login')">
                                                                        <div
                                                                                class="p-0 pe-2 d-flex align-items-center justify-content-start"
                                                                                style="width: inherit;">
                                                                            <i style="font-size:24px; color: var(--nav-icon-color);"
                                                                               class="bi bi-person-circle"
                                                                            ></i>
                                                                        </div>
                                                                        <div class="p-0 m-0 h6"
                                                                             style="width: inherit; color: var(--nav-emphasized-text-color);">
                                                                            Sign In
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <div class="container-fluid p-0 m-0">
                                                                    <a type="button" tabindex="0"
                                                                       class="row m-0 px-3 py-2 d-flex align-items-center text-decoration-none"
                                                                       style="cursor:pointer"
                                                                       (click)="navigateTo('/user/create-account')"
                                                                       (keydown.enter)="navigateTo('/user/create-account')">
                                                                        <div
                                                                                class="p-0 pe-2 d-flex align-items-center justify-content-start"
                                                                                style="width: inherit;">
                                                                            <i style="font-size:24px; color: var(--nav-icon-color);"
                                                                               class="bi bi-person-plus"
                                                                            ></i>
                                                                        </div>
                                                                        <div class="p-0 m-0 h6"
                                                                             style="width: inherit; color: var(--nav-emphasized-text-color);">
                                                                            Join Now
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="!(thirdPartyWrapped$ | async)">
                                                <ng-container
                                                        *ngIf="!(currentGroupOrder && currentGroupOrder?.role === 'member')">
                                                    <div class="container-fluid p-0 m-0">
                                                        <a type="button" tabindex="0"
                                                           class="row m-0 px-3 py-2 d-flex align-items-center text-decoration-none"
                                                           style="cursor:pointer"
                                                           (click)="navigateTo('/')"
                                                           (keydown.enter)="navigateTo('/')">
                                                            <div class="p-0 pe-2 d-flex align-items-center justify-content-start"
                                                                 style="width: inherit;">
                                                                <i style="font-size:24px; color: var(--nav-icon-color);"
                                                                   class="bi bi-house-door"></i>
                                                            </div>
                                                            <div class="p-0 m-0 h6"
                                                                 style="width: inherit; color: var(--nav-text-color);"
                                                            >
                                                                Home
                                                            </div>
                                                        </a>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="vendorSetup.order_provider !== 'none'">
                                                <div class="container-fluid p-0 m-0">
                                                    <a type="button" tabindex="0"
                                                       class="row m-0 px-3 py-2 d-flex align-items-center text-decoration-none"
                                                       style="cursor:pointer"
                                                       (click)="navigateTo(this.hamburgerMenuLink)"
                                                       (keydown.enter)="navigateTo(this.hamburgerMenuLink)">
                                                        <div class="p-0 pe-2 d-flex align-items-center justify-content-start"
                                                             style="width: inherit; height:36px;">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon"
                                                                 viewBox="0 0 512 512"
                                                                 style="height:1.5rem; width:1.5rem; color: var(--nav-icon-color);"
                                                            >
                                                                <title>Restaurant</title>
                                                                <path
                                                                        d="M57.49 47.74l368.43 368.43a37.28 37.28 0 010 52.72h0a37.29 37.29 0 01-52.72 0l-90-91.55a32 32 0 01-9.2-22.43v-5.53a32 32 0 00-9.52-22.78l-11.62-10.73a32 32 0 00-29.8-7.44h0a48.53 48.53 0 01-46.56-12.63l-85.43-85.44C40.39 159.68 21.74 83.15 57.49 47.74z"
                                                                        fill="none" stroke="currentColor"
                                                                        stroke-linejoin="round" stroke-width="32"/>
                                                                <path
                                                                        d="M400 32l-77.25 77.25A64 64 0 00304 154.51v14.86a16 16 0 01-4.69 11.32L288 192M320 224l11.31-11.31a16 16 0 0111.32-4.69h14.86a64 64 0 0045.26-18.75L480 112M440 72l-80 80M200 368l-99.72 100.28a40 40 0 01-56.56 0h0a40 40 0 010-56.56L128 328"
                                                                        fill="none" stroke="currentColor"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-width="32"/>
                                                            </svg>
                                                        </div>
                                                        <div class="p-0 m-0 h6"
                                                             style="width: inherit; color: var(--nav-text-color);">
                                                            Menu
                                                        </div>
                                                    </a>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="!(thirdPartyWrapped$ | async)">
                                                <ng-container
                                                        *ngIf="!(currentGroupOrder && currentGroupOrder?.role === 'member')">
                                                    <ng-container *ngIf="vendorSetup.user_provider !== 'none'">
                                                        <div class="container-fluid p-0 m-0">
                                                            <a type="button" tabindex="0"
                                                               class="ham-menu-profile-link row m-0 px-3 py-2 d-flex align-items-center text-decoration-none"
                                                               style="cursor:pointer"
                                                               (click)="navigateTo('/profile')"
                                                               (keydown.enter)="navigateTo('/profile')">
                                                                <div class="p-0 pe-2 d-flex align-items-center justify-content-start"
                                                                     style="width: inherit;">
                                                                    <i style="font-size:24px; color: var(--nav-icon-color);"
                                                                       class="bi bi-person"></i>
                                                                </div>
                                                                <ng-container
                                                                        *ngIf="textFields$ | async as textField">
                                                                    <div class="p-0 m-0 h6"
                                                                         style="width: inherit; color: var(--nav-text-color);">
                                                                        {{textField.user_profile_menu_link_title}}
                                                                    </div>
                                                                </ng-container>
                                                            </a>
                                                        </div>
                                                    </ng-container>

                                                    <div *ngIf="app?.vendorSetup?.loyalty_provider != 'none' && !(user$|async)?.isGuest"
                                                         class="container-fluid p-0 m-0">
                                                        <a type="button" tabindex="0"
                                                           class="row m-0 px-3 py-2 d-flex align-items-center text-decoration-none"
                                                           style="cursor:pointer"
                                                           (click)="navigateTo('/profile/rewards')"
                                                           (keydown.enter)="navigateTo('/profile/rewards')">
                                                            <div class="p-0 pe-2 d-flex align-items-center justify-content-start"
                                                                 style="width: inherit;">
                                                                <i style="font-size:24px; color: var(--nav-icon-color);"
                                                                   class="bi bi-award"></i>
                                                            </div>
                                                            <div class="p-0 m-0 h6"
                                                                 style="width: inherit; color: var(--nav-text-color);">
                                                                Rewards &
                                                                Offers
                                                            </div>
                                                        </a>
                                                        <ng-container
                                                                *ngIf="inboxMessages$ | async as inboxMessages">
                                                            <a type="button" tabindex="0"
                                                               class="row m-0 px-3 py-2 d-flex align-items-center text-decoration-none"
                                                               style="cursor:pointer"
                                                               (click)="navigateTo('/profile/inbox')"
                                                               (keydown.enter)="navigateTo('/profile/inbox')">
                                                                <div class="p-0 pe-2 d-flex align-items-center justify-content-start position-relative"
                                                                     style="width: inherit;">
                                                                    <ng-container
                                                                            *ngIf="(inboxMessages | unreadMessage) || (notificationService.unreadNotificationsCount$ | async)">
                                                                        <div class="position-absolute"
                                                                             style="top:0; right: 0; border-radius: 1rem; border: 1px solid var(--ion-color-primary); height: 8px; width: 8px; background-color: var(--ion-color-primary);"><span
                                                                                class="visually-hidden">You have unread messages</span>
                                                                        </div>
                                                                    </ng-container>
                                                                    <i style="font-size:24px; color: var(--nav-icon-color);"
                                                                       class="bi bi-envelope"></i>
                                                                </div>
                                                                <div class="p-0 m-0 h6"
                                                                     style="width: inherit; color: var(--nav-text-color);">
                                                                    Inbox
                                                                </div>
                                                            </a>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="vendorSetup.location_provider !== 'none'">
                                                <ng-container
                                                        *ngIf="!(currentGroupOrder && currentGroupOrder?.role === 'member')">
                                                    <div class="container-fluid p-0 m-0">
                                                        <a type="button" tabindex="0"
                                                           class="ham-menu-loc-link row m-0 px-3 py-2 d-flex align-items-center text-decoration-none"
                                                           style="cursor:pointer"
                                                           (click)="navigateTo('/locations')"
                                                           (keydown.enter)="navigateTo('/locations')">
                                                            <div class="p-0 pe-2 d-flex align-items-center justify-content-start"
                                                                 style="width: inherit;">
                                                                <i style="font-size:24px; color: var(--nav-icon-color);"
                                                                   class="bi bi-geo"></i>
                                                            </div>
                                                            <div class="p-0 m-0 h6"
                                                                 style="width: inherit; color: var(--nav-text-color);">
                                                                Locations
                                                            </div>
                                                        </a>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngFor="let page of (customPages$ | async)">
                                                <ng-container *ngIf="user$ | async as user">
                                                    <div class="container-fluid p-0 m-0"
                                                         *ngIf="page.show_on_side_menu && !page.hide_under_more  && (!page.require_login || (page.require_login && !user?.isGuest))">
                                                        <a type="button" tabindex="0"
                                                           class="row m-0 px-3 py-2 d-flex align-items-center text-decoration-none"
                                                           style="cursor:pointer"
                                                           (click)="pageClicked(page)"
                                                           (keydown.enter)="pageClicked(page)">
                                                            <div class="p-0 pe-2 d-flex align-items-center justify-content-start"
                                                                 style="width: inherit;">
                                                                <i style="font-size:24px; color: var(--nav-icon-color);"
                                                                   class="bi"
                                                                   [ngClass]="'bi-' + page.icon"></i>
                                                            </div>
                                                            <div class="p-0 m-0 h6"
                                                                 style="width: inherit; color: var(--nav-text-color);">{{page.title}}</div>
                                                        </a>
                                                    </div>
                                                </ng-container>
                                            </ng-container>

                                            <div class="py-2"></div>

                                            <div class="ps-3 p-0" *ngIf="this.atLeastOneMore">
                                                <hr class="m-0"
                                                    style="border-top: 2px solid var(--de-border-color);"/>
                                            </div>

                                            <div class="py-2" *ngIf="this.atLeastOneMore"></div>

                                            <div class="container-fluid p-0 m-0" *ngIf="this.atLeastOneMore">
                                                <a type="button" tabindex="0"
                                                   class="row m-0 px-3 py-2 d-flex align-items-center text-decoration-none"
                                                   style="cursor:pointer"
                                                   (click)="showingMore = !showingMore"
                                                   (keydown.enter)="showingMore = !showingMore">
                                                    <div class="p-0 pe-2 m-0 h6 fw-bold"
                                                         style="width: inherit; color: var(--nav-text-color);">
                                                        More
                                                    </div>
                                                    <div class="p-0 d-flex align-items-center justify-content-start"
                                                         style="width: inherit;">
                                                        <i style="font-size:16px; color: var(--nav-icon-color)"
                                                           class="bi"
                                                           [ngClass]="showingMore ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
                                                    </div>
                                                </a>
                                            </div>

                                            <ng-container *ngFor="let page of (customPages$ | async)">
                                                <ng-container *ngIf="user$ | async as user">
                                                    <div class="container-fluid p-0 m-0"
                                                         *ngIf="showingMore && page.show_on_side_menu && page.hide_under_more && (!page.require_login || (page.require_login && !user?.isGuest))">
                                                        <a type="button" tabindex="0"
                                                           class="row m-0 px-3 py-2 d-flex align-items-center text-decoration-none"
                                                           style="cursor:pointer"
                                                           (click)="pageClicked(page)"
                                                           (keydown.enter)="pageClicked(page)">
                                                            <div class="p-0 pe-2 d-flex align-items-center justify-content-start"
                                                                 style="width: inherit;">
                                                                <i style="font-size:24px; color: var(--nav-icon-color)"
                                                                   class="bi"
                                                                   [ngClass]="'bi-' + page.icon"></i>
                                                            </div>
                                                            <div class="p-0 m-0 h6"
                                                                 style="width: inherit; color: var(--nav-text-color);">{{page.title}}</div>
                                                        </a>
                                                    </div>
                                                </ng-container>
                                            </ng-container>

                                            <div class="pb-3"></div>
                                        </div>

                                        <div class="flex-container" style="place-items:center">
                                            <ng-container *ngIf="(mainSettings$ | async) as mainSettings">
                                        <span class="ms-3" style="width:40px; height:40px; display: inline-grid; place-items: center;
                                        border: 1px solid var(--de-border-color); border-radius: var(--cms-btn-radius); cursor: pointer;"
                                              tabindex="0"
                                              *ngIf="mainSettings.facebook_link as link"
                                              (click)="linkButtonClicked(link)"
                                              (keydown.enter)="linkButtonClicked(link)">
                                            <i class="bi bi-facebook" style="font-size:24px;"
                                               [ngStyle]="{'color':(app$ | async).navbarSettings?.promotional_button_color}"></i>
                                        </span>
                                                <span class="ms-2" style="width:40px; height:40px; display: inline-grid; place-items: center;
                  border: 1px solid var(--de-border-color); border-radius: var(--cms-btn-radius); cursor: pointer;"
                                                      tabindex="0"
                                                      *ngIf="mainSettings.twitter_link as link"
                                                      (click)="linkButtonClicked(link)"
                                                      (keydown.enter)="linkButtonClicked(link)">
                                            <i class="bi bi-twitter" style="font-size:24px;"
                                               [ngStyle]="{'color':(app$ | async).navbarSettings?.promotional_button_color}"></i>
                                        </span>
                                                <span class="ms-2" style="width:40px; height:40px; display: inline-grid; place-items: center;
                  border: 1px solid var(--de-border-color); border-radius: var(--cms-btn-radius); cursor: pointer;"
                                                      tabindex="0"
                                                      *ngIf="mainSettings.instagram_link as link"
                                                      (click)="linkButtonClicked(link)"
                                                      (keydown.enter)="linkButtonClicked(link)">
                                            <i class="bi bi-instagram" style="font-size:24px;"
                                               [ngStyle]="{'color':(app$ | async).navbarSettings?.promotional_button_color}"></i>
                                        </span>
                                                <span class="ms-2" style="width:40px; height:40px; display: inline-grid; place-items: center;
                  border: 1px solid var(--de-border-color); border-radius: var(--cms-btn-radius); cursor: pointer;"
                                                      tabindex="0"
                                                      *ngIf="mainSettings.youtube_link as link"
                                                      (click)="linkButtonClicked(link)"
                                                      (keydown.enter)="linkButtonClicked(link)">
                                            <i class="bi bi-youtube" style="font-size:24px;"
                                               [ngStyle]="{'color':(app$ | async).navbarSettings?.promotional_button_color}"></i>
                                        </span>
                                            </ng-container>
                                        </div>
                                        <div class="side-menu-footer" style="display:grid;">
                                            <div class="powered-by">
                                                    <span
                                                            [ngStyle]="{'color':(app$ | async).navbarSettings?.text_color}">Version: {{ version }}</span>
                                                <a type="button" tabindex="0"
                                                   [href]="app.mainSettings?.powered_by_link ? app.mainSettings.powered_by_link : 'https://dineengine.com/'"
                                                   [ngStyle]="{'color':(app$ | async).navbarSettings?.text_color}">
                                                    Powered By
                                                    <img
                                                            [src]="app.mainSettings?.powered_by_logo ? app.mainSettings.powered_by_logo.data.full_url : '/assets/icon/favicon.svg'"
                                                            [alt]="app.mainSettings?.powered_by_name ? app.mainSettings.powered_by_name : 'DineEngine'"/>
                                                </a>
                                            </div>
                                            <div class="powered-by">
                                      <span tabindex="0" class="bug-report-link"
                                            (click)="openFeedbackForm()"
                                            (keydown.enter)="openFeedbackForm()"
                                            [ngStyle]="{'color':(app$ | async).navbarSettings?.emphasized_text_color}">Report a Bug</span>
                                            </div>
                                        </div>
                                    </div>
                                </ion-content>
                            </ion-menu>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-template #desktopMenu>
                    <ion-menu #appMenu side="end" type="overlay" content-id="main-content" style="height: 100vh">
                        <ion-content class="desktop-side-menu" id="main-content-end">
                            <div style="height: 100%; display:grid; grid-template-rows: auto auto 1fr; overflow-y: auto; background-color: var(--nav-background-color)">
                                <div>
                                    <div class="container-fluid p-0 m-0"
                                         style="padding-top: env(safe-area-inset-top) !important;">
                                        <div class="row p-3 m-0 d-flex align-items-top justify-content-left">

                                            <div class="col-11 p-0 d-flex align-items-center justify-content-left">
                                                <ng-container *ngIf="order$ | async as order else mainLogoDesktop">
                                                    <ng-container
                                                            *ngIf="order.location.conceptLogoURL else mainLogoDesktop">
                                                        <img [src]="order.location.conceptLogoURL"
                                                             alt="Company logo"
                                                             style="max-width: 60%; max-height: 3.5rem; height: auto;">
                                                    </ng-container>
                                                </ng-container>
                                                <ng-template #mainLogoDesktop>
                                                    <img [src]="(app$ | async).branding?.main_logo.data.full_url"
                                                         alt="Company logo"
                                                         style="max-width: 60%; max-height: 3.5rem; height: auto;">
                                                </ng-template>
                                            </div>

                                            <div class="col-1 p-0 d-flex align-items-top justify-content-end">
                                                <div tabindex="0" class="bi bi-x-circle"
                                                     style="font-size:32px; cursor:pointer;"
                                                     [ngStyle]="{'color':(app$ | async).navbarSettings?.close_button_color}"
                                                     (click)="this.menu.close()"
                                                     (keydown.enter)="this.menu.close()"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="m-0 de-border"></div>
                                    <ng-container *ngIf="vendorSetup.user_provider !== 'none'">
                                        <ng-container *ngIf="(user$ | async)?.isGuest else desktopLoggedInTitle">
                                            <div class="container-fluid p-0 m-0">
                                                <div class="row m-0 px-3 pb-2 pt-3 d-flex align-items-center">
                                                    <h5 class="col-12 p-0 m-0 fw-bold"
                                                        style="color: var(--nav-text-color)">Welcome,</h5>
                                                    <div class="col-12 p-0 pt-2 m-0"
                                                         style="font-size:16px; color: var(--nav-text-color);">
                                                        Please log in to start earning rewards
                                                    </div>
                                                </div>
                                            </div>

                                        </ng-container>
                                        <ng-template #desktopLoggedInTitle>
                                            <div class="container-fluid p-0 m-0">
                                                <div class="row m-0 px-3 pb-2 pt-3 d-flex align-items-center">
                                                    <h5 class="col-12 p-0 m-0 fw-bold"
                                                        style="color: var(--nav-text-color)">{{this.is3rdWrapper ?
                                                        'Welcome' :
                                                        'Hello, ' + (user$ | async)?.firstName}}
                                                    </h5>
                                                </div>
                                            </div>

                                        </ng-template>
                                    </ng-container>
                                    <ng-container *ngIf="vendorSetup.user_provider !== 'none'">
                                        <ng-container *ngIf="!(thirdPartyWrapped$ | async)">
                                            <ng-container
                                                    *ngIf="!(currentGroupOrder && currentGroupOrder?.role === 'member')">
                                                <ng-container *ngIf="(user$ | async)?.isGuest">
                                                    <ng-container *ngIf="!this.is3rdWrapper">
                                                        <div class="container-fluid p-0 m-0">
                                                            <div tabindex="0"
                                                                 class="row m-0 px-3 py-2 d-flex align-items-center flex-nowrap"
                                                                 style="cursor:pointer"
                                                                 (click)="navigateTo('/user/login')"
                                                                 (keydown.enter)="navigateTo('/user/login')">
                                                                <div class="p-0 pe-2 d-flex align-items-center justify-content-start"
                                                                     style="width: inherit;">
                                                                    <i style="font-size:24px; color: var(--nav-icon-color);"
                                                                       class="bi bi-person-circle"></i>
                                                                </div>
                                                                <div class="p-0 m-0 h6"
                                                                     style="width: inherit; color: var(--nav-emphasized-text-color);">
                                                                    Sign In
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="container-fluid p-0 m-0">
                                                            <div tabindex="0"
                                                                 class="row m-0 px-3 py-2 d-flex align-items-center flex-nowrap"
                                                                 style="cursor:pointer"
                                                                 (click)="navigateTo('/user/create-account')"
                                                                 (keydown.enter)="navigateTo('/user/create-account')">
                                                                <div class="p-0 pe-2 d-flex align-items-center justify-content-start"
                                                                     style="width: inherit;">
                                                                    <i style="font-size:24px;"
                                                                       class="bi bi-person-plus"
                                                                       [ngStyle]="{'color':(app$ | async).navbarSettings?.icon_color}"></i>
                                                                </div>
                                                                <div class="p-0 m-0 h6"
                                                                     style="width: inherit; color: var(--nav-emphasized-text-color);">
                                                                    Join Now
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!(thirdPartyWrapped$ | async)">
                                        <ng-container
                                                *ngIf="!(currentGroupOrder && currentGroupOrder?.role === 'member')">
                                            <div class="container-fluid p-0 m-0">
                                                <div tabindex="0"
                                                     class="row m-0 px-3 py-2 d-flex align-items-center flex-nowrap"
                                                     style="cursor:pointer"
                                                     (click)="navigateTo(this.mobile.isMobile && this.noBrochure ? '/welcome' :  '')"
                                                     (keydown.enter)="navigateTo(this.mobile.isMobile && this.noBrochure ? '/welcome' :  '')">
                                                    <div class="p-0 pe-2 d-flex align-items-center justify-content-start"
                                                         style="width: inherit;">
                                                        <i style="font-size:24px; color: var(--nav-icon-color);"
                                                           class="bi bi-house-door"></i>
                                                    </div>
                                                    <div class="p-0 m-0 h6"
                                                         style="width: inherit; color: var(--nav-text-color);">
                                                        Home
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="vendorSetup.order_provider !== 'none'">
                                        <div class="container-fluid p-0 m-0">
                                            <div tabindex="0"
                                                 class="row m-0 px-3 py-2 d-flex align-items-center flex-nowrap"
                                                 style="cursor:pointer"
                                                 (click)="navigateTo(this.hamburgerMenuLink)"
                                                 (keydown.enter)="navigateTo(this.hamburgerMenuLink)">
                                                <div class="p-0 pe-2 d-flex align-items-center justify-content-start"
                                                     style="width: inherit; height:36px;">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon"
                                                         viewBox="0 0 512 512"
                                                         style="height:1.5rem; width:1.5rem; color: var(--nav-icon-color);">
                                                        <title>Restaurant</title>
                                                        <path
                                                                d="M57.49 47.74l368.43 368.43a37.28 37.28 0 010 52.72h0a37.29 37.29 0 01-52.72 0l-90-91.55a32 32 0 01-9.2-22.43v-5.53a32 32 0 00-9.52-22.78l-11.62-10.73a32 32 0 00-29.8-7.44h0a48.53 48.53 0 01-46.56-12.63l-85.43-85.44C40.39 159.68 21.74 83.15 57.49 47.74z"
                                                                fill="none" stroke="currentColor"
                                                                stroke-linejoin="round"
                                                                stroke-width="32"/>
                                                        <path
                                                                d="M400 32l-77.25 77.25A64 64 0 00304 154.51v14.86a16 16 0 01-4.69 11.32L288 192M320 224l11.31-11.31a16 16 0 0111.32-4.69h14.86a64 64 0 0045.26-18.75L480 112M440 72l-80 80M200 368l-99.72 100.28a40 40 0 01-56.56 0h0a40 40 0 010-56.56L128 328"
                                                                fill="none" stroke="currentColor"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="32"/>
                                                    </svg>
                                                </div>
                                                <div class="p-0 m-0 h6"
                                                     style="width: inherit; color: var(--nav-text-color);">
                                                    Menu
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!(thirdPartyWrapped$ | async)">
                                        <ng-container
                                                *ngIf="!(currentGroupOrder && currentGroupOrder?.role === 'member')">
                                            <ng-container *ngIf="vendorSetup.user_provider !== 'none'">
                                                <div class="container-fluid p-0 m-0">
                                                    <div tabindex="0"
                                                         class="ham-menu-profile-link row m-0 px-3 py-2 d-flex align-items-center flex-nowrap"
                                                         style="cursor:pointer"
                                                         (click)="navigateTo('/profile')"
                                                         (keydown.enter)="navigateTo('/profile')">
                                                        <div class="p-0 pe-2 d-flex align-items-center justify-content-start"
                                                             style="width: inherit;">
                                                            <i style="font-size:24px; color: var(--nav-icon-color);"
                                                               class="bi bi-person"></i>
                                                        </div>
                                                        <ng-container *ngIf="textFields$ | async as textField">
                                                            <div class="p-0 m-0 h6"
                                                                 style="width: inherit; color: var(--nav-text-color);">
                                                                {{textField.user_profile_menu_link_title}}
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="vendorSetup.location_provider !== 'none'">
                                        <ng-container
                                                *ngIf="!(currentGroupOrder && currentGroupOrder?.role === 'member')">
                                            <div class="container-fluid p-0 m-0">
                                                <div tabindex="0"
                                                     class="ham-menu-loc-link row m-0 px-3 py-2 d-flex align-items-center flex-nowrap"
                                                     style="cursor:pointer"
                                                     (click)="navigateTo('/locations')"
                                                     (keydown.enter)="navigateTo('/locations')">
                                                    <div class="p-0 pe-2 d-flex align-items-center justify-content-start"
                                                         style="width: inherit;">
                                                        <i style="font-size:24px; color: var(--nav-icon-color);"
                                                           class="bi bi-geo"></i>
                                                    </div>
                                                    <div class="p-0 m-0 h6"
                                                         style="width: inherit; color: var(--nav-text-color);">
                                                        Locations
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngFor="let page of (customPages$ | async)">
                                        <ng-container *ngIf="user$ | async as user">
                                            <div class="container-fluid p-0 m-0"
                                                 *ngIf="page.show_on_side_menu && !page.hide_under_more && (!page.require_login || (page.require_login && !user?.isGuest))">
                                                <div tabindex="0"
                                                     class="row m-0 px-3 py-2 d-flex align-items-center flex-nowrap"
                                                     style="cursor:pointer"
                                                     (click)="pageClicked(page)"
                                                     (keydown.enter)="pageClicked(page)">
                                                    <div class="p-0 pe-2 d-flex align-items-center justify-content-start"
                                                         style="width: inherit;">
                                                        <i style="font-size:24px; color: var(--nav-icon-color);"
                                                           class="bi" [ngClass]="'bi-' + page.icon"></i>
                                                    </div>
                                                    <div class="p-0 m-0 h6"
                                                         style="width: inherit; color: var(--nav-text-color);">{{page.title}}</div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>

                                    <div class="py-2"></div>

                                    <div class="ps-3 p-0" *ngIf="this.atLeastOneMore">
                                        <hr class="m-0" style="border-top: 2px solid var(--de-border-color);"/>
                                    </div>

                                    <div class="py-2" *ngIf="this.atLeastOneMore"></div>

                                    <div class="container-fluid p-0 m-0" *ngIf="this.atLeastOneMore">
                                        <div tabindex="0"
                                             class="row m-0 px-3 py-2 d-flex align-items-center flex-nowrap"
                                             style="cursor:pointer"
                                             (click)="showingMore = !showingMore"
                                             (keydown.enter)="showingMore = !showingMore">
                                            <div class="p-0 pe-2 m-0 h6 fw-bold"
                                                 style="width: inherit; color: var(--nav-text-color);">More
                                            </div>
                                            <div class="p-0 d-flex align-items-center justify-content-start"
                                                 style="width: inherit;">
                                                <i style="font-size:16px; color: var(--nav-icon-color);" class="bi"
                                                   [ngClass]="showingMore ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <ng-container *ngFor="let page of (customPages$ | async)">
                                        <ng-container *ngIf="user$ | async as user">
                                            <div class="container-fluid p-0 m-0"
                                                 *ngIf="showingMore && page.show_on_side_menu && page.hide_under_more && (!page.require_login || (page.require_login && !user?.isGuest))">
                                                <div tabindex="0"
                                                     class="row m-0 px-3 py-2 d-flex align-items-center flex-nowrap"
                                                     style="cursor:pointer"
                                                     (click)="pageClicked(page)"
                                                     (keydown.enter)="pageClicked(page)">
                                                    <div class="p-0 pe-2 d-flex align-items-center justify-content-start"
                                                         style="width: inherit;">
                                                        <i style="font-size:24px; color: var(--nav-icon-color);"
                                                           class="bi" [ngClass]="'bi-' + page.icon"></i>
                                                    </div>
                                                    <div class="p-0 m-0 h6"
                                                         style="width: inherit; color: var(--nav-text-color);">{{page.title}}</div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>

                                    <div class="pb-3"></div>

                                </div>

                                <div class="flex-container d-flex align-items-center justify-content-start"
                                     style="place-items:center">
                                    <ng-container *ngIf="(mainSettings$ | async) as mainSettings">
                  <span class="ms-3" style="width:40px; height:40px; display: inline-grid; place-items: center;
                  border: 1px solid var(--de-border-color); border-radius: var(--cms-btn-radius); cursor: pointer;" tabindex="0"
                        *ngIf="mainSettings.facebook_link"
                        (click)="linkButtonClicked(mainSettings.facebook_link)"
                        (keydown.enter)="linkButtonClicked(mainSettings.facebook_link)">
                    <i class="bi bi-facebook d-flex align-items-center justify-content-center" style="font-size:24px;"
                       [ngStyle]="{'color':(app$ | async).navbarSettings.promotional_button_color}"></i>
                  </span>
                                        <span class="ms-2" style="width:40px; height:40px; display: inline-grid; place-items: center;
                  border: 1px solid var(--de-border-color); border-radius: var(--cms-btn-radius); cursor: pointer;" tabindex="0"
                                              *ngIf="mainSettings.twitter_link"
                                              (click)="linkButtonClicked(mainSettings.twitter_link)"
                                              (keydown.enter)="linkButtonClicked(mainSettings.twitter_link)">
                    <i class="bi bi-twitter d-flex align-items-center justify-content-center" style="font-size:24px;"
                       [ngStyle]="{'color':(app$ | async).navbarSettings.promotional_button_color}"></i>
                  </span>
                                        <span class="ms-2" style="width:40px; height:40px; display: inline-grid; place-items: center;
                  border: 1px solid var(--de-border-color); border-radius: var(--cms-btn-radius); cursor: pointer;" tabindex="0"
                                              *ngIf="mainSettings.instagram_link"
                                              (click)="linkButtonClicked(mainSettings.instagram_link)"
                                              (keydown.enter)="linkButtonClicked(mainSettings.instagram_link)">
                    <i class="bi bi-instagram d-flex align-items-center justify-content-center" style="font-size:24px;"
                       [ngStyle]="{'color':(app$ | async).navbarSettings.promotional_button_color}"></i>
                  </span>
                                        <span class="ms-2" style="width:40px; height:40px; display: inline-grid; place-items: center;
                  border: 1px solid var(--de-border-color); border-radius: var(--cms-btn-radius); cursor: pointer;" tabindex="0"
                                              *ngIf="mainSettings.youtube_link as link"
                                              (click)="linkButtonClicked(mainSettings.youtube_link)"
                                              (keydown.enter)="linkButtonClicked(mainSettings.youtube_link)">
                    <i class="bi bi-youtube d-flex align-items-center justify-content-center" style="font-size:24px;"
                       [ngStyle]="{'color':(app$ | async).navbarSettings.promotional_button_color}"></i>
                  </span>
                                    </ng-container>
                                </div>
                                <div class="side-menu-footer pb-4" style="display:grid;">
                                    <div class="powered-by">
                                            <span
                                                    [ngStyle]="{'color':(app$ | async).navbarSettings?.text_color}">Version: {{ version }}</span>
                                        <a
                                                [href]="app.mainSettings?.powered_by_link ? app.mainSettings.powered_by_link : 'https://dineengine.io/'"
                                                [ngStyle]="{'color':(app$ | async).navbarSettings?.text_color}">
                                            Powered By
                                            <img
                                                    [src]="app.mainSettings?.powered_by_logo ? app.mainSettings.powered_by_logo.data.full_url : '/assets/icon/favicon.svg'"
                                                    [alt]="app.mainSettings?.powered_by_name ? app.mainSettings.powered_by_name : 'DineEngine'"/>
                                        </a>
                                    </div>
                                    <div class="powered-by">
                                <span tabindex="0" class="bug-report-link" (click)="openFeedbackForm()" (keydown.enter)="openFeedbackForm()"
                                      [ngStyle]="{'color':(app$ | async).navbarSettings?.emphasized_text_color}">Report a Bug</span>
                                    </div>
                                </div>
                            </div>
                        </ion-content>
                    </ion-menu>
                </ng-template>
                <div id="root" style="display: grid; max-height: 100vh; max-width: 100vw;" class="w-100 h-100"
                     [style.grid-template-rows]="app.mainSettings.mobile_app_layout === 'improved' && capacitor.getPlatform() !== 'web' ? 'auto 1fr auto' : 'auto 1fr'">
                    <div>
                        <ng-container *ngIf="!mobile.isMobile && modeService.mode !== 'tableside'">
                            <ng-container *ngIf="modeService.mode !== 'kiosk'">
                                <app-announcement-bar
                                        class="w-100"
                                        style="position: sticky; top: 0; z-index: 1000;"
                                ></app-announcement-bar>
                            </ng-container>
                            <app-navbar
                                    [order]="order$ | async"
                                    [user]="user$ | async"
                                    (addNewOrderType)="setNewOrderType()"
                                    (startNewOrder)="startOrder($event)"
                                    (startPickupOrder)="startPickupOrder()"
                                    (startCurbsideOrder)="startCurbsideOrder()"
                                    (startDriveThruOrder)="startDriveThruOrder()"
                                    (startDineInOrder)="startDineInOrder()"
                                    (startDeliveryOrder)="startDeliveryOrder()"
                                    [deliveryToggleLoading]="deliveryToggleLoading"
                                    [doneLoading]="doneLoading"
                            ></app-navbar>
                        </ng-container>
                        <ng-container
                                *ngIf="!(mobile.isMobile && (util.win?.location.pathname === '/' || util.win?.location.pathname === '' || util.win?.location.pathname === null) && noBrochure && modeService.mode !== 'tableside' && modeService.mode !== 'kiosk')">
                            <app-header-bar
                                    [title]="(app$ | async).title"
                                    [routeBack]="(app$ | async).backRoute"
                                    [order]="(order$ | async)"
                                    [inboxMessages]="inboxMessages$ | async"
                                    [user]="(user$ | async)"
                                    (addNewOrderType)="setNewOrderType()"
                                    (startNewOrder)="startOrder($event)">
                            </app-header-bar>
                            <ng-container *ngIf="modeService.mode !== 'kiosk'">
                                <app-announcement-bar
                                        class="w-100 d-block d-lg-none"
                                        style="position: sticky; top: 0; z-index: 1000;"
                                >
                                </app-announcement-bar>
                            </ng-container>
                        </ng-container>
                    </div>
                    <main class="app-main h-100 overflow-auto" id="main-content"
                          [class.no-nav-padding]="modeService.mode === 'tableside' || modeService.mode === 'kiosk'">
                        <router-outlet class="router-outlet"></router-outlet>
                    </main>
                    <ng-container
                            *ngIf="app.mainSettings.mobile_app_layout === 'improved' && capacitor.getPlatform() !== 'web' && !(util.win?.location.pathname === '/' || util.win?.location.pathname === '' || util.win?.location.pathname === null || util.win?.location.pathname.includes('/login') || util.win?.location.pathname.includes('/create-account'))">
                        <app-tab-bar *ngIf="!keyboardShowing"></app-tab-bar>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
<!--</ng-container>-->

<ng-template #offlinePage>
    <div class="d-flex flex-column h-100 mx-5 text-center justify-content-center align-items-center">
        <h1>⚠</h1>
        <h2>No connection to the internet</h2>
        <p class="desc">Please connect your device to the internet to use this application</p>
    </div>
</ng-template>

<ng-template #unsupportedBrowserPage>
    <div class="d-flex flex-column h-100 mx-5 text-center justify-content-center align-items-center">
        <h1>⚠</h1>
        <h2>Unsupported Browser</h2>
        <p class="desc">Your current browser version does not support features that are required by this application.
            Please update your browser to continue.</p>
        <a class="btn btn-primary" href="https://www.wikihow.com/Update-Your-Browser" target="_blank">Learn How</a>
    </div>
</ng-template>


<ng-template #constructionPage>
    <ng-container *ngIf="(app$ | async) as app">
        <div class="construction-container">
            <img id="logo" class="construction-logo" alt="logo" *ngIf="app.branding?.main_logo"
                 [src]="app.branding?.main_logo.data.full_url">
        </div>
    </ng-container>
</ng-template>
